import React from "react";
import { Descriptions as DescriptionAnt, Tooltip } from "antd";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";

function Descriptions(props) {
  const job = props.data;
  const client = props.client;

  const selectedClient = client?.results?.filter((item) => {
    return props?.selectedJob?.client === item.id;
  });

  return (
    <DescriptionAnt size={"small"} bordered={false} column={2} /*span={2}*/>
      {Object.entries(job).map(([key, value]) => {
        let isDivisor = value?.title == "div";
        let isTitle = value?.title == "title";
        if (typeof value?.title !== "object") {
          return (
            <DescriptionAnt.Item
              span={isDivisor || isTitle ? 2 : 1}
              style={{
                visibility: isDivisor ? "hidden" : "",
                paddingTop: isTitle ? "10px" : "",
              }}
              contentStyle={{ display: "inline" }}
              label={key}
              labelStyle={{
                fontWeight: "bold",
                fontSize: isTitle ? "16px" : "14px",
                color: props.darkMode ? "white" : "black",
              }}
            >
              {isTitle ? "" : value?.title}
              {key === "Cliente" || key === "Client" ? (
                <InfoCircleOutlined
                  style={{
                    color: "blue",
                    top: "4px",
                    position: "relative",
                    marginLeft: "3px",
                    float: "right",
                    right: "20px",
                  }}
                  onClick={() => {
                    props.setModalClientInfoVisible(true);
                    let clientDescription = {
                      [props?.content?.add.form.first_name.title]: {
                        title: selectedClient[0]?.first_name,
                      },
                      [props?.content?.add.form.last_name.title]: {
                        title: selectedClient[0]?.last_name,
                      },
                      [props.content.add.form.company_name.title]: {
                        title: selectedClient[0]?.company_name,
                      },
                      [props?.content?.add.form.balance.title]: {
                        title: selectedClient[0]?.balance,
                      },
                      [props?.content?.add.form.cell_phone.title]: {
                        title: selectedClient[0]?.cell_phone,
                      },
                      Email: {
                        title: selectedClient[0]?.email,
                      },
                    };
                    props.onClickClientInfo(clientDescription);
                  }}
                />
              ) : (
                value?.tooltip && (
                  <Tooltip title={value.tooltip}>
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                        top: "4px",
                        position: "relative",
                        marginLeft: "3px",
                        float: "right",
                        right: "20px",
                      }}
                    />
                  </Tooltip>
                )
              )}
            </DescriptionAnt.Item>
          );
        }
      })}
    </DescriptionAnt>
  );
}

export default Descriptions;
