export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

//generic user actions
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

//RECOVERY PASSWORD
export const USER_REQUEST_RECOVERY_PASSWORD_REQUEST =
  "USER_REQUEST_RECOVERY_PASSWORD_REQUEST";
export const USER_REQUEST_RECOVERY_PASSWORD_SUCCESS =
  "USER_REQUEST_RECOVERY_PASSWORD_SUCCESS";
export const USER_REQUEST_RECOVERY_PASSWORD_FAIL =
  "USER_REQUEST_RECOVERY_PASSWORD_FAIL";
export const USER_REQUEST_RECOVERY_PASSWORD_RESET =
  "USER_REQUEST_RECOVERY_PASSWORD_RESET";

export const USER_UPDATE_PASSWORD_REQUEST = "USER_UPDATE_PASSWORD_REQUEST";
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS";
export const USER_UPDATE_PASSWORD_FAIL = "USER_UPDATE_PASSWORD_FAIL";
export const USER_UPDATE_PASSWORD_RESET = "USER_UPDATE_PASSWORD_RESET";

//---

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USERS_LIST_REQUEST = "USERS_LIST_REQUEST";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_FAIL = "USERS_LIST_FAIL";
export const USERS_LIST_RESET = "USERS_LIST_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_RESET = "USER_DELETE_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_UPDATE_PROFILE_IMAGE_REQUEST =
  "USER_UPDATE_PROFILE_IMAGE_REQUEST";
export const USER_UPDATE_PROFILE_IMAGE_SUCCESS =
  "USER_UPDATE_PROFILE_IMAGE_SUCCESS";
export const USER_UPDATE_PROFILE_IMAGE_FAIL = "USER_UPDATE_PROFILE_IMAGE_FAIL";
export const USER_UPDATE_PROFILE_IMAGE_RESET =
  "USER_UPDATE_PROFILE_IMAGE_RESET";

//clients

export const CLIENT_REGISTER_REQUEST = "CLIENT_REGISTER_REQUEST";
export const CLIENT_REGISTER_SUCCESS = "CLIENT_REGISTER_SUCCESS";
export const CLIENT_REGISTER_FAIL = "CLIENT_REGISTER_FAIL";
export const CLIENT_REGISTER_RESET = "CLIENT_REGISTER_RESET";

export const CLIENT_DETAILS_REQUEST = "CLIENT_DETAILS_REQUEST";
export const CLIENT_DETAILS_SUCCESS = "CLIENT_DETAILS_SUCCESS";
export const CLIENT_DETAILS_FAIL = "CLIENT_DETAILS_FAIL";
export const CLIENT_DETAILS_RESET = "CLIENT_DETAILS_RESET";

export const CLIENT_UPDATE_REQUEST = "CLIENT_UPDATE_REQUEST";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
export const CLIENT_UPDATE_FAIL = "CLIENT_UPDATE_FAIL";
export const CLIENT_UPDATE_RESET = "CLIENT_UPDATE_RESET";

export const CLIENTS_LIST_REQUEST = "CLIENTS_LIST_REQUEST";
export const CLIENTS_LIST_SUCCESS = "CLIENTS_LIST_SUCCESS";
export const CLIENTS_LIST_FAIL = "CLIENTS_LIST_FAIL";
export const CLIENTS_LIST_RESET = "CLIENTS_LIST_RESET";

export const CLIENT_DELETE_REQUEST = "CLIENT_DELETE_REQUEST";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_DELETE_FAIL = "CLIENT_DELETE_FAIL";
export const CLIENT_DELETE_RESET = "CLIENT_DELETE_RESET";

export const ACTIVATE_CLIENT_REQUEST = "ACTIVATE_CLIENT_REQUEST";
export const ACTIVATE_CLIENT_SUCCESS = "ACTIVATE_CLIENT_SUCCESS";
export const ACTIVATE_CLIENT_FAIL = "ACTIVATE_CLIENT_FAIL";
export const ACTIVATE_CLIENT_RESET = "ACTIVATE_CLIENT_RESET";

//Workers

export const WORKER_REGISTER_REQUEST = "WORKER_REGISTER_REQUEST";
export const WORKER_REGISTER_SUCCESS = "WORKER_REGISTER_SUCCESS";
export const WORKER_REGISTER_FAIL = "WORKER_REGISTER_FAIL";
export const WORKER_REGISTER_RESET = "WORKER_REGISTER_RESET";

export const WORKER_DETAILS_REQUEST = "WORKER_DETAILS_REQUEST";
export const WORKER_DETAILS_SUCCESS = "WORKER_DETAILS_SUCCESS";
export const WORKER_DETAILS_FAIL = "WORKER_DETAILS_FAIL";
export const WORKER_DETAILS_RESET = "WORKER_DETAILS_RESET";

export const WORKER_UPDATE_REQUEST = "WORKER_UPDATE_REQUEST";
export const WORKER_UPDATE_SUCCESS = "WORKER_UPDATE_SUCCESS";
export const WORKER_UPDATE_FAIL = "WORKER_UPDATE_FAIL";
export const WORKER_UPDATE_RESET = "WORKER_UPDATE_RESET";

export const WORKERS_LIST_REQUEST = "WORKERS_LIST_REQUEST";
export const WORKERS_LIST_SUCCESS = "WORKERS_LIST_SUCCESS";
export const WORKERS_LIST_FAIL = "WORKERS_LIST_FAIL";
export const WORKERS_LIST_RESET = "WORKERS_LIST_RESET";

export const WORKER_DELETE_REQUEST = "WORKER_DELETE_REQUEST";
export const WORKER_DELETE_SUCCESS = "WORKER_DELETE_SUCCESS";
export const WORKER_DELETE_FAIL = "WORKER_DELETE_FAIL";
export const WORKER_DELETE_RESET = "WORKER_DELETE_RESET";

//admin

export const ADMIN_REGISTER_REQUEST = "ADMIN_REGISTER_REQUEST";
export const ADMIN_REGISTER_SUCCESS = "ADMIN_REGISTER_SUCCESS";
export const ADMIN_REGISTER_FAIL = "ADMIN_REGISTER_FAIL";
export const ADMIN_REGISTER_RESET = "ADMIN_REGISTER_RESET";

export const ADMIN_DETAILS_REQUEST = "ADMIN_DETAILS_REQUEST";
export const ADMIN_DETAILS_SUCCESS = "ADMIN_DETAILS_SUCCESS";
export const ADMIN_DETAILS_FAIL = "ADMIN_DETAILS_FAIL";
export const ADMIN_DETAILS_RESET = "ADMIN_DETAILS_RESET";

export const ADMIN_UPDATE_REQUEST = "ADMIN_UPDATE_REQUEST";
export const ADMIN_UPDATE_SUCCESS = "ADMIN_UPDATE_SUCCESS";
export const ADMIN_UPDATE_FAIL = "ADMIN_UPDATE_FAIL";
export const ADMIN_UPDATE_RESET = "ADMIN_UPDATE_RESET";

export const ADMINS_LIST_REQUEST = "ADMINS_LIST_REQUEST";
export const ADMINS_LIST_SUCCESS = "ADMINS_LIST_SUCCESS";
export const ADMINS_LIST_FAIL = "ADMINS_LIST_FAIL";
export const ADMINS_LIST_RESET = "ADMINS_LIST_RESET";

export const ADMIN_DELETE_REQUEST = "ADMIN_DELETE_REQUEST";
export const ADMIN_DELETE_SUCCESS = "ADMIN_DELETE_SUCCESS";
export const ADMIN_DELETE_FAIL = "ADMIN_DELETE_FAIL";
export const ADMIN_DELETE_RESET = "ADMIN_DELETE_RESET";
