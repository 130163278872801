import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  usersListReducer,
  activateClientReducer,
  userDeleteReducer,
  userUpdatePictureReducer,
  userUpdateReducer,
  userRequestRecoveryPasswordReducer,
  userUpdatePasswordReducer,
} from "./reducers/userReducers";

import {
  getCountriesReducer,
  getStatesReducer,
  getCitiesReducer,
} from "./reducers/locationReducers";

import {
  registerEcuBrandReducer,
  updateEcuBrandReducer,
  getEcuBrandDetailsReducer,
  getEcusBrandReducer,
  deleteEcuBrandReducer,
} from "./reducers/ecuReducers";

import {
  getEcusModelReducer,
  getEcuModelDetailsReducer,
  updateEcuModelReducer,
  registerEcuModelReducer,
  deleteEcuModelReducer,
} from "./reducers/ecuReducers";

import {
  getServiceDetailsReducer,
  getServicesReducer,
  updateServiceReducer,
  registerServiceReducer,
  deleteServiceReducer,
} from "./reducers/serviceReducers";

import {
  getProductDetailsReducer,
  getProductsReducer,
  updateProductReducer,
  registerProductReducer,
  deleteProductReducer,
} from "./reducers/productReducers";

import {
  registerSerialNumberReducer,
  getSerialNumbersReducer,
  deleteSerialNumberReducer,
  getSerialNumberDetailsReducer,
  updateSerialNumberReducer,
} from "./reducers/productReducers";

import {
  getVehicleBrandsReducer,
  getVehicleBrandDetailsReducer,
  updateVehicleBrandReducer,
  registerVehicleBrandReducer,
  deleteVehicleBrandReducer,
} from "./reducers/vehicleReducers";
import {
  getVehicleModelsReducer,
  getVehicleModelDetailsReducer,
  updateVehicleModelReducer,
  registerVehicleModelReducer,
  deleteVehicleModelReducer,
} from "./reducers/vehicleReducers";
import {
  getVehicleMotorsReducer,
  getVehicleMotorDetailsReducer,
  updateVehicleMotorReducer,
  registerVehicleMotorReducer,
  deleteVehicleMotorReducer,
} from "./reducers/vehicleReducers";
import {
  getVehicleTypesReducer,
  getVehicleTypeDetailsReducer,
  updateVehicleTypeReducer,
  registerVehicleTypeReducer,
  deleteVehicleTypeReducer,
} from "./reducers/vehicleReducers";

import {
  getClientsReducer,
  getClientDetailsReducer,
  updateClientReducer,
  registerClientReducer,
  deleteClientReducer,
} from "./reducers/userReducers";

import {
  getAdminsReducer,
  getAdminDetailsReducer,
  updateAdminReducer,
  registerAdminReducer,
  deleteAdminReducer,
} from "./reducers/userReducers";

import {
  getWorkersReducer,
  getWorkerDetailsReducer,
  updateWorkerReducer,
  registerWorkerReducer,
  deleteWorkerReducer,
} from "./reducers/userReducers";

import {
  getAccountMovementsReducer,
  getAccountMovementDetailsReducer,
  deleteAccountMovementReducer,
  updateAccountMovementReducer,
  registerAccountMovementReducer,
} from "./reducers/accountMovementReducers";

import {
  getAddressesReducer,
  deleteAddressReducer,
  getAddressDetailsReducer,
  updateAddressReducer,
  registerAddressReducer,
} from "../redux/reducers/addressReducers";

import {
  getJobsReducer,
  getJobDetailsReducer,
  deleteJobReducer,
  updateJobReducer,
  registerJobReducer,
  updateJobFinish,
  updateJobPending,
  updateJobInProccess,
  updateJobPendingReducer,
  updateJobInProccessReducer,
  updateJobFinishReducer,
  updateJobSendReducer,
  updateJobPauseReducer,
} from "../redux/reducers/jobReducers";
import {
  deleteFile,
  getFileDetails,
  getFiles,
  registerFile,
  updateFile,
} from "./actions/fileActions";
import {
  deleteFileReducer,
  getFileDetailsReducer,
  getFilesReducer,
  registerFileReducer,
  updateFileReducer,
  updateVisibleFileReducer,
} from "./reducers/fileReducers";
import {
  deleteCurrencyReducer,
  getCurrencyDetailsReducer,
  getCurrencysReducer,
  registerCurrencyReducer,
  updateCurrencyReducer,
} from "./reducers/currencyReducers";

const reducer = combineReducers({
  //USER
  userLogin: userLoginReducer,
  activateClient: activateClientReducer,
  userUpdateProfile: userUpdateProfileReducer,
  usersList: usersListReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdate: userUpdateReducer,
  userDelete: userDeleteReducer,
  userUpdatePicture: userUpdatePictureReducer,
  userRequestRecoveryPassword: userRequestRecoveryPasswordReducer,
  userResetPassword: userUpdatePasswordReducer,
  //CLIENTS
  clientList: getClientsReducer,
  clientDetails: getClientDetailsReducer,
  clientUpdate: updateClientReducer,
  clientCreate: registerClientReducer,
  clientDelete: deleteClientReducer,
  //WORKES
  workerList: getWorkersReducer,
  workerDetails: getWorkerDetailsReducer,
  workerUpdate: updateWorkerReducer,
  workerCreate: registerWorkerReducer,
  workerDelete: deleteWorkerReducer,
  //admin
  adminList: getAdminsReducer,
  adminDetails: getAdminDetailsReducer,
  adminUpdate: updateAdminReducer,
  adminCreate: registerAdminReducer,
  adminDelete: deleteAdminReducer,
  //ACCOUNT MOVEMENT
  accountMovementList: getAccountMovementsReducer,
  accountMovementDetaills: getAccountMovementDetailsReducer,
  accountMovementUpdate: updateAccountMovementReducer,
  accountMovementCreate: registerAccountMovementReducer,
  accountMovementDelete: deleteAccountMovementReducer,
  //LOCATION
  countries: getCountriesReducer,
  states: getStatesReducer,
  cities: getCitiesReducer,
  //ADDRESS
  addressList: getAddressesReducer,
  addressDetails: getAddressDetailsReducer,
  addressUpdate: updateAddressReducer,
  addressCreate: registerAddressReducer,
  addressDelete: deleteAddressReducer,
  //SERVICES
  servicesList: getServicesReducer,
  serviceDetails: getServiceDetailsReducer,
  serviceUpdate: updateServiceReducer,
  serviceCreate: registerServiceReducer,
  serviceDelete: deleteServiceReducer,
  //CURRENCY
  currencyList: getCurrencysReducer,
  currencyDetails: getCurrencyDetailsReducer,
  currencyUpdate: updateCurrencyReducer,
  currencyCreate: registerCurrencyReducer,
  currencyDelete: deleteCurrencyReducer,
  //ECUS BRAND
  EcusBrandList: getEcusBrandReducer,
  EcuBrandDetails: getEcuBrandDetailsReducer,
  EcuBrandUpdate: updateEcuBrandReducer,
  EcuBrandCreate: registerEcuBrandReducer,
  EcuBrandDelete: deleteEcuBrandReducer,
  //ECUS MODEL
  EcusModelList: getEcusModelReducer,
  EcuModelDetails: getEcuModelDetailsReducer,
  EcuModelUpdate: updateEcuModelReducer,
  EcuModelCreate: registerEcuModelReducer,
  EcuModelDelete: deleteEcuModelReducer,
  //JOBS
  jobsList: getJobsReducer,
  jobDetails: getJobDetailsReducer,
  jobUpdate: updateJobReducer,
  jobCreate: registerJobReducer,
  jobDelete: deleteJobReducer,
  //JOBS STATUS UPDATE
  jobUpdatePending: updateJobPendingReducer,
  jobUpdateInProccess: updateJobInProccessReducer,
  jobUpdateFinished: updateJobFinishReducer,
  jobUpdateSend: updateJobSendReducer,
  jobUpdatePause: updateJobPauseReducer,
  //Files
  filesList: getFilesReducer,
  fileDetails: getFileDetailsReducer,
  fileUpdate: updateFileReducer,
  fileCreate: registerFileReducer,
  fileDelete: deleteFileReducer,
  fileUpdateVisible: updateVisibleFileReducer,
  //PRODUCTS
  productsList: getProductsReducer,
  productDetails: getProductDetailsReducer,
  productUpdate: updateProductReducer,
  productCreate: registerProductReducer,
  productDelete: deleteProductReducer,
  //Serialnumber
  serialNumberList: getSerialNumbersReducer,
  serialNumberDetails: getSerialNumberDetailsReducer,
  serialNumberUpdate: updateSerialNumberReducer,
  serialNumberCreate: registerSerialNumberReducer,
  serialNumberDelete: deleteSerialNumberReducer,
  //VEHICLE BRAND
  vehicleBrandsList: getVehicleBrandsReducer,
  vehicleBrandDetails: getVehicleBrandDetailsReducer,
  vehicleBrandUpdate: updateVehicleBrandReducer,
  vehicleBrandCreate: registerVehicleBrandReducer,
  vehicleBrandDelete: deleteVehicleBrandReducer,
  //VEHICLE MODEL
  vehicleModelsList: getVehicleModelsReducer,
  vehicleModelDetails: getVehicleModelDetailsReducer,
  vehicleModelUpdate: updateVehicleModelReducer,
  vehicleModelCreate: registerVehicleModelReducer,
  vehicleModelDelete: deleteVehicleModelReducer,
  //VEHICLE MOTOR
  vehicleMotorsList: getVehicleMotorsReducer,
  vehicleMotorDetails: getVehicleMotorDetailsReducer,
  vehicleMotorUpdate: updateVehicleMotorReducer,
  vehicleMotorCreate: registerVehicleMotorReducer,
  vehicleMotorDelete: deleteVehicleMotorReducer,
  //VEHICLE MOTOR
  vehicleTypesList: getVehicleTypesReducer,
  vehicleTypeDetails: getVehicleTypeDetailsReducer,
  vehicleTypeUpdate: updateVehicleTypeReducer,
  vehicleTypeCreate: registerVehicleTypeReducer,
  vehicleTypeDelete: deleteVehicleTypeReducer,
});

let userInfoFromStorage = "";

try {
  userInfoFromStorage = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
} catch (e) {
  userInfoFromStorage = null;
}

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

export const apiURL =
  window.location.hostname == "localhost"
    ? "http://localhost:8000"
    //? "https://transyssapi.com"
    : "https://transyssapi.com";
    
export const wsApiURL =
  window.location.hostname == "localhost"
    ? "ws://localhost:8000"
    //? "wss://transyssapi.com"
    : "wss://transyssapi.com" //"wss://francofgp.pythonanywhere.com";
