import {
  FILE_REGISTER_REQUEST,
  FILE_REGISTER_SUCCESS,
  FILE_REGISTER_FAIL,
  FILE_REGISTER_RESET,
  FILE_DETAILS_REQUEST,
  FILE_DETAILS_SUCCESS,
  FILE_DETAILS_FAIL,
  FILE_DETAILS_RESET,
  FILE_UPDATE_REQUEST,
  FILE_UPDATE_SUCCESS,
  FILE_UPDATE_FAIL,
  FILE_UPDATE_RESET,
  FILES_LIST_REQUEST,
  FILES_LIST_SUCCESS,
  FILES_LIST_FAIL,
  FILES_LIST_RESET,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_FAIL,
  FILE_DELETE_RESET,
  FILE_UPDATE_VISIBLE_REQUEST,
  FILE_UPDATE_VISIBLE_SUCCESS,
  FILE_UPDATE_VISIBLE_FAIL,
  FILE_UPDATE_VISIBLE_RESET,
} from "../constants/fileConstants";

export const getFilesReducer = (state = {}, action) => {
  switch (action.type) {
    case FILES_LIST_REQUEST:
      return { loading: true };
    case FILES_LIST_SUCCESS:
      return { loading: false, filesList: action.payload };

    case FILES_LIST_FAIL:
      return { loading: false, error: action.payload };

    case FILES_LIST_RESET:
      return {};

    default:
      return state;
  }
};

export const getFileDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case FILE_DETAILS_REQUEST:
      return { loading: true };
    case FILE_DETAILS_SUCCESS:
      return { loading: false, file: action.payload };

    case FILE_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case FILE_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteFileReducer = (state = {}, action) => {
  switch (action.type) {
    case FILE_DELETE_REQUEST:
      return { loading: true };
    case FILE_DELETE_SUCCESS:
      return { loading: false, success: true, file: 1 }; //action.payload };

    case FILE_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case FILE_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

export const updateFileReducer = (state = {}, action) => {
  switch (action.type) {
    case FILE_UPDATE_REQUEST:
      return { loading: true };
    case FILE_UPDATE_SUCCESS:
      return { loading: false, success: true, file: action.payload };

    case FILE_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case FILE_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const registerFileReducer = (state = {}, action) => {
  switch (action.type) {
    case FILE_REGISTER_REQUEST:
      return { loading: true };
    case FILE_REGISTER_SUCCESS:
      return { loading: false, success: true, file: action.payload };

    case FILE_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case FILE_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};

export const updateVisibleFileReducer = (state = {}, action) => {
  switch (action.type) {
    case FILE_UPDATE_VISIBLE_REQUEST:
      return { loading: true };

    case FILE_UPDATE_VISIBLE_SUCCESS:
      return { loading: false, success: true, file: action.payload };

    case FILE_UPDATE_VISIBLE_FAIL:
      return { loading: false, error: action.payload };

    case FILE_UPDATE_VISIBLE_RESET:
      return {};

    default:
      return state;
  }
};
