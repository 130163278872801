import axios from "axios";
import {
  CURRENCY_DETAILS_FAIL,
  CURRENCY_DETAILS_REQUEST,
  CURRENCY_DETAILS_RESET,
  CURRENCY_DETAILS_SUCCESS,
  CURRENCY_DELETE_FAIL,
  CURRENCY_DELETE_REQUEST,
  CURRENCY_DELETE_RESET,
  CURRENCY_DELETE_SUCCESS,
  CURRENCY_REGISTER_FAIL,
  CURRENCY_REGISTER_REQUEST,
  CURRENCY_REGISTER_SUCCESS,
  CURRENCY_UPDATE_FAIL,
  CURRENCY_UPDATE_REQUEST,
  CURRENCY_UPDATE_RESET,
  CURRENCY_UPDATE_SUCCESS,
  CURRENCYS_LIST_FAIL,
  CURRENCYS_LIST_REQUEST,
  CURRENCYS_LIST_RESET,
  CURRENCYS_LIST_SUCCESS,
} from "../constants/currencyConstants";

import { apiURL } from "../store";

export const getCurrencyDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRENCY_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`${apiURL}/api/currency/${id}`, config);

    dispatch({
      type: CURRENCY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteCurrency = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRENCY_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(`${apiURL}/api/currency/${id}`, config);

    dispatch({
      type: CURRENCY_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getCurrencys = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRENCYS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/currency/?all=true`,
      config
    );

    dispatch({
      type: CURRENCYS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRENCYS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateCurrency = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRENCY_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/currency/${id}`,
      body,
      config
    );

    dispatch({
      type: CURRENCY_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerCurrency = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRENCY_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`${apiURL}/api/currency/`, body, config);

    dispatch({
      type: CURRENCY_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
