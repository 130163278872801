import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getProductDetails,
  getProducts,
  updateProduct,
  registerProduct,
  deleteProduct,
  deleteSerialNumber,
  registerSerialNumber,
  updateSerialNumber,
} from "../redux/actions/productActions";
import { getClientsList } from "../redux/actions/userActions";
import {
  Table,
  Input,
  Button,
  notification,
  Space,
  Modal,
  Form,
  Row,
  Col,
  DatePicker,
  Tag,
  Select,
  Popconfirm,
  Tooltip,
  InputNumber,
  Typography,
} from "antd";

import {
  QuestionCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import {
  PRODUCT_DELETE_RESET,
  PRODUCT_REGISTER_RESET,
  PRODUCT_UPDATE_RESET,
  SERIAL_NUMBER_DELETE_RESET,
  SERIAL_NUMBER_REGISTER_RESET,
  SERIAL_NUMBER_UPDATE_RESET,
} from "../redux/constants/productConstants";
import ExportTable from "../components/ExportTable";

const { Option } = Select;
const { Item } = Form;
const { Title } = Typography;

function ProductScreen(props) {
  const content = props.content;
  const userInfo = props.userInfo;
  const tableProps = props.tableProps;

  //--- Product
  const [searchText, setSearchText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [productsData, setProductsData] = useState({});
  const [isAddProduct, setIsAddProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [form] = Form.useForm();
  const [formAddStock] = Form.useForm();
  //-- Serials
  const [searchSerialText, setSearchSerialText] = useState("");
  const [stockToAdd, setStockToAdd] = useState(0);
  const [stock, setStock] = useState(0);
  const [isAddSerial, setIsAddSerial] = useState("");
  const [isModalSerialVisible, setIsModalSerialVisible] = useState(false);
  const [isModalEditSerialVisible, setIsModalEditSerialVisible] =
    useState(false);
  const [modalSerialTitle, setModalSerialTitle] = useState("");
  const [modalEditSerialTitle, setModalEditSerialTitle] = useState("");
  const [serialSelected, setSerialSelected] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [isModalStockOpen, setIsModalStockOpen] = useState(false);
  const [modalStockTitle, setModalStockTitle] = useState("Stock");

  const [formSerial] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { productsList, loading: loadingProducts } = useSelector(
    (state) => state.productsList
  );

  const {
    loading: loadingCreate,
    success: successCreate,
    error: errorCreate,
  } = useSelector((state) => state.productCreate);

  const {
    loading: loadingUpdate,
    success: successUpdate,
    error: errorUpdate,
  } = useSelector((state) => state.productUpdate);

  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = useSelector((state) => state.productDelete);

  const {
    loading: loadingSerialNumberCreate,
    success: successSerialNumberCreate,
    error: errorSerialNumberCreate,
  } = useSelector((state) => state.serialNumberCreate);

  const {
    loading: loadingSerialNumberUpdate,
    success: successSerialNumberUpdate,
    error: errorSerialNumberUpdate,
  } = useSelector((state) => state.serialNumberUpdate);

  const {
    loading: loadingSerialNumberDelete,
    success: successSerialNumberDelete,
    error: errorSerialNumberDelete,
  } = useSelector((state) => state.serialNumberDelete);

  const {
    userList,
    loading: loadingUsers,
    //success,
  } = useSelector((state) => state.usersList);

  const {
    clientsList,
    loading: loadingClients,
    success: successClients,
  } = useSelector((state) => state.clientList);

  useEffect(() => {
    if (userInfo && userInfo.role === 3) {
      navigate("/notFound");
    } else if (userInfo && userInfo.role !== 3) {
      resetState();
      dispatch(getProducts());
      dispatch(getClientsList());
    }
    if (userInfo === "no_logged") {
      navigate("/login");
    }
  }, [userInfo]);

  useEffect(() => {
    if (errorCreate) {
      notification.error({
        message: content?.notification?.create?.error?.title,
        description: content?.notification?.create?.error?.description,
        placement: "bottomRight",
      });
      // setIsModalVisible(false);
    }
    if (successCreate) {
      notification.success({
        message: content?.notification?.create?.success?.title,
        description: content?.notification?.create?.success?.description,
        placement: "bottomRight",
      });
      setIsModalVisible(false);
      dispatch(getProducts());
    }
    if (errorUpdate) {
      notification.error({
        message: content?.notification?.edited?.error?.title,
        description: content?.notification?.edited?.error?.description,
        placement: "bottomRight",
      });
      // setIsModalVisible(false);
    }
    if (successUpdate) {
      notification.success({
        message: content?.notification?.edited?.success?.title,
        description: content?.notification?.edited?.success?.description,
        placement: "bottomRight",
      });
      setIsModalVisible(false);
      dispatch(getProducts());
    }
    if (successDelete) {
      notification.success({
        message: content?.notification?.delete?.success?.title,
        description: content?.notification?.delete?.success?.description,
        placement: "bottomRight",
      });
      dispatch(getProducts());
      //setIsModalVisible(false);
    }
    if (errorDelete) {
      notification.error({
        message: content?.notification?.delete?.error?.title,
        description: content?.notification?.delete?.error?.description,
        placement: "bottomRight",
      });
      //setIsModalVisible(false);
    }
  }, [
    // dispatch,
    successCreate,
    successUpdate,
    successDelete,
    errorCreate,
    errorUpdate,
    successDelete,
    errorDelete,
  ]);

  useEffect(() => {
    if (errorSerialNumberCreate) {
      notification.error({
        message: content?.serialNumber?.notification?.create?.error?.title,
        description:
          content?.serialNumber?.notification?.create?.error?.description,
        placement: "bottomRight",
      });
      setIsModalEditSerialVisible(false);
    }
    if (successSerialNumberCreate) {
      notification.success({
        message: content?.serialNumber?.notification?.create?.success?.title,
        description:
          content?.serialNumber?.notification?.create?.success?.description,
        placement: "bottomRight",
      });
      setIsModalEditSerialVisible(false);
      dispatch(getProducts());
    }
    if (errorSerialNumberUpdate) {
      notification.error({
        message: content?.serialNumber?.notification?.edited?.error?.title,
        description:
          content?.serialNumber?.notification?.edited?.error?.description,
        placement: "bottomRight",
      });
      setIsModalEditSerialVisible(false);
    }
    if (successSerialNumberUpdate) {
      notification.success({
        message: content?.serialNumber?.notification?.edited?.success?.title,
        description:
          content?.serialNumber?.notification?.edited?.success?.description,
        placement: "bottomRight",
      });
      setIsModalEditSerialVisible(false);
      dispatch(getProducts());
    }
    if (successSerialNumberDelete) {
      notification.success({
        message: content?.serialNumber?.notification?.delete?.success?.title,
        description:
          content?.serialNumber?.notification?.delete?.success?.description,
        placement: "bottomRight",
      });
      dispatch(getProducts());
    }
    if (errorSerialNumberDelete) {
      notification.error({
        message: content?.serialNumber?.notification?.delete?.error?.title,
        description:
          content?.serialNumber?.notification?.delete?.error?.description,
        placement: "bottomRight",
      });
    }
  }, [
    // dispatch,
    successSerialNumberCreate,
    successSerialNumberUpdate,
    successSerialNumberDelete,
    errorSerialNumberCreate,
    errorSerialNumberUpdate,
    successSerialNumberDelete,
    errorSerialNumberDelete,
  ]);

  useEffect(() => {
    function getProductById(productId, product) {
      return product?.find((product) => product.id === productId);
    }
    if (selectedProduct && productsList) {
      let product = getProductById(selectedProduct?.id, productsList?.results);
      setSelectedProduct(product);
    }
  }, [productsList, selectedProduct]);

  const resetState = () => {
    dispatch({
      type: PRODUCT_DELETE_RESET,
    });
    dispatch({
      type: PRODUCT_REGISTER_RESET,
    });
    dispatch({
      type: PRODUCT_UPDATE_RESET,
    });
    dispatch({
      type: SERIAL_NUMBER_DELETE_RESET,
    });
    dispatch({
      type: SERIAL_NUMBER_REGISTER_RESET,
    });
    dispatch({
      type: SERIAL_NUMBER_UPDATE_RESET,
    });
  };

  const dateFormat = "YYYY/MM/DD";

  //-- search

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchSerialChange = (event) => {
    setSearchSerialText(event.target.value);
  };

  //-- when click in button
  //-- produts
  const handleEditClick = (record) => {
    form.resetFields();
    setIsAddProduct(false);
    setModalTitle(content.modal.edit);
    setIsModalVisible(true);
    record.expiration_date = record.expiration_date
      ? dayjs(record.expiration_date, dateFormat)
      : dayjs().format(dateFormat);
    setProductsData(record);
    form.setFieldsValue(record);
  };

  const handleAddClick = () => {
    form.resetFields();
    let record = {
      description: "",
      created_time: "",
      name: "",
      code: "",
      serial_number: "",
      expiration_date: "",
      client: null,
    };
    setIsAddProduct(true);
    setModalTitle(content.modal.add);
    setIsModalVisible(true);
    setProductsData(record);
    form.setFieldsValue(record);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  //-- serial

  const handleEditSerialClick = (record) => {
    let tempRecord = {
      ...record,
    };
    formSerial.resetFields();
    setIsAddSerial(false);
    setIsModalEditSerialVisible(true);
    setModalEditSerialTitle(content.serialNumber.modal.title.edit);
    tempRecord.expiration_date = dayjs(record.expiration_date, dateFormat);
    setSerialSelected(tempRecord);
    formSerial.setFieldsValue(tempRecord);
  };

  const handleAddSerialClick = () => {
    setIsAddSerial(true);
    setModalEditSerialTitle(content.serialNumber.modal.title.add);
    formSerial.resetFields();
    let record = {
      description: "",
      created_time: "",
      name: "",
      code: "",
      serial_number: "",
      expiration_date: "",
      cost: "",
      client: null,
    };
    formSerial.setFieldsValue(record);
  };

  const handleCancelModalSerial = () => {
    setIsModalSerialVisible(false);
    // form.resetFields();
  };

  //stock

  const handleAddStock = (e) => {
    let aux = {
      quantity: e.stock,
      product: selectedProduct.id,
    };
    if (e.stock > 0) {
      dispatch(registerSerialNumber(aux));
    }
    setIsModalStockOpen(false);
  };

  //-- dispatch
  //-- produts
  const handleDeleteProduct = (productId) => {
    resetState();
    dispatch(deleteProduct(productId));
  };

  const handleSubmit = (values) => {
    resetState();
    if (isAddProduct) {
      // values.serial_number = [values.serial_number];
      /*values.expiration_date = values.expiration_date
        .toISOString()
        .split("T")[0];*/
      dispatch(registerProduct(values));
      setIsModalVisible(false);
    } else {
      /* values.expiration_date = values.expiration_date
        .toISOString()
        .split("T")[0];*/
      dispatch(updateProduct(productsData.id, values));
    }
  };

  //-- serial

  const handleDeleteSerial = (serialId) => {
    resetState();
    dispatch(deleteSerialNumber(serialId));
  };

  const handleSerialSubmit = (values) => {
    resetState();
    if (isAddSerial) {
      values.product = selectedProduct.id;
      values.expiration_date = values.expiration_date
        .toISOString()
        .split("T")[0];
      dispatch(registerSerialNumber(values));
      setIsModalEditSerialVisible(false);
    } else {
      values.expiration_date = values.expiration_date
        .toISOString()
        .split("T")[0];
      dispatch(updateSerialNumber(selectedProduct.id, values));
    }
  };

  const columns = [
    {
      title: content?.add?.form?.code.title,
      defaultSortOrder: "descend",
      dataIndex: "code",
      key: "code",
      width: "50px",
      sorter: (a, b) => {
        if (a.code < b.code) {
          return -1;
        }
        if (a.code > b.code) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      title: content?.add?.form?.name.title,
      dataIndex: "name",
      key: "name",
      width: "140px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      render: (_, record) => (
        <Tooltip placement="topLeft" title={record.name}>
          {record.name}
        </Tooltip>
      ),
    },
    {
      title: content?.add?.form?.description.title,
      dataIndex: "description",
      key: "description",
      width: "300px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      title: content?.add?.form?.stock.title,
      dataIndex: "stock",
      key: "code",
      width: "50px",
      sorter: (a, b) => {
        if (a.stock < b.stock) {
          return -1;
        }
        if (a.stock > b.stock) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      //title: "Actions",
      width: "50px",
      key: "action",
      render: (text, record) => (
        <Space size={18}>
          <Tooltip placement="top" title={content.tooltips.edit}>
            <EditOutlined onClick={() => handleEditClick(record)} />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.delete}>
            <Popconfirm
              title={content?.confirmation?.delete?.title}
              description={content?.confirmation?.delete?.description}
              onConfirm={() => handleDeleteProduct(record.id)}
              cancelText={content?.confirmation?.delete?.button.cancel}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.serial}>
            <UnorderedListOutlined
              onClick={(e) => {
                setModalSerialTitle(
                  record.code +
                    " - " +
                    record.name +
                    " - " +
                    content.serialNumber.serial_x_client
                );
                setIsModalSerialVisible(true);
                setSelectedProduct(record);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.add}>
            <PlusCircleOutlined
              onClick={(e) => {
                formAddStock.setFieldsValue({ stock: 0 });
                setStockToAdd(record.stock);
                setStock(record?.stock ?? 0);
                setIsModalStockOpen(true);
                setSelectedProduct(record);
                setModalStockTitle(
                  record.code + " - " + content.stock.modal.title
                );
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  function getUserById(users, userId) {
    return users?.filter((user) => user.id === userId);
  }

  const columnsSerial = [
    {
      title: content?.serialNumber?.form?.client.title,
      dataIndex: "client",
      width: "200px",
      sorter: (a, b) => {
        if (a.client < b.client) {
          return -1;
        }
        if (a.client > b.client) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        let client = text
          ? getUserById(clientsList?.results ?? [], parseInt(text))[0]
          : "";
        return props.renderTooltipInEllipsis(
          client ? client?.first_name + " " + client?.last_name : "-"
        ); //;
      },
    },
    {
      title: content?.serialNumber?.form?.serial.title,
      dataIndex: "serial_number",
      key: "code",
      width: "200px",
      sorter: (a, b) => {
        if (a.code < b.code) {
          return -1;
        }
        if (a.code > b.code) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text ?? "-"); //;
      },
    },
    {
      title: content?.serialNumber?.form?.expiration_date.title,
      dataIndex: "expiration_date",
      width: "200px",
      sorter: (a, b) => {
        if (a.expiration_date < b.expiration_date) {
          return -1;
        }
        if (a.expiration_date > b.expiration_date) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text ?? "-"); //;
      },
    },
    {
      title: content?.serialNumber?.form?.tag.title,
      key: "tags",
      width: "100px",
      dataIndex: "tags",
      //render: (_, { expiration_date }) => {
      render: (text, record) => {
        if (!record.expiration_date) return "";
        record.tags =
          new Date().getTime() > new Date(record.expiration_date).getTime()
            ? content.tag["expired"]
            : content.tag["valid"];
        return (
          <>
            {new Date().getTime() >
            new Date(record.expiration_date).getTime() ? (
              <Tag color={"red"} key={record.expiration_date}>
                {content.tag["expired"]}
              </Tag>
            ) : (
              <Tag color={"green"} key={record.expiration_date}>
                {content.tag["valid"]}
              </Tag>
            )}
          </>
        );
      },
      filters: [
        {
          text: "Expired",
          value: "expired",
        },
        {
          text: "Valid",
          value: "valid",
        },
      ],
      onFilter: (value, record) => {
        let status =
          new Date().getTime() > new Date(record.expiration_date).getTime()
            ? "expired"
            : "valid";
        return value === status;
      },
    },
    /*
    {
      title: content?.serialNumber?.form?.cost.title,
      dataIndex: "cost",
      width: "200px",
      sorter: (a, b) => {
        if (a.cost < b.cost) {
          return -1;
        }
        if (a.cost > b.cost) {
          return 1;
        }
        return 0;
      },
    },
    */
    {
      //title: "Actions",
      width: "60px",
      key: "action",
      render: (text, record) => (
        <Space size={18}>
          <Tooltip placement="top" title={content.tooltips.edit}>
            <EditOutlined onClick={() => handleEditSerialClick(record)} />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.delete}>
            <Popconfirm
              title={content?.confirmation?.delete?.title}
              description={content?.confirmation?.delete?.description}
              onConfirm={() => handleDeleteSerial(record.id)}
              cancelText={content?.confirmation?.delete?.button.cancel}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const filteredProducts = productsList?.results?.filter((product) =>
    Object.values(product)
      .join(" ")
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );

  const filteredSerials = selectedProduct?.serial_numbers?.filter((sn) => {
    if (sn.client == null) return false;
    return Object.values(sn)
      .join(" ")
      .toLowerCase()
      .includes(searchSerialText.toLowerCase());
  });

  if (userInfo.role === 3 || !userInfo || userInfo === "no_logged")
    return <></>;
  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      <div style={{ marginBottom: 16 }}>
        {/*<h1 className="title" style={{ margin: 0 }}>
        {content.title}
      </h1>*/}
        <Title className="titleCenter">{content.title}</Title>
        <Row>
          <Col span={21}>
            <Input
              placeholder={content.search}
              value={searchText}
              onChange={handleSearchChange}
            />
          </Col>
          <ExportTable
            data={filteredProducts}
            fileTitle={content.title}
            columns={columns}
            extraData={{}}
          />
          <Col span={1}>
            <Button
              icon={<PlusOutlined />}
              shape="circle"
              onClick={() => handleAddClick()}
              style={{ marginBottom: 16, float: "right" }}
            ></Button>
          </Col>
        </Row>
      </div>
      <Table
        {...tableProps}
        loading={loadingProducts}
        dataSource={filteredProducts}
        columns={columns}
      ></Table>
      {/*MODAL add/edit product */}
      <Modal
        width={800}
        onCancel={handleCancel}
        title={modalTitle}
        visible={isModalVisible}
        footer={null}
        //onOk={form.submit}
      >
        <Form
          form={form}
          initialValues={productsData}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Item
            label={content?.add?.form?.name.title}
            name="name"
            rules={[
              { required: true, message: content?.add?.form?.name.placeholder },
            ]}
          >
            <Input />
          </Item>
          <Item
            label={content?.add?.form?.description.title}
            name="description"
          >
            <Input />
          </Item>
          <Item>
            <Button
              style={{ float: "right" }}
              type="primary"
              htmlType="submit"
              loading={isAddProduct ? loadingCreate : loadingUpdate}
            >
              {isAddProduct
                ? content.modal.button.add
                : content.modal.button.edit}
            </Button>
          </Item>
        </Form>
      </Modal>
      {/*MODAL serial table*/}
      <Modal
        width={1300}
        onCancel={handleCancelModalSerial}
        title={modalSerialTitle}
        open={isModalSerialVisible}
        footer={null}
        //onOk={form.submit}
      >
        <Row>
          <Col span={20}>
            <Input
              placeholder={content.serialNumber.search}
              value={searchSerialText}
              onChange={handleSearchSerialChange}
            />
          </Col>
          <ExportTable
            data={filteredSerials}
            fileTitle={modalSerialTitle}
            columns={columnsSerial}
            extraData={{
              client: clientsList?.results,
            }}
          />
          <Col span={1}>
            <Button
              icon={<PlusOutlined />}
              shape="circle"
              onClick={() => {
                handleAddSerialClick();
                setIsModalEditSerialVisible(true);
              }}
              style={{ marginBottom: 16, float: "right" }}
            ></Button>
          </Col>
        </Row>
        <Table
          {...tableProps}
          dataSource={filteredSerials}
          columns={columnsSerial}
        ></Table>
      </Modal>
      {/*MODAL add/edit serial*/}
      <Modal
        width={800}
        onCancel={(e) => setIsModalEditSerialVisible(false)}
        title={modalEditSerialTitle}
        open={isModalEditSerialVisible}
        footer={null}
        //onOk={form.submit}
      >
        <Form
          form={formSerial}
          initialValues={serialSelected}
          layout="vertical"
          onFinish={handleSerialSubmit}
        >
          <Item
            label={content?.serialNumber?.form?.client.title}
            name="client"
            rules={[
              {
                required: true,
                message: content?.serialNumber?.form?.client.placeholder,
              },
            ]}
          >
            <Select
              // placeholder="Seleccione un usuario"
              onChange={(value) => {
                setSelectedUser(value);
              }}
              allowClear
              value={selectedUser}
              style={{ width: "100%" }}
            >
              {clientsList?.results?.map((user) => (
                <Option key={user.id} value={user.id}>
                  {`${user.first_name} ${user.last_name}`}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            label={content?.add?.form?.serial.title}
            name="serial_number"
            rules={[
              {
                required: true,
                message: content?.serialNumber?.form?.serial.placeholder,
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            label={content?.serialNumber?.form?.expiration_date.title}
            name="expiration_date"
            rules={[
              {
                required: true,
                message:
                  content?.serialNumber?.form?.expiration_date.placeholder,
              },
            ]}
          >
            <DatePicker
              format={"YYYY-MM-DD"}
              style={{ width: "100%" }}
              placeholder=""
            />
          </Item>
          <Item
            label={content?.add?.form?.description.title}
            name="description"
          >
            <Input />
          </Item>
          <Item>
            <Button
              style={{ float: "right" }}
              type="primary"
              htmlType="submit"
              //loading={isAddProduct ? loadingCreate : loadingUpdate}
            >
              {isAddSerial
                ? content.serialNumber.button.add
                : content.serialNumber.button.edit}
            </Button>
          </Item>
        </Form>
      </Modal>
      {/*MODAL ADD STOCK */}
      <Modal
        title={modalStockTitle}
        open={isModalStockOpen}
        footer={null}
        width={500}
        onCancel={() => setIsModalStockOpen(false)}
      >
        <Form
          form={formAddStock}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          onFinish={handleAddStock}
        >
          <Form.Item label={content?.stock?.form?.stock?.title} name="Stock">
            <div style={{ marginLeft: 12 }}>{parseInt(stock ?? 0)}</div>
          </Form.Item>
          <Form.Item label={content?.stock?.form?.add?.title} name="stock">
            <InputNumber
              defaultValue={0}
              value={stockToAdd}
              //min={0}
              onChange={(e, a, b) => {
                setStockToAdd(e + parseInt(stock));
              }}
            />
          </Form.Item>
          <Form.Item label={content?.stock?.form?.total?.title} name="total">
            <div style={{ marginLeft: 12 }}>
              {parseInt(stockToAdd ?? 0)} {/*+ parseInt(stock ?? 0)}*/}
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: "right" }}
              //loading={isAddProduct ? loadingCreate : loadingUpdate}
            >
              {content?.stock?.form?.button}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default ProductScreen;
