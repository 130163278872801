import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Form, Input, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { loginUser, updatePassword } from "../redux/actions/userActions";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

const RecoveryPasswordEnterPasswordScreen = (props) => {
  const content = props.content;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const {
    error: errorResetPassword,
    loading: loadingResetPassword,
    success: successResetPassword,
  } = userResetPassword;

  console.log(errorResetPassword);
  useEffect(() => {
    if (successResetPassword) {
      navigate("/");
    }

    /* if (userInfo) {
      navigate(redirect);
    }
    */
    if (errorResetPassword) {
      notification.error({
        message: content.notification.create.error.title,
        description: errorResetPassword,
        placement: "bottomRight",
      });
    }

    //}, [navigate, userInfo, redirect, error]);
  }, [successResetPassword, errorResetPassword]);

  const submitHandler = (e) => {
    let pathname = location.pathname;

    let body = {
      uid: pathname.split("/")[2],
      token: pathname.split("/")[3],
      new_password: e.password,
      re_new_password: e.confirm,
    };
    console.log(body);
    dispatch(updatePassword(body));
  };

  return (
    <div className="centerForm" style={{ height: "80vh" }}>
      {!userInfo && (
        <Form
          name="normal_login"
          className="login-form"
          style={{ width: "50%" }}
          onFinish={submitHandler}
        >
          {" "}
          <h1 className="title">{content?.title ?? "Loading..."}</h1>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: content?.form?.password?.placeholder ?? "Loading...",
              },
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={content?.form?.password?.title ?? "Pass"}
              autoComplete="none"
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: content?.form?.password?.placeholder ?? "Loading...",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={content?.form?.password_1?.title ?? "Confirm pass"}
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {content.button ?? "Loading..."}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default RecoveryPasswordEnterPasswordScreen;
