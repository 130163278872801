import {
  ADDRESS_DETAILS_FAIL,
  ADDRESS_DETAILS_REQUEST,
  ADDRESS_DETAILS_RESET,
  ADDRESS_DETAILS_SUCCESS,
  ADDRESS_DELETE_FAIL,
  ADDRESS_DELETE_REQUEST,
  ADDRESS_DELETE_RESET,
  ADDRESS_DELETE_SUCCESS,
  ADDRESS_REGISTER_FAIL,
  ADDRESS_REGISTER_REQUEST,
  ADDRESS_REGISTER_SUCCESS,
  ADDRESS_UPDATE_FAIL,
  ADDRESS_UPDATE_REQUEST,
  ADDRESS_UPDATE_RESET,
  ADDRESS_UPDATE_SUCCESS,
  ADDRESSES_LIST_FAIL,
  ADDRESSES_LIST_REQUEST,
  ADDRESSES_LIST_RESET,
  ADDRESSES_LIST_SUCCESS,
} from "../constants/addressConstants";

export const getAddressesReducer = (state = {}, action) => {
  switch (action.type) {
    case ADDRESSES_LIST_REQUEST:
      return { loading: true };

    case ADDRESSES_LIST_SUCCESS:
      return { loading: false, addressesList: action.payload };

    case ADDRESSES_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const deleteAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_DELETE_REQUEST:
      return { loading: true };

    case ADDRESS_DELETE_SUCCESS:
      return { loading: false, success: true, address: action.payload };

    case ADDRESS_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getAddressDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_DETAILS_REQUEST:
      return { loading: true };

    case ADDRESS_DETAILS_SUCCESS:
      return { loading: false, address: action.payload };

    case ADDRESS_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_UPDATE_REQUEST:
      return { loading: true };

    case ADDRESS_UPDATE_SUCCESS:
      return { loading: false, success: true, address: action.payload };

    case ADDRESS_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const registerAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_REGISTER_REQUEST:
      return { loading: true };

    case ADDRESS_REGISTER_SUCCESS:
      return { loading: false, success: true, address: action.payload };

    case ADDRESS_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
