import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Skeleton,
  Tooltip,
  Card,
  Col,
  Row,
  Form,
  Input,
  Button,
  Select,
  Popconfirm,
  Modal,
  Table,
  Typography,
  Space,
  Tabs,
  notification,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  getEcuBrandDetails,
  getEcuBrands,
  updateEcuBrand,
  registerEcuBrands,
  deleteEcuBrand,
  getEcuModelDetails,
  getEcuModels,
  updateEcuModels,
  registerEcuModel,
  deleteEcuModel,
} from "../redux/actions/ecuActions";
import {
  ECU_BRAND_REGISTER_RESET,
  ECU_BRAND_UPDATE_RESET,
  ECU_BRAND_DELETE_RESET,
  ECU_MODEL_REGISTER_RESET,
  ECU_MODEL_UPDATE_RESET,
  ECU_MODEL_DELETE_RESET,
} from "../redux/constants/ecuConstants";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExportTable from "../components/ExportTable";

const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;

function EcuScreen(props) {
  let content = props.content;
  const userInfo = props.userInfo;
  const tableProps = props.tableProps;

  const navigate = useNavigate();

  const [brandSelected, setBrandSelected] = useState();
  const [modelSelected, setModelSelected] = useState();

  const [ecuSelected, setEcuSelected] = useState();
  const [modelSelectedValue, setModelSelectedValue] = useState();

  const [visibleBrand, setVisibleBrand] = useState(false);
  const [visibleModel, setVisibleModel] = useState(false);

  const [searchTextBrand, setSearchTextBrand] = useState("");
  const [searchTextModel, setSearchTextModel] = useState("");

  const [editingEcuBrand, setEditingEcuBrand] = useState(null);
  const [editingEcuModel, setEditingEcuModel] = useState(null);

  const [brandColumFilter, setBrandColumFilter] = useState(null);
  const [filteredValue, setFilteredValue] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  const [modalTitle, setModalTitle] = useState("");

  const [deleteEcuModelId, setDeleteEcuModelId] = useState("");
  const [ecuModelList, setEcuModelList] = useState([]);

  const [deleteEcuBrandId, setDeleteEcuBrandId] = useState("");
  const [ecuBrandList, setEcuBrandList] = useState([]);

  const { ecusBrandList, loading: loadingBrand } = useSelector(
    (state) => state.EcusBrandList
  );
  const { ecusModelList } = useSelector((state) => state.EcusModelList);

  const {
    success: successCreate,
    error: errorCreate,
    loading: loadingCreate,
    ecuBrand: ecuBrandCreateList,
  } = useSelector((state) => state.EcuBrandCreate);
  const {
    success: successUpdate,
    error: errorUpdate,
    loading: loadingUpdate,
    ecuBrand: ecuBrandUpdateList,
  } = useSelector((state) => state.EcuBrandUpdate);
  const {
    success: successDelete,
    error: errorDelete,
    loading: loadingDelete,
    ecuBrand: ecuBrandDeleteList,
  } = useSelector((state) => state.EcuBrandDelete);

  const {
    success: successUpdateModel,
    error: errorUpdateModel,
    loading: loadingUpdateModel,
    ecuModel: ecuModelUpdateList,
  } = useSelector((state) => state.EcuModelUpdate);
  const {
    success: successCreateModel,
    error: errorCreateModel,
    loading: loadingCreateModel,
    ecuModel: ecuModelCreateList,
  } = useSelector((state) => state.EcuModelCreate);
  const {
    success: successDeleteModel,
    error: errorDeleteModel,
    loading: loadingDeleteModel,
    ecuModel: ecuModelDeleteList,
  } = useSelector((state) => state.EcuModelDelete);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (successUpdate || successCreate || successDelete) {
      //dispatch(getEcuBrands());
    }

    if (successUpdateModel || successCreateModel || successDeleteModel) {
      //dispatch(getEcuModels());
    }

    //BRANDS
    if (successUpdate) {
      notification.success({
        message: content?.tab1?.notification?.edited?.success?.title,
        description: content?.tab1?.notification?.edited?.success?.description,
        placement: "bottomRight",
      });
      setVisibleBrand(false);
    }
    if (errorUpdate) {
      notification.error({
        message: content?.tab1?.notification?.edited?.error?.title,
        description: content?.tab1?.notification?.edited?.error?.description,
        placement: "bottomRight",
      });
      //  setVisibleBrand(false);
    }

    if (errorCreate) {
      notification.error({
        message: content?.tab1?.notification?.create?.error?.title,
        description: content?.tab1?.notification?.create?.error?.description,
        placement: "bottomRight",
      });
      //   setVisibleBrand(false);
    }
    if (successCreate) {
      notification.success({
        message: content?.tab1?.notification?.create?.success?.title,
        description: content?.tab1?.notification?.create?.success?.description,
        placement: "bottomRight",
      });
      setVisibleBrand(false);
    }

    if (successDelete) {
      notification.success({
        message: content?.tab1?.notification?.delete?.success?.title,
        description: content?.tab1?.notification?.delete?.success?.description,
        placement: "bottomRight",
      });
      //setIsModalVisible(false);
    }
    if (errorDelete) {
      notification.error({
        message: content?.tab1?.notification?.delete?.error?.title,
        description: content?.tab1?.notification?.delete?.error?.description,
        placement: "bottomRight",
      });
      //setIsModalVisible(false);
    }

    //MODELS
    if (successUpdateModel) {
      notification.success({
        message: content?.tab2?.notification?.edited?.success?.title,
        description: content?.tab2?.notification?.edited?.success?.description,
        placement: "bottomRight",
      });
      setVisibleModel(false);
    }

    if (errorUpdateModel) {
      notification.error({
        message: content?.tab2?.notification?.edited?.error?.title,
        description: content?.tab2?.notification?.edited?.error?.description,
        placement: "bottomRight",
      });
      //setVisibleModel(false);
    }

    if (errorCreateModel) {
      notification.error({
        message: content?.tab2?.notification?.create?.error?.title,
        description: content?.tab2?.notification?.create?.error?.description,
        placement: "bottomRight",
      });
      // setVisibleModel(false);
    }
    if (successCreateModel) {
      notification.success({
        message: content?.tab2?.notification?.create?.success?.title,
        description: content?.tab2?.notification?.create?.success?.description,
        placement: "bottomRight",
      });
      setVisibleModel(false);
    }

    if (successDeleteModel) {
      notification.success({
        message: content?.tab2?.notification?.delete?.success?.title,
        description: content?.tab2?.notification?.delete?.success?.description,
        placement: "bottomRight",
      });
    }
    if (errorDeleteModel) {
      notification.error({
        message: content?.tab2?.notification?.delete?.error?.title,
        description: content?.tab2?.notification?.delete?.error?.description,
        placement: "bottomRight",
      });
    }
  }, [
    //dispatch,
    successUpdate,
    errorUpdate,
    successDelete,
    errorDelete,
    successCreate,
    errorCreate,
    successUpdateModel,
    errorUpdateModel,
    successCreateModel,
    errorCreateModel,
    successDeleteModel,
    errorDeleteModel,
  ]);

  useEffect(() => {
    resetBrandStates();
    resetModelStates();
    if (userInfo && userInfo.role === 3) {
      navigate("/notFound");
    } else if (userInfo && userInfo.role !== 3) {
      dispatch(getEcuBrands());
      dispatch(getEcuModels());
    }
    if (userInfo === "no_logged") {
      navigate("/login");
    }
  }, [userInfo]);

  useEffect(() => {
    let temp = [];
    let tempServicesList = ecusModelList?.results ?? [];
    temp = [...temp, ...tempServicesList];
    setEcuModelList(temp);
  }, [ecusModelList]);

  useEffect(() => {
    if (ecuModelUpdateList || ecuModelDeleteList || ecuModelCreateList) {
      let ecuObj = {};
      let temp = [];
      let tempEcuUpdateList = ecuModelUpdateList ? [ecuModelUpdateList] : [];
      ecuModelList?.forEach((item) => {
        if (ecuModelDeleteList === 1 && item.id === deleteEcuModelId)
          return false;
        ecuObj[item.id] = item;
      });
      tempEcuUpdateList?.forEach((item) => {
        ecuObj[item.id] = item;
      });
      Object.entries(ecuObj).forEach(([key, item]) => {
        temp.push(item);
      });
      if (ecuModelCreateList) temp = [...temp, ecuModelCreateList];
      setEcuModelList(temp);
      resetBrandStates();
      resetModelStates();
    }
  }, [ecuModelUpdateList, ecuModelCreateList, ecuModelDeleteList]);

  useEffect(() => {
    let temp = [];
    let tempServicesList = ecusBrandList?.results ?? [];
    temp = [...temp, ...tempServicesList];
    setEcuBrandList(temp);
  }, [ecusBrandList]);

  useEffect(() => {
    if (ecuBrandCreateList || ecuBrandUpdateList || ecuBrandDeleteList) {
      let ecuObj = {};
      let temp = [];
      let tempEcuUpdateList = ecuModelUpdateList ? [ecuModelUpdateList] : [];
      ecuBrandList?.forEach((item) => {
        if (ecuBrandDeleteList === 1 && item.id === deleteEcuBrandId)
          return false;
        ecuObj[item.id] = item;
      });
      tempEcuUpdateList?.forEach((item) => {
        ecuObj[item.id] = item;
      });
      Object.entries(ecuObj).forEach(([key, item]) => {
        temp.push(item);
      });
      if (ecuBrandCreateList) temp = [...temp, ecuBrandCreateList];
      setEcuBrandList(temp);
      resetBrandStates();
      resetModelStates();
    }
  }, [ecuBrandCreateList, ecuBrandUpdateList, ecuBrandDeleteList]);

  const resetBrandStates = () => {
    dispatch({
      type: ECU_BRAND_REGISTER_RESET,
    });
    dispatch({
      type: ECU_BRAND_UPDATE_RESET,
    });
    dispatch({
      type: ECU_BRAND_DELETE_RESET,
    });
  };

  const resetModelStates = () => {
    dispatch({
      type: ECU_MODEL_REGISTER_RESET,
    });
    dispatch({
      type: ECU_MODEL_UPDATE_RESET,
    });
    dispatch({
      type: ECU_MODEL_DELETE_RESET,
    });
  };

  const handleDeleteEcuBrand = (ecuBrandId) => {
    setDeleteEcuBrandId(ecuBrandId);
    resetBrandStates();
    dispatch(deleteEcuBrand(ecuBrandId));
  };

  const handleDeleteEcuModel = (ecuModelId) => {
    setDeleteEcuModelId(ecuModelId);
    resetModelStates();
    dispatch(deleteEcuModel(ecuModelId));
  };

  const handleEditEcuBrand = (ecuBrand, id) => {
    setModalTitle(content?.tab1?.modal.edit);
    form.resetFields();
    setEditingEcuBrand(true);
    setBrandSelected(ecuBrand);
    form.setFieldsValue({
      name: ecuBrand.name,
      description: ecuBrand.description,
    });
    setVisibleBrand(true);
  };

  const handleEditEcuModel = (ecuBrand, id) => {
    setModalTitle(content?.tab2?.modal.edit);
    form.resetFields();
    setEditingEcuModel(true);
    setModelSelected(ecuBrand);
    form.setFieldsValue(ecuBrand);
    setVisibleModel(true);
  };

  const handleAddEcuBrand = () => {
    setModalTitle(content?.tab1?.modal.add);
    setEditingEcuBrand(false);
    form.resetFields();
    setVisibleBrand(true);
  };

  const handleAddEcuModel = () => {
    setModalTitle(content?.tab2?.modal.add);
    setEditingEcuModel(false);
    form.resetFields();
    setVisibleModel(true);
  };

  const handleSubmitBrand = (record) => {
    resetBrandStates();
    resetModelStates();
    if (editingEcuBrand) {
      dispatch(updateEcuBrand(brandSelected.id, record));
    } else {
      dispatch(registerEcuBrands(record));
    }
  };

  const handleSubmitModel = (record) => {
    resetBrandStates();
    resetModelStates();
    if (editingEcuModel) {
      dispatch(updateEcuModels(modelSelected.id, record));
    } else {
      dispatch(registerEcuModel(record));
    }
    setVisibleModel(false);
  };

  const filteredEcusBrandList = ecuBrandList?.filter((service) =>
    Object.values(service)
      .join(" ")
      .toLowerCase()
      .includes(searchTextBrand.toLowerCase())
  );

  const filtersBrandColumns = ecuBrandList?.map((e) => ({
    text: e.name,
    value: e.id,
  }));

  const filteredEcusModelList = ecuModelList?.filter((service) =>
    Object.values(service)
      .join(" ")
      .toLowerCase()
      .includes(searchTextModel.toLowerCase())
  );

  const columnsBrand = [
    {
      title: content?.tab1?.add?.form?.name.title,
      dataIndex: "name",
      width: "200px",
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      onCell: (record) => {
        return {
          onClick: () => {
            setFilteredValue([record.id]);
            setActiveTab("2");
          },
        };
      },
      className: "cursorPointer",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      title: content?.tab1?.add?.form?.description.title,
      dataIndex: "description",
      width: "200px",
      sorter: (a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      //title: "Action",
      dataIndex: "id",
      key: "action",
      width: "20px",
      render: (id, record) => (
        <Space size={18}>
          <Tooltip placement="top" title={content.tooltips.edit}>
            <EditOutlined
              onClick={() => {
                handleEditEcuBrand(record, id);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.delete}>
            <Popconfirm
              title={content?.tab1?.confirmation?.delete?.title}
              description={content?.tab1?.confirmation?.delete?.description}
              onConfirm={() => handleDeleteEcuBrand(id)}
              cancelText={content?.tab1?.confirmation?.delete?.button.cancel}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const columnsModel = [
    {
      title: content?.tab2?.add?.form?.name.title,
      dataIndex: "name",
      width: "200px",
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      title: content?.tab2?.add?.form?.description.title,
      dataIndex: "description",
      width: "200px",
      sorter: (a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      title: content?.tab2?.add?.form?.brand.title,
      dataIndex: "brand",
      width: "200px",
      render: (a, b) => {
        return props.renderTooltipInEllipsis(
          ecuBrandList?.filter((e) => {
            return a === e.id;
          })?.[0]?.name
        );
      },
      filteredValue: filteredValue,
      filterSearch: true,
      filters: filtersBrandColumns,
      onFilter: (value, record) => {
        return value === record.brand;
      },
      sorter: (a, b) => {
        if (a.brand < b.brand) {
          return -1;
        }
        if (a.brand > b.brand) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      //title: "Action",
      // dataIndex: "id",
      key: "action",
      width: "30px",
      render: (id, record) => (
        <Space size={18}>
          <Tooltip placement="top" title={content.tooltips.edit}>
            <EditOutlined
              onClick={() => {
                handleEditEcuModel(record, id);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.delete}>
            <Popconfirm
              title={content?.tab2?.confirmation?.delete?.title}
              description={content?.tab2?.confirmation?.delete?.description}
              onConfirm={() => handleDeleteEcuModel(id.id)}
              cancelText={content?.tab2?.confirmation?.delete?.button.cancel}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleSearchBrand = (value) => {
    setSearchTextBrand(value);
  };

  const handleSearchModel = (value) => {
    setSearchTextModel(value);
  };

  const items = [
    {
      key: "1",
      label: content?.tab1?.title,
      children: (
        <>
          <div style={{ marginBottom: 16 }}>
            <Row>
              <Col span={21}>
                <Input
                  placeholder={content?.tab1?.search}
                  onChange={(e) => handleSearchBrand(e.target.value)}
                />
              </Col>
              <ExportTable
                data={filteredEcusBrandList}
                fileTitle={"ECU " + content?.tab1?.title}
                columns={columnsBrand}
              />
              <Col span={1}>
                <Button
                  onClick={handleAddEcuBrand}
                  shape="circle"
                  style={{ marginBottom: 16, float: "right" }}
                  icon={<PlusOutlined />}
                ></Button>
              </Col>
            </Row>
          </div>
          <Table
            {...tableProps}
            dataSource={filteredEcusBrandList}
            columns={columnsBrand}
            loading={loadingBrand}
          />
          <Modal
            open={visibleBrand}
            onCancel={() => setVisibleBrand(false)}
            footer={null}
            width={800}
            title={modalTitle}
          >
            <Form
              form={form}
              onFinish={handleSubmitBrand}
              layout="vertical"
              initialValues={ecuSelected}
            >
              <Form.Item
                label={content?.tab1?.add?.form?.name.title}
                name="name"
                rules={[
                  {
                    required: true,
                    message: content?.tab1?.add?.form?.name.placeholder,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={content?.tab1?.add?.form?.description.title}
                name="description"
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ float: "right" }}
                  type="primary"
                  htmlType="submit"
                  loading={loadingCreate || loadingUpdate}
                >
                  {editingEcuBrand
                    ? content?.tab1?.add?.form?.buttonUpdate
                    : content?.tab1?.add?.form?.button}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </>
      ),
    },
    {
      key: "2",
      label: content?.tab2?.title,
      children: (
        <>
          <div style={{ marginBottom: 16 }}>
            <Row>
              <Col span={21}>
                <Input
                  placeholder={content?.tab2?.search}
                  onChange={(e) => handleSearchModel(e.target.value)}
                />
              </Col>
              <ExportTable
                data={ecuModelList}
                fileTitle={"ECU " + content?.tab2?.title}
                columns={columnsModel}
                extraData={{
                  brand: ecusBrandList?.results,
                  ecusModelList: ecusModelList?.results,
                }}
              />
              <Col span={1}>
                <Button
                  onClick={handleAddEcuModel}
                  shape="circle"
                  style={{ marginBottom: 16, float: "right" }}
                  icon={<PlusOutlined />}
                ></Button>
              </Col>
            </Row>
          </div>
          <Table
            {...tableProps}
            onChange={(e, a, b) => {
              setFilteredValue(a?.brand);
            }}
            dataSource={filteredEcusModelList}
            columns={columnsModel}
            rowKey="id"
          />
          <Modal
            visible={visibleModel}
            onCancel={() => setVisibleModel(false)}
            title={modalTitle}
            footer={null}
            width={800}
          >
            <Form
              form={form}
              onFinish={handleSubmitModel}
              layout="vertical"
              initialValues={ecuSelected}
            >
              <Form.Item
                label={content?.tab2?.add?.form?.name.title}
                name="name"
                rules={[
                  {
                    required: true,
                    message: content?.tab2?.add?.form?.name.placeholder,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={content?.tab2?.add?.form?.description.title}
                name="description"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={content?.tab2?.add?.form?.brand.title}
                name="brand"
                rules={[
                  {
                    required: true,
                    message: content?.tab2?.add?.form?.brand.placeholder,
                  },
                ]}
              >
                <Select label={content?.tab2?.add?.form?.brand.title}>
                  {ecuBrandList?.map((marca) => (
                    <Option key={marca.id} value={marca.id}>
                      {
                        ecuBrandList?.filter((e) => {
                          return marca.id === e.id;
                        })[0]?.name
                      }
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ float: "right" }}
                  type="primary"
                  htmlType="submit"
                  loading={loadingCreateModel || loadingUpdateModel}
                >
                  {content?.tab2?.add?.form?.button}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </>
      ),
    },
  ];

  if (userInfo.role === 3 || !userInfo || userInfo === "no_logged")
    return <></>;
  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      {/*<h1 className="title" style={{ margin: 0 }}>
        {content.title ?? "Loading..."}
      </h1>*/}
      <Title className="titleCenter">{content.title}</Title>
      <Tabs
        onChange={(e) => {
          setActiveTab(e);
        }}
        activeKey={activeTab}
        items={items}
      />
    </div>
  );
}
export default EcuScreen;
