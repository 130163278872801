import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  CITIES_DETAILS_RESET,
  STATE_DETAILS_RESET,
} from "../redux/constants/locationConstants";

import {
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  notification,
  Divider,
  Result,
  Typography,
} from "antd";
import { registerUser, registerClient } from "../redux/actions/userActions";
import {
  getCities,
  getCountries,
  getStates,
} from "../redux/actions/locationActions";
import PhoneInput from "react-phone-input-2";
import { USER_REGISTER_RESET } from "../redux/constants/userConstants";

const { Option } = Select;

const RegisterScreen = (props) => {
  const content = props.content;

  const [form] = Form.useForm();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sex, setSex] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [message, setMessage] = useState("");
  const [countryIso2, setCountryIso2] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userRegister = useSelector((state) => state.userRegister);
  const {
    error: errorRegister,
    loading: loadingRegister,
    userInfo,
    success: successRegister,
  } = userRegister;

  const { countries, loading: loadingCountries } = useSelector(
    (state) => state.countries
  );
  const { states, loading: loadingState } = useSelector(
    (state) => state.states
  );
  const { cities, loading: loadingCities } = useSelector(
    (state) => state.cities
  );

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo: userInfoLogged } = userLogin;

  useEffect(() => {
    dispatch({ type: USER_REGISTER_RESET });
  }, []);

  useEffect(() => {
    if (userInfoLogged) {
      navigate("/");
    }
    if (errorRegister) {
      let description = "";
      Object.entries(errorRegister).forEach(([key, value]) => {
        description = description + value + " ";
      });
      notification.error({
        message: "Algo salio mal." ?? "",
        description: description ?? "",
        placement: "bottomRight",
      });
    }
    if (!countries && !loadingCountries) dispatch(getCountries());
  }, [
    navigate,
    dispatch,
    userInfo,
    redirect,
    countries,
    loadingCountries,
    userInfoLogged,
    errorRegister,
    props,
  ]);

  const dropdownSearch = (input, option) => {
    return (option?.children ?? "")
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(
        input
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      );
  };

  const sortByName = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  if (states) states.sort(sortByName);
  if (countries) countries.sort(sortByName);
  if (cities) cities.sort(sortByName);

  const submitHandler = (e) => {
    if (password !== confirmPassword) {
      setMessage("Password do not match");
    }

    const newUser = {
      first_name: e.first_name,
      last_name: e.last_name,
      email: e.email,
      username: e.username,
      password: e.password,
      sex: e.sex,
      company_name: e.company_name,
      dni: e.dni,
      cell_phone: e.cell_phone,
      address: [
        {
          country,
          province,
          city,
          street_name: e.street,
          house_number: e.house_number,
        },
      ],
    };
    dispatch(registerUser(newUser, location.pathname.split("/")[1]));
  };

  return (
    <div className="centerForm">
      {!successRegister ? (
        <Form
          name="basic"
          style={{ width: "50%" }}
          form={form}
          onFinish={submitHandler}
          layout="vertical"
          autoComplete="off"
        >
          <Typography.Title className="titleCenter">
            {content.title}
          </Typography.Title>
          <Divider style={{ margin: 0 }}>{content.divisor.basic}</Divider>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              style={{ display: "inline-block", width: "calc(49% - 8px)" }}
              label={content?.form?.first_name.title ?? "Loading..."}
              name="first_name"
              rules={[
                {
                  required: true,
                  message:
                    content?.form?.first_name.placeholder ?? "Loading...",
                },
              ]}
            >
              <Input autoComplete="none" />
            </Form.Item>
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(49% - 8px)",
                float: "right",
              }}
              label={content?.form?.last_name.title ?? "Loading..."}
              name="last_name"
              rules={[
                {
                  required: true,
                  message: content?.form?.last_name.placeholder ?? "Loading...",
                },
              ]}
            >
              <Input autoComplete="none" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={content?.form?.email.title ?? "Loading..."}
            name="email"
            rules={[
              {
                required: true,
                message: content?.form?.email.placeholder ?? "Loading...",
              },
            ]}
          >
            <Input autoComplete="none" />
          </Form.Item>
          <Form.Item
            label={content?.form?.dni?.title ?? "Loading..."}
            name="dni"
            rules={[
              {
                required: true,
                message: content?.form?.dni.placeholder ?? "Loading...",
              },
            ]}
          >
            <Input autoComplete="none" />
          </Form.Item>
          <Form.Item
            label={content?.form?.username.title ?? "Loading..."}
            name="username"
            rules={[
              {
                required: true,
                message: content?.form?.username.placeholder ?? "Loading...",
              },
            ]}
          >
            <Input autoComplete="none" />
          </Form.Item>
          <Form.Item
            name="sex"
            label={content?.form?.sex.title ?? "Loading..."}
            rules={[
              {
                required: true,
                message: content?.form?.sex.placeholder ?? "Loading...",
              },
            ]}
          >
            <Select
              onChange={(value) => {
                setSex(value);
              }}
              allowClear
            >
              <Option value="1">
                {content?.form?.sex.options[0] ?? "Loading..."}
              </Option>
              <Option value="2">
                {content?.form?.sex.options[1] ?? "Loading..."}
              </Option>
              <Option value="3">
                {content?.form?.sex.options[2] ?? "Loading..."}
              </Option>
            </Select>
          </Form.Item>
          <Divider style={{ margin: 0 }}>{content.divisor.ubication}</Divider>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                justifyContent: "space-between",
                display: "inline-flex",
                width: "100%",
              }}
            >
              <Form.Item
                style={{ display: "inline-block", width: "31%" }}
                name="country"
                label={content?.form?.country.title ?? "Loading..."}
                rules={[
                  {
                    required: true,
                    message: content?.form?.country.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Select
                  showSearch
                  name="country"
                  value={country}
                  optionFilterProp="children"
                  disabled={loadingCountries}
                  loading={loadingCountries}
                  filterOption={dropdownSearch}
                  onChange={(value, a, b) => {
                    value = a.children;
                    let iso2 = a["data-iso2"];
                    //dispatch({ type: STATE_DETAILS_RESET });
                    setCountry(value);
                    setCountryIso2(iso2);
                    setCity("");
                    setProvince("");
                    dispatch({ type: STATE_DETAILS_RESET });
                    dispatch({ type: CITIES_DETAILS_RESET });
                    dispatch(getStates(iso2));
                    form.setFieldsValue({ state: "", city: "", cities: "" });
                  }}
                >
                  {countries?.map((obj) => {
                    return (
                      <Option key={obj.id} value={obj.id} data-iso2={obj.iso2}>
                        {obj.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ display: "inline-block", width: "31%" }}
                name="state"
                label={content?.form?.state.title ?? "Loading..."}
                rules={[
                  {
                    required: true,
                    message: content?.form?.state.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Select
                  showSearch
                  value={province}
                  disabled={country ? false : true}
                  loading={loadingState}
                  optionFilterProp="children"
                  filterOption={dropdownSearch}
                  //placeholder={content?.form?.state.placeholder ?? "Loading..."}
                  id="state"
                  onChange={(value, a, b) => {
                    value = a.children;
                    let iso2 = a["data-iso2"];
                    dispatch({ type: CITIES_DETAILS_RESET });
                    form.setFieldsValue({ cities: "" });
                    setCity("");
                    dispatch(getCities(countryIso2, iso2));
                    setProvince(a.children);
                  }}
                >
                  {states?.map((obj) => {
                    return (
                      <Option id={obj.id} value={obj.id} data-iso2={obj.iso2}>
                        {obj.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ display: "inline-block", width: "31%" }}
                name="cities"
                label={content?.form?.city.title ?? "Loading..."}
                rules={[
                  {
                    required: true,
                    message: content?.form?.city.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={dropdownSearch}
                  disabled={province ? false : true}
                  //placeholder={content?.form?.city.placeholder ?? "Loading..."}
                  value={city}
                  loading={loadingCities}
                  onChange={(value, a, b) => {
                    setCity(a.children);
                  }}
                >
                  {cities?.map((obj) => {
                    return (
                      <Option id={obj.id} value={obj.id} data-iso2={obj.iso2}>
                        {obj.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                justifyContent: "space-between",
                display: "inline-flex",
                width: "100%",
              }}
            >
              <Form.Item
                style={{ display: "inline-block", width: "72%" }}
                name="street"
                label={content?.form?.street.title ?? "Loading..."}
                rules={[
                  {
                    required: true,
                    message: content?.form?.street.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Input
                  // placeholder={content?.form?.street.title ?? "Loading..."}
                  autoComplete="none"
                />
              </Form.Item>
              <Form.Item
                style={{ display: "inline-block", width: "25%" }}
                name="house_number"
                label={content?.form?.house_number.title ?? "Loading..."}
                rules={[
                  {
                    required: true,
                    message:
                      content?.form?.house_number.placeholder ?? "Loading...",
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  style={{ display: "inline-block", width: "100%" }}
                  autoComplete="none"
                  //placeholder={content?.form?.house_number.title ?? "Loading..."}
                />
              </Form.Item>
            </div>
          </Form.Item>
          <Divider style={{ margin: 0 }}>{content.divisor.password}</Divider>
          <Form.Item
            name="password"
            label={content?.form?.password_1.title ?? "Loading..."}
            rules={[
              {
                required: true,
                message: content?.form?.password_1.placeholder ?? "Loading...",
              },
            ]}
            hasFeedback
          >
            <Input.Password autoComplete="none" />
          </Form.Item>
          <Form.Item
            name="confirm"
            label={content?.form?.password_2.title ?? "Loading..."}
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: content?.form?.password_2.placeholder ?? "Loading...",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Divider style={{ margin: 0 }}>{content.divisor.company}</Divider>
          <Form.Item
            label={content?.form?.company_name.title ?? "Loading..."}
            name="company_name"
            rules={[
              {
                required: true,
                message:
                  content?.form?.company_name.placeholder ?? "Loading...",
              },
            ]}
          >
            <Input autoComplete="none" />
          </Form.Item>
          {/*
          <Form.Item
            label={content?.form?.phone.title ?? "Loading..."}
            name="cell_phone"
            rules={[
              {
                required: true,
                message: content?.form?.phone.placeholder ?? "Loading...",
              },
            ]}
          >
            <Input autoComplete="none" />
          </Form.Item>
          */}
          <Form.Item
            label={content?.form?.phone?.title ?? "Loading..."}
            name="cell_phone"
            rules={[
              {
                required: true,
                message: content?.form?.username.placeholder ?? "Loading...",
              },
            ]}
          >
            <PhoneInput
              enableSearch
              country={"ar"}
              inputClass={
                "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
              }
              buttonClass={
                "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
              }
              searchClass={
                "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
              }
              // dropdownClass="phoneInput"
              inputStyle={{
                width: "100%",
                height: "32px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                background: props.darkMode ? "#141414" : "white",
                color: props.darkMode ? "white" : "black",
              }}
              buttonStyle={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: props.darkMode ? "#424242 " : "#d9d9d9",
                background: props.darkMode ? "#141414" : "white",
                color: props.darkMode ? "white" : "black",
              }}
              dropdownStyle={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                background: props.darkMode ? "#141414" : "white",
                color: props.darkMode ? "white" : "black",
              }}
              searchStyle={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                background: props.darkMode ? "#141414" : "white",
                color: props.darkMode ? "white" : "black",
              }}
            />
          </Form.Item>
          <Form.Item style={{ display: "inline-block", float: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingRegister}
              size="large"
              style={{ width: "100%", float: "right" }}
            >
              {content?.button?.title ?? "Loading..."}
            </Button>
            <br /> <br />
            <div style={{ float: "rigth" }}>
              {content?.subtitle?.title ?? "Loading..."} &nbsp;
              <Link type="primary" to="/login" key="console">
                {content?.subtitle?.link ?? "Loading..."}
              </Link>
            </div>
          </Form.Item>
        </Form>
      ) : (
        <Result
          status="success"
          title={content.result.title}
          subTitle={content.result.message}
          extra={[
            <Link type="primary" to="/login" key="console">
              {content.result.button}
            </Link>,
          ]}
        />
      )}
    </div>
  );
};

export default RegisterScreen;
