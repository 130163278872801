import React, { useEffect, useState } from "react";
import { Breadcrumb, Layout, Menu, Switch, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  ControlOutlined,
  LoginOutlined,
  HomeOutlined,
  LogoutOutlined,
  ToolOutlined,
  FlagOutlined,
  SettingOutlined,
  BarcodeOutlined,
  CarOutlined,
  CarFilled,
  SwapOutlined,
  UsbOutlined,
  DollarOutlined,
  ShoppingCartOutlined,
  FileTextOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { logoutUser } from "../redux/actions/userActions";
import jsPDF from "jspdf";
import "jspdf-autotable";
import dayjs from "dayjs";

const { Header, Content, Footer, Sider } = Layout;

function Slider(props) {
  const userToken = props.userInfo;
  const content = props.content;
  const servicesList = props.servicesList;
  const serviceScreen = props.contentServices;
  const user = props.user;
  const dateFormat = "YYYY-MM-DD HH:mm:ss";

  const modules = [
    "/ecus",
    "/products",
    "/services",
    "/clients",
    "/users",
    "/vehicles",
    "/jobs",
  ];

  // const [collapsed, setCollapsed] = useState(false);
  const [language, setLanguage] = useState(props.language);
  //const [openLanguaje, setOpenLanguaje] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState(window.location.pathname);

  const dispatch = useDispatch();

  function getItem(label, key, icon, children, onClick, style = {}) {
    return {
      key,
      icon,
      children,
      label,
      onClick: onClick,
      style,
    };
  }

  let loginItems = [];

  let configItems = [
    getItem(
      <Link to="/users">{content.worker ?? "Loading"}</Link>,
      "/users",
      <UserOutlined />,
      null,
      function (a, b) {}
    ),
    getItem(
      <Link to="/clients">{content.user ?? "Loading"}</Link>,
      "/clients",
      <TeamOutlined />,
      null,
      function (a, b) {}
    ),
    getItem(
      <Link to="/services">{content.service ?? "Loading"}</Link>,
      "/services",
      <ShoppingCartOutlined />,
      null,
      function (a, b) {}
    ),
    getItem(
      <Link to="/products">{content.product ?? "Loading"}</Link>,
      "/products",
      <BarcodeOutlined />,
      null,
      function (a, b) {}
    ),
    getItem(
      <Link to="/vehicles">{content.vehicle ?? "Loading"}</Link>,
      "/vehicles",
      <CarFilled />,
      null,
      function (a, b) {}
    ),
    getItem(
      <Link to="/ecus">{content.ecu ?? "Loading"}</Link>,
      "/ecus",
      <UsbOutlined />,
      null,
      function (a, b) {}
    ),
  ];
  let items = [];

  if (userToken?.role === 1 && !props.collapsed) {
    items = [
      ...items,
      ...[
        getItem(
          <Link to="/jobs">{content.job ?? "Loading"}</Link>,
          "17",
          <ToolOutlined />
        ),
        getItem(
          content.admin ?? "Loading",
          "adminPanel",
          <SettingOutlined />,
          configItems
        ),
        getItem(
          <Switch
            style={{ marginLeft: 10, width: 70 }}
            checkedChildren="USD"
            unCheckedChildren="USD"
            checked={props.useUSD}
            onChange={(e) => {
              props.setUseUSD(e);
              props.setIsUseCost(false);
              if (props.useARS) {
                props.setUseARS(!e);
              } else if (!e && !props.useARS) {
                props.setIsUseCost(true);
              }
            }}
          />,
          "equ",
          <DollarOutlined /> /*
          {
            background: "transparent !important",
            cursor: "default",
          }*/
        ),
        getItem(
          <Switch
            style={{ marginLeft: 10, width: 70 }}
            checkedChildren="ARS"
            unCheckedChildren="ARS"
            checked={props.useARS}
            onChange={(e) => {
              props.setUseARS(e);
              props.setIsUseCost(false);
              if (props.useUSD) {
                props.setUseUSD(!e);
              } else if (!e && !props.useUSD) {
                props.setIsUseCost(true);
              }
            }}
          />,
          "usd",
          <DollarOutlined />
        ),
      ],
    ];
  } else if (userToken?.role === 2 || userToken?.role === 3) {
    items = [
      ...items,
      getItem(
        <Link to="/jobs">{content.job ?? "Loading"}</Link>,
        "17",
        <ToolOutlined />
      ),
    ];
  }

  if (props.collapsed) {
    if (userToken?.role === 2 || userToken?.role === 3) {
      items = [
        //...items,
        getItem(
          <Link to="/jobs">{content.job ?? "Loading"}</Link>,
          "17",
          <ToolOutlined />
        ),
        //...configItems,
      ];
    } else if (userToken?.role === 1) {
      items = [
        ...items,
        getItem(
          <Link to="/jobs">{content.job ?? "Loading"}</Link>,
          "17",
          <ToolOutlined />
        ),
        ...configItems,
      ];
    }
  }

  items = [
    ...items,
    /*
    {
      key:"18",
      label: "Lista de precios",
      icon: <FileTextOutlined />,
      onClick: async function(){
        const columns = [
          {
            title: serviceScreen.add.form.code.title,
            dataIndex: "code",
            defaultSortOrder: "descend",
            width: "200px",
          },
          {
            title: serviceScreen.add.form.name.title,
            dataIndex: "name",
            width: "200px",
          },
          {
            title: serviceScreen.add.form.description.title,
            dataIndex: "description",
            width: "200px",
          },          
          {
            title: serviceScreen.add.form.credit_price.title + " (US$)",
            dataIndex: "",
            width: "200px",
          },
          // {
          //   title: "",
          //   dataIndex: "credit_price_dolar",
          //   width: "200px",
          // },
          {
            title: serviceScreen.add.form.credit_price.title + " (AR$)",
            dataIndex: "",
            width: "200px",
          },
          // {
          //   title: "",
          //   dataIndex: "credit_price_ars",
          //   width: "200px",
          // },
        ];

        let columns_aux = []
        let headObj = {};
        let headArray = [];
        let headName = [];
        let body = [];
        let extraDataArray = ["credit_price_ars", "credit_price_dolar"]

        let dolarPrice = 0

        const getDolarPrice = async () =>{
          await fetch(`https://dolarapi.com/v1/dolares/tarjeta`)
            .then((e)=> e.json())
            .then((e)=> {
              dolarPrice = e.venta * 1.21
              console.log(e)
            })
        }
        await getDolarPrice()
    
        columns.forEach((item) => {
          if (item.key !== "action") {
            headObj[item.dataIndex] = [];
            headName[item.title] = {};
            headArray.push(item.dataIndex);
          }
        });

        headName = [Object.keys(headName)];
        function validarFormatoFechaHora(cadena) {
          var patron = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z$/;
          if (patron.test(cadena)) {
            return true;
          } else {
            return false;
          }
        }

        servicesList?.results?.forEach((item) => {
          let temp = [];
          let price = 0
          Object.entries(item).forEach(([key, val]) => {
            // console.log(key, val)
            if(key == "credit_price") price = val
            if (headObj[key]) {
              if (extraDataArray.includes(key)) {
                let t = props?.extraData[key]?.filter((item) => {
                  return item.id == val;
                });
                let name = t?.[0]?.name;
                headObj[key].push(name);
                temp[headArray?.indexOf(key)] = name;
              } else {
                if (validarFormatoFechaHora(val))
                  val = dayjs(val).format("YYYY-MM-DD HH:mm");
                headObj[key].push(val);
                temp[headArray?.indexOf(key)] = val;
              }
            }
          });

          // temp[temp.length] = "US$"

          temp[temp.length] = "US$ " + (price * 1 * props.USDvalue).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          }).substring(1)

          // temp[temp.length] = "AR$"

          temp[temp.length] = "AR$ " + (dolarPrice * price * 1 * props.USDvalue).toLocaleString('es-AR', {
            style: 'currency',
            currency: 'ARS',
          }).substring(1)
          
          //(price * 1 * props.USDvalue).toFixed(2)
          // temp[temp.length] = "AR$ " + (dolarPrice * price * 1 * props.USDvalue).toFixed(2)

          body.push(temp);
        });
        
        const pdf = new jsPDF({ orientation: "l" });
        pdf.setFont("Helvetica", "bold");
        pdf.setFontSize(12);
        let text = "Lista de precios";
        let textWidth =
          (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        var docWidth = pdf.internal.pageSize.getWidth();
        var posX = (docWidth - textWidth) / 2;
        let fileTitle = "Lista de precios"
   
        //top left
        pdf.text(posX, 10, fileTitle);
        pdf.text(14, 10, "Transyss");

        text = user.company_name;
        textWidth =
          (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        docWidth = pdf.internal.pageSize.getWidth();
        posX = docWidth - textWidth;

        //top right
        pdf.text(`${user.company_name}`, posX - 15, 10);

        pdf.autoTable({
          //pageBreak: "avoid",
          head: headName,
          body: body,
          columnStyles: [
            {halign: "left", cellWidth: 20 },
            {halign: "left" },
            {halign: "left"},
            {halign: "right",cellWidth: 40 },
            {halign: "right",cellWidth: 40 }
           
          ],
          didDrawPage: function (data) {
            //bottom left
            pdf.text(
              `${user?.first_name} ${user?.last_name}`,
              data.settings.margin.left,
              pdf.internal.pageSize.getHeight() - 10
            );

            let pageSize = data.doc.getPageWidth();
            let pageWidth = pageSize; //pageSize.width;
            let text = dayjs().format(dateFormat);
            let fontSize = 12;
            let textWidth =
              (data.doc.getStringUnitWidth(text) * fontSize) /
              data.doc.internal.scaleFactor;
            let positionX = pageWidth - textWidth;

            //bottom rigth
            pdf.text(
              `${dayjs().format(dateFormat)}`,
              positionX - 15,
              pdf.internal.pageSize.getHeight() - 10
            );
          },
        });

        // Generar un blob (objeto binario grande) a partir del documento PDF
        const blob = pdf.output('blob');

        // Crear una URL para el blob
        const url = URL.createObjectURL(blob);

        // Abrir una nueva pestaña y mostrar el PDF
        window.open(url, '_blank');
      }
    }
    */
  ]

  return (
    <Sider
      collapsible
      collapsed={props.collapsed}
      onCollapse={(value) => props.setCollapsed(value)}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 64,
        bottom: 0,
        zIndex: 9,
      }}
    >
      <Menu
        selectable={false}
        theme="dark"
        className="sliderMenu"
        //selectedKeys={[selectedKeys]}
        mode="inline"
        //defaultOpenKeys={[modules.includes(selectedKeys) ? "adminPanel" : ""]}
        items={items}
        onSelect={(e) => {
          setSelectedKeys(e.key);
        }}
      />
    </Sider>
  );
}

export default Slider;
