export const CITIES_DETAILS_REQUEST = "CITIES_DETAILS_REQUEST";
export const CITIES_DETAILS_SUCCESS = "CITIES_DETAILS_SUCCESS";
export const CITIES_DETAILS_FAIL = "CITIES_DETAILS_FAIL";
export const CITIES_DETAILS_RESET = "CITIES_DETAILS_RESET";

export const COUNTRY_DETAILS_REQUEST = "COUNTRY_DETAILS_REQUEST";
export const COUNTRY_DETAILS_SUCCESS = "COUNTRY_DETAILS_SUCCESS";
export const COUNTRY_DETAILS_FAIL = "COUNTRY_DETAILS_FAIL";
export const COUNTRY_DETAILS_RESET = "COUNTRY_DETAILS_RESET";

export const STATE_DETAILS_REQUEST = "STATE_DETAILS_REQUEST";
export const STATE_DETAILS_SUCCESS = "STATE_DETAILS_SUCCESS";
export const STATE_DETAILS_FAIL = "STATE_DETAILS_FAIL";
export const STATE_DETAILS_RESET = "STATE_DETAILS_RESET";
