import axios from "axios";
import {
  ACCOUNT_MOVEMENT_DETAILS_FAIL,
  ACCOUNT_MOVEMENT_DETAILS_REQUEST,
  ACCOUNT_MOVEMENT_DETAILS_RESET,
  ACCOUNT_MOVEMENT_DETAILS_SUCCESS,
  ACCOUNT_MOVEMENT_DELETE_FAIL,
  ACCOUNT_MOVEMENT_DELETE_REQUEST,
  ACCOUNT_MOVEMENT_DELETE_RESET,
  ACCOUNT_MOVEMENT_DELETE_SUCCESS,
  ACCOUNT_MOVEMENT_REGISTER_FAIL,
  ACCOUNT_MOVEMENT_REGISTER_REQUEST,
  ACCOUNT_MOVEMENT_REGISTER_SUCCESS,
  ACCOUNT_MOVEMENT_UPDATE_FAIL,
  ACCOUNT_MOVEMENT_UPDATE_REQUEST,
  ACCOUNT_MOVEMENT_UPDATE_RESET,
  ACCOUNT_MOVEMENT_UPDATE_SUCCESS,
  ACCOUNT_MOVEMENTS_LIST_FAIL,
  ACCOUNT_MOVEMENTS_LIST_REQUEST,
  ACCOUNT_MOVEMENTS_LIST_RESET,
  ACCOUNT_MOVEMENTS_LIST_SUCCESS,
} from "../constants/accountMovementConstants";

import { apiURL } from "../store";

export const getAccountMovementDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ACCOUNT_MOVEMENT_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/account-movements/${id}`,
      config
    );

    dispatch({
      type: ACCOUNT_MOVEMENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_MOVEMENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteAccountMovement = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ACCOUNT_MOVEMENT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(
      `${apiURL}/api/account-movements/${id}`,
      config
    );

    dispatch({
      type: ACCOUNT_MOVEMENT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_MOVEMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getAccountMovements = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ACCOUNT_MOVEMENTS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/account-movements/?all=true`,
      config
    );

    dispatch({
      type: ACCOUNT_MOVEMENTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_MOVEMENTS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const updateAccountMovement =
  (id, body) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ACCOUNT_MOVEMENT_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const { data } = await axios.put(
        `${apiURL}/api/account-movements/${id}`,
        body,
        config
      );

      dispatch({
        type: ACCOUNT_MOVEMENT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ACCOUNT_MOVEMENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
export const createAccountMovement = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ACCOUNT_MOVEMENT_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(
      `${apiURL}/api/account-movements/`,
      body,
      config
    );

    dispatch({
      type: ACCOUNT_MOVEMENT_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_MOVEMENT_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
