import axios from "axios";
import {
  JOB_DETAILS_FAIL,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_RESET,
  JOB_DETAILS_SUCCESS,
  JOB_DELETE_FAIL,
  JOB_DELETE_REQUEST,
  JOB_DELETE_RESET,
  JOB_DELETE_SUCCESS,
  JOB_REGISTER_FAIL,
  JOB_REGISTER_REQUEST,
  JOB_REGISTER_SUCCESS,
  JOB_UPDATE_FAIL,
  JOB_UPDATE_REQUEST,
  JOB_UPDATE_RESET,
  JOB_UPDATE_SUCCESS,
  JOBS_LIST_FAIL,
  JOBS_LIST_REQUEST,
  JOBS_LIST_RESET,
  JOBS_LIST_SUCCESS,
  JOB_UPDATE_PENDING_REQUEST,
  JOB_UPDATE_PENDING_SUCCESS,
  JOB_UPDATE_PENDING_FAIL,
  JOB_UPDATE_IN_PROCCESS_REQUEST,
  JOB_UPDATE_IN_PROCCESS_SUCCESS,
  JOB_UPDATE_IN_PROCCESS_FAIL,
  JOB_UPDATE_FINISHED_REQUEST,
  JOB_UPDATE_FINISHED_SUCCESS,
  JOB_UPDATE_FINISHED_FAIL,
  JOB_UPDATE_SEND_REQUEST,
  JOB_UPDATE_SEND_SUCCESS,
  JOB_UPDATE_SEND_FAIL,
  JOB_UPDATE_PAUSE_REQUEST,
  JOB_UPDATE_PAUSE_SUCCESS,
  JOB_UPDATE_PAUSE_FAIL,
} from "../constants/jobConstants";

import { apiURL } from "../store";

export const getJobDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`${apiURL}/api/jobs/${id}`, config);

    dispatch({
      type: JOB_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteJob = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(`${apiURL}/api/jobs/${id}`, config);

    dispatch({
      type: JOB_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getJobs = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOBS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`${apiURL}/api/jobs/?all=true`, config);

    dispatch({
      type: JOBS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOBS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateJob = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(`${apiURL}/api/jobs/${id}`, body, config);

    dispatch({
      type: JOB_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerJob = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`${apiURL}/api/jobs/`, body, config);

    dispatch({
      type: JOB_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateJobPending = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_UPDATE_PENDING_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/jobs/${id}/pending/`,
      body,
      config
    );

    dispatch({
      type: JOB_UPDATE_PENDING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_UPDATE_PENDING_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateJobPause = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_UPDATE_PAUSE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/jobs/${id}/paused/`,
      body,
      config
    );

    dispatch({
      type: JOB_UPDATE_PAUSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_UPDATE_PAUSE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateJobInProccess = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_UPDATE_IN_PROCCESS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/jobs/${id}/in-process/`,
      body,
      config
    );

    dispatch({
      type: JOB_UPDATE_IN_PROCCESS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_UPDATE_IN_PROCCESS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateJobFinished = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_UPDATE_FINISHED_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        // "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/jobs/${id}/finished/`,
      body,
      config
    );

    dispatch({
      type: JOB_UPDATE_FINISHED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_UPDATE_FINISHED_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateJobSend = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_UPDATE_SEND_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        // "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/jobs/${id}/sent/`,
      body,
      config
    );

    dispatch({
      type: JOB_UPDATE_SEND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_UPDATE_SEND_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
