import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Button,
  Typography,
  Modal,
  Form,
  Input,
  Row,
  Col,
  notification,
  Popconfirm,
  Tooltip,
  Space,
} from "antd";

import {
  QuestionCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  getServices,
  registerService,
  updateService,
  deleteService,
} from "../redux/actions/serviceActions";
import {
  SERVICE_DELETE_RESET,
  SERVICE_REGISTER_RESET,
  SERVICE_UPDATE_RESET,
} from "../redux/constants/serviceConstants";
import ExportTable from "../components/ExportTable";

const { Title } = Typography;

function ServiceScreen(props) {
  const content = props.content;
  const userInfo = props.userInfo;
  const tableProps = props.tableProps;

  const useUSD = props.useUSD;
  const isUseCost = props.isUseCost;
  //--
  const USDvalue = props.USDvalue;
  const ARSvalue = props.ARSvalue;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    servicesList,
    loading: loadingServices,
    error,
  } = useSelector((state) => state.servicesList);

  const {
    success: successRegister,
    loading: loadingCreate,
    error: errorCreated,
    service: serviceCreateList,
  } = useSelector((state) => state.serviceCreate);

  const {
    success: successUpdate,
    loading: loadingUpdate,
    error: errorUpdate,
    service: serviceUpdateList,
  } = useSelector((state) => state.serviceUpdate);

  const {
    success: successDelete,
    loading: loadingDelete,
    error: errorDelete,
    service: serviceDeleteList,
  } = useSelector((state) => state.serviceDelete);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [serviceData, setServiceData] = useState({});
  const [sortOrder, setSortOrder] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isAddService, setIsAddService] = useState(false);
  const [deleteServiceId, setDeleteServiceId] = useState("");
  const [form] = Form.useForm();

  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    if (userInfo && userInfo.role === 3) {
      navigate("/notFound");
    } else if (userInfo && userInfo.role !== 3) {
      resetStates();
      dispatch(getServices());
    }
    if (userInfo === "no_logged") {
      navigate("/login");
    }
  }, [userInfo]);

  useEffect(() => {
    if (successUpdate) {
      notification.success({
        message: content?.notification?.edited?.success?.title,
        description: content?.notification?.edited?.success?.description,
        placement: "bottomRight",
      });
      //dispatch(getServices());
      setModalVisible(false);
    }
    if (errorCreated) {
      notification.error({
        message: content?.notification?.create?.error?.title,
        description: content?.notification?.create?.error?.description,
        placement: "bottomRight",
      });
      setModalVisible(false);
    }
    if (successRegister) {
      notification.success({
        message: content?.notification?.create?.success?.title,
        description: content?.notification?.create?.success?.description,
        placement: "bottomRight",
      });
      // dispatch(getServices());
      setModalVisible(false);
    }
    if (errorUpdate) {
      notification.error({
        message: content?.notification?.edited?.error?.title,
        description: content?.notification?.edited?.error?.description,
        placement: "bottomRight",
      });
      setModalVisible(false);
    }
    if (successDelete) {
      notification.success({
        message: content?.notification?.delete?.success?.title,
        description: content?.notification?.delete?.success?.description,
        placement: "bottomRight",
      });
      //dispatch(getServices());
      setModalVisible(false);
    }
    if (errorDelete) {
      notification.error({
        message: content?.notification?.delete?.error?.title,
        description: content?.notification?.delete?.error?.description,
        placement: "bottomRight",
      });
      setModalVisible(false);
    }
  }, [
    // dispatch,
    successRegister,
    successUpdate,
    successDelete,
    errorCreated,
    errorUpdate,
    successDelete,
    errorDelete,
  ]);

  useEffect(() => {
    let temp = [];
    let tempServicesList = servicesList?.results ?? [];
    temp = [...temp, ...tempServicesList];
    setServiceList(temp);
  }, [servicesList]);

  useEffect(() => {
    let serviceObj = {};
    let temp = [];
    let tempServicesUpdateList = serviceUpdateList ? [serviceUpdateList] : [];
    serviceList?.forEach((item) => {
      if (serviceDeleteList === 1 && item.id === deleteServiceId) return false;
      serviceObj[item.id] = item;
    });
    tempServicesUpdateList?.forEach((item) => {
      serviceObj[item.id] = item;
    });
    Object.entries(serviceObj).forEach(([key, item]) => {
      temp.push(item);
    });
    if (serviceCreateList) temp = [...temp, serviceCreateList];
    setServiceList(temp);
  }, [serviceUpdateList, serviceCreateList, serviceDeleteList]);

  const resetStates = () => {
    dispatch({
      type: SERVICE_DELETE_RESET,
    });
    dispatch({
      type: SERVICE_REGISTER_RESET,
    });
    dispatch({
      type: SERVICE_UPDATE_RESET,
    });
  };

  const handleAddClick = () => {
    setIsAddService(true);
    setModalTitle(content.add.form.title);
    let record = {
      description: "",
      national_price: "",
      international_price: "",
      credit_price: "",
      name: "",
      code: "",
    };
    setServiceData({});
    setModalVisible(true);
    form.setFieldsValue(record);
  };

  const handleEditClick = (record) => {
    setIsAddService(false);
    setModalTitle(content.edit.title);
    setModalVisible(true);
    setServiceData(record);
    form.setFieldsValue(record);
  };

  const handleDeleteClick = (id) => {
    resetStates();
    setDeleteServiceId(id);
    dispatch(deleteService(id));
  };

  const handleSubmit = (values) => {
    resetStates();
    values.international_price = 0;
    values.national_price = 0;
    if (isAddService) {
      dispatch(registerService(values));
    } else {
      dispatch(updateService(serviceData.id, values));
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const newSortOrder = {};
    if (sorter.columnKey) {
      newSortOrder[sorter.columnKey] = sorter.order;
    }
    setSortOrder(newSortOrder);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const columns = [
    {
      title: content.add.form.code.title,
      dataIndex: "code",
      defaultSortOrder: "descend",
      width: "200px",
      sorter: (a, b) => {
        if (a.code < b.code) {
          return -1;
        }
        if (a.code > b.code) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      title: content.add.form.name.title,
      dataIndex: "name",
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
      width: "200px",
    },
    {
      title: content.add.form.description.title,
      dataIndex: "description",
      width: "200px",
      sorter: (a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      title: content.add.form.credit_price.title,
      dataIndex: "credit_price",
      width: "200px",
      sorter: (a, b) => {
        a = parseFloat(a.credit_price);
        b = parseFloat(b.credit_price);
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(
          (isUseCost
            ? text
            : useUSD
            ? parseFloat(text) * parseFloat(USDvalue)
            : parseFloat(text) * parseFloat(ARSvalue)
          ).toLocaleString(window.clientInformation.language, {
            minimumFractionDigits: 2,
          })
        ); //;
      },
    },
    {
      //title: "Action",
      dataIndex: "id",
      key: "action",
      width: "60px",
      render: (id, record) => (
        <Space size={18}>
          <Tooltip placement="top" title={content.tooltips.edit}>
            <EditOutlined
              onClick={() => {
                handleEditClick(record);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.delete}>
            <Popconfirm
              loading={loadingDelete}
              title={content?.confirmation?.delete?.title}
              description={content?.confirmation?.delete?.description}
              onConfirm={() => handleDeleteClick(id)}
              cancelText={content?.confirmation?.delete?.button.cancel}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  /*
  const filteredServices = servicesList?.results?.filter((service) =>
    Object.values(service)
      .join(" ")
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );
  */

  const filteredServices = serviceList?.filter((service) =>
    Object.values(service)
      .join(" ")
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );

  if (userInfo.role === 3 || !userInfo || userInfo === "no_logged")
    return <></>;
  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      <div style={{ marginBottom: 16 }}>
        {/*<h1 className="title" style={{ margin: 0 }}>
        {content.title}
      </h1>*/}
        <Title className="titleCenter">{content.title}</Title>
        <Row>
          <Col xs={18} sm={20} md={20} lg={21} xl={21}>
            <Input
              placeholder={content.search}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Col>
          <ExportTable
            data={filteredServices}
            fileTitle={content.title}
            columns={columns}
            extraData={{}}
          />
          <Col xs={2} sm={1} md={1} lg={1} xl={1}>
            <Button
              icon={<PlusOutlined />}
              // type="primary"
              shape="circle"
              onClick={handleAddClick}
              style={{ marginBottom: 16, float: "right" }}
            ></Button>
          </Col>
        </Row>
      </div>
      <Table
        {...tableProps}
        dataSource={filteredServices}
        columns={columns}
        onChange={handleTableChange}
        loading={loadingServices}
      />
      <Modal
        width={800}
        title={modalTitle}
        open={modalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={serviceData}
          onFinish={handleSubmit}
        >
          <Form.Item
            label={content.add.form.name.title}
            name="name"
            rules={[
              { required: true, message: content.add.form.name.placeholder },
            ]}
          >
            <Input />
          </Form.Item>
          {/*
          <Form.Item
            label={content.add.form.code.title}
            name="code"
            rules={[
              { required: true, message: content.add.form.code.placeholder },
            ]}
          >
            <Input />
          </Form.Item>
          */}
          <Form.Item
            label={content.add.form.credit_price.title}
            name="credit_price"
            rules={[
              {
                required: true,
                message: content.add.form.credit_price.placeholder,
              },
            ]}
          >
            <Input type="number" min="0" step="0.01" />
          </Form.Item>
          <Form.Item
            label={content.add.form.description.title}
            name="description"
            //rules={[{ required: true, message: content.add.form.description.placeholder}]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ float: "right" }}
              type="primary"
              htmlType="submit"
              loading={isAddService ? loadingCreate : loadingUpdate}
            >
              {isAddService ? content.button.add : content.button.edit}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default ServiceScreen;
