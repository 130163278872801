import React, { useEffect, useState } from "react";

function LanguageSwitcher(props) {
  let languaje = props.languaje ?? "es";
  const languages = [
    { code: "en", name: "English" },
    { code: "es", name: "Deutsch" },
  ];
  const options = languages.map((language) => {
    if (language.code !== languaje) {
      return (
        <li key={language.code}>
          <div
            onClick={(e, a, b) => {
              props.handleSetLanguage(e.target.className);
            }}
            value={language.code}
            className={language.code}
          ></div>
        </li>
      );
    }
  });
  return (
    <div className="lang">
      <div className={languaje}></div>
      <ul className="dropdown">{options}</ul>
    </div>
  );
}

export default LanguageSwitcher;
