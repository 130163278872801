export const FILE_REGISTER_REQUEST = "FILE_REGISTER_REQUEST";
export const FILE_REGISTER_SUCCESS = "FILE_REGISTER_SUCCESS";
export const FILE_REGISTER_FAIL = "FILE_REGISTER_FAIL";
export const FILE_REGISTER_RESET = "FILE_REGISTER_RESET";

export const FILE_DETAILS_REQUEST = "FILE_DETAILS_REQUEST";
export const FILE_DETAILS_SUCCESS = "FILE_DETAILS_SUCCESS";
export const FILE_DETAILS_FAIL = "FILE_DETAILS_FAIL";
export const FILE_DETAILS_RESET = "FILE_DETAILS_RESET";

export const FILE_UPDATE_REQUEST = "FILE_UPDATE_REQUEST";
export const FILE_UPDATE_SUCCESS = "FILE_UPDATE_SUCCESS";
export const FILE_UPDATE_FAIL = "FILE_UPDATE_FAIL";
export const FILE_UPDATE_RESET = "FILE_UPDATE_RESET";

export const FILES_LIST_REQUEST = "FILES_LIST_REQUEST";
export const FILES_LIST_SUCCESS = "FILES_LIST_SUCCESS";
export const FILES_LIST_FAIL = "FILES_LIST_FAIL";
export const FILES_LIST_RESET = "FILES_LIST_RESET";

export const FILE_DELETE_REQUEST = "FILE_DELETE_REQUEST";
export const FILE_DELETE_SUCCESS = "FILE_DELETE_SUCCESS";
export const FILE_DELETE_FAIL = "FILE_DELETE_FAIL";
export const FILE_DELETE_RESET = "FILE_DELETE_RESET";

export const FILE_UPDATE_VISIBLE_REQUEST = "FILE_UPDATE_VISIBLE_REQUEST";
export const FILE_UPDATE_VISIBLE_SUCCESS = "FILE_UPDATE_VISIBLE_SUCCESS";
export const FILE_UPDATE_VISIBLE_FAIL = "FILE_UPDATE_VISIBLE_FAIL";
export const FILE_UPDATE_VISIBLE_RESET = "FILE_UPDATE_VISIBLE_RESET";
