import React from "react";
import { Button, Col, Layout } from "antd";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  FileExcelOutlined,
  QuestionCircleOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

function ExportTable(props) {
  const data = props?.data ?? [];
  const fileTitle = props?.fileTitle ?? "data";
  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  let colWidth = props.colWidth ? props.colWidth : 1;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  function validarFormatoFechaHora(cadena) {
    var patron = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z$/;
    if (patron.test(cadena)) {
      return true;
    } else {
      return false;
    }
  }

  const generateDataForExport = (payload) => {
    let head = [];
    let headObj = {};
    let headArray = [];
    let headName = [];
    let body = [];
    let extraDataArray = props?.extraData ? Object.keys(props?.extraData) : [];
    props?.columns.forEach((item) => {
      if (item.key !== "action") {
        headObj[item.dataIndex] = [];
        headName[item.title] = {};
        headArray.push(item.dataIndex);
      }
    });
    headName = [Object.keys(headName)];
    payload.data.forEach((item) => {
      let temp = [];
      Object.entries(item).forEach(([key, val]) => {
        if (headObj[key]) {
          if (extraDataArray.includes(key)) {
            let t = props?.extraData[key]?.filter((item) => {
              return item.id == val;
            });
            let name = t?.[0]?.first_name
              ? t?.[0]?.first_name + " " + t?.[0]?.last_name
              : t?.[0]?.name;
            headObj[key].push(name);
            temp[headArray?.indexOf(key)] = name;
          } else {
            if (validarFormatoFechaHora(val))
              val = dayjs(val).format("YYYY-MM-DD HH:mm");
            headObj[key].push(val);
            temp[headArray?.indexOf(key)] = val;
          }
        }
      });
      body.push(temp);
    });
    /*
    body = Array(50)
      .fill()
      .map(function (item) {
        return body[0];
      });*/
    return {
      head: headName,
      body,
    };
  };

  const exportTableDataToPdf = (data) => {
    let payload = {
      data,
    };
    data = generateDataForExport(payload);
    const pdf = new jsPDF({ orientation: "l" });
    pdf.setFont("Helvetica", "bold");
    pdf.setFontSize(12);
    let text = fileTitle;
    let textWidth =
      (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    var docWidth = pdf.internal.pageSize.getWidth();
    var posX = (docWidth - textWidth) / 2;

    //top left
    pdf.text(posX, 10, fileTitle);
    pdf.text(14, 10, "Transyss");

    text = user.company_name;
    textWidth =
      (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    docWidth = pdf.internal.pageSize.getWidth();
    posX = docWidth - textWidth;

    //top right
    pdf.text(`${user.company_name}`, posX - 15, 10);

    pdf.autoTable({
      //pageBreak: "avoid",
      head: data.head,
      body: data.body,
      didDrawPage: function (data) {
        //bottom left
        pdf.text(
          `${user?.first_name} ${user?.last_name}`,
          data.settings.margin.left,
          pdf.internal.pageSize.getHeight() - 10
        );

        let pageSize = data.doc.getPageWidth();
        let pageWidth = pageSize; //pageSize.width;
        let text = dayjs().format(dateFormat);
        let fontSize = 12;
        let textWidth =
          (data.doc.getStringUnitWidth(text) * fontSize) /
          data.doc.internal.scaleFactor;
        let positionX = pageWidth - textWidth;

        //bottom rigth
        pdf.text(
          `${dayjs().format(dateFormat)}`,
          positionX - 15,
          pdf.internal.pageSize.getHeight() - 10
        );
      },
    });
    pdf.save(fileTitle + "." + dayjs().format("YYYYMMDDHHmmss") + ".pdf");
  };

  const dataToExport = generateDataForExport({
    data,
  });

  return (
    <>
      <Col span={colWidth}>
        <CSVLink
          filename={fileTitle + "." + dayjs().format("YYYYMMDDHHmmss") + ".csv"}
          data={[
            ...(dataToExport?.head && dataToExport?.body
              ? dataToExport?.head
              : ""),
            ...(props?.data?.length > 0
              ? dataToExport?.body
              : dataToExport?.head),
          ]}
        >
          <Button
            shape="circle"
            style={{ marginBottom: 16, float: "right" }}
            icon={<FileExcelOutlined />}
          ></Button>
        </CSVLink>
      </Col>
      <Col span={colWidth}>
        <Button
          onClick={() => {
            exportTableDataToPdf(data);
          }}
          shape="circle"
          style={{ marginBottom: 16, float: "right" }}
          icon={<FilePdfOutlined />}
        ></Button>
      </Col>
    </>
  );
}

export default ExportTable;
