import {
  JOB_DETAILS_FAIL,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_RESET,
  JOB_DETAILS_SUCCESS,
  JOB_DELETE_FAIL,
  JOB_DELETE_REQUEST,
  JOB_DELETE_RESET,
  JOB_DELETE_SUCCESS,
  JOB_REGISTER_FAIL,
  JOB_REGISTER_REQUEST,
  JOB_REGISTER_SUCCESS,
  JOB_REGISTER_RESET,
  JOB_UPDATE_FAIL,
  JOB_UPDATE_REQUEST,
  JOB_UPDATE_RESET,
  JOB_UPDATE_SUCCESS,
  JOBS_LIST_FAIL,
  JOBS_LIST_REQUEST,
  JOBS_LIST_RESET,
  JOBS_LIST_SUCCESS,
  JOB_UPDATE_PENDING_SUCCESS,
  JOB_UPDATE_PENDING_REQUEST,
  JOB_UPDATE_PENDING_FAIL,
  JOB_UPDATE_PENDING_RESET,
  JOB_UPDATE_IN_PROCCESS_REQUEST,
  JOB_UPDATE_IN_PROCCESS_RESET,
  JOB_UPDATE_IN_PROCCESS_FAIL,
  JOB_UPDATE_IN_PROCCESS_SUCCESS,
  JOB_UPDATE_FINISHED_REQUEST,
  JOB_UPDATE_FINISHED_RESET,
  JOB_UPDATE_FINISHED_FAIL,
  JOB_UPDATE_FINISHED_SUCCESS,
  JOB_UPDATE_SEND_REQUEST,
  JOB_UPDATE_SEND_SUCCESS,
  JOB_UPDATE_SEND_FAIL,
  JOB_UPDATE_SEND_RESET,
  JOB_UPDATE_PAUSE_REQUEST,
  JOB_UPDATE_PAUSE_SUCCESS,
  JOB_UPDATE_PAUSE_FAIL,
  JOB_UPDATE_PAUSE_RESET,
} from "../constants/jobConstants";

export const getJobsReducer = (state = {}, action) => {
  switch (action.type) {
    case JOBS_LIST_REQUEST:
      return { loading: true };

    case JOBS_LIST_SUCCESS:
      return { loading: false, jobsList: action.payload };

    case JOBS_LIST_FAIL:
      return { loading: false, error: action.payload };

    case JOBS_LIST_RESET:
      return {};

    default:
      return state;
  }
};

export const getJobDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_DETAILS_REQUEST:
      return { loading: true };

    case JOB_DETAILS_SUCCESS:
      return { loading: false, job: action.payload };

    case JOB_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case JOB_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteJobReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_DELETE_REQUEST:
      return { loading: true };

    case JOB_DELETE_SUCCESS:
      return { loading: false, success: true, job: 1 }; //action.payload };

    case JOB_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case JOB_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

export const updateJobReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_UPDATE_REQUEST:
      return { loading: true };

    case JOB_UPDATE_SUCCESS:
      return { loading: false, success: true, job: action.payload };

    case JOB_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case JOB_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const registerJobReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_REGISTER_REQUEST:
      return { loading: true };

    case JOB_REGISTER_SUCCESS:
      return { loading: false, success: true, job: action.payload };

    case JOB_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case JOB_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};

//------

export const updateJobPendingReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_UPDATE_PENDING_REQUEST:
      return { loading: true };

    case JOB_UPDATE_PENDING_SUCCESS:
      return { loading: false, success: true, job: action.payload };

    case JOB_UPDATE_PENDING_FAIL:
      return { loading: false, error: action.payload };

    case JOB_UPDATE_PENDING_RESET:
      return {};

    default:
      return state;
  }
};

export const updateJobInProccessReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_UPDATE_IN_PROCCESS_REQUEST:
      return { loading: true };

    case JOB_UPDATE_IN_PROCCESS_SUCCESS:
      return { loading: false, success: true, job: action.payload };

    case JOB_UPDATE_IN_PROCCESS_FAIL:
      return { loading: false, error: action.payload };

    case JOB_UPDATE_IN_PROCCESS_RESET:
      return {};

    default:
      return state;
  }
};

export const updateJobFinishReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_UPDATE_FINISHED_REQUEST:
      return { loading: true };

    case JOB_UPDATE_FINISHED_SUCCESS:
      return { loading: false, success: true, job: action.payload };

    case JOB_UPDATE_FINISHED_FAIL:
      return { loading: false, error: action.payload };

    case JOB_UPDATE_FINISHED_RESET:
      return {};

    default:
      return state;
  }
};

export const updateJobSendReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_UPDATE_SEND_REQUEST:
      return { loading: true };

    case JOB_UPDATE_SEND_SUCCESS:
      return { loading: false, success: true, job: action.payload };

    case JOB_UPDATE_SEND_FAIL:
      return { loading: false, error: action.payload };

    case JOB_UPDATE_SEND_RESET:
      return {};

    default:
      return state;
  }
};

export const updateJobPauseReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_UPDATE_PAUSE_REQUEST:
      return { loading: true };

    case JOB_UPDATE_PAUSE_SUCCESS:
      return { loading: false, success: true, job: action.payload };

    case JOB_UPDATE_PAUSE_FAIL:
      return { loading: false, error: action.payload };

    case JOB_UPDATE_PAUSE_RESET:
      return {};

    default:
      return state;
  }
};
