import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Button,
  Form,
  Input,
  Select,
  Collapse,
  InputNumber,
  notification,
  Upload,
  Typography,
  Modal,
  Row,
  Divider,
  Col,
} from "antd";

import {
  CITIES_DETAILS_RESET,
  STATE_DETAILS_RESET,
} from "../redux/constants/locationConstants";
import {
  getUserDetails,
  updateImageProfile,
  updateProfile,
} from "../redux/actions/userActions";
import {
  getCities,
  getCountries,
  getStates,
} from "../redux/actions/locationActions";
import { getProfileDetails } from "../redux/actions/userActions";
import {
  registerAddress,
  updateAddress,
} from "../redux/actions/addressActions";

import { PlusOutlined } from "@ant-design/icons";
import {
  USER_UPDATE_PROFILE_IMAGE_RESET,
  USER_UPDATE_PROFILE_RESET,
} from "../redux/constants/userConstants";
import { apiURL } from "../redux/store";

const { Option } = Select;
const { Panel } = Collapse;
const { Title } = Typography;

const ProfileScreen = (props) => {
  const content = props.content;
  const userData = props.userInfo;
  const dataToken = props.dataToken;

  const [form] = Form.useForm();

  const [addressId, setAddressId] = useState("");

  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countryIso2, setCountryIso2] = useState("");

  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [sex, setSex] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const [imageToSave, setImageToSave] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: user?.profile_picture ?? "/empty-user.png", //"https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"  //"https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
  ]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success, error: errorUpdate } = userUpdateProfile;

  const userUpdateImageProfile = useSelector(
    (state) => state.userUpdatePicture
  );
  const { success: successImage, error: errorUpdateImage } =
    userUpdateImageProfile;

  const { countries, loading: loadingCountries } = useSelector(
    (state) => state.countries
  );
  const { states, loading: loadingState } = useSelector(
    (state) => state.states
  );
  const { cities, loading: loadingCities } = useSelector(
    (state) => state.cities
  );

  useEffect(() => {
    resetStates();
    //dispatch(getProfileDetails());
  }, []);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    if (success) {
      if (imageToSave) {
        dispatch(updateImageProfile(imageToSave));
        setImageToSave(false);
      }
      if (!imageToSave) {
        dispatch(getProfileDetails());
        resetStates();
      }
      notification.success({
        message: content?.notification?.edited?.success.title,
        description: content?.notification?.edited?.success.description,
        placement: "bottomRight",
      });
    }
    if (successImage) {
      resetStates();
    }
    if (errorUpdate) {
      notification.error({
        message: content?.notification?.edited?.error.title,
        description: content?.notification?.edited?.error.description,
        placement: "bottomRight",
      });
    }
    //resetStates();
  }, [errorUpdate, success, successImage]);

  useEffect(() => {
    if (user) {
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url:
            (window.location.hostname == "localhost" ? apiURL : "") +
              user?.profile_picture ?? "", //"https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"  //"https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
        },
      ]);
      //let tempSex = "";
      let tempUser = user;
      tempUser.password = "";
      if (user.address_.length > 0) {
        tempUser.house_number = user.address_?.[0].house_number;
        tempUser.street = user.address_?.[0].street_name;
        tempUser.country = user.address_?.[0].country;
        tempUser.state = user.address_?.[0].province;
        tempUser.city = user.address_?.[0].city;
        setCity(tempUser.city);
        setProvince(tempUser.state);
        setCountry(tempUser.country);
        setAddressId(user.address_?.[0].id);
      } else {
        setAddressId(false);
        if (!countries) dispatch(getCountries());
      }
      // if (isNaN(user.sex)) {
      //  tempSex = content?.form?.sex.options.indexOf(user.sex);
      tempUser.sex = user.sex; // content?.form?.sex.options[user.sex - 1 ?? 1];
      //  } else {
      //    tempSex = user.sex;
      //    tempUser.sex = content?.form?.sex.options[user.sex ?? 1];
      //  }
      tempUser.company_name = user.company_name;
      tempUser.phone = user.cell_phone;
      setSex(tempUser?.sex ?? 1);
      form.setFieldsValue(tempUser);
      //if (!countries) dispatch(getCountries());
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      let iso2Country;
      let iso2State;
      if (!countries) dispatch(getCountries());
      if (country && countries && !states) {
        countries.forEach((e) => {
          if (
            country
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") ===
            e?.name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          ) {
            iso2Country = e.iso2;
          }
        });
        if (iso2Country) {
          dispatch(getStates(iso2Country));
          setCountryIso2(iso2Country);
        }
      }

      if (countries && states && !cities && province) {
        states.forEach((e) => {
          if (
            province
              ?.toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") ===
            e?.name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          ) {
            iso2State = e.iso2;
            //setProvince(e.name);
          }
        });
        if (iso2State) {
          dispatch(getCities(countryIso2, iso2State));
        }
      }
    }
  }, [country, countries, states, province]);

  const dropdownSearch = (input, option) => {
    return (option?.children ?? "")
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(
        input
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      );
  };

  const submitHandler = (e) => {
    const formData = new FormData();
    if (e?.profile_picture?.file) {
      formData.append("image", e.profile_picture.file);
    }

    const newUser = {
      ...e,
      first_name: e.first_name,
      last_name: e.last_name,
      email: e.email,
      username: e.username,
      password: e.password === "" ? undefined : e.password,
      sex,
      cell_phone: e.phone,
      profile_picture: undefined,
    };
    const address = {
      country,
      province,
      city,
      street_name: e.street,
      house_number: e.house_number,
      user: user.id,
    };
    if (!addressId) {
      dispatch(registerAddress(address));
    } else {
      dispatch(updateAddress(addressId, address));
    }
    dispatch(updateProfile(newUser));
    setImageToSave(false);
    if (e?.profile_picture?.file) {
      setImageToSave(formData);
      //dispatch(updateImageProfile(formData));
    }
  };

  const resetStates = () => {
    dispatch({
      type: USER_UPDATE_PROFILE_RESET,
    });
    dispatch({
      type: USER_UPDATE_PROFILE_IMAGE_RESET,
    });
  };

  const sortByName = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url?.substring(file.url?.lastIndexOf("/") + 1)
    );
  };

  if (states) states.sort(sortByName);
  if (countries) countries.sort(sortByName);
  if (cities) cities.sort(sortByName);

  return (
    <div className="centerForm">
      <Form
        form={form}
        style={{ width: "50%" }}
        onFinish={submitHandler}
        layout="vertical"
        autoComplete="off"
      >
        <Title className="titleCenter">{content.title}</Title>
        <Collapse defaultActiveKey={["1", "2", "4", "5"]} ghost>
          <Panel
            header={
              <Divider style={{ margin: 0 }}>{content.divisor.basic}</Divider>
            }
            key="1"
          >
            <Form.Item style={{ marginBottom: 0 }}>
              <Row>
                <Col span={20}>
                  <Row>
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: "95%",
                      }}
                      label={content?.form?.first_name.title ?? "Loading..."}
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message:
                            content?.form?.first_name.placeholder ??
                            "Loading...",
                        },
                      ]}
                    >
                      <Input autoComplete="none" />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: "95%",
                        float: "right",
                      }}
                      label={content?.form?.last_name.title ?? "Loading..."}
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message:
                            content?.form?.last_name.placeholder ??
                            "Loading...",
                        },
                      ]}
                    >
                      <Input autoComplete="none" />
                    </Form.Item>
                  </Row>
                </Col>
                <Col span={4}>
                  <Form.Item
                    style={{
                      display: "inline-block",
                      // width: "calc(49% - 8px)",
                      float: "right",
                    }}
                    label="Picture"
                    name="profile_picture"
                  >
                    <Upload
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onRemove={(file) => {
                        const index = fileList.indexOf(file);
                        const newFileList = fileList.slice();
                        newFileList.splice(index, 1);
                        setFileList(newFileList);
                      }}
                      onChange={(e) => setFileList(e.fileList)}
                      beforeUpload={() => false}
                    >
                      {fileList.length < 1 && (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Subir</div>
                        </div>
                      )}
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              label={content?.form?.email.title ?? "Loading..."}
              name="email"
              rules={[
                {
                  required: true,
                  message: content?.form?.email.placeholder ?? "Loading...",
                },
              ]}
            >
              <Input autoComplete="none" />
            </Form.Item>
            <Form.Item
              label={content?.form?.dni?.title ?? "Loading..."}
              name="dni"
              rules={[
                {
                  required: true,
                  message: content?.form?.dni.placeholder ?? "Loading...",
                },
              ]}
            >
              <Input autoComplete="none" />
            </Form.Item>
            <Form.Item
              label={content?.form?.username.title ?? "Loading..."}
              name="username"
              rules={[
                {
                  required: true,
                  message: content?.form?.username.placeholder ?? "Loading...",
                },
              ]}
            >
              <Input autoComplete="none" />
            </Form.Item>
            <Form.Item
              name="sex"
              label={content?.form?.sex.title ?? "Loading..."}
              rules={[
                {
                  required: true,
                  message: content?.form?.sex.placeholder ?? "Loading...",
                },
              ]}
            >
              <Select
                placeholder={content?.form?.sex.title ?? "Loading..."}
                onChange={(value) => {
                  setSex(value);
                }}
                value={sex}
                allowClear
              >
                <Option value={1} key={1}>
                  {content?.form?.sex.options[1] ?? "Loading..."}
                </Option>
                <Option value={2} key={2}>
                  {content?.form?.sex.options[2] ?? "Loading..."}
                </Option>
                <Option value={3} key={3}>
                  {content?.form?.sex.options[3] ?? "Loading..."}
                </Option>
              </Select>
            </Form.Item>
          </Panel>
          <Panel
            header={
              <Divider style={{ margin: 0 }}>
                {content.divisor.ubication}
              </Divider>
            }
            key="2"
          >
            <Form.Item style={{ marginBottom: 0 }}>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "inline-flex",
                  width: "100%",
                }}
              >
                <Form.Item
                  style={{ display: "inline-block", width: "31%" }}
                  name="country"
                  label={content?.form?.country.title ?? "Loading..."}
                  rules={[
                    {
                      required: true,
                      message:
                        content?.form?.country.placeholder ?? "Loading...",
                    },
                  ]}
                >
                  <Select
                    name="country"
                    showSearch
                    value={country}
                    optionFilterProp="children"
                    disabled={loadingCountries}
                    loading={loadingCountries}
                    filterOption={dropdownSearch}
                    onChange={(value, a, b) => {
                      value = a.children;
                      let iso2 = a["data-iso2"];
                      setCountry(value);
                      setCountryIso2(iso2);
                      setCity("");
                      setProvince("");
                      dispatch({ type: STATE_DETAILS_RESET });
                      dispatch({ type: CITIES_DETAILS_RESET });
                      form.setFieldsValue({ state: "", city: "", cities: "" });
                    }}
                  >
                    {countries?.map((obj) => {
                      return (
                        <Option
                          key={obj.id}
                          value={obj.name}
                          data-iso2={obj.iso2}
                        >
                          {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ display: "inline-block", width: "31%" }}
                  name="state"
                  label={content?.form?.state.title ?? "Loading..."}
                  rules={[
                    {
                      required: true,
                      message: content?.form?.state.placeholder ?? "Loading...",
                    },
                  ]}
                >
                  <Select
                    name="state"
                    showSearch
                    value={province}
                    optionFilterProp="children"
                    filterOption={dropdownSearch}
                    disabled={country ? false : true}
                    loading={loadingState}
                    onChange={(value, a, b) => {
                      value = a.children;
                      let iso2 = a["data-iso2"];
                      dispatch({ type: CITIES_DETAILS_RESET });
                      form.setFieldsValue({
                        cities: "",
                        city: "",
                      });
                      setCity("");
                      // dispatch(getCities(countryIso2, iso2));
                      setProvince(a.children);
                    }}
                  >
                    {states?.map((obj) => {
                      return (
                        <Option
                          key={obj.id}
                          value={obj.id}
                          data-iso2={obj.iso2}
                        >
                          {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>{" "}
                <Form.Item
                  style={{ display: "inline-block", width: "31%" }}
                  label={content?.form?.city.title ?? "Loading..."}
                  rules={[
                    {
                      required: true,
                      message: content?.form?.city.placeholder,
                    },
                  ]}
                  name="city"
                >
                  <Select
                    showSearch
                    value={city}
                    name="city"
                    loading={loadingCities}
                    optionFilterProp="children"
                    disabled={province ? false : true}
                    filterOption={dropdownSearch}
                    onChange={(value, a, b) => {
                      setCity(a.children);
                    }}
                  >
                    {cities?.map((obj) => {
                      return (
                        <Option
                          key={obj.id}
                          value={obj.id}
                          data-iso2={obj.iso2}
                        >
                          {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "inline-flex",
                  width: "100%",
                }}
              >
                <Form.Item
                  label={content?.form?.street.title ?? "Loading..."}
                  style={{ display: "inline-block", width: "72%" }}
                  name="street"
                  rules={[
                    {
                      required: true,
                      message:
                        content?.form?.street.placeholder ?? "Loading...",
                    },
                  ]}
                >
                  <Input
                    //value={street}
                    name="house_number"
                    placeholder={content?.form?.street.title ?? "Loading..."}
                    autoComplete="none"
                  />
                </Form.Item>
                <Form.Item
                  label={content?.form?.house_number.title ?? "Loading..."}
                  name="house_number"
                  style={{ display: "inline-block", width: "25%" }}
                  rules={[
                    {
                      required: true,
                      message:
                        content?.form?.house_number.placeholder ?? "Loading...",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    style={{ display: "inline-block", width: "100%" }}
                    autoComplete="none"
                    onChange={(e, a, s) => {
                      setHouseNumber(e);
                    }}
                  />
                </Form.Item>
              </div>
            </Form.Item>
          </Panel>
          <Panel
            header={
              <Divider style={{ margin: 0 }}>
                {content.divisor.password}
              </Divider>
            }
            key="3"
          >
            <Form.Item
              name="password"
              label={content?.form?.password_1.title ?? "Loading..."}
              rules={[
                {
                  required: false,
                  message:
                    content?.form?.password_2.placeholder ?? "Loading...",
                },
              ]}
              hasFeedback
            >
              <Input.Password autoComplete="none" />
            </Form.Item>
            <Form.Item
              name="confirm"
              label={content?.form?.password_2.title ?? "Loading..."}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: false,
                  message:
                    content?.form?.password_2.placeholder ?? "Loading...",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
            {/*
            {dataToken?.role === 2 && (
              <>
                <Divider>Other info</Divider>
                <Form.Item
                  label={content?.form?.bussiness_phone.title ?? "Loading..."}
                  name="business_phone"
                  rules={[
                    {
                      required: true,
                      message:
                        content?.form?.bussiness_phone.placeholder ??
                        "Loading...",
                    },
                  ]}
                >
                  <Input autoComplete="none" />
                </Form.Item>
                <Form.Item
                  label={content?.form?.profession.title ?? "Loading..."}
                  name="profession"
                  rules={[
                    {
                      required: true,
                      message:
                        content?.form?.profession.placeholder ?? "Loading...",
                    },
                  ]}
                >
                  <Input autoComplete="none" />
                </Form.Item>
              </>
            )}
            {dataToken?.role === 3 && (
              <>
                <Divider>Company info</Divider>
                <Form.Item
                  label={content?.form?.company_name.title ?? "Loading..."}
                  name="company_name"
                  rules={[
                    {
                      required: true,
                      message:
                        content?.form?.company_name.placeholder ?? "Loading...",
                    },
                  ]}
                >
                  <Input autoComplete="none" />
                </Form.Item>
                <Form.Item
                  label={content?.form?.phone?.title ?? "Loading..."}
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message:
                        content?.form?.username.placeholder ?? "Loading...",
                    },
                  ]}
                >
                  <PhoneInput
                    enableSearch
                    country={"ar"}
                    inputClass={
                      "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                    }
                    buttonClass={
                      "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                    }
                    searchClass={
                      "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                    }
                    // dropdownClass="phoneInput"
                    inputStyle={{
                      width: "100%",
                      height: "32px",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                      background: props.darkMode ? "#141414" : "white",
                      color: props.darkMode ? "white" : "black",
                    }}
                    buttonStyle={{
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: props.darkMode ? "#424242 " : "#d9d9d9",
                      background: props.darkMode ? "#141414" : "white",
                      color: props.darkMode ? "white" : "black",
                    }}
                    dropdownStyle={{
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                      background: props.darkMode ? "#141414" : "white",
                      color: props.darkMode ? "white" : "black",
                    }}
                    searchStyle={{
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                      background: props.darkMode ? "#141414" : "white",
                      color: props.darkMode ? "white" : "black",
                    }}
                  />
                </Form.Item>
                {/*
            <Form.Item
              label={content?.form?.phone.title ?? "Loading..."}
              name="phone"
              rules={[
                {
                  required: true,
                  message: content?.form?.phone.placeholder ?? "Loading...",
                },
              ]}
            >
              <Input autoComplete="none" />
            </Form.Item>
            
              </>
            )}*/}
          </Panel>
          {dataToken?.role === 2 && (
            <Panel
              header={
                <Divider style={{ margin: 0 }}>{content.divisor.info}</Divider>
              }
              key="4"
            >
              <Form.Item
                label={content?.form?.bussiness_phone.title ?? "Loading..."}
                name="business_phone"
                rules={[
                  {
                    required: true,
                    message:
                      content?.form?.bussiness_phone.placeholder ??
                      "Loading...",
                  },
                ]}
              >
                <Input autoComplete="none" />
              </Form.Item>
              <Form.Item
                label={content?.form?.profession.title ?? "Loading..."}
                name="profession"
                rules={[
                  {
                    required: true,
                    message:
                      content?.form?.profession.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Input autoComplete="none" />
              </Form.Item>
            </Panel>
          )}
          {dataToken?.role === 3 && (
            <Panel
              header={
                <Divider style={{ margin: 0 }}>
                  {content.divisor.company}
                </Divider>
              }
              key="4"
            >
              <Form.Item
                label={content?.form?.company_name.title ?? "Loading..."}
                name="company_name"
                rules={[
                  {
                    required: true,
                    message:
                      content?.form?.company_name.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Input autoComplete="none" />
              </Form.Item>
              <Form.Item
                label={content?.form?.phone?.title ?? "Loading..."}
                name="phone"
                rules={[
                  {
                    required: true,
                    message:
                      content?.form?.username.placeholder ?? "Loading...",
                  },
                ]}
              >
                <PhoneInput
                  enableSearch
                  country={"ar"}
                  inputClass={
                    "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                  }
                  buttonClass={
                    "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                  }
                  searchClass={
                    "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                  }
                  // dropdownClass="phoneInput"
                  inputStyle={{
                    width: "100%",
                    height: "32px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                  buttonStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242 " : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                  dropdownStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                  searchStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                />
              </Form.Item>
            </Panel>
          )}
          <Form.Item style={{ textAlign: "center", marginRight: "-30px" }}>
            <Button
              type="primary"
              htmlType="submit"
              //loading={loadingRegister}
              size="large"
            >
              {content?.button?.title ?? "Loading..."}
            </Button>
          </Form.Item>
        </Collapse>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default ProfileScreen;
