import {
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_RESET,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_RESET,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_REGISTER_FAIL,
  PRODUCT_REGISTER_REQUEST,
  PRODUCT_REGISTER_SUCCESS,
  PRODUCT_REGISTER_RESET,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_RESET,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCTS_LIST_FAIL,
  PRODUCTS_LIST_REQUEST,
  PRODUCTS_LIST_RESET,
  PRODUCTS_LIST_SUCCESS,
  SERIAL_NUMBER_DETAILS_FAIL,
  SERIAL_NUMBER_DETAILS_REQUEST,
  SERIAL_NUMBER_DETAILS_RESET,
  SERIAL_NUMBER_DETAILS_SUCCESS,
  SERIAL_NUMBER_DELETE_FAIL,
  SERIAL_NUMBER_DELETE_REQUEST,
  SERIAL_NUMBER_DELETE_RESET,
  SERIAL_NUMBER_DELETE_SUCCESS,
  SERIAL_NUMBER_REGISTER_FAIL,
  SERIAL_NUMBER_REGISTER_REQUEST,
  SERIAL_NUMBER_REGISTER_SUCCESS,
  SERIAL_NUMBER_REGISTER_RESET,
  SERIAL_NUMBER_UPDATE_FAIL,
  SERIAL_NUMBER_UPDATE_REQUEST,
  SERIAL_NUMBER_UPDATE_RESET,
  SERIAL_NUMBER_UPDATE_SUCCESS,
  SERIAL_NUMBERS_LIST_FAIL,
  SERIAL_NUMBERS_LIST_REQUEST,
  SERIAL_NUMBERS_LIST_RESET,
  SERIAL_NUMBERS_LIST_SUCCESS,
} from "../constants/productConstants";

export const getProductsReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCTS_LIST_REQUEST:
      return { loading: true };

    case PRODUCTS_LIST_SUCCESS:
      return { loading: false, productsList: action.payload };

    case PRODUCTS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const deleteProductReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };

    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true, product: action.payload };

    case PRODUCT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

export const getProductDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true };

    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };

    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateProductReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };

    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };

    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case PRODUCT_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const registerProductReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_REGISTER_REQUEST:
      return { loading: true };

    case PRODUCT_REGISTER_SUCCESS:
      return { loading: false, success: true, product: action.payload };

    case PRODUCT_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case PRODUCT_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};

//------

export const getSerialNumbersReducer = (state = {}, action) => {
  switch (action.type) {
    case SERIAL_NUMBERS_LIST_REQUEST:
      return { loading: true };

    case SERIAL_NUMBERS_LIST_SUCCESS:
      return { loading: false, serialNumbersList: action.payload };

    case SERIAL_NUMBERS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const deleteSerialNumberReducer = (state = {}, action) => {
  switch (action.type) {
    case SERIAL_NUMBER_DELETE_REQUEST:
      return { loading: true };

    case SERIAL_NUMBER_DELETE_SUCCESS:
      return { loading: false, success: true, serialNumber: action.payload };

    case SERIAL_NUMBER_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case SERIAL_NUMBER_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

export const getSerialNumberDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case SERIAL_NUMBER_DETAILS_REQUEST:
      return { loading: true };

    case SERIAL_NUMBER_DETAILS_SUCCESS:
      return { loading: false, serialNumber: action.payload };

    case SERIAL_NUMBER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateSerialNumberReducer = (state = {}, action) => {
  switch (action.type) {
    case SERIAL_NUMBER_UPDATE_REQUEST:
      return { loading: true };

    case SERIAL_NUMBER_UPDATE_SUCCESS:
      return { loading: false, success: true, serialNumber: action.payload };

    case SERIAL_NUMBER_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case SERIAL_NUMBER_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const registerSerialNumberReducer = (state = {}, action) => {
  switch (action.type) {
    case SERIAL_NUMBER_REGISTER_REQUEST:
      return { loading: true };

    case SERIAL_NUMBER_REGISTER_SUCCESS:
      return { loading: false, success: true, serialNumber: action.payload };

    case SERIAL_NUMBER_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case SERIAL_NUMBER_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};
