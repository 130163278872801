import axios from "axios";
import {
  CITIES_DETAILS_FAIL,
  CITIES_DETAILS_REQUEST,
  CITIES_DETAILS_RESET,
  CITIES_DETAILS_SUCCESS,
  COUNTRY_DETAILS_FAIL,
  COUNTRY_DETAILS_REQUEST,
  COUNTRY_DETAILS_RESET,
  COUNTRY_DETAILS_SUCCESS,
  STATE_DETAILS_FAIL,
  STATE_DETAILS_REQUEST,
  STATE_DETAILS_RESET,
  STATE_DETAILS_SUCCESS,
} from "../constants/locationConstants";

import { apiURL } from "../store";

export const getCountries = () => async (dispatch) => {
  try {
    dispatch({ type: COUNTRY_DETAILS_REQUEST });

    const { data } = await axios.get(
      `${apiURL}/api/locations/countries/?all=true`
    );

    dispatch({
      type: COUNTRY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COUNTRY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getStates = (countryId) => async (dispatch) => {
  try {
    dispatch({ type: STATE_DETAILS_REQUEST });

    const { data } = await axios.get(
      `${apiURL}/api/locations/countries/${countryId}/states/?all=true`
    );

    dispatch({
      type: STATE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STATE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getCities = (countryId, stateId) => async (dispatch) => {
  try {
    dispatch({ type: CITIES_DETAILS_REQUEST });

    const { data } = await axios.get(
      `${apiURL}/api/locations/countries/${countryId}/states/${stateId}/cities/?all=true`
    );

    dispatch({
      type: CITIES_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CITIES_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
