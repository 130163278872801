import axios from "axios";
import {
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_RESET,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_REGISTER_FAIL,
  PRODUCT_REGISTER_REQUEST,
  PRODUCT_REGISTER_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_RESET,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCTS_LIST_FAIL,
  PRODUCTS_LIST_REQUEST,
  PRODUCTS_LIST_RESET,
  PRODUCTS_LIST_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_RESET,
  PRODUCT_DELETE_SUCCESS,
  //---
  SERIAL_NUMBER_DETAILS_FAIL,
  SERIAL_NUMBER_DETAILS_REQUEST,
  SERIAL_NUMBER_DETAILS_RESET,
  SERIAL_NUMBER_DETAILS_SUCCESS,
  SERIAL_NUMBER_REGISTER_FAIL,
  SERIAL_NUMBER_REGISTER_REQUEST,
  SERIAL_NUMBER_REGISTER_SUCCESS,
  SERIAL_NUMBER_UPDATE_FAIL,
  SERIAL_NUMBER_UPDATE_REQUEST,
  SERIAL_NUMBER_UPDATE_RESET,
  SERIAL_NUMBER_UPDATE_SUCCESS,
  SERIAL_NUMBERS_LIST_FAIL,
  SERIAL_NUMBERS_LIST_REQUEST,
  SERIAL_NUMBERS_LIST_RESET,
  SERIAL_NUMBERS_LIST_SUCCESS,
  SERIAL_NUMBER_DELETE_FAIL,
  SERIAL_NUMBER_DELETE_REQUEST,
  SERIAL_NUMBER_DELETE_RESET,
  SERIAL_NUMBER_DELETE_SUCCESS,
} from "../constants/productConstants";

import { apiURL } from "../store";

export const getProductDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`${apiURL}/api/products/${id}`, config);

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(`${apiURL}/api/products/${id}`, config);

    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getProducts = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCTS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/products/?all=true`,
      config
    );

    dispatch({
      type: PRODUCTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCTS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateProduct = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/products/${id}`,
      body,
      config
    );

    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerProduct = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`${apiURL}/api/products/`, body, config);

    dispatch({
      type: PRODUCT_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

//----

export const getSerialNumberDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERIAL_NUMBER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/products/serial-numbers/${id}`,
      config
    );

    dispatch({
      type: SERIAL_NUMBER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERIAL_NUMBER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteSerialNumber = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERIAL_NUMBER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(
      `${apiURL}/api/products/serial-numbers/${id}`,
      config
    );

    dispatch({
      type: SERIAL_NUMBER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERIAL_NUMBER_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getSerialNumbers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERIAL_NUMBERS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/products/serial-numbers/?all=true`,
      config
    );

    dispatch({
      type: SERIAL_NUMBERS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERIAL_NUMBERS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateSerialNumber = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERIAL_NUMBER_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/products/serial-numbers/${id}`,
      body,
      config
    );

    dispatch({
      type: SERIAL_NUMBER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERIAL_NUMBER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerSerialNumber = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERIAL_NUMBER_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(
      `${apiURL}/api/products/serial-numbers/`,
      body,
      config
    );

    dispatch({
      type: SERIAL_NUMBER_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERIAL_NUMBER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
