export const registerScreen = {
  en: {
    title: "Register",
    form: {
      first_name: {
        title: "First name",
        placeholder: "Enter your name.",
      },
      dni: { title: "DNI", placeholder: "Enter the DNI/ID" },
      last_name: {
        title: "Last name",
        placeholder: "Enter your last name",
      },
      email: {
        title: "Email",
        placeholder: "Enter your email",
      },
      username: {
        title: "Username",
        placeholder: "Enter your username",
      },
      sex: {
        title: "Sex",
        placeholder: "Enter your sex",
        options: ["Male", "Female", "No gender"],
      },
      country: {
        title: "Country",
        placeholder: "Enter your country",
      },
      state: {
        title: "State",
        placeholder: "Enter your Province/State",
      },
      city: {
        title: "City",
        placeholder: "Enter your city",
      },
      street: {
        title: "Street",
        placeholder: "Enter your street",
      },
      house_number: {
        title: "Number",
        placeholder: "Enter your number",
      },
      password_1: {
        title: "Password",
        placeholder: "Enter your password",
      },
      password_2: {
        title: "Confirm your password",
        placeholder: "Confirm your password",
      },
      company_name: {
        title: "Company name",
        placeholder: "Enter your company name",
      },
      phone: {
        title: "Phone",
        placeholder: "Enter your phone",
      },
      bussiness_phone: {
        title: "bussiness_phone",
        placeholder: "Enter your password",
      },
      profession: {
        title: "profession",
        placeholder: "Confirm your password",
      },
    },
    button: {
      title: "Register",
    },
    subtitle: {
      title: "If you have an account, you can",
      link: "log in",
    },
    divisor: {
      basic: "Basic Information",
      ubication: "Location Information",
      password: "Password",
      company: "Company Information",
      info: "Other info",
    },
    result: {
      title: "Awaiting Approval",
      message:
        "Thank you for registering! We are reviewing your application and will inform you soon regarding the approval. Thank you for your patience!",
      button: "Sign in",
    },
  },
  es: {
    title: "Registrate",
    form: {
      first_name: {
        title: "Nombre",
        placeholder: "Ingrese su nombre",
      },
      last_name: {
        title: "Apellido",
        placeholder: "Ingrese su apellido",
      },
      email: {
        title: "Email",
        placeholder: "Ingrese su email",
      },
      username: {
        title: "Usuario",
        placeholder: "Ingrese su usuario",
      },
      sex: {
        title: "Sexo",
        placeholder: "Ingrese su sexo",
        options: ["Masculino", "Femenino", "No especificado"],
      },
      country: {
        title: "País",
        placeholder: "Ingrese su país",
      },
      state: {
        title: "Provincia",
        placeholder: "Ingrese su provincia/estado",
      },
      city: {
        title: "Ciudad",
        placeholder: "Ingrese su ciudad",
      },
      dni: {
        title: "DNI",
        placeholder: "Ingrese su DNI/ID",
      },
      street: {
        title: "Calle",
        placeholder: "Ingrese su calle",
      },
      house_number: {
        title: "Número",
        placeholder: "Ingrese su número de dirección",
      },
      password_1: {
        title: "Contraseña",
        placeholder: "Ingrese su contraseña",
      },
      password_2: {
        title: "Confirmación contraseña",
        placeholder: "Confirme su contraseña",
      },
      company_name: {
        title: "Nombre de la Compañía",
        placeholder: "Ingrese el nombre de la Compañía",
      },
      phone: {
        title: "Teléfono personal",
        placeholder: "Ingrese su teléfono personal",
      },
      bussiness_phone: {
        title: "Teléfono compañía",
        placeholder: "Ingrese su telefono corporativo",
      },
      profession: {
        title: "Profesión",
        placeholder: "Ingrese su profesión",
      },
    },
    button: {
      title: "Registrate",
    },
    subtitle: {
      title: "Si tiene una cuenta, puede",
      link: "Iniciar Sesión",
    },
    divisor: {
      basic: "Información Básica",
      ubication: "Información de Ubicación",
      company: "Información de la Compañía",
      info: "Otra Información",
      password: "Contraseña",
    },
    result: {
      title: "Esperando aprobación",
      message:
        "¡Gracias por registrarte! Estamos revisando tu solicitud y te informaremos pronto sobre la aprobación. ¡Gracias por tu paciencia!",
      button: "Iniciar Sesión",
    },
  },
};

export const profileScreen = {
  //informacion basica, informacion de ubicacion, cambio de contraseña
  en: {
    title: "Profile Configuration",
    form: {
      first_name: {
        title: "First name",
        placeholder: "Enter your name.",
      },
      last_name: {
        title: "Last name",
        placeholder: "Enter your last name",
      },
      email: {
        title: "Email",
        placeholder: "Enter your email",
      },
      username: {
        title: "Username",
        placeholder: "Enter your username",
      },
      sex: {
        title: "Sex",
        placeholder: "Enter your sex",
        options: ["", "Male", "Female", "No gender"],
      },
      country: {
        title: "Country",
        placeholder: "Enter your country",
      },
      dni: { title: "DNI", placeholder: "Enter the DNI/ID" },
      state: {
        title: "Province",
        placeholder: "Enter your Province",
      },
      city: {
        title: "City",
        placeholder: "Enter your city",
      },
      street: {
        title: "Street",
        placeholder: "Enter your street",
      },
      house_number: {
        title: "Number",
        placeholder: "Enter your number",
      },
      password_1: {
        title: "Password",
        placeholder: "Enter your password",
      },
      password_2: {
        title: "Confirm your password",
        placeholder: "Confirm your password",
      },
      company_name: {
        title: "Company name",
        placeholder: "Enter your company name",
      },
      phone: {
        title: "Phone",
        placeholder: "Enter your phone",
      },
      bussiness_phone: {
        title: "bussiness_phone",
        placeholder: "Enter your bussiness phone",
      },
      profession: {
        title: "profession",
        placeholder: "Enter you profession",
      },
    },
    button: {
      title: "Update changes",
    },
    notification: {
      edited: {
        success: {
          title: "Profile",
          description: "Your profile has been edited successfully.",
        },
        error: {
          title: "Profile",
          description: "Error editing your profile. Please try again later.",
        },
      },
    },
    divisor: {
      basic: "Basic Information",
      ubication: "Location Information",
      company: "Company Information",
      info: "Other Info",
      password: "Password Change",
    },
  },
  es: {
    title: "Configuración de Perfil",
    form: {
      first_name: {
        title: "Nombre",
        placeholder: "Ingrese su nombre",
      },
      last_name: {
        title: "Apellido",
        placeholder: "Ingrese su apellido",
      },
      email: {
        title: "Email",
        placeholder: "Ingrese su email",
      },
      username: {
        title: "Usuario",
        placeholder: "Ingrese su usuario",
      },
      sex: {
        title: "Sexo",
        placeholder: "Ingrese su sexo",
        options: ["", "Masculino", "Femenino", "No especificado"],
      },
      country: {
        title: "País",
        placeholder: "Ingrese su país",
      },
      dni: {
        title: "DNI",
        placeholder: "Ingrese su DNI/ID",
      },
      state: {
        title: "Provincia",
        placeholder: "Ingrese su provincia/estado",
      },
      city: {
        title: "Ciudad",
        placeholder: "Ingrese su ciudad",
      },
      street: {
        title: "Calle",
        placeholder: "Ingrese su calle",
      },
      house_number: {
        title: "Número",
        placeholder: "Ingrese su número de dirección",
      },
      password_1: {
        title: "Contraseña",
        placeholder: "Ingrese su contraseña",
      },
      password_2: {
        title: "Confirmación contraseña",
        placeholder: "Confirme su contraseña",
      },
      company_name: {
        title: "Nombre de la Compañía",
        placeholder: "Ingrese el nombre de la Compañía",
      },
      phone: {
        title: "Teléfono personal",
        placeholder: "Ingrese su teléfono personal",
      },
      bussiness_phone: {
        title: "Teléfono compañía",
        placeholder: "Ingrese su telefono corporativo",
      },
      profession: {
        title: "Profesión",
        placeholder: "Ingrese su profesión",
      },
    },
    divisor: {
      basic: "Información Básica",
      ubication: "Información de Ubicación",
      company: "Información de la Compañía",
      info: "Otra Información",
      password: "Cambio de Contraseña",
    },
    button: {
      title: "Guardar cambios",
    },
    notification: {
      edited: {
        success: {
          title: "Perfil",
          description: "Su perfil se ha editado correctamente.",
        },
        error: {
          title: "Perfil",
          description:
            "Error al editar su perfil. Vuelva a intentarlo más tarde.",
        },
      },
    },
  },
};

export const loginScreen = {
  en: {
    title: "Login",
    greeting: "Welcome back!",
    imageSubtitle: "New look, same security. Connect with confidence.",
    form: {
      email: {
        title: "Email",
        placeholder: "Enter your email.",
      },
      password: {
        title: "Password",
        placeholder: "Enter your password.",
      },
    },
    button: "Log in",
    subtitle: "Register now!",
    recovery: "Recovery password!",
    notification: {
      create: {
        success: {
          title: "Login",
          description: "",
        },
        error: {
          title: "Login",
          description: "User or Password incorrect. Try again",
        },
      },
    },
  },
  es: {
    title: "Iniciar Sesión",
    greeting: "Bienvenidos!",
    imageSubtitle: "Nueva imagen, misma seguridad. Conéctate con confianza.",
    form: {
      email: {
        title: "Email",
        placeholder: "Ingrese su email.",
      },
      password: {
        title: "Contraseña",
        placeholder: "Ingrese su contraseña.",
      },
    },
    button: "Iniciar Sesión",
    subtitle: "Registrate ahora!",
    recovery: "¿Has olvidado la contraseña?",
    notification: {
      create: {
        success: {
          title: "Inicio de Sesion",
          description: "",
        },
        error: {
          title: "Login",
          description: "Usuario o contraseña incorrecta. Intente de nuevo.",
        },
      },
    },
  },
};

export const enterEmailScreen = {
  en: {
    title: "Reset your password",
    form: {
      email: {
        title: "Email",
        placeholder: "Enter your email.",
      },
    },
    button: "Reset password",
    subtitle: "Sign in!",
    notification: {
      create: {
        success: {
          title: "Login",
          description: "",
        },
        error: {
          title: "Login",
          description: "Error recovery the password. Please try again later.",
        },
      },
    },
    result: {
      title:
        "We have received your password reset request. Please check your email to proceed.",
      subtitle:
        "Check your inbox, including your spam folder, and follow the instructions we have sent you. If you need assistance, please contact us.",
      button: "Sign in",
    },
  },
  es: {
    title: "Reiniciar contraseña",
    form: {
      email: {
        title: "Email",
        placeholder: "Ingrese su email.",
      },
      password: {
        title: "Contraseña",
        placeholder: "Ingrese su contraseña.",
      },
    },
    button: "Restablecer contraseña",
    subtitle: "Registrarse",
    recovery: "Recupere la contraseña!",
    notification: {
      create: {
        success: {
          title: "Login",
          description: "",
        },
        error: {
          title: "Login",
          description:
            "Error al recuperar la contraseña. Vuelva a intentarlo más tarde.",
        },
      },
    },
    result: {
      title:
        "Recibimos tu solicitud de restablecimiento de contraseña. Revisa tu correo para continuar.",
      subtitle:
        "Revisa tu bandeja de entrada, incluyendo la carpeta de SPAM, y sigue las instrucciones que te hemos enviado. Si necesitas ayuda, contáctanos.",
      button: "Iniciar Sesión",
    },
  },
};

export const enterPasswordScreen = {
  en: {
    title: "Enter the password",
    form: {
      password: {
        title: "Password",
        placeholder: "Enter your password.",
      },
    },
    button: "Reset password",
    notification: {
      create: {
        success: {
          title: "Login",
          description: "",
        },
        error: {
          title: "Reset password",
          description: "Error reset the password. Please try again later.",
        },
      },
    },
  },
  es: {
    title: "Ingrese la nueva contraseña",
    form: {
      password: {
        title: "Contraseña",
        placeholder: "Ingrese su contraseña.",
      },
    },
    button: "Restablecer contraseña",
    notification: {
      create: {
        error: {
          title: "Recuperar contraseña",
          description:
            "Error al recuperar la contraseña. Vuelva a intentarlo más tarde.",
        },
      },
    },
  },
};

export const tableTraslations = {
  es: {
    items_per_page: "/ página",
    jump_to: "Ir a",
    page: "Página",
  },
  en: {
    items_per_page: "/ page",
    jump_to: "Go to",
    page: "Page",
  },
};

export const slider = {
  en: {
    home: "home",
    register: "SigIn",
    login: "Login",
    language: {
      title: "Languaje",
      sub: {
        spanish: "Español",
        english: "English",
      },
    },
    admin: "Configuration",
    logout: "Logout",
    ecu: "ECUs",
    product: "Products",
    service: "Services",
    job: "Inbox",
    user: "Clients",
    vehicle: "Vehicles",
    worker: "Users",
    credit: "Credit",
    profile: "Profile Configuration",
    equ_modal: {
      title: "Credit equivalence",
      dolar: "1 Credit", //American dollar",
      ars: "1 Credit", //Argentine peso",
      button: "Update",
    },
  },
  es: {
    home: "home",
    register: "Registrarse",
    login: "Iniciar Sesión",
    language: {
      title: "Lenguaje",
      sub: {
        spanish: "Español",
        english: "English",
      },
    },
    admin: "Configuración",
    logout: "Cerrar Sesión",
    ecu: "ECUs",
    product: "Productos",
    service: "Servicios",
    job: "Bandeja de Entrada",
    user: "Clientes",
    vehicle: "Vehículos",
    worker: "Usuarios",
    credit: "Crédito",
    profile: "Configuración de Perfil",
    equ_modal: {
      title: "Equivalencia de Créditos",
      dolar: "1 Crédito", //"American dollar",
      ars: "1 Crédito", //Argentine peso",
      button: "Actualizar",
    },
  },
};

export const adminPanel = {
  en: {
    cancel_button: "Cancel",
    title: "Admin panel",
    tabs: [
      {
        title: "Users",
        children: [
          {
            title: "Inactive/Potential",
          },
          {
            title: "Clients",
          },
        ],
      },
    ],
  },
  es: {
    cancel_button: "Cancelar",
    title: "Panel del admin",
    tabs: [
      {
        title: "Usuarios",
        items: [
          {
            title: "Inactivos/Potenciales",
          },
          {
            title: "Clientes",
          },
        ],
      },
    ],
  },
};

export const serviceScreen = {
  en: {
    cancel_button: "Cancel",
    title: "Services",
    search: "Search Services",
    tooltips: {
      edit: "Edit",
      delete: "Delete",
    },
    button: {
      add: "Add",
      edit: "Update",
    },
    edit: {
      title: "Edit Service",
    },
    add: {
      button: "Add service",
      form: {
        title: "Add service",
        name: { title: "Name", placeholder: "Enter the name" },
        code: { title: "Code", placeholder: "Enter the code" },
        // nat_price: { title: "National price", placeholder:"enter the na"
        //  int_price: { title: "International price", placeholder: ""
        credit_price: { title: "Price", placeholder: "Enter the price" },
        description: {
          title: "Description",
          placeholder: "Enter the description",
        },
        button: "Submit",
      },
    },
    table: {
      edit: "Edit",
      delete: "Delete",
    },
    notification: {
      create: {
        success: {
          title: "Service",
          description: "The service has been created successfully.",
        },
        error: {
          title: "Service",
          description: "Error creating the service. Please try again later.",
        },
      },
      edited: {
        success: {
          title: "Service",
          description: "The service has been edited successfully.",
        },
        error: {
          title: "Service",
          description: "Error editing the service. Please try again later.",
        },
      },
      delete: {
        success: {
          title: "Service",
          description: "The service has been deleted successfully.",
        },
        error: {
          title: "Service",
          description: "Error deleting the service. Please try again later.",
        },
      },
    },
    confirmation: {
      delete: {
        title: "Delete the service",
        description: "Are you sure to delete this service?",
        button: {
          cancel: "Cancel",
        },
      },
    },
  },
  es: {
    cancel_button: "Cancelar",
    title: "Servicios",
    search: "Buscar Servicios",
    tooltips: {
      edit: "Editar",
      delete: "Eliminar",
      account_movement: "Movimientos de Cuenta",
      balance: "Agregar Créditos",
    },
    edit: {
      title: "Editar Servicio",
    },
    button: {
      add: "Agregar",
      edit: "Actualizar",
    },
    add: {
      button: "Agregar Servicio",
      form: {
        title: "Agregar Servicio",
        code: { title: "Código", placeholder: "Ingrese el código" },
        name: { title: "Nombre", placeholder: "Ingrese el nombre" },
        // nat_price: { title: "National price", placeholder:""
        // int_price: { title: "International price", placeholder: ""
        credit_price: { title: "Costo", placeholder: "Ingrese el costo" },
        description: {
          title: "Descripción",
          placeholder: "Ingrese la descripción",
        },
        button: "Agregar",
      },
    },
    table: {
      edit: "Editar",
      delete: "Eliminar",
    },
    notification: {
      create: {
        success: {
          title: "Servicio",
          description: "El servicio se ha creado correctamente.",
        },
        error: {
          title: "Servicio",
          description:
            "Error al crear el servicio. Vuelva a intentarlo más tarde.",
        },
      },
      edited: {
        success: {
          title: "Servicio",
          description: "El servicio se ha editado correctamente.",
        },
        error: {
          title: "Servicio",
          description:
            "Error al editar el servicio. Vuelva a intentarlo más tarde.",
        },
      },
      delete: {
        success: {
          title: "Servicio",
          description: "El servicio se ha eliminado correctamente.",
        },
        error: {
          title: "Servicio",
          description:
            "Error al eliminar el servicio. Vuelva a intentarlo más tarde.",
        },
      },
    },
    confirmation: {
      delete: {
        title: "Eliminar Servicio",
        description: "¿Está seguro de eliminar el Servicio?",
        button: {
          cancel: "Cancelar",
        },
      },
    },
  },
};

export const productScreen = {
  en: {
    cancel_button: "Cancel",
    title: "Products",
    tooltips: {
      edit: "Edit",
      serial: "Serial",
      delete: "Delete",
      add: "Add stock",
    },
    tag: {
      expired: "EXPIRED",
      valid: "VALID",
    },
    modal: {
      button: {
        add: "Add",
        edit: "Update",
      },
      add: "Add Product",
      edit: "Edit Product",
    },
    search: "Search Product",
    add: {
      button: "Add Product",
      form: {
        title: "Add Product",
        name: {
          title: "Name",
          placeholder: "Enter the name",
        },
        code: {
          title: "Code",
          placeholder: "Enter the code",
        },
        exp_date: {
          title: "Expiration Date",
          placeholder: "Enter the expiration date",
        },
        tags: {
          title: "Tags",
          placeholder: "Enter the tags",
        },
        serial: {
          title: "Serial Number",
          placeholder: "Enter the serial number",
        },
        stock: {
          title: "Stock",
          placeholder: "Enter the stock",
        },
        client: {
          title: "Client",
          placeholder: "Enter the client",
        },
        description: {
          title: "Description",
          placeholder: "Enter the description",
        },
        button: "Submit",
      },
    },
    table: {
      edit: "Edit",
      delete: "Delete",
    },
    notification: {
      create: {
        success: {
          title: "Product",
          description: "The product has been created successfully.",
        },
        error: {
          title: "Product",
          description: "Error creating the product. Please try again later.",
        },
      },
      edited: {
        success: {
          title: "Product",
          description: "The product has been edited successfully.",
        },
        error: {
          title: "Product",
          description: "Error editing the product. Please try again later.",
        },
      },
      delete: {
        success: {
          title: "Product",
          description: "The product has been deleted successfully.",
        },
        error: {
          title: "Service",
          description: "Error deleting the product. Please try again later.",
        },
      },
    },
    confirmation: {
      delete: {
        title: "Delete the product",
        description: "Are you sure to delete this product?",
        button: {
          cancel: "Cancel",
        },
      },
    },
    serialNumber: {
      search: "Search serial",
      name: "Serial list",
      serial_x_client: "Serial x client",
      modal: {
        title: {
          add: "Add Product",
          edit: "Edit serial",
        },
      },
      form: {
        serial: {
          title: "Serial",
          placeholder: "Enter the serial",
        },
        cost: {
          title: "Cost",
          placeholder: "Enter the cost",
        },
        product: {
          title: "Product",
          placeholder: "Enter the product",
        },
        client: {
          title: "Client",
          placeholder: "Enter the client",
        },
        expiration_date: {
          title: "Expiration date",
          placeholder: "Enter the expiration date",
        },
        tag: {
          title: "State",
          placeholder: "Ingrese una fecha de expiracion",
        },
      },
      button: {
        add: "Add",
        edit: "Update",
      },
      notification: {
        create: {
          success: {
            title: "Serial",
            description: "The serial has been created successfully.",
          },
          error: {
            title: "Serial",
            description: "Error creating the serial. Please try again later.",
          },
        },
        edited: {
          success: {
            title: "Serial",
            description: "The serial has been edited successfully.",
          },
          error: {
            title: "Serial",
            description:
              "Error editing the produserialct. Please try again later.",
          },
        },
        delete: {
          success: {
            title: "Serial",
            description: "The serial has been deleted successfully.",
          },
          error: {
            title: "Serial",
            description: "Error deleting the serial. Please try again later.",
          },
        },
      },
    },
    stock: {
      modal: {
        title: "Add stock",
      },
      form: {
        stock: {
          title: "Stock actual",
          placeholder: "Add stock >= 0",
        },
        add: {
          title: "Stock to add",
          placeholder: "Add stock >= 0",
        },
        total: {
          title: "Stock total",
          //placeholder: "Agrege stock >= 0",
        },
        button: "Add",
      },
    },
  },
  es: {
    cancel_button: "Cancelar",
    title: "Productos",
    search: "Buscar Productos",
    tag: {
      expired: "EXPIRADO",
      valid: "VALIDO",
    },
    tooltips: {
      edit: "Editar",
      delete: "Eliminar",
      serial: "Vincular Cliente",
      account_movement: "Movimientos de Cuenta",
      add: "Agregar Stock",
    },
    modal: {
      button: {
        add: "Agregar",
        edit: "Actualizar",
      },
      add: "Agregar Producto",
      edit: "Editar Producto",
    },
    add: {
      button: "Agregar producto",
      form: {
        title: "Agregar Producto",
        code: { title: "Código", placeholder: "Ingrese el código" },
        name: { title: "Nombre", placeholder: "Ingrese el nombre" },
        exp_date: {
          title: "Fecha de Expiración",
          placeholder: "Ingrese la fecha de expiración",
        },
        tags: { title: "Tags", placeholder: "Ingrese los tags" },
        serial: {
          title: "Número de Serie",
          placeholder: "Ingrese el número de serie",
        },
        stock: {
          title: "Stock",
          placeholder: "Ingrese el stock",
        },
        client: { title: "Cliente", placeholder: "Seleccione el cliente" },
        description: {
          title: "Descripción",
          placeholder: "Ingrese la descripción",
        },
        button: "Agregar",
      },
    },
    table: {
      edit: "Editar",
      delete: "Eliminar",
    },
    notification: {
      create: {
        success: {
          title: "Producto",
          description: "El producto se ha creado correctamente.",
        },
        error: {
          title: "Producto",
          description:
            "Error al crear el producto. Vuelva a intentarlo más tarde.",
        },
      },
      edited: {
        success: {
          title: "Producto",
          description: "El producto se ha editado correctamente.",
        },
        error: {
          title: "Producto",
          description:
            "Error al editar el producto. Vuelva a intentarlo más tarde.",
        },
      },
      delete: {
        success: {
          title: "Producto",
          description: "El producto se ha eliminado correctamente.",
        },
        error: {
          title: "Servicio",
          description:
            "Error al eliminar el producto. Vuelva a intentarlo más tarde.",
        },
      },
    },
    confirmation: {
      delete: {
        title: "Eliminar Producto",
        description: "¿Está seguro de eliminar el producto?",
        button: {
          cancel: "Cancelar",
        },
      },
    },
    serialNumber: {
      search: "Buscar Serial",
      name: "Lista de seriales",
      serial_x_client: "Serial x cliente",
      modal: {
        title: {
          add: "Registrar Producto",
          edit: "Editar serial",
        },
      },
      form: {
        serial: {
          title: "Serial",
          placeholder: "Ingrese el serial",
        },
        cost: {
          title: "Costo",
          placeholder: "Ingrese el costo",
        },
        product: {
          title: "Producto",
          placeholder: "Seleccione el producto",
        },
        client: {
          title: "Cliente",
          placeholder: "Seleccione el ciente",
        },
        expiration_date: {
          title: "Fecha de expiración",
          placeholder: "Ingrese la fecha de expiración",
        },
        tag: {
          title: "Estado",
          placeholder: "Ingrese la fecha de expiración",
        },
      },
      notification: {
        create: {
          success: {
            title: "Stock",
            description: "El stock se ha modificado correctamente.",
          },
          error: {
            title: "Serial",
            description:
              "Error al vincular el cliente. Vuelva a intentarlo más tarde.",
          },
        },
        edited: {
          success: {
            title: "Serial",
            description:
              "La vinculación del cliente se ha editado correctamente.",
          },
          error: {
            title: "Serial",
            description:
              "Error al editar la vinculación del cliente. Vuelva a intentarlo más tarde.",
          },
        },
        delete: {
          success: {
            title: "Serial",
            description:
              "La vinculación del cliente se ha eliminado correctamente.",
          },
          error: {
            title: "Serial",
            description:
              "Error al eliminar la vinculación del cliente. Vuelva a intentarlo más tarde.",
          },
        },
      },
      button: {
        add: "Agregar",
        edit: "Actualizar",
      },
    },
    stock: {
      modal: {
        title: "Agregar Stock",
      },
      form: {
        stock: {
          title: "Stock actual",
          placeholder: "Agrege stock >= 0",
        },
        add: {
          title: "Stock a agregar",
          placeholder: "Add stock >= 0",
        },
        total: {
          title: "Stock total",
          placeholder: "Agrege stock >= 0",
        },
        button: "Agregar",
      },
    },
  },
};

export const clientScreen = {
  en: {
    cancel_button: "Cancel",
    title: "Clients",
    search: "Search Users",
    tooltips: {
      edit: "Edit",
      delete: "Delete",
      account_movement: "Account movement",
      balance: "Add balance",
      information: "Client Information"
    },
    activate_client: "Activate client",
    modal: {
      add: "Add Client",
      edit: "Edit client",
    },
    divisor: {
      basic: "Basic Information",
      ubication: "Location Information",
      password: "Password Change",
      company: "Company Information",
      info: "Other info",
    },
    tabs: {
      inactive: {
        title: "Inactive/Potential",
        article: "this client",
        article_2: "The client",
      },
      active: {
        title: "Active/Oficials",
        article: "this client",
        article_2: "The client",
      },
    },
    form: {
      first_name: {
        title: "First name",
        placeholder: "Enter the name.",
      },
      last_name: {
        title: "Last name",
        placeholder: "Enter the last name",
      },
      email: {
        title: "Email",
        placeholder: "Enter the email",
      },
      username: {
        title: "Username",
        placeholder: "Enter the username",
      },
      sex: {
        title: "Sex",
        placeholder: "Enter the sex",
        options: ["", "Male", "Female", "No gender"],
      },
      country: {
        title: "Country",
        placeholder: "Enter the country",
      },
      state: {
        title: "Province",
        placeholder: "Enter the Province",
      },
      address: {
        title: "Address",
        placeholder: "Enter the Address",
      },
      balance: {
        title: "Balance",
        placeholder: "Enter the balance",
      },
      dni: { title: "DNI", placeholder: "Enter the DNI/ID" },
      city: {
        title: "City",
        placeholder: "Enter the city",
      },
      street: {
        title: "Street",
        placeholder: "Enter the street",
      },
      house_number: {
        title: "Number",
        placeholder: "Enter the number",
      },
      password_1: {
        title: "Password",
        placeholder: "Enter the password",
      },
      password_2: {
        title: "Confirm the password",
        placeholder: "Confirm the password",
      },
      company_name: {
        title: "Company name",
        placeholder: "Enter the company name",
      },
      phone: {
        title: "Phone",
        placeholder: "Enter the phone",
      },
      button: {
        add: "Add",
        edit: "Edit",
      },
    },
    notification: {
      cancel: {
        title: "Delete the client",
        description: "Are you sure to delete the client?",
        button: {
          cancel: "Cancel",
        },
      },
      create: {
        success: {
          title: "_user_",
          description: "_user_ has been created successfully.",
        },
        error: {
          title: "_user_",
          description: "Error creating _user_. Please try again later.",
        },
      },
      edited: {
        success: {
          title: "_user_",
          description: "_user_ has been edited successfully.",
        },
        error: {
          title: "ECU",
          description: "Error editing _user_. Please try again later.",
        },
      },
      delete: {
        success: {
          title: "_user_",
          description: "_user_ has been deleted successfully.",
        },
        error: {
          title: "_user_",
          description: "Error deleting _user_. Please try again later.",
        },
      },
      activate: {
        success: {
          title: "_user_",
          description: "_user_ has been activated successfully.",
        },
        error: {
          title: "_user_",
          description: "Error activating _user_. Please try again later.",
        },
      },
    },
    balance: {
      modal: {
        title: "Add credit",
      },
      form: {
        balance: {
          title: "Actual Balance",
          placeholder: "Enter the actual balance",
        },
        credit: {
          title: "Credit",
          placeholder: "Enter the credit",
        },
        total: {
          title: "Total",
          placeholder: "Enter the total",
        },
        concept: {
          title: "Concept",
          placeholder: "Enter the concept",
        },
        amount: {
          title: "Amount",
        },
        balance_total: {
          title: "Balance",
        },
        created_time: {
          title: "Creation Time",
          placeholder: "Enter the creation time",
        },
      },
    },
    account_movement: {
      title: "Account Movements",
    },
    userInformationModal:{
      title: "Client Information"
    }
  },
  es: {
    cancel_button: "Cancelar",
    title: "Clientes",
    search: "Buscar Clientes",
    tooltips: {
      edit: "Editar",
      delete: "Eliminar",
      account_movement: "Movimientos de Cuenta",
      balance: "Agregar Créditos",
      information: "Información del Cliente"
    },
    activate_client: "Activar Cliente",
    modal: {
      add: "Agregar Cliente",
      edit: "Editar Cliente",
    },
    divisor: {
      basic: "Información Básica",
      ubication: "Información de Ubicación",
      company: "Información de la Compañía",
      info: "Otra Información",
      password: "Cambio de Contraseña",
    },
    tabs: {
      inactive: {
        title: "Inactivos/Potenciales",
        article: "este cliente",
        article_2: "El cliente",
      },
      active: {
        title: "Activos/Oficiales",
        article: "este cliente",
        article_2: "El cliente",
      },
    },
    form: {
      first_name: {
        title: "Nombre",
        placeholder: "Ingrese el nombre",
      },
      last_name: {
        title: "Apellido",
        placeholder: "Ingrese el apellido",
      },
      email: {
        title: "Email",
        placeholder: "Ingrese el email",
      },
      dni: {
        title: "DNI",
        placeholder: "Ingrese el DNI/ID",
      },
      username: {
        title: "Usuario",
        placeholder: "Ingrese el usuario",
      },
      sex: {
        title: "Sexo",
        placeholder: "Ingrese el sexo",
        options: ["", "Masculino", "Femenino", "No especificado"],
      },
      country: {
        title: "País",
        placeholder: "Ingrese el país",
      },
      state: {
        title: "Provincia",
        placeholder: "Ingrese la provincia/estado",
      },
      city: {
        title: "Ciudad",
        placeholder: "Ingrese la ciudad",
      },
      street: {
        title: "Calle",
        placeholder: "Ingrese la calle",
      },
      balance: {
        title: "Balance",
        placeholder: "Ingrese el balance",
      },
      address: {
        title: "Dirección",
        placeholder: "Ingrese la dirección",
      },
      house_number: {
        title: "Número",
        placeholder: "Ingrese el número de dirección",
      },
      password_1: {
        title: "Contraseña",
        placeholder: "Ingrese la contraseña",
      },
      password_2: {
        title: "Confirmación contraseña",
        placeholder: "Confirme la contraseña",
      },
      company_name: {
        title: "Nombre de la Compañía",
        placeholder: "Ingrese el nombre de la Compañía",
      },
      phone: {
        title: "Teléfono personal",
        placeholder: "Ingrese su teléfono personal",
      },
      bussiness_phone: {
        title: "Teléfono compañía",
        placeholder: "Ingrese su telefono corporativo",
      },
      profession: {
        title: "Profesión",
        placeholder: "Ingrese su profesión",
      },
      button: {
        add: "Agregar",
        edit: "Editar",
      },
    },
    notification: {
      cancel: {
        title: "Eliminar Cliente",
        description: "¿Está seguro de eliminar el cliente?",
        button: {
          cancel: "Cancelar",
        },
      },
      create: {
        success: {
          title: "_user_",
          description: "_user_ se ha creado correctamente.",
        },
        error: {
          title: "_user_",
          description: "Error al crear _user_. Vuelva a intentarlo más tarde.",
        },
      },
      edited: {
        success: {
          title: "_user_",
          description: "_user_ se ha editado correctamente.",
        },
        error: {
          title: "_user_",
          description: "Error al editar _user_. Vuelva a intentarlo más tarde.",
        },
      },
      delete: {
        success: {
          title: "_user_",
          description: "_user_ se ha eliminado correctamente.",
        },
        error: {
          title: "_user_",
          description:
            "Error al eliminar _user_. Vuelva a intentarlo más tarde.",
        },
      },
      activate: {
        success: {
          title: "_user_",
          description: "_user_ se ha activado correctamente.",
        },
        error: {
          title: "_user_",
          description:
            "Error al activar _user_. Vuelva a intentarlo más tarde.",
        },
      },
    },
    balance: {
      modal: {
        title: "Agregar Créditos",
      },
      form: {
        balance: {
          title: "Saldo",
          placeholder: "",
        },
        credit: {
          title: "Créditos",
          placeholder: "Ingrese la cantidad de créditos",
        },
        total: {
          title: "Total",
          placeholder: "",
        },
        concept: {
          title: "Concepto",
          placeholder: "Ingrese el concepto",
        },
        created_time: {
          title: "Fecha",
          placeholder: "",
        },
        amount: {
          title: "Monto",
        },
        balance_total: {
          title: "Saldo",
        },
      },
    },
    account_movement: {
      title: "Movimientos de Cuenta",
    },
    userInformationModal:{
      title: "Información del Cliente"
    }
  },
};

export const ecuScreen = {
  en: {
    cancel_button: "Cancel",
    title: "ECUs (Engine Control Unit)",
    search: "Search ECUs",
    tooltips: {
      edit: "Edit",
      delete: "Delete",
      account_movement: "Account movement",
      balance: "Add balance",
    },
    tab1: {
      modal: {
        add: "Add brand",
        edit: "Edit brand",
      },
      title: "Brands",
      search: "Search brand",
      add: {
        button: "Add brand",
        form: {
          title: "Add brand",
          name: {
            title: "Name",
            placeholder: "Enter the name",
          },
          description: {
            title: "Description",
            placeholder: "Enter the description",
          },
          brand: { title: "Brand", placeholder: "Select the brand" },
          button: "add",
          buttonUpdate: "Update",
        },
      },
      table: {
        edit: "Edit",
        delete: "Delete",
      },
      notification: {
        create: {
          success: {
            title: "ECU",
            description: "The brand has been created successfully.",
          },
          error: {
            title: "ECU",
            description: "Error creating the brand. Please try again later.",
          },
        },
        edited: {
          success: {
            title: "ECU",
            description: "The brand has been edited successfully.",
          },
          error: {
            title: "ECU",
            description: "Error editing the brand. Please try again later.",
          },
        },
        delete: {
          success: {
            title: "ECU",
            description: "The brand has been deleted successfully.",
          },
          error: {
            title: "ECU",
            description: "Error deleting the brand. Please try again later.",
          },
        },
      },
      confirmation: {
        delete: {
          title: "Delete the brand",
          description: "Are you sure to delete this brand?",
          button: {
            cancel: "Cancel",
          },
        },
      },
    },
    tab2: {
      modal: {
        add: "Add Model",
        edit: "Edit Model",
      },
      title: "Model",
      search: "Search model",
      add: {
        button: "Add model",
        form: {
          title: "Add Model",
          name: {
            title: "Name",
            placeholder: "Enter the name",
          },
          description: {
            title: "Description",
            placeholder: "Enter the description",
          },
          brand: { title: "Brand", placeholder: "Select the brand" },
          button: "add",
          buttonUpdate: "Update",
        },
      },
      table: {
        edit: "Edit",
        delete: "Delete",
      },
      notification: {
        create: {
          success: {
            title: "ECU",
            description: "The model has been created successfully.",
          },
          error: {
            title: "ECU",
            description: "Error creating the model. Please try again later.",
          },
        },
        edited: {
          success: {
            title: "ECU",
            description: "The model has been edited successfully.",
          },
          error: {
            title: "ECU",
            description: "Error editing the model. Please try again later.",
          },
        },
        delete: {
          success: {
            title: "ECU",
            description: "The model has been deleted successfully.",
          },
          error: {
            title: "ECU",
            description: "Error deleting the model. Please try again later.",
          },
        },
      },
      confirmation: {
        delete: {
          title: "Delete the model",
          description: "Are you sure to delete this model?",
          button: {
            cancel: "Cancel",
          },
        },
      },
    },
  },
  es: {
    cancel_button: "Cancelar",
    title: "ECUs (Engine Control Unit)",
    search: "Buscar ECUs",
    tooltips: {
      edit: "Editar",
      delete: "Eliminar",
      account_movement: "Movimientos de Cuenta",
      balance: "Agregar Créditos",
    },
    tab1: {
      modal: {
        add: "Agregar Marca",
        edit: "Editar Marca",
      },
      title: "Marcas",
      search: "Buscar Marcas de ECUs",
      add: {
        button: "Agregar Marca",
        form: {
          title: "Agregar Marca",
          name: {
            title: "Nombre",
            placeholder: "Ingrese un nombre",
          },
          description: {
            title: "Descripción",
            placeholder: "Ingrese una descripción",
          },
          brand: { title: "Marca", placeholder: "Seleccione una marca" },
          button: "Agregar",
          buttonUpdate: "Actualizar",
        },
      },
      table: {
        edit: "Edit",
        delete: "Delete",
      },
      notification: {
        create: {
          success: {
            title: "ECU",
            description: "La marca se ha creado correctamente.",
          },
          error: {
            title: "ECU",
            description:
              "Error al crear la marca. Vuelva a intentarlo más tarde.",
          },
        },
        edited: {
          success: {
            title: "ECU",
            description: "La marca se ha editado correctamente.",
          },
          error: {
            title: "ECU",
            description:
              "Error al editar la marca. Vuelva a intentarlo más tarde.",
          },
        },
        delete: {
          success: {
            title: "ECU",
            description: "La marca se ha eliminado correctamente.",
          },
          error: {
            title: "Servicio",
            description:
              "Error al eliminar el servicio. Vuelva a intentarlo más tarde.",
          },
        },
      },
      confirmation: {
        delete: {
          title: "Eliminar Marca",
          description: "¿Está seguro de eliminar esta marca?",
          button: {
            cancel: "Cancelar",
          },
        },
      },
    },
    tab2: {
      modal: {
        add: "Agregar Modelo",
        edit: "Editar Modelo",
      },
      title: "Modelos",
      search: "Buscar Modelos de ECUs",
      add: {
        button: "Agregar Modelo",
        form: {
          title: "Agregar Modelo",
          name: {
            title: "Nombre",
            placeholder: "Ingrese un nombre",
          },
          description: {
            title: "Descripción",
            placeholder: "Ingrese una descripción.",
          },
          brand: { title: "Marca", placeholder: "Seleccione una marca" },
          button: "Agregar",
          buttonUpdate: "Actualizar",
        },
      },
      table: {
        edit: "Edit",
        delete: "Delete",
      },
      notification: {
        create: {
          success: {
            title: "ECU",
            description: "El modelo se ha creado correctamente.",
          },
          error: {
            title: "ECU",
            description:
              "Error al crear el modelo. Vuelva a intentarlo más tarde.",
          },
        },
        edited: {
          success: {
            title: "ECU",
            description: "El modelo se ha editado correctamente.",
          },
          error: {
            title: "ECU",
            description:
              "Error al editar el modelo. Vuelva a intentarlo más tarde.",
          },
        },
        delete: {
          success: {
            title: "ECU",
            description: "El modelo se ha eliminado correctamente.",
          },
          error: {
            title: "Servicio",
            description:
              "Error al eliminar el modelo. Vuelva a intentarlo más tarde.",
          },
        },
      },
      confirmation: {
        delete: {
          title: "Eliminar un Modelo",
          description: "¿Está seguro de eliminar este modelo?",
          button: {
            cancel: "Cancelar",
          },
        },
      },
    },
  },
};

export const vehicleScreen = {
  es: {
    cancel_button: "Cancelar",
    title: "Vehículos",
    tabs: {
      model: {
        title: "Modelo",
        search: "Buscar Modelos de Vehículos",
        article: "este modelo",
        article_2: "El modelo",
        modal: {
          edit: "Editar Modelo",
          add: "Agregar Modelo",
        },
      },
      brand: {
        title: "Marca",
        search: "Buscar Marcas de Vehículos",
        article: "esta marca",
        article_2: "La marca",
        modal: {
          edit: "Editar Marca",
          add: "Agregar Marca",
        },
      },
      motor: {
        title: "Motor",
        search: "Buscar Motores de Vehículos",
        article: "este motor",
        article_2: "El motor",
        modal: {
          edit: "Editar Motor",
          add: "Agregar Motor",
        },
      },
      type: {
        title: "Tipo",
        search: "Buscar Tipos de Vehículos",
        article: "este tipo",
        article_2: "El tipo",
        modal: {
          edit: "Editar Tipo",
          add: "Agregar Tipo",
        },
      },
    },
    tooltips: {
      edit: "Editar",
      delete: "Eliminar",
      account_movement: "Movimientos de Cuenta",
      balance: "Agregar Créditos",
    },
    form: {
      name: {
        title: "Nombre",
        placeholder: "Ingrese el nombre",
      },
      description: {
        title: "Descripción",
        placeholder: "Ingrese la descripción",
      },
      brand: {
        title: "Marca",
        placeholder: "Ingrese la marca",
      },
      motor: {
        title: "Motor",
        placeholder: "Ingrese el motor",
      },
      type: {
        title: "Tipo",
        placeholder: "Ingrese el tipo",
      },
      ecu: {
        title: "Modelo de ECU",
        placeholder: "Seleccione el modelo de ECU",
      },
      ecu_brand: {
        title: "Marca de ECU",
        placeholder: "Seleccione la marca de ECU",
      },
      button: {
        add: "Agregar",
        edit: "Actualizar",
      },
    },
    notification: {
      cancel: {
        title: "Eliminar _tab_",
        description: "¿Está seguro de eliminar _tab_?",
        button: {
          cancel: "Cancelar",
        },
      },
      create: {
        success: {
          title: "_tab_",
          description: "_tab_ se ha creado correctamente",
        },
        error: {
          title: "_tab_",
          description: "Error al crear _tab_. Vuelva a intentarlo más tarde.",
        },
      },
      edited: {
        success: {
          title: "_tab_",
          description: "_tab_ se ha editado correctamente.",
        },
        error: {
          title: "_tab_",
          description: "Error al editar _tab_. Vuelva a intentarlo más tarde.",
        },
      },
      delete: {
        success: {
          title: "_tab_",
          description: "_tab_ se ha eliminado correctamente.",
        },
        error: {
          title: "_tab_",
          description:
            "Error al eliminar _tab_. Vuelva a intentarlo más tarde.",
        },
      },
    },
  },
  en: {
    cancel_button: "Cancel",
    title: "Vehicles",
    tabs: {
      model: {
        title: "Model",
        search: "Search model",
        article: "this model",
        article_2: "the model",
        modal: {
          edit: "Edit Model",
          add: "Add Model",
        },
      },
      brand: {
        title: "Brand",
        search: "Search brand",
        article: "this brand",
        article_2: "the brand",
        modal: {
          edit: "Edit brand",
          add: "Add brand",
        },
      },
      motor: {
        title: "Motor",
        search: "Search motor",
        article: "this motor",
        article_2: "the motor",
        modal: {
          edit: "Edit Motor",
          add: "Add Motor",
        },
      },
      type: {
        title: "Type",
        search: "Search type",
        article: "this type",
        article_2: "the type",
        modal: {
          edit: "Edit Type",
          add: "Add Type",
        },
      },
    },
    tooltips: {
      edit: "Edit",
      delete: "Delete",
      account_movement: "Account movement",
      balance: "Add balance",
    },
    form: {
      name: {
        title: "Name",
        placeholder: "Enter the name",
      },
      description: {
        title: "Description",
        placeholder: "Enter the description",
      },
      brand: {
        title: "Brand",
        placeholder: "Enter the brand",
      },
      motor: {
        title: "Motor",
        placeholder: "Enter the motor",
      },
      type: {
        title: "Type",
        placeholder: "Enter the type",
      },
      ecu: {
        title: "ECU Model",
        placeholder: "Enter the ECU model",
      },
      ecu_brand: {
        title: "ECU Brand",
        placeholder: "Select the ECU brand",
      },
      button: {
        add: "Add",
        edit: "Update",
      },
    },
    notification: {
      cancel: {
        title: "Delete the _tab_",
        description: "Are you sure to delete _tab_?",
        button: {
          cancel: "Cancel",
        },
      },
      create: {
        success: {
          title: "_tab_",
          description: "_tab_ has been created successfully.",
        },
        error: {
          title: "_tab_",
          description: "Error creating _tab_. Please try again later.",
        },
      },
      edited: {
        success: {
          title: "_tab_",
          description: "_tab_ has been edited successfully.",
        },
        error: {
          title: "ECU",
          description: "Error editing _tab_. Please try again later.",
        },
      },
      delete: {
        success: {
          title: "_tab_",
          description: "_tab_ has been deleted successfully.",
        },
        error: {
          title: "_tab_",
          description: "Error deleting _tab_. Please try again later.",
        },
      },
    },
  },
};

export const userScreen = {
  en: {
    cancel_button: "Cancel",
    title: "User",
    tooltips: {
      edit: "Edit",
      delete: "Delete",
      account_movement: "Account movement",
      balance: "Add balance",
      information: "User Information"
    },
    divisor: {
      basic: "Basic Information",
      ubication: "Location Information",
      password: "Password Change",
      company: "Company Information",
      info: "Other info",
    },
    tabs: [
      {},
      {
        title: "Admin",
        article: "this admin",
        article_2: "The admin",
      },
      {
        title: "Worker",
        article: "this worker",
        article_2: "The worker",
      },
      {
        title: "Client",
        article: "this client",
        article_2: "The client",
      },
    ],
    form: {
      button: {
        add: "Add",
        edit: "Update",
      },
      edit: "Edit user",
      add: "Add User",
      search: "Search Users",
      first_name: { title: "First name", placeholder: "Enter the first name" },
      last_name: { title: "Last name", placeholder: "Enter the last name" },
      email: { title: "Email", placeholder: "Enter the email" },
      username: { title: "Username", placeholder: "Enter the username" },
      sex: {
        title: "Sex",
        placeholder: "Enter the sex",
        options: ["", "Male", "Female", "No gender"],
      },
      rol: { title: "Rol", placeholder: "Enter the rol" },
      country: { title: "Country", placeholder: "Enter the country" },
      state: { title: "Province", placeholder: "Enter the province" },
      city: { title: "City", placeholder: "Enter the city" },
      dni: { title: "DNI", placeholder: "Enter the DNI/ID" },
      address: { title: "Address", placeholder: "Enter the address" },
      number: { title: "Number", placeholder: "Enter the number" },
      password_1: { title: "Password", placeholder: "Enter the password" },
      password_2: {
        title: "Repeat password",
        placeholder: "Repeat the password",
      },
      company_name: {
        title: "Company name",
        placeholder: "Enter the company name",
      },
      phone: { title: "Phone", placeholder: "Enter the phone number" },
      profession: { title: "Profession", placeholder: "Enter the profession" },
      bussiness_phone: {
        title: "Bussiness phone",
        placeholder: "Enter the bussiness phone number",
      },
    },
    notification: {
      cancel: {
        title: "Delete the _user_",
        description: "Are you sure to delete _user_?",
        button: {
          cancel: "Cancel",
        },
      },
      create: {
        success: {
          title: "_user_",
          description: "_user_ has been created successfully.",
        },
        error: {
          title: "_user_",
          description: "Error creating _user_. Please try again later.",
        },
      },
      edited: {
        success: {
          title: "_user_",
          description: "_user_ has been edited successfully.",
        },
        error: {
          title: "ECU",
          description: "Error editing _user_. Please try again later.",
        },
      },
      delete: {
        success: {
          title: "_user_",
          description: "_user_ has been deleted successfully.",
        },
        error: {
          title: "_user_",
          description: "Error deleting _user_. Please try again later.",
        },
      },
    },
    userInformationModal:{
      title: "User Information"
    }
  },
  es: {
    cancel_button: "Cancelar",
    title: "Usuarios",
    form: {
      button: {
        add: "Agregar",
        edit: "Actualizar",
      },
      edit: "Editar Usuario",
      add: "Agregar Usuario",
      search: "Buscar Usuarios",
      first_name: { title: "Nombre", placeholder: "Ingrese el nombre" },
      last_name: { title: "Apellido", placeholder: "Ingrese el apellido" },
      password_1: { title: "Contraseña", placeholder: "Ingrese la contraseña" },
      password_2: {
        title: "Repetir Contraseña",
        placeholder: "Repita la contraseña",
      },
      email: {
        title: "Correo electrónico",
        placeholder: "Ingrese el correo electrónico",
      },
      username: {
        title: "Usuario",
        placeholder: "Ingrese el usuario",
      },
      sex: {
        title: "Sexo",
        placeholder: "Ingrese el sexo",
        options: ["", "Masculino", "Femenino", "Sin especificar"],
      },
      rol: { title: "Rol", placeholder: "Ingrese el rol" },
      country: { title: "País", placeholder: "Ingrese el país" },
      dni: { title: "DNI", placeholder: "Ingrese el DNI/ID" },
      state: { title: "Provincia", placeholder: "Ingrese la provincia/estado" },
      city: { title: "Ciudad", placeholder: "Ingrese la ciudad" },
      address: { title: "Dirección", placeholder: "Ingrese la dirección" },
      number: {
        title: "Número",
        placeholder: "Ingrese el número de su dirección",
      },
      company_name: {
        title: "Nombre de la Compañía",
        placeholder: "Ingrese el nombre de la Compañía",
      },
      phone: {
        title: "Teléfono personal",
        placeholder: "Ingrese su teléfono personal",
      },
      bussiness_phone: {
        title: "Teléfono compañía",
        placeholder: "Ingrese su telefono corporativo",
      },
      profession: {
        title: "Profesión",
        placeholder: "Ingrese su profesión",
      },
    },
    tooltips: {
      edit: "Editar",
      delete: "Eliminar",
      account_movement: "Movimientos de Cuenta",
      balance: "Agregar Créditos",
      information: "Información del Usuario"
    },
    notification: {
      cancel: {
        title: "Eliminar _user_",
        description: "¿Está seguro de eliminar _user_?",
        button: {
          cancel: "Cancelar",
        },
      },
      create: {
        success: {
          title: "_user_",
          description: "_user_ se ha creado correctamente.",
        },
        error: {
          title: "_user_",
          description: "Error al crear _user_. Vuelva a intentarlo más tarde.",
        },
      },
      edited: {
        success: {
          title: "_user_",
          description: "_user_ se ha editado correctamente.",
        },
        error: {
          title: "_user_",
          description: "Error al editar _user_. Vuelva a intentarlo más tarde.",
        },
      },
      delete: {
        success: {
          title: "_user_",
          description: "_user_ se ha eliminado correctamente.",
        },
        error: {
          title: "_user_",
          description:
            "Error al eliminar _user_. Vuelva a intentarlo más tarde.",
        },
      },
    },
    divisor: {
      basic: "Información Básica",
      ubication: "Información de Ubicación",
      company: "Información de la Compañía",
      info: "Otra información",
      password: "Cambio de Contraseña",
    },
    tabs: [
      {},
      {
        title: "Administrador",
        article: "este admin",
        article_2: "El admin",
      },
      {
        title: "Trabajador",
        article: "este trabajador",
        article_2: "El trabajador",
      },
      {
        title: "Cliente",
        article: "este cliente",
        article_2: "El cliente",
      },
    ],
    userInformationModal:{
      title: "Información del Usuario"
    }
  },
};

export const jobScreen = {
  en: {
    cancel_button: "Cancel",
    file: "Files",
    title: "Inbox",
    search: "Search job",
    tooltips: {
      edit: "Edit",
      delete: "Delete",
      download: "Download",
      file: "Files",
      as_worker: "Assign worker",
      start_job: "Start job",
      job_info: "Job info",
      go_back: "Review to pending state",
      //start_job: "Start job",
      end_job: "End job",
      send_job: "Send job",
      pause: "Pause job",
      discount: `Value greater than 0, it is an increase on the total cost.
      Value less than 0, is a discount on the total cost.
      The application range is from -100% to 100%.`,
    },
    states: {
      pending: "Pending",
      "in process": "In process",
      "in queue": "In queue",
      finished: "Finished",
      delivered: "Delivered",
      sent: "Delivered",
      pause: "Paused",
      paused: "Paused",
    },
    button: {
      add: "Add",
      edit: "Update",
      draft: "Save draft",
      end: "Finish",
      end_send: "Finish and send",
      cancel: "Cancel",
    },
    edit: {
      title: "Edit job",
    },
    add: {
      button: "Add job",
      form: {
        title: "Add job",
        start_time: {
          title: "Start date",
          placeholder: "Enter the start date",
        },
        position: {
          title: "Posiciòn",
          placeholder: "Seleccione el modelo de ECU",
        },
        end_time: { title: "Send date", placeholder: "Enter the end date" },
        entry_date: {
          title: "entry_date",
          placeholder: "Enter the entry_date",
        },
        country: {
          title: "country",
          placeholder: "Enter the entry_date",
        },
        finish_date: {
          title: "finish_date",
          placeholder: "Enter the finish_date",
        },
        client: { title: "Client", placeholder: "Enter the client" },
        vehicle_model: {
          title: "Vehicle model",
          placeholder: "Enter the vehicle model",
        },
        vehicle: {
          title: "Vehicle",
          placeholder: "Enter the vehicle model",
        },
        vehicle_brand: {
          title: "Vehicle brand",
          placeholder: "Enter the vehicle brand",
        },
        ecu: {
          title: "ECU Model",
          placeholder: "Enter the ECU model",
        },
        ecu_brand: {
          title: "ECU Brand",
          placeholder: "Select the ECU brand",
        },
        vehicle_motor: {
          title: "Vehicle motor",
          placeholder: "Enter the vehicle motor",
        },
        vehicle_type: {
          title: "Vehicle Type",
          placeholder: "Enter the vehicle type",
        },
        client_description: {
          title: "File`s description",
          placeholder: "Enter the file`s description",
        },
        client_comment: {
          title: "Client comment",
          placeholder: "Enter the client comment",
        },
        worker_comment: {
          title: "Worker comment",
          placeholder: "Enter the worker comment",
        },
        worker: { title: "Worker", placeholder: "Select the worker" },
        first_name: { title: "First name", placeholder: "Select the worker" },
        last_name: { title: "Last name", placeholder: "Select the worker" },
        balance: { title: "Balance", placeholder: "Select the worker" },
        company_name: {
          title: "Company name",
          placeholder: "Select the worker",
        },
        cell_phone: { title: "Cell phone", placeholder: "Select the worker" },
        file: {
          title: "Files",
          placeholder: "Select the files",
          description: "Click or drag files to this area to upload",
        },
        service: { title: "Service", placeholder: "Select the service" },
        discount: {
          title: "Discount/Aument",
          placeholder: "Select the discount",
        },
        actions: {
          title: "Edited file`s modification",
          placeholder: "Enter the edited file`s modification",
        },
        state: { title: "State", placeholder: "Select the state" },
        name: { title: "Name", placeholder: "Enter the name" },
        code: { title: "Code", placeholder: "Enter the code" },
        credit_price: { title: "Price", placeholder: "Enter the price" },
        description: {
          title: "Description",
          placeholder: "Enter the description",
        },
        custom: {
          brand: "Custom brand",
          type: "Custom type",
          model: "Custom model",
          motor: "Custom motor",
          ecu_brand: "Custom ecu brand",
          ecu_model: "Custom ecu motor",
        },
        button: "Submit",
      },
    },
    table: {
      edit: "Edit",
      delete: "Delete",
    },
    notification: {
      create: {
        success: {
          title: "Job",
          description: "The job has been created successfully.",
        },
        error: {
          title: "Job",
          description: "Error creating the job. Please try again later.",
        },
      },
      edited: {
        success: {
          title: "Job",
          description: "The job has been edited successfully.",
        },
        error: {
          title: "Job",
          description: "Error editing the job. Please try again later.",
        },
      },
      delete: {
        success: {
          title: "Job",
          description: "The job has been deleted successfully.",
        },
        error: {
          title: "Job",
          description: "Error deleting the job. Please try again later.",
        },
      },
      cancel: {
        title: "Delete the job",
        description: "Are you sure to delete the job?",
        button: {
          cancel: "Cancel",
        },
      },
    },
    confirmation: {
      delete: {
        title: "Delete the job",
        description: "Are you sure to delete this job?",
        button: {
          cancel: "Cancel",
        },
      },
      start: {
        title: "Start the job",
        description: "Are you sure to start this job?",
        button: {
          cancel: "Cancel",
        },
      },
      finish: {
        title: "Finish the job",
        description: "Are you sure to finish this job?",
        button: {
          cancel: "Cancel",
        },
      },
      pending: {
        title: "Review job",
        description: "Are you sure to review this job?",
        button: {
          cancel: "Cancelar",
        },
      },
      send: {
        title: "Send the job",
        description: "Are you sure to send this job?",
        button: {
          cancel: "Cancel",
        },
      },
      pause: {
        title: "Pause the job",
        description: "Are you sure to pause this job?",
        button: {
          cancel: "Cancel",
        },
      },
    },
    files: {
      search: "Search Files",
      confirmation: {
        delete: {
          title: "Delete the file",
          description: "Are you sure to delete this file?",
          button: {
            cancel: "Cancel",
          },
        },
      },
      notification: {
        create: {
          success: {
            title: "File",
            description: "The file has been created successfully.",
          },
          error: {
            title: "File",
            description: "Error creating the file. Please try again later.",
          },
        },
        delete: {
          success: {
            title: "File",
            description: "The file has been deleted successfully.",
          },
          error: {
            title: "File",
            description: "Error deleting the file. Please try again later.",
          },
        },
      },
      modal: {
        upload: {
          title: "Upload file",
        },
        list: {
          title: "Archivos",
        },
      },
      form: {
        name: "Name",
        date: "Date",
      },
    },
    description: {
      job: "Job information",
      client: "Client information",
    },
    balance_resume: {
      cost: "Job cost",
      discount: "Job discount",
      total: "Job total cost",
      credit: "User credit",
      balance_total: "User balance total",
    },
  },
  es: {
    cancel_button: "Cancelar",
    title: "Bandeja de Entrada",
    search: "Buscar Trabajos",
    file: "Archivos",
    tooltips: {
      edit: "Editar",
      delete: "Eliminar",
      account_movement: "Movimientos de Cuenta",
      balance: "Agregar Créditos",
      download: "Descargar",
      file: "Archivos",
      as_worker: "Asignar trabajador",
      start_job: "Iniciar Trabajo",
      job_info: "Información del Trabajo",
      go_back: "Pausar Trabajo",
      end_job: "Finalizar Trabajo",
      send_job: "Enviar Trabajo",
      pause: "Pausar Trabajo",
      discount: `Valor mayor a 0, es un aumento sobre el costo total.
      Valor menor a 0, es un descuento sobre el costo total.
      El rango de aplicación es de -100% a 100%.`,
    },
    states: {
      pending: "Pendiente",
      "in process": "En proceso",
      "in queue": "En fila",
      finished: "Finalizado",
      delivered: "Enviado",
      sent: "Enviado",
      pause: "Pausado",
      paused: "Pausado",
    },
    edit: {
      title: "Editar Trabajo",
    },
    button: {
      add: "Agregar Trabajo",
      edit: "Actualizar",
      draft: "Guardar borrador",
      end: "Finalizar",
      end_send: "Finalizar y enviar",
      cancel: "Cancelar",
    },
    add: {
      button: "Agregar Trabajo",
      form: {
        start_time: {
          title: "Fecha de inicio",
          placeholder: "Ingrese la fecha de inicio",
        },
        discount: {
          title: "Descuento/Aumento",
          placeholder: "Ingrese el porcentaje de aumento o descuento a aplicar",
        },
        end_time: {
          title: "Fecha de envío",
          placeholder: "",
        },
        entry_date: {
          title: "Fecha de entrada",
          placeholder: "",
        },
        country: {
          title: "Pais",
          placeholder: "Enter the entry_date",
        },
        finish_date: {
          title: "Fecha de finalización",
          placeholder: "",
        },
        client: { title: "Cliente", placeholder: "Ingrese el cliente" },
        vehicle_model: {
          title: "Modelo de vehículo",
          placeholder: "Ingrese el modelo del vehículo",
        },
        vehicle_brand: {
          title: "Marca del vehículo",
          placeholder: "Seleccione la marca del vehículo",
        },
        vehicle: {
          title: "Vehículo",
          placeholder: "Seleccione la marca del vehículo",
        },
        ecu: {
          title: "Modelo de ECU",
          placeholder: "Seleccione el modelo de ECU",
        },
        position: {
          title: "Posición",
          placeholder: "",
        },
        ecu_brand: {
          title: "Marca de ECU",
          placeholder: "Seleccione la marca de ECU",
        },
        vehicle_motor: {
          title: "Motor del vehículo",
          placeholder: "Seleccione el motor del vehículo",
        },
        vehicle_type: {
          title: "Tipo de vehículo",
          placeholder: "Seleccione el tipo de vehículo",
        },
        client_description: {
          title: "Descripción del archivo",
          placeholder: "Ingrese la descripción del archivo",
        },
        client_comment: {
          title: "Comentario del cliente",
          placeholder: "Ingrese el comentario del cliente",
        },
        worker_comment: {
          title: "Comentario del trabajador",
          placeholder: "Ingrese el comentario del trabajador",
        },
        worker: {
          title: "Trabajador",
          placeholder: "Seleccione el trabajador",
        },
        first_name: { title: "Nombre", placeholder: "" },
        last_name: { title: "Apellido", placeholder: "" },
        balance: { title: "Balance", placeholder: "" },
        company_name: {
          title: "Nombre de la Compañía",
          placeholder: "",
        },
        cell_phone: { title: "Celular", placeholder: "" },
        service: { title: "Servicio", placeholder: "Seleccione el servicio" },
        actions: {
          title: "Descripción del archivo modificado",
          placeholder: "Ingrese el comentario del archivo modificado",
        },
        state: { title: "Estado", placeholder: "" },
        file: {
          title: "Archivos",
          placeholder: "Seleccione los archivos",
          description:
            "Haga click o arrastre los archivos a esta área para cargarlos.",
        },
        title: "Agregar Trabajo",
        name: { title: "Nombre", placeholder: "Ingrese el nombre" },
        code: { title: "Código", placeholder: "Ingrese el código" },
        credit_price: { title: "Costo", placeholder: "Ingrese el costo" },
        description: {
          title: "Descripción",
          placeholder: "Ingrese la descripción",
        },
        custom: {
          brand: "Marca personalizada",
          type: "Tipo personalizado",
          model: "Modelo personalizado",
          motor: "Motor personalizado",
          ecu_brand: "Marca de ECU personalizado",
          ecu_model: "Modelo de ECU personalizado",
        },
        button: "Enviar",
      },
    },
    table: {
      edit: "Editar",
      delete: "Eliminar",
    },
    notification: {
      create: {
        success: {
          title: "Trabajo",
          description: "El Trabajo se ha creado correctamente.",
        },
        error: {
          title: "Trabajo",
          description:
            "Error al crear el Trabajo. Vuelva a intentarlo más tarde.",
        },
      },
      edited: {
        success: {
          title: "Trabajo",
          description: "El Trabajo se ha editado correctamente.",
        },
        error: {
          title: "Trabajo",
          description:
            "Error al crear el Trabajo. Por favor, inténtalo de nuevo más tarde.",
        },
      },
      delete: {
        success: {
          title: "Trabajo",
          description: "El Trabajo ha sido eliminado exitosamente.",
        },
        error: {
          title: "Trabajo",
          description:
            "Error al eliminar el Trabajo. Por favor, inténtalo de nuevo más tarde.",
        },
      },
    },
    confirmation: {
      delete: {
        title: "Eliminar Trabajo",
        description: "¿Estás seguro de que deseas eliminar este Trabajo?",
        button: {
          cancel: "Cancelar",
        },
      },
      start: {
        title: "Iniciar el Trabajo",
        description: "¿Estás seguro de que deseas iniciar este Trabajo?",
        button: {
          cancel: "Cancelar",
        },
      },
      pending: {
        title: "Pausar Trabajo",
        description: "¿Estás seguro de que deseas pausar este Trabajo?",
        button: {
          cancel: "Cancelar",
        },
      },
      finish: {
        title: "Finalizar Trabajo",
        description: "¿Estás seguro de que deseas finalizar este Trabajo?",
        button: {
          cancel: "Cancelar",
        },
      },
      send: {
        title: "Enviar Trabajo",
        description: "¿Estás seguro de que deseas enviar este Trabajo?",
        button: {
          cancel: "Cancelar",
        },
      },
      pause: {
        title: "Pausar Trabajo",
        description: "¿Estás seguro de que deseas pausar este Trabajo?",
        button: {
          cancel: "Cancelar",
        },
      },
    },
    files: {
      search: "Buscar Archivos",
      modal: {
        upload: {
          title: "Subir archivo",
        },
        list: {
          title: "Archivos",
        },
      },
      confirmation: {
        delete: {
          title: "Eliminar Archivo",
          description: "¿Estás seguro de que deseas eliminar este archivo?",
          button: {
            cancel: "Cancelar",
          },
        },
      },
      notification: {
        create: {
          success: {
            title: "Archivo",
            description: "El archivo se ha creado correctamente.",
          },
          error: {
            title: "Archivo",
            description:
              "Error al crear el archivo. Vuelva a intentarlo más tarde.",
          },
        },
        edited: {
          success: {
            title: "Archivo",
            description: "El archivo se ha editado correctamente.",
          },
          error: {
            title: "Archivo",
            description:
              "Error al crear el archivo. Por favor, inténtalo de nuevo más tarde.",
          },
        },
        delete: {
          success: {
            title: "Archivo",
            description: "El archivo ha sido eliminado exitosamente.",
          },
          error: {
            title: "Archivo",
            description:
              "Error al Eliminar Archivo. Por favor, inténtalo de nuevo más tarde.",
          },
        },
      },
      form: {
        name: "Nombre",
        date: "Fecha",
      },
    },
    description: {
      job: "Información del Trabajo",
      client: "Información del Cliente",
    },
    balance_resume: {
      cost: "Costo del Trabajo",
      discount: "Descuento del Trabajo",
      total: "Costo total del Trabajo",
      credit: "Créditos del Usuario",
      balance_total: "Créditos totales del Usuario",
    },
  },
};

export const homeScreen = {
  en: {
    title: "Welcome",
    subtitle: "Select the function that you want to do:",
    home: "home",
    register: "SigIn",
    login: "Login",
    language: {
      title: "Languaje",
      sub: {
        spanish: "Español",
        english: "English",
      },
    },
    admin: "Configuration",
    logout: "Logout",
    ecu: "ECUs",
    product: "Products",
    service: "Services",
    job: "Inbox",
    user: "Users",
    vehicle: "Vehicles",
    worker: "Users",
    client: "Client",
    credit: "Credit",
    profile: "Profile Configuration",
  },
  es: {
    title: "Bienvenido",
    subtitle: "Seleccione la funcionalidad que desea utilizar:",
    home: "home",
    register: "Registrarse",
    login: "Iniciar Sesión",
    language: {
      title: "Lenguaje",
      sub: {
        spanish: "Español",
        english: "English",
      },
    },
    admin: "Configuración",
    logout: "Cerrar Sesión",
    ecu: "ECUs",
    product: "Productos",
    service: "Servicios",
    job: "Bandeja de Entrada",
    user: "Usuarios",
    vehicle: "Vehículos",
    //worker: "Usuarios",
    client: "Clientes",
    credit: "Créditos",
    profile: "Configuración de Perfil",
  },
};

export const chatScreen = {
  en: {
    title: "Chat"
  },
  es: {
    title: "Mensajería"
  },
};

export const userInformationModal = {
  en: {
    fName: "Last name",
    lName: "First name",
    DNI: "DNI",
    username: "Username",
    cellphone: "Cellphone",
    country: "Country",
    state: "State/Province",
    city: "City",
    address: "Address",
    companyName: "Comapny name",
    balance: "Balance",
    email: "Email"
  },
  es: {
    fName: "Nombre",
    lName: "Apellido",
    DNI: "DNI",
    username: "Nombre de usuario",
    cellphone: "Celular",
    country: "País",
    state: "Provincia",
    city: "Ciudad",
    address: "Dirección",
    companyName: "Nombre de la compania",
    balance: "Balance",
    email: "Email"
  },
};