export const CURRENCY_REGISTER_REQUEST = "CURRENCY_REGISTER_REQUEST";
export const CURRENCY_REGISTER_SUCCESS = "CURRENCY_REGISTER_SUCCESS";
export const CURRENCY_REGISTER_FAIL = "CURRENCY_REGISTER_FAIL";
export const CURRENCY_REGISTER_RESET = "CURRENCY_REGISTER_RESET";

export const CURRENCY_DETAILS_REQUEST = "CURRENCY_DETAILS_REQUEST";
export const CURRENCY_DETAILS_SUCCESS = "CURRENCY_DETAILS_SUCCESS";
export const CURRENCY_DETAILS_FAIL = "CURRENCY_DETAILS_FAIL";
export const CURRENCY_DETAILS_RESET = "CURRENCY_DETAILS_RESET";

export const CURRENCY_UPDATE_REQUEST = "CURRENCY_UPDATE_REQUEST";
export const CURRENCY_UPDATE_SUCCESS = "CURRENCY_UPDATE_SUCCESS";
export const CURRENCY_UPDATE_FAIL = "CURRENCY_UPDATE_FAIL";
export const CURRENCY_UPDATE_RESET = "CURRENCY_UPDATE_RESET";

export const CURRENCYS_LIST_REQUEST = "CURRENCYS_LIST_REQUEST";
export const CURRENCYS_LIST_SUCCESS = "CURRENCYS_LIST_SUCCESS";
export const CURRENCYS_LIST_FAIL = "CURRENCYS_LIST_FAIL";
export const CURRENCYS_LIST_RESET = "CURRENCYS_LIST_RESET";

export const CURRENCY_DELETE_REQUEST = "CURRENCY_DELETE_REQUEST";
export const CURRENCY_DELETE_SUCCESS = "CURRENCY_DELETE_SUCCESS";
export const CURRENCY_DELETE_FAIL = "CURRENCY_DELETE_FAIL";
export const CURRENCY_DELETE_RESET = "CURRENCY_DELETE_RESET";
