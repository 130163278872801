import {
  CURRENCY_DETAILS_FAIL,
  CURRENCY_DETAILS_REQUEST,
  CURRENCY_DETAILS_RESET,
  CURRENCY_DETAILS_SUCCESS,
  CURRENCY_DELETE_FAIL,
  CURRENCY_DELETE_REQUEST,
  CURRENCY_DELETE_RESET,
  CURRENCY_DELETE_SUCCESS,
  CURRENCY_REGISTER_FAIL,
  CURRENCY_REGISTER_REQUEST,
  CURRENCY_REGISTER_SUCCESS,
  CURRENCY_REGISTER_RESET,
  CURRENCY_UPDATE_FAIL,
  CURRENCY_UPDATE_REQUEST,
  CURRENCY_UPDATE_RESET,
  CURRENCY_UPDATE_SUCCESS,
  CURRENCYS_LIST_FAIL,
  CURRENCYS_LIST_REQUEST,
  CURRENCYS_LIST_RESET,
  CURRENCYS_LIST_SUCCESS,
} from "../constants/currencyConstants";

export const getCurrencysReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENCYS_LIST_REQUEST:
      return { loading: true };

    case CURRENCYS_LIST_SUCCESS:
      return { loading: false, currencysList: action.payload };

    case CURRENCYS_LIST_FAIL:
      return { loading: false, error: action.payload };

    case CURRENCYS_LIST_RESET:
      return {};

    default:
      return state;
  }
};

export const getCurrencyDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENCY_DETAILS_REQUEST:
      return { loading: true };

    case CURRENCY_DETAILS_SUCCESS:
      return { loading: false, currency: action.payload };

    case CURRENCY_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case CURRENCY_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteCurrencyReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENCY_DELETE_REQUEST:
      return { loading: true };

    case CURRENCY_DELETE_SUCCESS:
      return { loading: false, success: true, currency: action.payload };

    case CURRENCY_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case CURRENCY_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

export const updateCurrencyReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENCY_UPDATE_REQUEST:
      return { loading: true };

    case CURRENCY_UPDATE_SUCCESS:
      return { loading: false, success: true, currency: action.payload };

    case CURRENCY_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case CURRENCY_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const registerCurrencyReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENCY_REGISTER_REQUEST:
      return { loading: true };

    case CURRENCY_REGISTER_SUCCESS:
      return { loading: false, success: true, currency: action.payload };

    case CURRENCY_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case CURRENCY_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};
