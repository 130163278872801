import React, { useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

import { Button, Form, Input, notification, Result, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  loginUser,
  recoveryPasswordRequest,
} from "../redux/actions/userActions";
import {
  MailOutlined,
  LockOutlined,
  HomeOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { USER_REQUEST_RECOVERY_PASSWORD_RESET } from "../redux/constants/userConstants";

const { Title } = Typography;

const RecoveryPasswordEmailScreen = (props) => {
  const content = props.content;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  const requestEmail = useSelector(
    (state) => state.userRequestRecoveryPassword
  );
  const {
    error: errorRequest,
    loading: loadingRequest,
    requestInfo,
    success: successRecoveryEmail,
  } = requestEmail;

  useEffect(() => {
    dispatch({
      type: USER_REQUEST_RECOVERY_PASSWORD_RESET,
    });
    if (userInfo) {
      navigate(redirect);
    }
    if (errorRequest) {
      notification.error({
        message: content.notification.create.error.title,
        description: content.notification.create.error.description,
        placement: "bottomRight",
      });
    }
  }, [navigate, userInfo, redirect, errorRequest]);

  const submitHandler = (e) => {
    dispatch(recoveryPasswordRequest(e));
  };

  return (
    <>
      {successRecoveryEmail ? (
        <Result
          status="success"
          title={content.result.title}
          subTitle={content.result.subtitle}
          //style={{height:"100vh"}}
          extra={[
            <Link to="/login">
              <Button type="primary" key="console" icon={<LoginOutlined />}>
                {content.result.button}
              </Button>
            </Link>,
          ]}
        />
      ) : (
        <div className="centerForm" style={{ height: "80vh" }}>
          {!userInfo && (
            <Form
              name="normal_login"
              className="login-form"
              style={{ width: "50%" }}
              onFinish={submitHandler}
            >
              <Title level={3} className="titleCenter">
                {content.title}
              </Title>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: content?.form?.email.placeholder },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder={content.form.email.title ?? "Loading..."}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  {content.button ?? "Loading..."}
                </Button>
                <Link to="/login" style={{ float: "right", marginTop: "13px" }}>
                  {content.result.button}
                </Link>
              </Form.Item>
            </Form>
          )}
        </div>
      )}
    </>
  );
};

export default RecoveryPasswordEmailScreen;
