import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Skeleton,
  Tabs,
  Empty,
  Button,
  Typography,
  Popconfirm,
  Table,
  Modal,
  Form,
  Select,
  Input,
  Row,
  Col,
  notification,
  Tooltip,
  Space,
} from "antd";

import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InboxOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import {
  VEHICLE_MODEL_REGISTER_RESET,
  VEHICLE_MODEL_UPDATE_RESET,
  VEHICLE_MODEL_DELETE_RESET,
  VEHICLE_TYPE_REGISTER_RESET,
  VEHICLE_TYPE_UPDATE_RESET,
  VEHICLE_TYPE_DELETE_RESET,
  VEHICLE_MOTOR_REGISTER_RESET,
  VEHICLE_MOTOR_UPDATE_RESET,
  VEHICLE_MOTOR_DELETE_RESET,
  VEHICLE_BRAND_REGISTER_RESET,
  VEHICLE_BRAND_UPDATE_RESET,
  VEHICLE_BRAND_DELETE_RESET,
} from "../redux/constants/vehicleConstants";

import {
  getVehicleBrands,
  updateVehicleBrand,
  registerVehicleBrand,
  deleteVehicleBrand,
  getVehicleMotors,
  updateVehicleMotor,
  registerVehicleMotor,
  deleteVehicleMotor,
  getVehicleTypes,
  updateVehicleType,
  registerVehicleType,
  deleteVehicleType,
  getVehicleModels,
  updateVehicleModel,
  registerVehicleModel,
  deleteVehicleModel,
} from "../redux/actions/vehicleActions";

import { getEcuBrands, getEcuModels } from "../redux/actions/ecuActions";
import ExportTable from "../components/ExportTable";

const { Option } = Select;
const { Title } = Typography;

function VehicleScreen(props) {
  let content = props.content;
  const userInfo = props.userInfo;
  const tableProps = props.tableProps;
  const selectProps = props.selectProps;

  const [activeTab, setActiveTab] = useState("type");

  const [searchTextBrand, setSearchTextBrand] = useState("");
  const [searchTextMotor, setSearchTextMotor] = useState("");
  const [searchTextType, setSearchTextType] = useState("");
  const [searchTextModel, setSearchTextModel] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [visibleModel, setVisibleModel] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [recordSelected, setRecordSelected] = useState(true);
  const [selectedEcuBrand, setSelectedEcuBrand] = useState("");

  //----
  const [motorList, setMotorList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  const [deleteMotorId, setDeleteMotorId] = useState("");
  const [deleteModelId, setDeleteModelId] = useState("");
  const [deleteBrandId, setDeleteBrandId] = useState("");
  const [deleteTypeId, setDeleteTypeId] = useState("");

  //----

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  /*
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;*/

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  //brand
  const {
    vehicleBrandsList,
    loading: loadingBrand,
    error: errorBrand,
  } = useSelector((state) => state.vehicleBrandsList);

  const {
    success: successBrandUpdate,
    loading: loadingBrandUpdate,
    error: errorBrandUpdate,
    vehicleBrand: brandUpdateList,
  } = useSelector((state) => state.vehicleBrandUpdate);

  const {
    success: successBrandCreate,
    loading: loadingBrandCreate,
    error: errorBrandCreate,
    vehicleBrand: brandCreateList,
  } = useSelector((state) => state.vehicleBrandCreate);

  const {
    success: successBrandDelete,
    loading: loadingBrandDelete,
    error: errorBrandDelete,
    vehicleBrand: brandDeleteList,
  } = useSelector((state) => state.vehicleBrandDelete);

  //motor
  const {
    vehicleMotorsList,
    loading: loadingMotor,
    error: errorMotor,
  } = useSelector((state) => state.vehicleMotorsList);

  const {
    success: successMotorUpdate,
    loading: loadingMotorUpdate,
    error: errorMotorUpdate,
    vehicleMotor: motorUpdateList,
  } = useSelector((state) => state.vehicleMotorUpdate);

  const {
    success: successMotorCreate,
    loading: loadingMotorCreate,
    error: errorMotorCreate,
    vehicleMotor: motorCreateList,
  } = useSelector((state) => state.vehicleMotorCreate);

  const {
    success: successMotorDelete,
    loading: loadingMotorDelete,
    error: errorMotorDelete,
    vehicleMotor: motorDeleteList,
  } = useSelector((state) => state.vehicleMotorDelete);

  //type
  const {
    vehicleTypesList,
    loading: loadingType,
    error: errorType,
  } = useSelector((state) => state.vehicleTypesList);

  const {
    success: successTypeUpdate,
    loading: loadingTypeUpdate,
    error: errorTypeUpdate,
    vehicleType: typeUpdateList,
  } = useSelector((state) => state.vehicleTypeUpdate);

  const {
    success: successTypeCreate,
    loading: loadingTypeCreate,
    error: errorTypeCreate,
    vehicleType: typeCreateList,
  } = useSelector((state) => state.vehicleTypeCreate);

  const {
    success: successTypeDelete,
    loading: loadingTypeDelete,
    error: errorTypeDelete,
    vehicleType: typeDeleteList,
  } = useSelector((state) => state.vehicleTypeDelete);

  //model
  const {
    vehicleModelsList,
    loading: loadingModel,
    error: errorModel,
  } = useSelector((state) => state.vehicleModelsList);

  const {
    success: successModelUpdate,
    loading: loadingModelUpdate,
    error: errorModelUpdate,
    vehicleModel: modelUpdateList,
  } = useSelector((state) => state.vehicleModelUpdate);

  const {
    success: successModelCreate,
    loading: loadingModelCreate,
    error: errorModelCreate,
    vehicleModel: modelCreateList,
  } = useSelector((state) => state.vehicleModelCreate);

  const {
    success: successModelDelete,
    loading: loadingModelDelete,
    error: errorModelDelete,
    vehicleModel: modelDeleteList,
  } = useSelector((state) => state.vehicleModelDelete);

  const { ecusModelList } = useSelector((state) => state.EcusModelList);
  const { ecusBrandList } = useSelector((state) => state.EcusBrandList);

  useEffect(() => {
    if (
      successBrandUpdate ||
      successModelUpdate ||
      successTypeUpdate ||
      successMotorUpdate
    ) {
      let article_2 = content?.tabs[activeTab]?.article_2;
      notification.success({
        message: content?.tabs[activeTab].title,
        description: content?.notification?.edited?.success.description.replace(
          /_tab_/g,
          article_2
        ),
        placement: "bottomRight",
      });
    }

    if (
      errorBrandUpdate ||
      errorModelUpdate ||
      errorTypeUpdate ||
      errorMotorUpdate
    ) {
      let article = content?.tabs[activeTab]?.article;
      notification.error({
        message: content?.tabs[activeTab].title,
        description: content?.notification?.edited?.error.description.replace(
          /_tab_/g,
          article
        ),
        placement: "bottomRight",
      });
    }

    if (
      successBrandCreate ||
      successModelCreate ||
      successTypeCreate ||
      successMotorCreate
    ) {
      let article_2 = content?.tabs[activeTab]?.article_2;
      notification.success({
        message: content?.tabs[activeTab].title,
        description: content?.notification?.create?.success.description.replace(
          /_tab_/g,
          article_2
        ),
        placement: "bottomRight",
      });
    }

    if (
      errorBrandCreate ||
      errorModelCreate ||
      errorTypeCreate ||
      errorMotorCreate
    ) {
      let article = content?.tabs[activeTab]?.article;
      notification.error({
        message: content?.tabs[activeTab].title,
        description: content?.notification?.create?.error.description.replace(
          /_tab_/g,
          article
        ),
        placement: "bottomRight",
      });
    }

    if (
      successModelDelete ||
      successTypeDelete ||
      successMotorDelete ||
      successBrandDelete
    ) {
      let article_2 = content?.tabs[activeTab]?.article_2;
      notification.success({
        message: content?.tabs[activeTab].title,
        description: content?.notification?.delete?.success.description.replace(
          /_tab_/g,
          article_2
        ),
        placement: "bottomRight",
      });
    }

    if (
      errorModelDelete ||
      errorTypeDelete ||
      errorMotorDelete ||
      errorBrandDelete
    ) {
      let article_2 = content?.tabs[activeTab]?.article_2;
      notification.success({
        message: content?.tabs[activeTab].title,
        description: content?.notification?.delete?.success.description.replace(
          /_tab_/g,
          article_2
        ),
        placement: "bottomRight",
      });
    }

    /*
    if (successBrandUpdate || successBrandDelete || successBrandCreate)
      dispatch(getVehicleBrands());
    if (successMotorUpdate || successMotorDelete || successMotorCreate)
      dispatch(getVehicleMotors());
    if (successModelUpdate || successModelDelete || successModelCreate)
      dispatch(getVehicleModels());
    if (successTypeUpdate || successTypeDelete || successTypeCreate)
      dispatch(getVehicleTypes());
      */

    //resetStates();
  }, [
    successBrandUpdate,
    successModelUpdate,
    successTypeUpdate,
    successMotorUpdate,
    //---
    errorBrandUpdate,
    errorModelUpdate,
    errorTypeUpdate,
    errorMotorUpdate,
    //---
    successBrandCreate,
    successModelCreate,
    successTypeCreate,
    successMotorCreate,
    //---
    errorBrandCreate,
    errorModelCreate,
    errorTypeCreate,
    errorMotorCreate,
    //---
    successModelDelete,
    successTypeDelete,
    successMotorDelete,
    successBrandDelete,
    //---
    errorModelDelete,
    errorTypeDelete,
    errorMotorDelete,
    errorBrandDelete,
  ]);

  useEffect(() => {
    if (userInfo && userInfo.role === 3) {
      navigate("/notFound");
    } else if (userInfo && userInfo.role !== 3) {
      dispatch(getVehicleBrands());
      dispatch(getVehicleMotors());
      dispatch(getVehicleTypes());
      dispatch(getVehicleModels());
      dispatch(getEcuModels());
      dispatch(getEcuBrands());
    }
    if (userInfo === "no_logged") {
      navigate("/login");
    }
  }, [userInfo]);

  //vehicleType
  useEffect(() => {
    let temp = [];
    let tempTypeList = vehicleTypesList?.results ?? [];
    temp = [...temp, ...tempTypeList];
    setTypeList(temp);
  }, [vehicleTypesList]);

  useEffect(() => {
    if (typeUpdateList || typeDeleteList || typeCreateList) {
      resetStates();
      let typeObj = {};
      let temp = [];
      let tempTypeUpdateList = typeUpdateList ? [typeUpdateList] : [];
      typeList?.forEach((item) => {
        if (typeDeleteList === 1 && item.id === deleteTypeId) return false;
        typeObj[item.id] = item;
      });
      tempTypeUpdateList?.forEach((item) => {
        typeObj[item.id] = item;
      });
      Object.entries(typeObj).forEach(([key, item]) => {
        temp.push(item);
      });
      if (typeCreateList) temp = [...temp, typeCreateList];
      setTypeList(temp);
    }
  }, [typeUpdateList, typeCreateList, typeDeleteList]);

  //vehicleBrand
  useEffect(() => {
    let temp = [];
    let tempBrandList = vehicleBrandsList?.results ?? [];
    temp = [...temp, ...tempBrandList];
    setBrandList(temp);
  }, [vehicleBrandsList]);

  useEffect(() => {
    if (brandUpdateList || brandDeleteList || brandCreateList) {
      resetStates();
      let typeObj = {};
      let temp = [];
      let tempBrandUpdateList = brandUpdateList ? [brandUpdateList] : [];
      brandList?.forEach((item) => {
        if (brandDeleteList === 1 && item.id === deleteBrandId) return false;
        typeObj[item.id] = item;
      });
      tempBrandUpdateList?.forEach((item) => {
        typeObj[item.id] = item;
      });
      Object.entries(typeObj).forEach(([key, item]) => {
        temp.push(item);
      });
      if (brandCreateList) temp = [...temp, brandCreateList];
      setBrandList(temp);
    }
  }, [brandUpdateList, brandCreateList, brandDeleteList]);

  //vehicleModel
  useEffect(() => {
    let temp = [];
    let tempModelList = vehicleModelsList?.results ?? [];
    temp = [...temp, ...tempModelList];
    setModelList(temp);
  }, [vehicleModelsList]);

  useEffect(() => {
    if (modelUpdateList || modelDeleteList || modelCreateList) {
      resetStates();
      let typeObj = {};
      let temp = [];
      let tempModelUpdateList = modelUpdateList ? [modelUpdateList] : [];
      modelList?.forEach((item) => {
        if (modelDeleteList === 1 && item.id === deleteModelId) return false;
        typeObj[item.id] = item;
      });
      tempModelUpdateList?.forEach((item) => {
        typeObj[item.id] = item;
      });
      Object.entries(typeObj).forEach(([key, item]) => {
        temp.push(item);
      });
      if (modelCreateList) temp = [...temp, modelCreateList];
      setModelList(temp);
    }
  }, [modelUpdateList, modelCreateList, modelDeleteList]);

  //vehicleMotor
  useEffect(() => {
    let temp = [];
    let tempMotorList = vehicleMotorsList?.results ?? [];
    temp = [...temp, ...tempMotorList];
    setMotorList(temp);
  }, [vehicleMotorsList]);

  useEffect(() => {
    if (motorUpdateList || motorDeleteList || motorCreateList) {
      resetStates();
      let typeObj = {};
      let temp = [];
      let tempMotorUpdateList = motorUpdateList ? [motorUpdateList] : [];
      motorList?.forEach((item) => {
        if (motorDeleteList === 1 && item.id === deleteMotorId) return false;
        typeObj[item.id] = item;
      });
      tempMotorUpdateList?.forEach((item) => {
        typeObj[item.id] = item;
      });
      Object.entries(typeObj).forEach(([key, item]) => {
        temp.push(item);
      });
      if (motorCreateList) temp = [...temp, motorCreateList];
      setMotorList(temp);
    }
  }, [motorUpdateList, motorCreateList, motorDeleteList]);

  const resetStates = () => {
    //model

    dispatch({
      type: VEHICLE_MODEL_REGISTER_RESET,
    });
    dispatch({
      type: VEHICLE_MODEL_UPDATE_RESET,
    });
    dispatch({
      type: VEHICLE_MODEL_DELETE_RESET,
    });
    //type
    dispatch({
      type: VEHICLE_TYPE_REGISTER_RESET,
    });
    dispatch({
      type: VEHICLE_TYPE_UPDATE_RESET,
    });
    dispatch({
      type: VEHICLE_TYPE_DELETE_RESET,
    });
    //motor
    dispatch({
      type: VEHICLE_MOTOR_REGISTER_RESET,
    });
    dispatch({
      type: VEHICLE_MOTOR_UPDATE_RESET,
    });
    dispatch({
      type: VEHICLE_MOTOR_DELETE_RESET,
    });
    //brand
    dispatch({
      type: VEHICLE_BRAND_REGISTER_RESET,
    });
    dispatch({
      type: VEHICLE_BRAND_UPDATE_RESET,
    });
    dispatch({
      type: VEHICLE_BRAND_DELETE_RESET,
    });
  };

  const filteredBrandList = brandList.filter((service) =>
    Object.values(service)
      .join(" ")
      .toLowerCase()
      .includes(searchTextBrand.toLowerCase())
  );

  const filteredMotorList = motorList?.filter((service) =>
    Object.values(service)
      .join(" ")
      .toLowerCase()
      .includes(searchTextMotor.toLowerCase())
  );

  const filteredTypeList = typeList?.filter((service) =>
    Object.values(service)
      .join(" ")
      .toLowerCase()
      .includes(searchTextType.toLowerCase())
  );

  const filteredModelsList = modelList?.filter((service) =>
    Object.values(service)
      .join(" ")
      .toLowerCase()
      .includes(searchTextModel.toLowerCase())
  );

  const handleEdit = (record) => {
    let ecuModelData = ecusModelList?.results?.filter((item) => {
      return item.id === record.ecu_model;
    });
    record.ecu_brand = ecuModelData[0]?.brand;
    setSelectedEcuBrand(record.ecu_brand);
    setModalTitle(content?.tabs?.[activeTab].modal.edit);
    setIsEditing(true);
    setRecordSelected(record);
    form.setFieldsValue(record);
    setVisibleModel(true);
  };

  const handleAdd = () => {
    setModalTitle(content?.tabs?.[activeTab].modal.add);
    setIsEditing(false);
    setSelectedEcuBrand("");
    form.resetFields();
    setVisibleModel(true);
  };

  const hadleSubmitEdit = (record) => {
    setVisibleModel(false);
    //resetStates();
    switch (activeTab) {
      case "brand":
        dispatch(updateVehicleBrand(recordSelected.id, record));
        break;
      case "model":
        dispatch(updateVehicleModel(recordSelected.id, record));
        break;
      case "type":
        dispatch(updateVehicleType(recordSelected.id, record));
        break;
      case "motor":
        dispatch(updateVehicleMotor(recordSelected.id, record));
        break;

      default:
        break;
    }
  };

  const hadleSubmitDelete = (record) => {
    // resetStates();
    switch (activeTab) {
      case "brand":
        setDeleteBrandId(record);
        dispatch(deleteVehicleBrand(record));
        break;
      case "model":
        setDeleteModelId(record);
        dispatch(deleteVehicleModel(record));
        break;
      case "type":
        setDeleteTypeId(record);
        dispatch(deleteVehicleType(record));
        break;
      case "motor":
        setDeleteMotorId(record);
        dispatch(deleteVehicleMotor(record));
        break;

      default:
        break;
    }
  };

  const hadleSubmitAdd = (record) => {
    setVisibleModel(false);
    // resetStates();
    switch (activeTab) {
      case "brand":
        dispatch(registerVehicleBrand(record));
        break;
      case "model":
        dispatch(registerVehicleModel(record));
        break;
      case "type":
        dispatch(registerVehicleType(record));
        break;
      case "motor":
        dispatch(registerVehicleMotor(record));
        break;

      default:
        break;
    }
  };

  const dropdownSearch = (input, option) => {
    return (option?.children ?? "")
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(
        input
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      );
  };

  const locale = {
    emptyText: (
      <Empty image={InboxOutlined} description="No hay datos disponibles" />
    ),
  };

  const columnsNoModel = [
    {
      title: content?.form?.name.title,
      dataIndex: "name",
      width: "270px",
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      title: content?.form?.description.title,
      dataIndex: "description",
      width: "200px",
      sorter: (a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      //title: "Action",
      dataIndex: "id",
      key: "action",
      width: "25px",
      render: (id, record) => (
        <Space size={18}>
          <Tooltip placement="top" title={content.tooltips.edit}>
            <EditOutlined
              onClick={() => {
                setVisibleModel(true);
                handleEdit(record, id);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.delete}>
            <Popconfirm
              cancelText={content?.cancel_button}
              title={content?.tabs?.[activeTab].title}
              description={content?.notification?.cancel?.description?.replace(
                /_tab_/g,
                content?.tabs?.[activeTab].article
              )}
              onConfirm={() => hadleSubmitDelete(id)}
              okButtonProps={{
                loading:
                  loadingMotorDelete || loadingBrandDelete || loadingTypeDelete,
              }}
              //cancelText={content?.tab2?.confirmation?.delete?.button.cancel}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const columnsModel = [
    {
      title: content?.form?.type.title,
      dataIndex: "type",
      width: "200px",
      sorter: (a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (a, b) => {
        return props.renderTooltipInEllipsis(
          typeList?.filter((e) => {
            return a === e.id;
          })?.[0]?.name
        );
      },
    },
    {
      title: content?.form?.brand.title,
      dataIndex: "brand",
      width: "200px",
      sorter: (a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (a, b) => {
        return props.renderTooltipInEllipsis(
          brandList.filter((e) => {
            return a === e.id;
          })?.[0]?.name
        );
      },
    },
    {
      title: content?.form?.name.title,
      dataIndex: "name",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      title: content?.form?.motor.title,
      dataIndex: "motor",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      },
      render: (a, b) => {
        let name = [];
        //return a
        motorList?.forEach((e) => {
          if (a.includes(e.id)) {
            name.push(e.name);
          }
          //return a === e.id;
        });
        return props.renderTooltipInEllipsis(name.join(" - "));
      },
    },
    {
      title: content?.form?.ecu.title,
      dataIndex: "ecu_model",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      },
      render: (a, b) => {
        return props.renderTooltipInEllipsis(
          ecusModelList?.results?.filter((e) => {
            return a === e.id;
          })?.[0]?.name
        );
      },
    },
    {
      title: content?.form?.description.title,
      dataIndex: "description",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      },
    },
    {
      //title: "Action",
      dataIndex: "id",
      key: "action",
      width: "60px",
      render: (id, record) => (
        <Space size={18}>
          <Tooltip placement="top" title={content.tooltips.edit}>
            <EditOutlined
              onClick={() => {
                handleEdit(record);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.delete}>
            <Popconfirm
              title={content?.tabs?.[activeTab].title}
              description={content?.notification?.cancel?.description?.replace(
                /_tab_/g,
                content?.tabs?.[activeTab].article
              )}
              onConfirm={() => hadleSubmitDelete(id)}
              okButtonProps={{
                loading: loadingModelDelete,
              }}
              cancelText={content?.notification?.cancel?.button?.cancel}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const items = [
    {
      key: "type",
      label: content?.tabs?.type?.title,
      children: (
        <>
          <div style={{ marginBottom: 16 }}>
            <Row>
              <Col span={21}>
                <Input
                  placeholder={content?.tabs?.type.search}
                  onChange={(e) => setSearchTextType(e.target.value)}
                />
              </Col>
              <ExportTable
                data={filteredTypeList}
                fileTitle={content.title + " - " + content?.tabs?.type?.title}
                columns={columnsNoModel}
                extraData={
                  {
                    // brand: ecusBrandList?.results,
                    // ecusModelList: ecusModelList?.results,
                  }
                }
              />
              <Col span={1}>
                <Button
                  icon={<PlusOutlined />}
                  shape="circle"
                  style={{ marginBottom: 16, float: "right" }}
                  onClick={handleAdd}
                ></Button>
              </Col>
            </Row>
          </div>
          <Table
            {...tableProps}
            dataSource={filteredTypeList}
            columns={columnsNoModel}
            loading={loadingType}
            // locale={locale}
          />
        </>
      ),
    },
    {
      key: "brand",
      label: content?.tabs?.brand.title,
      children: (
        <>
          <div style={{ marginBottom: 16 }}>
            <Row>
              <Col span={21}>
                <Input
                  placeholder={content?.tabs?.brand.search}
                  onChange={(e) => setSearchTextBrand(e.target.value)}
                />
              </Col>
              <ExportTable
                data={filteredBrandList}
                fileTitle={content.title + " - " + content?.tabs?.brand?.title}
                columns={columnsNoModel}
                extraData={
                  {
                    // brand: ecusBrandList?.results,
                    // ecusModelList: ecusModelList?.results,
                  }
                }
              />
              <Col span={1}>
                <Button
                  icon={<PlusOutlined />}
                  // type="primary"
                  shape="circle"
                  onClick={handleAdd}
                  style={{ marginBottom: 16, float: "right" }}
                ></Button>
              </Col>
            </Row>
          </div>
          <Table
            {...tableProps}
            dataSource={filteredBrandList}
            columns={columnsNoModel}
            loading={loadingBrand}
          />
        </>
      ),
    },
    {
      key: "model",
      label: content?.tabs?.model?.title,
      children: (
        <>
          <div style={{ marginBottom: 16 }}>
            <Row>
              <Col span={21}>
                <Input
                  placeholder={content?.tabs?.model.search}
                  onChange={(e) => setSearchTextModel(e.target.value)}
                />
              </Col>
              <ExportTable
                data={filteredModelsList}
                fileTitle={content.title + " - " + content?.tabs?.model?.title}
                columns={columnsModel}
                extraData={{
                  brand: vehicleBrandsList?.results,
                  type: vehicleTypesList?.results,
                  motor: vehicleMotorsList?.results,
                  ecu_model: ecusModelList?.results,
                }}
              />
              <Col span={1}>
                <Button
                  icon={<PlusOutlined />}
                  shape="circle"
                  onClick={handleAdd}
                  style={{ marginBottom: 16, float: "right" }}
                ></Button>
              </Col>
            </Row>
          </div>
          <Table
            {...tableProps}
            dataSource={filteredModelsList}
            columns={columnsModel}
            loading={loadingModel}
          />
        </>
      ),
    },
    {
      key: "motor",
      label: content?.tabs?.motor?.title,
      children: (
        <>
          <div style={{ marginBottom: 16 }}>
            <Row>
              <Col span={21}>
                <Input
                  placeholder={content?.tabs?.motor.search}
                  onChange={(e) => setSearchTextMotor(e.target.value)}
                />
              </Col>
              <ExportTable
                data={filteredMotorList}
                fileTitle={content.title + " - " + content?.tabs?.motor?.title}
                columns={columnsNoModel}
                extraData={
                  {
                    // brand: ecusBrandList?.results,
                    // ecusModelList: ecusModelList?.results,
                  }
                }
              />
              <Col span={1}>
                <Button
                  icon={<PlusOutlined />}
                  shape="circle"
                  onClick={handleAdd}
                  style={{ marginBottom: 16, float: "right" }}
                ></Button>
              </Col>
            </Row>
          </div>
          <Table
            {...tableProps}
            dataSource={filteredMotorList}
            columns={columnsNoModel}
            loading={loadingMotor}
          />
        </>
      ),
    },
  ];

  if (userInfo.role === 3 || !userInfo || userInfo === "no_logged")
    return <></>;
  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      {/*<h1 className="title" style={{ margin: 0 }}>
        {content.title}
      </h1>*/}
      <Title className="titleCenter">{content.title}</Title>
      <Tabs
        onChange={(e) => {
          setActiveTab(e);
        }}
        activeKey={activeTab}
        items={items}
        // style={{ padding: "20px" }}
      />
      <Modal
        open={visibleModel}
        onCancel={() => setVisibleModel(false)}
        //onOk={handleSaveEcuBrand}
        footer={null}
        width={800}
        title={modalTitle}
      >
        <Form
          form={form}
          onFinish={(e) => {
            if (isEditing) {
              hadleSubmitEdit(e);
            } else {
              hadleSubmitAdd(e);
            }
          }}
          layout="vertical"
          //initialValues={ecuSelected}
        >
          <Form.Item
            label={content?.form?.name.title}
            name="name"
            rules={[
              { required: true, message: content?.form?.name.placeholder },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={content?.form?.description.title}
            name="description"
          >
            <Input />
          </Form.Item>

          {activeTab === "model" && (
            <>
              <Form.Item
                label={content?.form?.type.title}
                name="type"
                rules={[
                  { required: true, message: content?.form?.type.placeholder },
                ]}
              >
                <Select {...selectProps} label="Brand">
                  {typeList?.map((marca) => (
                    <Option key={marca.id} value={marca.id}>
                      {
                        typeList?.filter((e) => {
                          return marca.id === e.id;
                        })[0]?.name
                      }
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={content?.form?.brand.title}
                name="brand"
                rules={[
                  { required: true, message: content?.form?.brand.placeholder },
                ]}
              >
                <Select {...selectProps} label="Brand">
                  {brandList.map((marca) => (
                    <Option key={marca.id} value={marca.id}>
                      {
                        brandList.filter((e) => {
                          return marca.id === e.id;
                        })[0]?.name
                      }
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={content?.form?.motor.title}
                name="motor"
                rules={[
                  { required: true, message: content?.form?.motor.placeholder },
                ]}
              >
                <Select {...selectProps} mode="multiple" label="Motor">
                  {motorList?.map((marca) => (
                    <Option key={marca.id} value={marca.id}>
                      {
                        motorList?.filter((e) => {
                          return marca.id === e.id;
                        })[0]?.name
                      }
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={content?.form?.ecu_brand.title}
                name="ecu_brand"
                //rules={[
                //  { required: true, message: content?.form?.ecu.placeholder },
                //]}
              >
                <Select
                  {...selectProps}
                  // label={content?.form?.ecu_brand}
                  value={selectedEcuBrand}
                  onChange={(e) => {
                    form.setFieldsValue({ ecu_model: "" });
                    setSelectedEcuBrand(e);
                  }}
                >
                  {ecusBrandList?.results?.map((model) => {
                    return (
                      <Option key={model.id} value={model.id}>
                        {
                          ecusBrandList?.results?.filter((e) => {
                            return model.id === e.id;
                          })[0]?.name
                        }
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={content?.form?.ecu.title}
                name="ecu_model"
                rules={[
                  { required: true, message: content?.form?.ecu.placeholder },
                ]}
              >
                <Select
                  {...selectProps}
                  label={content?.form?.brand}
                  disabled={!selectedEcuBrand}
                  showSearch
                  //value={selectedEcubrand}
                  optionFilterProp="children"
                  filterOption={dropdownSearch}
                >
                  {ecusModelList?.results?.map((marca) => {
                    if (marca.brand !== selectedEcuBrand) return false;
                    return (
                      <Option key={marca.id} value={marca.id}>
                        {
                          ecusModelList?.results?.filter((e) => {
                            return marca.id === e.id;
                          })[0]?.name
                        }
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Button
              style={{ float: "right" }}
              type="primary"
              htmlType="submit"
              loading={
                loadingBrandCreate ||
                loadingBrandUpdate ||
                loadingModelCreate ||
                loadingModelUpdate ||
                loadingMotorCreate ||
                loadingMotorUpdate ||
                loadingTypeCreate ||
                loadingTypeUpdate
              }
            >
              {isEditing
                ? content?.form?.button?.edit
                : content?.form?.button?.add}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default VehicleScreen;
