import React, { useState } from "react";

import Toggle from "../toggle";
//import useDarkMode from "use-dark-mode";

const DarkModeToggle = (props) => {
  //const [darkMode, setDarkMode] = useState(false) // =  useDarkMode(false);

  return (
    <div className="dark-mode-toggle">
      <button
        type="button"
        onClick={() => {
          props.setDarkMode(false);
          props.storeThemeInLocalStorage(false);
        }}
      >
        ☀
      </button>
      <Toggle
        checked={props.darkMode}
        onChange={props.setDarkMode}
        storeThemeInLocalStorage={props.storeThemeInLocalStorage}
      />
      <button
        type="button"
        onClick={() => {
          props.storeThemeInLocalStorage(true);
          props.setDarkMode(true);
        }}
      >
        ☾
      </button>
    </div>
  );
};

export default DarkModeToggle;
