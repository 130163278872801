import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Skeleton,
  Typography,
  Input,
  Tabs,
  Checkbox,
  Row,
  Col,
  Button,
  Form,
  Popconfirm,
  Space,
  Table,
  Modal,
  Tooltip,
  InputNumber,
  Select,
  Collapse,
  Divider,
} from "antd";
import { notification } from "antd";
import {
  getClientsList,
  activateClient,
  getUserList,
  registerWorker,
  registerUser,
  registerClient,
  deleteWorker,
  deleteUser,
  deleteClient,
  updateWorker,
  updateClient,
  //updateUser,
  getWorkers,
  getAdmins,
  deleteAdmin,
  updateAdmin,
  registerAdmin,
} from "../redux/actions/userActions";

import {
  updateAddress,
  registerAddress,
} from "../redux/actions/addressActions";

import {
  USER_UPDATE_PROFILE_RESET,
  USER_REGISTER_RESET,
  USER_DETAILS_RESET,
  USERS_LIST_RESET,
  USER_DELETE_RESET,
  USER_UPDATE_RESET,
  CLIENT_REGISTER_RESET,
  CLIENT_DETAILS_RESET,
  CLIENT_UPDATE_RESET,
  CLIENTS_LIST_RESET,
  CLIENT_DELETE_RESET,
  ACTIVATE_CLIENT_RESET,
  WORKER_REGISTER_RESET,
  WORKER_DETAILS_RESET,
  WORKER_UPDATE_RESET,
  WORKERS_LIST_RESET,
  WORKER_DELETE_RESET,
  ADMIN_REGISTER_RESET,
  ADMIN_DETAILS_RESET,
  ADMIN_UPDATE_RESET,
  ADMINS_LIST_RESET,
  ADMIN_DELETE_RESET,
} from "../redux/constants/userConstants";

import {
  MailOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import {
  getCities,
  getCountries,
  getStates,
} from "../redux/actions/locationActions";

import {
  CITIES_DETAILS_RESET,
  STATE_DETAILS_RESET,
} from "../redux/constants/locationConstants";
import PhoneInput from "react-phone-input-2";
import ExportTable from "../components/ExportTable";
import UserInformationModal from "../components/UserInformationModal";

const { Option } = Select;
const { Title } = Typography;
const { Panel } = Collapse;

function UserScreen(props) {
  const userInfo = props.userInfo;
  const content = props.content;
  const tableProps = props.tableProps;

  const [usersToActivate, setUsersToActivate] = useState([]);
  const [formEdit] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [sex, setSex] = useState("");
  const [customPassword, setCustomPassword] = useState(false);
  const [isUserInformationModalEdit, setIsUserInformationModalEdit] = useState(false);
  //-------
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [countryIso2, setCountryIso2] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //client
  const { success: sucessActivateClient } = useSelector(
    (state) => state.activateClient
  );

  const {
    clientsList,
    loading: loadingClients,
    error,
  } = useSelector((state) => state.clientList);

  const {
    success: successClientRegister,
    loading: loadingCreate,
    error: errorClientRegister,
  } = useSelector((state) => state.clientCreate);

  const {
    success: successClientUpdate,
    loading: loadingUpdate,
    error: errorClientUpdate,
  } = useSelector((state) => state.clientUpdate);

  const {
    success: successClientDelete,
    loading: loadingClientDelete,
    error: errorClientDelete,
  } = useSelector((state) => state.clientDelete);

  //user-admin

  const {
    userList,
    loading: loadingUsers,
    success: successUser,
  } = useSelector((state) => state.usersList);

  const {
    user: userDetails,
    loading: loadingUserDetails,
    error: errorUserDetails,
  } = useSelector((state) => state.userDetails);

  const {
    success: successUserDelete,
    loading: loadingUserDelete,
    error: errorUserDelete,
  } = useSelector((state) => state.userDelete);

  const {
    success: successUserUpdate,
    //loading: loadingUserUpdate,
    error: errorUserUpdate,
  } = useSelector((state) => state.userUpdate);

  const {
    success: successUserRegister,
    loading: loadingUserRegister,
    error: errorUserRegister,
  } = useSelector((state) => state.userRegister);

  //worker

  const {
    workersList,
    loading: loadingWorker,
    success: successWorker,
  } = useSelector((state) => state.workerList);

  const {
    success: successWorkerRegister,
    loading: loadingRegister,
    error: errorWorkerRegister,
  } = useSelector((state) => state.workerCreate);

  const {
    success: successWorkerUpdate,
    loading: loadingWorkerUpdate,
    error: errorWorkerUpdate,
  } = useSelector((state) => state.workerUpdate);

  const {
    success: successWorkerDelete,
    loading: loadingWorkerDelete,
    error: errorWorkerDelete,
  } = useSelector((state) => state.workerDelete);

  //admin

  const {
    adminsList: adminsList,
    loading: loadingAdmins,
    success: successAdmins,
  } = useSelector((state) => state.adminList);

  const {
    success: successAdminRegister,
    loading: loadingAdminRegister,
    error: errorAdminRegister,
  } = useSelector((state) => state.adminCreate);

  const {
    success: successAdminUpdate,
    loading: loadingAdminUpdate,
    error: errorAdminUpdate,
  } = useSelector((state) => state.adminUpdate);

  const {
    success: successAdminDelete,
    loading: loadingAdminDelete,
    error: errorAdminDelete,
  } = useSelector((state) => state.adminDelete);

  //location
  const { countries, loading: loadingCountries } = useSelector(
    (state) => state.countries
  );
  const { states, loading: loadingState } = useSelector(
    (state) => state.states
  );
  const { cities, loading: loadingCities } = useSelector(
    (state) => state.cities
  );

  useEffect(() => {
    let iso2Country;
    let iso2State;
    if (!countries) dispatch(getCountries());
    if (country && countries && !states) {
      countries.forEach((e) => {
        if (
          country
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") ===
          e?.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        ) {
          iso2Country = e.iso2;
        }
      });
      if (iso2Country) {
        dispatch(getStates(iso2Country));
        setCountryIso2(iso2Country);
      }
    }
    if (countries && states && !cities && province) {
      states.forEach((e) => {
        if (
          province
            ?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") ===
          e?.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        ) {
          iso2State = e.iso2;
          //setProvince(e.name);
        }
      });
      if (iso2State) {
        dispatch(getCities(countryIso2, iso2State));
      }
    }
  }, [country, countries, states, province]);

  useEffect(() => {
    if (successAdminUpdate || successAdminRegister || successAdminDelete)
      dispatch(getAdmins());
    if (successWorkerUpdate || successWorkerRegister || successWorkerDelete)
      dispatch(getWorkers());
    if (successClientUpdate || successClientRegister || successClientDelete)
      dispatch(getClientsList());

    if (
      //  successUserUpdate ||
      successWorkerUpdate ||
      successClientUpdate ||
      successAdminUpdate
    ) {
      let article_2 = content?.tabs[selectedRole]?.article_2;
      notification.success({
        message: content?.tabs[selectedRole]?.title,
        description:
          content?.notification?.edited?.success?.description?.replace(
            /_user_/g,
            article_2
          ),
        placement: "bottomRight",
      });
      setIsModalEdit(false);
    }
    if (
      //errorUserUpdate ||
      errorWorkerUpdate ||
      errorClientUpdate ||
      errorAdminUpdate
    ) {
      let article_2 = content?.tabs[selectedRole]?.article_2;
      notification.error({
        message: content?.tabs[selectedRole]?.title,
        description: content?.notification?.edited?.error?.description.replace(
          /_user_/g,
          article_2
        ),
        placement: "bottomRight",
      });
      //setIsModalEdit(false);
    }

    if (
      //  successUserRegister ||
      successClientRegister ||
      successWorkerRegister ||
      successAdminRegister
    ) {
      let article_2 = content?.tabs[selectedRole]?.article_2;
      notification.success({
        message: content?.tabs[selectedRole]?.title,
        description: content?.notification?.create?.success.description.replace(
          /_user_/g,
          article_2
        ),
        placement: "bottomRight",
      });
      setIsModalEdit(false);
    }

    if (
      //   errorUserRegister ||
      errorWorkerRegister ||
      errorClientRegister ||
      errorAdminRegister
    ) {
      let article_2 = content?.tabs[selectedRole]?.article_2;
      notification.error({
        message: content?.tabs[selectedRole]?.title,
        description: content?.notification?.create?.error?.description?.replace(
          /_user_/g,
          article_2
        ),
        placement: "bottomRight",
      });
      // setIsModalEdit(false);
    }

    if (
      //    successUserDelete ||
      successWorkerDelete ||
      successClientDelete ||
      successAdminDelete
    ) {
      let article_2 = content?.tabs[selectedRole]?.article_2;
      notification.success({
        message: content?.tabs[selectedRole]?.title,
        description: content?.notification?.delete?.success.description.replace(
          /_user_/g,
          article_2
        ),
        placement: "bottomRight",
      });
    }

    if (
      //    errorUserDelete ||
      errorWorkerDelete ||
      errorClientDelete ||
      errorAdminUpdate
    ) {
      let article_2 = content?.tabs[selectedRole]?.article_2;
      notification.error({
        message: content?.tabs[selectedRole]?.title,
        description: content?.notification?.delete?.error.description.replace(
          /_user_/g,
          article_2
        ),
        placement: "bottomRight",
      });
      //setIsModalEdit(false);
    }
  }, [
    //   successUserUpdate,
    successWorkerUpdate,
    successClientUpdate,
    successAdminDelete,
    //
    //   errorUserUpdate,
    errorWorkerUpdate,
    errorClientUpdate,
    errorAdminUpdate,
    //---
    //   successUserRegister,
    successClientRegister,
    successWorkerRegister,
    successAdminRegister,
    //---
    //  errorUserRegister,
    errorClientRegister,
    errorWorkerRegister,
    errorAdminRegister,
    //---
    //  successUserDelete,
    successWorkerDelete,
    successClientDelete,
    successAdminUpdate,
    //---
    //  errorUserDelete,
    errorWorkerDelete,
    errorClientDelete,
    errorAdminUpdate,
  ]);

  useEffect(() => {
    setCustomPassword(false);
    if (userInfo && userInfo.role === 3) {
      navigate("/notFound");
    } else if (userInfo && userInfo.role !== 3) {
      resetStates();
      dispatch(getAdmins());
      dispatch(getWorkers());
      dispatch(getClientsList());
    }
    if (userInfo === "no_logged") {
      navigate("/login");
    }
  }, [userInfo]);

  function generatePassword() {
    let characters = "abcdefghijklmnopqrstuvwxyz";
    let length = 6;
    let password = "";
    for (let i = 0; i < length; i++) {
      let position = Math.floor(Math.random() * characters.length);
      password += characters[position];
    }
    return password;
  }

  const resetStates = () => {
    dispatch({
      type: CLIENT_REGISTER_RESET,
    });

    dispatch({
      type: CLIENT_UPDATE_RESET,
    });

    dispatch({
      type: CLIENT_DELETE_RESET,
    });

    dispatch({
      type: WORKER_REGISTER_RESET,
    });

    dispatch({
      type: WORKER_UPDATE_RESET,
    });

    dispatch({
      type: WORKER_DELETE_RESET,
    });

    dispatch({
      type: ADMIN_REGISTER_RESET,
    });

    dispatch({
      type: ADMIN_UPDATE_RESET,
    });

    dispatch({
      type: ADMIN_DELETE_RESET,
    });
  };
  //dispatch -----

  const handleDelete = (record) => {
    resetStates();
    switch (record.role) {
      case 1:
        setSelectedRole(record.role);
        dispatch(deleteAdmin(record.id));
        break;

      case 2:
        setSelectedRole(record.role);
        dispatch(deleteWorker(record.id));
        break;

      case 3:
        setSelectedRole(record.role);
        dispatch(deleteClient(record.id));
        break;

      default:
        break;
    }
  };

  const handleAdd = (record) => {
    resetStates();
    if (!customPassword) {
      let randomPassword = generatePassword();
      record.password = randomPassword;
      record.Config = {
        randomPassword,
      };
    }
    switch (parseInt(record.role)) {
      case 1:
        record = {
          ...record,
          address: [
            {
              country: country,
              province: province,
              city: city,
              street_name: record.street,
              house_number: record.house_number,
            },
          ],
        };
        dispatch(registerAdmin(record));
        break;

      case 2:
        record = {
          ...record,
          address: [
            {
              country: country,
              province: province,
              city: city,
              street_name: record.street,
              house_number: record.house_number,
            },
          ],
        };
        dispatch(registerWorker(record));
        break;

      case 3:
        record = {
          ...record,
          address: [
            {
              country: country,
              province: province,
              city: city,
              street_name: record.street,
              house_number: record.house_number,
            },
          ],
        };
        dispatch(registerClient(record));
        break;

      default:
        break;
    }
  };

  const handleEdit = (record) => {
    resetStates();
    let address = "";
    //if (isNaN(parseInt(record.sex))) {
    //  record.sex = content?.form?.sex.options.indexOf(record.sex);
    //}
    record.cell_phone = record.phone;
    record.phone = record.cell_phone;
    switch (parseInt(selectedRole)) {
      case 1:
        address = {
          country: country,
          province: province,
          city: city,
          street_name: record.street,
          house_number: record.house_number,
          user: selectedUser.id,
        };

        if (!selectedUser.address_?.[0]?.id) {
          dispatch(registerAddress(address));
        } else {
          dispatch(updateAddress(selectedUser.address_?.[0]?.id, address));
        }
        dispatch(updateAdmin(selectedUser.id, record));

        break;

      case 2:
        address = {
          country: country,
          province: province,
          city: city,
          street_name: record.street,
          house_number: record.house_number,
          user: selectedUser.id,
        };

        if (!selectedUser.address_?.[0]?.id) {
          dispatch(registerAddress(address));
        } else {
          dispatch(updateAddress(selectedUser.address_?.[0]?.id, address));
        }
        dispatch(updateWorker(selectedUser.id, record));
        break;

      case 3:
        address = {
          country: country,
          province: province,
          city: city,
          street_name: record.street,
          house_number: record.house_number,
          user: selectedUser.id,
        };

        if (!selectedUser.address_?.[0]?.id) {
          dispatch(registerAddress(address));
        } else {
          dispatch(updateAddress(selectedUser.address_?.[0]?.id, address));
        }
        dispatch(updateClient(selectedUser.id, record));
        break;

      default:
        break;
    }
  };

  //when you click the button ---

  const handleEditClick = (record) => {
    setCustomPassword(false);
    dispatch({ type: STATE_DETAILS_RESET });
    dispatch({ type: CITIES_DETAILS_RESET });
    setIsModalEdit(true);
    setModalTitle(content?.form?.edit);
    setIsEditing(true);
    // setCustomPassword(false)
    setSelectedUser(record);
    setSelectedRole(record.role);
    record.country = record?.address_?.[0]?.country;
    record.state = record.address_?.[0]?.province;
    record.cities = record.address_?.[0]?.city;
    record.street = record.address_?.[0]?.street_name;
    record.house_number = record.address_?.[0]?.house_number;
    record.password = "";
    record.confirm = "";
    record.phone = record.cell_phone;
    record.cell_phone = record.phone;
    setCountry(record.country);
    setCity(record.city);
    setProvince(record.state);

    //if (isNaN(parseInt(record.sex))) {
    // record.sex = record.sex;
    //  setSex(content?.form?.sex.options.indexOf(record.sex) ?? 1);
    // } else {
    //   record.sex = content?.form?.sex.options[record.sex ?? 1];
    setSex(record.sex);
    // }
    formEdit.resetFields();
    formEdit.setFieldsValue(record);
  };

  const handleAddClick = () => {
    setCustomPassword(false);
    dispatch({ type: STATE_DETAILS_RESET });
    dispatch({ type: CITIES_DETAILS_RESET });
    setCountry("");
    setCountryIso2("");
    setProvince("");
    setIsModalEdit(true);
    setIsEditing(false);
    setSelectedUser("");
    setSelectedRole(1);
    setModalTitle(content?.form?.add);
    formEdit.resetFields();
    formEdit.setFieldsValue({
      address: [],
      country: null,
      description: null,
      password: null,
      sex: null,
      dni: null,
      email: null,
      username: null,
      first_name: null,
      last_name: null,
      currency: null,
      cell_phone: null,
      cities: "",
      city: "",
      business_phone: "",
      profession: "",
      company_name: null,
      street_name: "",
      street: "",
      house_number: "",
      province: "",
      floor: null,
      zip_code: null,
      apartment_number: null,
      role: null,
      rol: null,
    });
  };

  const handleSubmitClick = (e) => {
    if (isEditing) {
      if (e.password === "") e.password = undefined;
      handleEdit(e);
    } else {
      let record = {
        ...e,
        password: e.password === "" ? undefined : e.password,
        cell_phone: e.phone,
        address: [
          {
            country: country,
            province: province,
            city: city,
            street_name: e.street,
            house_number: e.house_number,
          },
        ],
      };
      handleAdd(record);
    }
  };

  //----

  const sortByName = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  if (states) states.sort(sortByName);
  if (countries) countries.sort(sortByName);
  if (cities) cities.sort(sortByName);

  const columns = [
    {
      width: "200px",
      title: content?.form?.last_name?.title,
      dataIndex: "last_name",
      sorter: (a, b) => {
        if (a.last_name < b.last_name) {
          return -1;
        }
        if (a.last_name > b.last_name) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      width: "200px",
      title: content?.form?.first_name?.title,
      dataIndex: "first_name",
      sorter: (a, b) => {
        if (a.first_name < b.first_name) {
          return -1;
        }
        if (a.first_name > b.first_name) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      width: "200px",
      title: content?.form?.email?.title,
      dataIndex: "email",
      sorter: (a, b) => {
        if (a.email < b.email) {
          return -1;
        }
        if (a.email > b.email) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      title: content?.form?.username?.title,
      dataIndex: "username",
      width: "200px",
      sorter: (a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      title: content?.form?.rol?.title,
      dataIndex: "role",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(
          content?.tabs?.[parseInt(text)]?.title ?? "N/A"
        ); //;
      },
      filters: [
        {
          text: "Client",
          value: "3",
        },
        {
          text: "Worker",
          value: "2",
        },
        {
          text: "Admin",
          value: "1",
        },
      ],
      onFilter: (value, record) => {
        return parseInt(value) === parseInt(record.role);
      },
      sorter: (a, b) => {
        a = content?.tabs?.[parseInt(a.role)]?.title;
        b = content?.tabs?.[parseInt(b.role)]?.title;
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      },
    },
    {
      //title: "Actions",
      width: "80px",
      key: "action",
      render: (text, record) => (
        <Space size={18}>
          <Tooltip placement="top" title={content.tooltips.edit}>
            <EditOutlined onClick={() => handleEditClick(record)} />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.delete}>
            <Popconfirm
              cancelText={content?.notification.cancel.button.cancel}
              onConfirm={() => handleDelete(record)}
              title={content?.notification.cancel?.title.replace(
                /_user_/g,
                content?.tabs?.[parseInt(record.role)]?.title
              )}
              description={content?.notification.cancel.description?.replace(
                /_user_/g,
                content?.tabs?.[parseInt(record.role)]?.article
              )}
              okButtonProps={{
                loading:
                  loadingUserDelete ||
                  loadingClientDelete ||
                  loadingWorkerDelete,
              }}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.information}>
              <InfoCircleOutlined
                onClick={(e) => {
                  setIsUserInformationModalEdit(true)
                  setSelectedUser(record);
                }}
              />
            </Tooltip>
        </Space>
      ),
    },
  ];

  const dropdownSearch = (input, option) => {
    return (option?.children ?? "")
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(
        input
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      );
  };

  const filteredAdmins = adminsList?.results?.filter((service) =>
    Object.values(service)
      .join(" ")
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );

  const filteredWorkers = workersList?.results?.filter((service) =>
    Object.values(service)
      .join(" ")
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );

  const filteredClients = clientsList?.results?.filter((service) =>
    Object.values(service)
      .join(" ")
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );

  let all = [];
  const combinedUsers = all.concat(
    filteredAdmins ?? [],
    filteredWorkers ?? [],
    filteredClients ?? []
  );

  if (userInfo.role === 3 || !userInfo || userInfo === "no_logged")
    return <></>;
  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      <Title className="titleCenter">{content.title}</Title>
      <div style={{ marginBottom: 16 }}>
        <Row>
          <Col span={21}>
            <Input
              placeholder={content.form.search}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Col>
          <ExportTable
            data={combinedUsers}
            fileTitle={content?.title}
            columns={columns}
            extraData={{
              role: [
                {
                  id: 1,
                  name: content?.tabs?.[1]?.title,
                },
                {
                  id: 2,
                  name: content?.tabs?.[2]?.title,
                },
                {
                  id: 3,
                  name: content?.tabs?.[3]?.title,
                },
              ],
            }}
          />
          <Col span={1}>
            <Button
              icon={<PlusOutlined />}
              // type="primary"
              shape="circle"
              onClick={() => handleAddClick()}
              style={{ marginBottom: 16, float: "right" }}
            ></Button>
          </Col>
        </Row>
      </div>
      <Table
        {...tableProps}
        loading={loadingUsers}
        columns={columns}
        dataSource={combinedUsers}
      />
      <Modal
        title={modalTitle}
        open={isModalEdit}
        footer={null}
        //onOk={form.submit}
        onCancel={() => setIsModalEdit(false)}
        width={800}
      >
        <Form
          form={formEdit}
          name="basic"
          initialValues={selectedUser}
          onFinish={handleSubmitClick}
          layout="vertical"
        >
          <Divider style={{ margin: 0 }}>{content.divisor.basic}</Divider>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              style={{ display: "inline-block", width: "calc(49% - 8px)" }}
              label={content?.form?.first_name?.title ?? "Loading..."}
              name="first_name"
              rules={[
                {
                  required: true,
                  message:
                    content?.form?.first_name.placeholder ?? "Loading...",
                },
              ]}
            >
              <Input autoComplete="none" />
            </Form.Item>
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(49% - 8px)",
                float: "right",
              }}
              label={content?.form?.last_name?.title ?? "Loading..."}
              name="last_name"
              rules={[
                {
                  required: true,
                  message: content?.form?.last_name.placeholder ?? "Loading...",
                },
              ]}
            >
              <Input autoComplete="none" />
            </Form.Item>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              style={{ display: "inline-block", width: "calc(49% - 8px)" }}
              name="sex"
              onChange={(value) => {
                setSex(value);
              }}
              value={sex}
              label={content?.form?.sex?.title ?? "Loading..."}
              rules={[
                {
                  required: true,
                  message: content?.form?.username.placeholder ?? "Loading...",
                },
              ]}
            >
              <Select allowClear>
                <Option value={1} key={1}>
                  {content?.form?.sex.options[1] ?? "Loading..."}
                </Option>
                <Option value={2} key={2}>
                  {content?.form?.sex.options[2] ?? "Loading..."}
                </Option>
                <Option value={3} key={3}>
                  {content?.form?.sex.options[3] ?? "Loading..."}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(49% - 8px)",
                float: "right",
              }}
              label={content?.form?.dni?.title ?? "Loading..."}
              name="dni"
              rules={[
                {
                  required: true,
                  message: content?.form?.dni.placeholder ?? "Loading...",
                },
              ]}
            >
              <Input autoComplete="none" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={content?.form?.email?.title ?? "Loading..."}
            name="email"
            rules={[
              {
                required: true,
                message: content?.form?.email.placeholder ?? "Loading...",
              },
            ]}
          >
            <Input autoComplete="none" />
          </Form.Item>
          <Form.Item
            label={content?.form?.username?.title ?? "Loading..."}
            name="username"
            rules={[
              {
                required: true,
                message: content?.form?.username.placeholder ?? "Loading...",
              },
            ]}
          >
            <Input autoComplete="none" />
          </Form.Item>
          {!isEditing && (
            <Form.Item
              name="role"
              label={content?.form?.rol?.title ?? "Loading..."}
              rules={[
                {
                  required: true,
                  message: content?.form?.username.placeholder ?? "Loading...",
                },
              ]}
            >
              <Select
                onChange={(value) => {
                  setSelectedRole(parseInt(value));
                }}
                value={selectedRole}
              >
                <Option key="1" value="1">
                  Admin
                </Option>
                <Option key="2" value="2">
                  Worker
                </Option>
                <Option key="3" value="3">
                  Client
                </Option>
              </Select>
            </Form.Item>
          )}
          <Divider style={{ margin: 0 }}>{content.divisor.ubication}</Divider>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                justifyContent: "space-between",
                display: "inline-flex",
                width: "100%",
              }}
            >
              <Form.Item
                style={{ display: "inline-block", width: "31%" }}
                name="country"
                label={content?.form?.country?.title ?? "Loading..."}
                rules={[
                  {
                    required: true,
                    message:
                      content?.form?.username.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Select
                  name="country"
                  showSearch
                  value={country}
                  optionFilterProp="children"
                  disabled={loadingCountries}
                  loading={loadingCountries}
                  filterOption={dropdownSearch}
                  onChange={(value, a, b) => {
                    setCountry(a.children);
                    setCountryIso2(a["data-iso2"]);
                    setProvince("");
                    setCity("");
                    formEdit.setFieldsValue({
                      state: "",
                      cities: "",
                      city: "",
                    });
                    dispatch({ type: STATE_DETAILS_RESET });
                    dispatch({ type: CITIES_DETAILS_RESET });
                    //dispatch(getStates(a["data-iso2"]));
                  }}
                >
                  {countries?.map((obj) => {
                    return (
                      <Option
                        key={obj.id}
                        value={obj.name}
                        data-iso2={obj.iso2}
                      >
                        {obj.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ display: "inline-block", width: "31%" }}
                name="state"
                label={content?.form?.state?.title ?? "Loading..."}
                rules={[
                  {
                    //required: true,
                    message: content?.form?.state.placeholder ?? "Loading...",
                  },
                ]}
              >
                {" "}
                <Select
                  name="state"
                  showSearch
                  value={province}
                  optionFilterProp="children"
                  filterOption={dropdownSearch}
                  placeholder={
                    content?.form?.country.placeholder ?? "Loading..."
                  }
                  disabled={country ? loadingState : true}
                  loading={loadingState}
                  onChange={(value, a, b) => {
                    value = a.children;
                    let iso2 = a["data-iso2"];
                    //dispatch({ type: STATE_DETAILS_RESET });
                    dispatch({ type: CITIES_DETAILS_RESET });
                    dispatch(getCities(countryIso2, iso2));
                    setCity("");
                    formEdit.setFieldsValue({ cities: "", city: "" });
                    setProvince(a.children);
                  }}
                >
                  {states?.map((obj) => {
                    return (
                      <Option key={obj.id} value={obj.id} data-iso2={obj.iso2}>
                        {obj.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ display: "inline-block", width: "31%" }}
                name="cities"
                label={content?.form?.city?.title ?? "Loading..."}
                rules={[
                  {
                    required: true,
                    message: content?.form?.city.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={dropdownSearch}
                  value={city}
                  name="city"
                  disabled={province ? loadingCities || loadingState : true}
                  loading={loadingCities}
                  id="state"
                  onChange={(value, a, b) => {
                    setCity(a.children);
                  }}
                >
                  {cities?.map((obj) => {
                    return (
                      <Option id={obj.id} value={obj.id} data-iso2={obj.iso2}>
                        {obj.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                justifyContent: "space-between",
                display: "inline-flex",
                width: "100%",
              }}
            >
              <Form.Item
                style={{ display: "inline-block", width: "72%" }}
                name="street"
                label={content?.form?.address?.title ?? "Loading..."}
                rules={[
                  {
                    required: true,
                    message: content?.form?.address.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Input
                  //placeholder={content?.form?.street.title ?? "Loading..."}
                  autoComplete="none"
                />
              </Form.Item>
              <Form.Item
                style={{ display: "inline-block", width: "25%" }}
                name="house_number"
                label={content?.form?.number?.title ?? "Loading..."}
                rules={[
                  {
                    required: true,
                    message: content?.form?.number.placeholder ?? "Loading...",
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  style={{ display: "inline-block", width: "100%" }}
                  autoComplete="none"
                  /* placeholder={
                    content?.form?.house_number?.title ?? "Loading..."
                  }*/
                />
              </Form.Item>
            </div>
          </Form.Item>
          {selectedRole === 2 ? (
            <>
              <Divider style={{ margin: 0 }}>{content.divisor.info}</Divider>
              <Form.Item
                label={content?.form?.bussiness_phone?.title ?? "Loading..."}
                name="business_phone"
                rules={[
                  {
                    required: true,
                    message:
                      content?.form?.bussiness_phone.placeholder ??
                      "Loading...",
                  },
                ]}
              >
                <PhoneInput
                  enableSearch
                  country={"ar"}
                  inputClass={
                    "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                  }
                  buttonClass={
                    "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                  }
                  searchClass={
                    "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                  }
                  // dropdownClass="phoneInput"
                  inputStyle={{
                    width: "100%",
                    height: "32px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                  buttonStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242 " : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                  dropdownStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                  searchStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                />
              </Form.Item>
              <Form.Item
                label={content?.form?.profession?.title ?? "Loading..."}
                name="profession"
                rules={[
                  {
                    required: true,
                    message:
                      content?.form?.profession.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Input autoComplete="none" />
              </Form.Item>
            </>
          ) : (
            ""
          )}
          {selectedRole === 3 && (
            <>
              <Divider style={{ margin: 0 }}>{content.divisor.company}</Divider>
              <Form.Item
                label={content?.form?.company_name?.title ?? "Loading..."}
                name="company_name"
                rules={[
                  {
                    required: true,
                    message:
                      content?.form?.company_name.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Input autoComplete="none" />
              </Form.Item>
              <Form.Item
                label={content?.form?.phone?.title ?? "Loading..."}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: content?.form?.phone.placeholder ?? "Loading...",
                  },
                ]}
              >
                <PhoneInput
                  enableSearch
                  country={"ar"}
                  inputClass={
                    "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                  }
                  buttonClass={
                    "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                  }
                  searchClass={
                    "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                  }
                  // dropdownClass="phoneInput"
                  inputStyle={{
                    width: "100%",
                    height: "32px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                  buttonStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242 " : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                  dropdownStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                  searchStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                />
              </Form.Item>
            </>
          )}
          {
            /*!isEditing*/ true ? (
              <>
                <Divider style={{ margin: 0 }}>
                  {content.divisor.password}
                </Divider>
                <Form.Item
                  name="disabled"
                  valuePropName="checked"
                  tooltip="test"
                >
                  <Checkbox
                    checked={customPassword}
                    onChange={(e) => setCustomPassword(e.target.checked)}
                  >
                    Custom password
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name="password"
                  label={content?.form?.password_1?.title ?? "Loading..."}
                  rules={[
                    {
                      required: customPassword,
                      message:
                        content?.form?.password_1.placeholder ?? "Loading...",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    autoComplete="none"
                    disabled={!customPassword}
                  />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label={content?.form?.password_2?.title ?? "Loading..."}
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: customPassword,
                      message:
                        content?.form?.password_2.placeholder ?? "Loading...",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          (value.length >= 6 &&
                            getFieldValue("password") === value)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The password must be at least 6 characters long and match the original password."
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    autoComplete="new-password"
                    disabled={!customPassword}
                  />
                </Form.Item>
              </>
            ) : (
              ""
            )
          }
          <Form.Item>
            <Button
              style={{ float: "right" }}
              type="primary"
              htmlType="submit"
              disabled={!isEditing ? !selectedRole : false}
              loading={
                loadingRegister ||
                loadingUpdate ||
                loadingUserRegister ||
                loadingCreate ||
                loadingWorkerUpdate ||
                loadingAdminRegister ||
                loadingAdminUpdate
              }
            >
              {isEditing ? content.form.button.edit : content.form.button.add}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <UserInformationModal
        setIsUserInformationModalEdit={setIsUserInformationModalEdit}
        isUserInformationModalEdit={isUserInformationModalEdit}
        selectedUser={selectedUser}
        title={content.userInformationModal.title}
      >
      </UserInformationModal>

    </div>
  );
}

export default UserScreen;
