import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Button,
  Typography,
  Modal,
  Tag,
  Space,
  Tabs,
  Alert,
  Checkbox,
  Select,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  notification,
  Popconfirm,
  Upload,
  InputNumber,
  Tooltip,
  Switch,
  List,
} from "antd";
import download from "downloadjs";
import {
  InboxOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
  ReloadOutlined,
  EditOutlined,
  FilterOutlined,
  FilterFilled,
  PauseOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  UploadOutlined,
  InfoCircleOutlined,
  FileOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  CloudUploadOutlined,
  ClockCircleOutlined,
  CaretRightOutlined,
  CheckOutlined,
  SendOutlined,
  UserAddOutlined,
  RollbackOutlined,
  CloudDownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  getJobs,
  registerJob,
  updateJob,
  deleteJob,
  updateJobInProccess,
  updateJobFinished,
  updateJobSend,
  updateJobPause,
} from "../redux/actions/jobActions";
import {
  JOB_DELETE_RESET,
  JOB_REGISTER_RESET,
  JOB_UPDATE_FINISHED_RESET,
  JOB_UPDATE_IN_PROCCESS_RESET,
  JOB_UPDATE_PAUSE_RESET,
  JOB_UPDATE_PENDING_RESET,
  JOB_UPDATE_RESET,
  JOB_UPDATE_SEND_RESET,
} from "../redux/constants/jobConstants";
import { apiURL } from "../redux/store";
import { getServices } from "../redux/actions/serviceActions";
import dayjs from "dayjs";
import {
  getAdmins,
  getClients,
  getWorkers,
} from "../redux/actions/userActions";
import {
  getVehicleBrands,
  getVehicleModels,
  getVehicleMotors,
  getVehicleTypes,
} from "../redux/actions/vehicleActions";
import {
  deleteFile,
  registerFile,
  updateVisibleFile,
} from "../redux/actions/fileActions";
import Descriptions from "../components/Descriptions";
import { updateJobPending } from "../redux/actions/jobActions";
import { getEcuBrands, getEcuModels } from "../redux/actions/ecuActions";
import {
  FILES_LIST_RESET,
  FILE_DELETE_RESET,
  FILE_UPDATE_VISIBLE_RESET,
  FILE_REGISTER_RESET,
} from "../redux/constants/fileConstants";
import axios from "axios";
import ExportTable from "../components/ExportTable";
import Filters from "../components/Filters";

const { Option } = Select;
const { Title } = Typography;

function JobScreen(props) {
  const content = props.content;
  const userInfo = props.userInfo;
  const tableProps = props.tableProps;
  const selectProps = props.selectProps;

  const dateFormat = "YYYY-MM-DD";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  //----

  const {
    jobsList,
    loading: loadingJobs,
    error,
  } = useSelector((state) => state.jobsList);

  const {
    success: successRegister,
    loading: loadingCreate,
    error: errorCreated,
    job: jobCreateList,
  } = useSelector((state) => state.jobCreate);

  const {
    success: successUpdate,
    loading: loadingUpdate,
    error: errorUpdate,
    job: jobUpdateList,
  } = useSelector((state) => state.jobUpdate);

  const {
    success: successDelete,
    loading: loadingDelete,
    error: errorDelete,
    job: jobDeleteList,
  } = useSelector((state) => state.jobDelete);

  //----

  const {
    success: successDeleteFile,
    loading: loadingDeleteFile,
    error: errorDeleteFile,
    file: fileDeleteList,
  } = useSelector((state) => state.fileDelete);

  const {
    success: successRegisterFile,
    loading: loadingRegisterFile,
    error: errorRegisterFile,
    file: fileCreateList,
  } = useSelector((state) => state.fileCreate);

  const {
    success: successUpdateVisibleFile,
    loading: loadingUpdateVisibleFile,
    error: errorUpdateVisibleFile,
    file: fileUpdateVisibleList,
  } = useSelector((state) => state.fileUpdateVisible);

  //----

  const {
    servicesList,
    loading: loadingServices,
    error: errorServiceList,
  } = useSelector((state) => state.servicesList);

  const {
    clientsList,
    loading: loadingClients,
    success: successClients,
  } = useSelector((state) => state.clientList);

  const {
    adminsList: adminsList,
    loading: loadingAdmins,
    success: successAdmins,
  } = useSelector((state) => state.adminList);

  const {
    workersList,
    loading: loadingWorker,
    success: successWorker,
  } = useSelector((state) => state.workerList);

  const {
    vehicleModelsList,
    loading: loadingModel,
    error: errorModel,
  } = useSelector((state) => state.vehicleModelsList);

  const {
    vehicleMotorsList,
    loading: loadingMotor,
    error: errorMotor,
  } = useSelector((state) => state.vehicleMotorsList);

  const {
    vehicleTypesList,
    loading: loadingType,
    error: errorType,
  } = useSelector((state) => state.vehicleTypesList);

  const {
    vehicleBrandsList,
    loading: loadingBrand,
    error: errorBrand,
  } = useSelector((state) => state.vehicleBrandsList);

  const { ecusModelList } = useSelector((state) => state.EcusModelList);
  const { ecusBrandList } = useSelector((state) => state.EcusBrandList);

  const {
    success: successChangePending,
    loading: loadingChangePending,
    error: errorChangePending,
    job: jobChangePendingList,
  } = useSelector((state) => state.jobUpdatePending);

  const {
    success: successChangeInProcess,
    loading: loadingChangeInProcess,
    error: errorChangeInProcess,
    job: jobChangeInProcessList,
  } = useSelector((state) => state.jobUpdateInProccess);

  const {
    success: successChangeFinished,
    loading: loadingChangeFinished,
    error: errorChangeFinished,
    job: jobChangeFinishedList,
  } = useSelector((state) => state.jobUpdateFinished);

  const {
    success: successChangeSend,
    loading: loadingChangeSend,
    error: errorChangeSend,
    job: jobChangeSendList,
  } = useSelector((state) => state.jobUpdateSend);

  const {
    success: successChangePause,
    loading: loadingChangePause,
    error: errorChangePause,
    job: jobChangePauseList,
  } = useSelector((state) => state.jobUpdatePause);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalFileVisible, setModalFileVisible] = useState(false);
  const [modalFileUploadVisible, setModalFileUploadVisible] = useState(false);
  const [modalWorkerAssignVisible, setModalWorkerAssignVisible] =
    useState(false);
  const [modalJobInfoVisible, setModalJobInfoVisible] = useState(false);
  const [modalClientInfoVisible, setModalClientInfoVisible] = useState(false);
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalFileTitle, setModalFileTitle] = useState("");
  const [modalFileUploadTitle, setModalFileUploadTitle] = useState("");
  //const [jobData, setJobData] = useState({});
  const [selectedJob, setSelectedJob] = useState({});
  const [jobDescription, setJobDescription] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isAddJob, setIsAddJob] = useState(false);
  const [isFinishJob, setIsFinishJob] = useState(false);
  const [isCredit, setIsCredit] = useState(true);
  const [previousCost, setPreviousCost] = useState(0);
  const [costTotal, setCostTotal] = useState(0);
  const [jobDiscount, setJobDiscount] = useState(0);
  const [selectedEcuBrand, setSelectedEcuBrand] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [finishState, setFinishState] = useState("");
  const [selectedFile, setSelectedFile] = useState(0);
  const [selectedVehicleBrandId, setSelectedVehicleBrandId] = useState("");
  const [selectedVehicleMotorId, setSelectedVehicleMotorId] = useState("");
  const [selectedVehicleModelId, setSelectedVehicleModelId] = useState("");
  const [selectedEcuModel, setSelectedEcuModel] = useState("");
  const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState("");
  const [vehicleBrandFiltered, setVehicleBrandFiltered] = useState([]);
  const [filesToVisible, setFilesToVisible] = useState([])
  //--
  const [customVehicleBrand, setCustomVehicleBrand] = useState("");
  const [customVehicleMotor, setCustomVehicleMotor] = useState("");
  const [customVehicleModel, setCustomVehicleModel] = useState("");

  const [customEcuModel, setCustomEcuModel] = useState("");
  const [customEcuBrand, setCustomEcuBrand] = useState("");
  //--
  const [clientDescription, setClientDescription] = useState("");
  const [form] = Form.useForm();
  const [formUploadFile] = Form.useForm();

  const [jobList, setJobList] = useState([]);
  const [deleteJobId, setDeleteJobId] = useState("");
  const [deleteFileId, setDeleteFileId] = useState("");

  const [fromTo, setFromTo] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalFilterOpen, setModalFilterOpen] = useState(false);

  const [showSpinner, setShowSpinner] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);

  //useeffects

  useEffect(() => {
    if (userInfo && userInfo.role > 0) {
      resetStates();
      dispatch(getJobs());
      if (userInfo.role === 1 || userInfo.role === 2) {
        dispatch(getServices());
        dispatch(getClients());
        dispatch(getAdmins());
        dispatch(getWorkers());
      }
      dispatch(getVehicleModels());
      dispatch(getVehicleBrands());
      dispatch(getVehicleTypes());
      dispatch(getVehicleMotors());
      dispatch(getEcuModels());
      dispatch(getEcuBrands());
    }
    if (userInfo === "no_logged") {
      navigate("/login");
    }
  }, [userInfo]);

  useEffect(() => {
    if (
      successChangePending ||
      successChangeInProcess ||
      successChangeFinished ||
      successChangePause
    ) {
      setModalVisible(false);
    }
  }, [
    successChangePending,
    successChangeInProcess,
    successChangePause,
    successChangeFinished,
  ]);

  useEffect(() => {
    if (successUpdate) {
      notification.success({
        message: content?.notification?.edited?.success?.title,
        description: content?.notification?.edited?.success?.description,
        placement: "bottomRight",
      });
      setModalVisible(false);
    }
    if (errorCreated) {
      notification.error({
        message: content?.notification?.create?.error?.title,
        description: content?.notification?.create?.error?.description,
        placement: "bottomRight",
      });
    }
    if (successRegister) {
      notification.success({
        message: content?.notification?.create?.success?.title,
        description: content?.notification?.create?.success?.description,
        placement: "bottomRight",
      });
      setModalVisible(false);
    }
    if (errorUpdate) {
      notification.error({
        message: content?.notification?.edited?.error?.title,
        description: content?.notification?.edited?.error?.description,
        placement: "bottomRight",
      });
    }
    if (successDelete) {
      notification.success({
        message: content?.notification?.delete?.success?.title,
        description: content?.notification?.delete?.success?.description,
        placement: "bottomRight",
      });
      setModalVisible(false);
    }
    if (errorDelete) {
      notification.error({
        message: content?.notification?.delete?.error?.title,
        description: content?.notification?.delete?.error?.description,
        placement: "bottomRight",
      });
    }
  }, [
    successRegister,
    successUpdate,
    successDelete,
    errorCreated,
    errorUpdate,
    successDelete,
    errorDelete,
  ]);

  useEffect(() => {
    if (errorRegisterFile) {
      notification.error({
        message: content?.files?.notification?.create?.error?.title,
        description: content?.files?.notification?.create?.error?.description,
        placement: "bottomRight",
      });
    }
    if (successRegisterFile) {
      notification.success({
        message: content?.files?.notification?.create?.success?.title,
        description: content?.files?.notification?.create?.success?.description,
        placement: "bottomRight",
      });
      setModalFileUploadVisible(false);
    }
    if (successDeleteFile) {
      notification.success({
        message: content?.files?.notification?.delete?.success?.title,
        description: content?.files?.notification?.delete?.success?.description,
        placement: "bottomRight",
      });
      setModalFileUploadVisible(false);
    }
    if (errorDeleteFile) {
      notification.error({
        message: content?.files?.notification?.delete?.error?.title,
        description: content?.files?.notification?.delete?.error?.description,
        placement: "bottomRight",
      });
    }
  }, [
    // dispatch,
    successRegisterFile,
    successDeleteFile,
    errorRegisterFile,
    errorDeleteFile,
  ]);

  useEffect(() => {
    function getProductById(productId, product) {
      return product?.find((product) => product.id === productId);
    }
    if (selectedJob && jobList) {
      let job = getProductById(selectedJob?.id, jobList); // jobsList?.results);
      setSelectedJob(job);
    }
  }, [/*jobsList */ jobList, selectedJob]);

  /*---------------------*/

  /*
  useEffect(() => {
    const fetchData = () => {
      dispatch(getJobs());
      setShowSpinner(false)
    };
    
    const interval = setInterval(fetchData, 30000);

    return () => clearInterval(interval);
  }, [dispatch]);
  */

  useEffect(() => {
    setShowSpinner(false);
    let temp = [];
    let jobObj = {};
    jobList.forEach((item) => {
      jobObj[item.id] = item;
    });
    jobsList?.results?.forEach((item) => {
      jobObj[item.id] = item;
    });
    Object.entries(jobObj).forEach(([key, item]) => {
      temp.push(item);
    });
    setJobList(temp);
  }, [jobsList]);

  useEffect(() => {
    if (
      jobUpdateList ||
      jobCreateList ||
      jobDeleteList ||
      jobChangePendingList ||
      jobChangeInProcessList ||
      jobChangeFinishedList ||
      jobChangeSendList ||
      jobChangePauseList ||
      fileCreateList ||
      fileUpdateVisibleList ||
      fileDeleteList
    ) {
      let jobObj = {};
      let temp = [];
      let tempJobUpdateList = jobUpdateList ? [jobUpdateList] : [];
      let tempJobUpdatePendingList = jobChangePendingList
        ? [jobChangePendingList]
        : [];
      let tempJobUpdateInProccessList = jobChangeInProcessList
        ? [jobChangeInProcessList]
        : [];
      let tempJobUpdateFinishedList = jobChangeFinishedList
        ? [jobChangeFinishedList]
        : [];
      let tempJobUpdatePauseList = jobChangePauseList
        ? [jobChangePauseList]
        : [];
      let tempJobUpdateSendList = jobChangeSendList ? [jobChangeSendList] : [];
      let tempfileCreateList = fileCreateList ? [fileCreateList] : [];
      let tempfileUpdateVisibleList = fileUpdateVisibleList
        ? [fileUpdateVisibleList]
        : [];
      //let tempfileDeleteList = fileDeleteList ? [fileDeleteList] : [];
      jobList?.forEach((item) => {
        if (jobDeleteList === 1 && item.id === deleteJobId) return false;
        jobObj[item.id] = item;
      });
      //----
      tempJobUpdateList?.forEach((item) => {
        jobObj[item.id] = item;
      });
      tempJobUpdatePauseList?.forEach((item) => {
        jobObj[item.id] = item;
      });
      tempJobUpdatePendingList?.forEach((item) => {
        jobObj[item.id] = item;
      });
      tempJobUpdateInProccessList?.forEach((item) => {
        jobObj[item.id] = item;
      });
      tempJobUpdateFinishedList?.forEach((item) => {
        jobObj[item.id] = item;
      });
      tempJobUpdateSendList?.forEach((item) => {
        jobObj[item.id] = item;
      });
      tempfileCreateList?.forEach((item) => {
        jobObj[item.id] = item;
      });
      tempfileUpdateVisibleList?.forEach((item) => {
        jobObj[item.id] = item;
      });
      if (fileDeleteList == 1) {
        jobObj[selectedJob.id].files = jobObj[selectedJob.id].files.filter(
          (file) => {
            return file.id !== deleteFileId;
          }
        );
      }
      //-----
      Object.entries(jobObj).forEach(([key, item]) => {
        temp.push(item);
      });
      if (jobCreateList) temp = [...temp, jobCreateList];
      setJobList(temp);
      resetStates();
    }
  }, [
    jobUpdateList,
    jobCreateList,
    jobDeleteList,
    jobChangePendingList,
    jobChangeInProcessList,
    jobChangeFinishedList,
    jobChangePauseList,
    jobChangeSendList,
    fileCreateList,
    fileDeleteList,
    fileUpdateVisibleList,
  ]);

  /*---------------------*/

  useEffect(() => {
    if (finishState === "finishAndSendJob" && successChangeFinished) {
      let payload = {
        model: selectedJob.vehicle_model,
        motor: selectedJob.motor,
        record: selectedJob,
      };

      let body = {
        vehicle_name: createVehicleModelName(payload),
      };
      const formData = {};
      dispatch(updateJobSend(selectedJob.id, body)); //formData));
      resetStates();
      setFinishState("");
    }

    if ((finishState === "finishAndSendJob" || finishState === "finishJob") && successChangeFinished) {
      if(filesToVisible.length > 0){
        filesToVisible.forEach((item)=>{
          dispatch(updateVisibleFile(item.body, item.id));
        })
      }

      if(filesToDelete.length > 0){
        filesToDelete.forEach((item)=>{
          dispatch(deleteFile(item.id))
        })
      }
    }
    //   
  }, [successChangeFinished]);

  useEffect(() => {
    if ((finishState != "finishAndSendJob" && finishState != "finishJob") && successUpdate) {
        if(filesToVisible.length > 0){
          filesToVisible.forEach((item)=>{
            dispatch(updateVisibleFile(item.body, item.id));
          })
        }

        if(filesToDelete?.length > 0){
          filesToDelete?.forEach((item)=>{
            dispatch(deleteFile(item.id))
          })
        }
    }
  }, [successUpdate]);

  const resetStates = () => {
    dispatch({
      type: JOB_DELETE_RESET,
    });
    dispatch({
      type: JOB_REGISTER_RESET,
    });
    dispatch({
      type: JOB_UPDATE_RESET,
    });
    dispatch({
      type: FILES_LIST_RESET,
    });
    dispatch({
      type: FILE_DELETE_RESET,
    });
    dispatch({
      type: FILE_REGISTER_RESET,
    });
    dispatch({
      type: JOB_UPDATE_PENDING_RESET,
    });
    dispatch({
      type: JOB_UPDATE_IN_PROCCESS_RESET,
    });
    dispatch({
      type: JOB_UPDATE_FINISHED_RESET,
    });
    dispatch({
      type: JOB_UPDATE_SEND_RESET,
    });
    dispatch({
      type: JOB_UPDATE_PAUSE_RESET,
    });
    dispatch({
      type: FILE_UPDATE_VISIBLE_RESET,
    });
  };

  //format forms with the data, empty on add, with data on edit
  const handleAddClick = () => {
    setIsFinishJob(false);
    setPreviousCost(0);
    setIsAddJob(true);
    setModalTitle(content.add.form.title);
    setSelectedVehicleBrandId("");
    setSelectedVehicleMotorId("");
    setSelectedVehicleModelId("");
    setSelectedVehicleTypeId("");
    setCustomVehicleBrand(false);
    setCustomVehicleMotor(false);
    setCustomVehicleModel(false);
    setModalVisible(true);
    let record = {
      files: [],
      entry_date: "",
      description: "",
      config: "",
      is_deleted: "",
      created_time: "",
      deleted_time: "",
      edited_time: "",
      start_time: "",
      end_time: "",
      finish_date: "",
      client_description: "",
      client_comment: "",
      worker_comment: "",
      actions: "",
      state: "",
      client: "",
      vehicle_model: "",
      vehicle_motor: "",
      motor: "",
      vehicle_brand: "",
      ecu_brand: "",
      ecu_model: "",
      vehicle_type: "",
      worker: "",
      service: undefined,
    };
    form.setFieldsValue(record);
  };

  const handleEditClick = (record) => {
    /*
    let model = vehicleModelsList.results.filter((model) => {
      return record.vehicle_model === model.id;
    });*/
    //let ecuModel = getEcuModelData(record.ecu_model);
    setSelectedVehicleTypeId(record.vehicle_type);
    setSelectedVehicleBrandId(record.vehicle_brand);
    setSelectedVehicleModelId(record.vehicle_model);
    setSelectedVehicleMotorId(record.motor);
    setSelectedEcuModel(record.ecu_model);
    setSelectedEcuBrand(record.ecu_brand);
    if (user?.role == 1 || user?.role == 2) {
      let total = 0;
      let serviceObj = {};
      servicesList?.results?.forEach((item) => {
        serviceObj[item.id] = {
          ...item,
        };
      });
      const clientData = getClientData(record.client);
      setSelectedClient(clientData);
      record?.service?.forEach((item) => {
        total = total + parseFloat(serviceObj[item]?.credit_price ?? 0);
      });
      if (total > parseFloat(clientData?.balance)) {
        setIsCredit(false);
        setCostTotal(total);
        setPreviousCost(total);
      } else {
        setIsCredit(true);
        setCostTotal(total);
        setPreviousCost((total * (100 - record.discount * -1)) / 100);
      }
    }

    //getBalanceUser(record);
    setIsAddJob(false);
    setModalTitle(content.edit.title);

    let filtedFiles = record?.files?.filter((item) => {
      item.name = item.name.split("/")?.[3] ?? item.name;
      return !item.is_from_client;
    });
    setModalVisible(true); //setJobData(record);
    let filterVehicleBrand = //getVehicleBrand(model?.[0].brand);
      vehicleBrandsList?.results?.filter((brand) => {
        return brand.id === record.vehicle_brand;
      });

    setJobDiscount(record.discount ? record.discount : 0);
    setVehicleBrandFiltered(filterVehicleBrand); //vehicleBrandsList);
    let tempRecord = {
      ...record,
      discount: record.discount ? record.discount : 0,
      ecu_model: record.ecu_model ?? "",
      ecu_brand: record.ecu_brand ?? "",
      vehicle_model: record.vehicle_model ?? "",
      vehicle_motor: record.motor ?? "",
      vehicle_brand: record.vehicle_brand ?? "",
      vehicle_type: record.vehicle_type ?? "",
      files: filtedFiles,
    };

    if (record.custom_brand) {
      setCustomVehicleBrand(true);
    }

    if (record.custom_model) {
      setCustomVehicleModel(true);
    }

    if (record.custom_motor) {
      setCustomVehicleMotor(true);
    }

    if (record.custom_ecu_model) {
      setCustomEcuModel(true);
    }

    if (record.custom_ecu_brand) {
      setCustomEcuBrand(true);
    }

    form.setFieldsValue(tempRecord);
  };

  //close modal
  const handleCancel = () => {
    setModalVisible(false);
    setFilesToUpload([]);
    setFilesToDelete([]);
    form.resetFields();
  };

  const handleCancelFile = () => {
    setModalFileVisible(false);
    //form.resetFields();
  };

  //dispatchs
  const handleSubmit = (values, a, b) => {
    // resetStates();

    if (customVehicleMotor) {
      values.motor = "";
    } else {
      values.custom_motor = "";
    }

    if (customVehicleModel) {
      values.vehicle_model = "";
    } else {
      values.custom_model = "";
    }

    if (customVehicleBrand) {
      values.vehicle_brand = "";
    } else {
      values.custom_brand = "";
    }

    if (customEcuBrand) {
      values.ecu_brand = "";
    } else {
      values.custom_ecu_brand = "";
    }

    if (customEcuModel) {
      values.ecu_model = "";
    } else {
      values.custom_ecu_model = "";
    }

    let payload = {
      model: values.vehicle_model,
      motor: values.motor,
      record: values,
    };
    let tempJOb = {
      ...values,
      service: values.service ?? undefined,
      vehicle_name: createVehicleModelName(payload),
    };

    if (tempJOb.service)
      tempJOb.service = tempJOb.service.map((item) => parseInt(item));

    if (isAddJob) {
      const formData = new FormData();
      Object.entries(tempJOb).forEach(([key, value]) => {
        if (!value) return false;
        if (Array.isArray(value)) {
          value.forEach((file) => {
            if (key === "files") {
              if (file.originFileObj)
                formData.append(`file[]`, file.originFileObj);
            } else {
              formData.append(key + "[]", file);
            }
          });
        } else {
          formData.append(key, value);
        }
      });
      dispatch(registerJob(formData));
    } else if (!isAddJob || isFinishJob) {
      tempJOb = {
        ...tempJOb,
        //client: values.client ?? selectedJob.client,
        //vehicle_model: values.vehicle_model ?? selectedJob.vehicle_model,
        file: undefined,
        files: undefined,
      };

      if (finishState === "finishJob") {
        values.files = filesToUpload
        let aux_ = []
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          if (!value) return false;
          if (Array.isArray(value)) {
            value.forEach((file) => {
              if (key === "files") {
                if (file.originFileObj) {
                  formData.append(`file[]`, file.originFileObj);
                  formData.append('isVisible[]', file.is_visible_to_client ? 1 :  0);
                } else {       
                  let body = {
                    isVisible: file.is_visible_to_client //true,
                  };
                  aux_.push({body, id:file.id})
                  setFilesToVisible([...filesToVisible, {body, id:file.id}])
                }
              } else {
                formData.append(key + "[]", file);
              }
            });
          } else {
            formData.append(key, value);
          }
        });
        setFilesToVisible(aux_)
        dispatch(updateJobFinished(selectedJob.id, formData));
      } else if (finishState === "finishAndSendJob") {
        values.files = filesToUpload
        let aux_ = []
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          if (!value) return false;
          if (Array.isArray(value)) {
            value.forEach((file) => {
              if (key === "files") {
                if (file.originFileObj) {
                  formData.append(`file[]`, file.originFileObj);
                  formData.append('isVisible[]', file.is_visible_to_client ? 1 : 0);
                } else {
                  let body = {
                    isVisible: file.is_visible_to_client //true,
                  };
                  aux_.push({body, id:file.id})
                }
              } else {
                formData.append(key + "[]", file);
              }
            });
          } else {
            formData.append(key, value);
          }
        });
        setFilesToVisible(aux_)
        dispatch(updateJobFinished(selectedJob.id, formData));
        //dispatch(updateJobSend(selectedJob.id, formData));
      } else {
        values.files = filesToUpload        
        dispatch(updateJob(selectedJob.id, tempJOb));
        if (values?.files?.length > 0) handleUploadFile(values);
      }
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteJobId(id);
    dispatch(deleteJob(id));
  };

  const handleUploadFile = (value) => {
    let aux_ = []
    const formData = new FormData();
    Object.entries(value).forEach(([key, value]) => {
      if (key !== "files") return false;
      if (Array.isArray(value)) {
        value.forEach((file) => {
          if (file.originFileObj) {
            formData.append('isVisible[]', file.is_visible_to_client ? 1 :  0);
            formData.append(`file[]`, file.originFileObj);
          } else {
            let body = {
              isVisible: file.is_visible_to_client //true,
            };
            aux_.push({body, id:file.id})
          }
        });
      }
    });
    setFilesToVisible(aux_)
    dispatch(registerFile(formData, selectedJob.id));
  };

  const handleDeleteFileClick = (id) => {
    setDeleteFileId(id);
    dispatch(deleteFile(id));
  };

  /*
  const handleTableChange = (pagination, filters, sorter) => {
    const newSortOrder = {};
    if (sorter.columnKey) {
      newSortOrder[sorter.columnKey] = sorter.order;
    }
    setSortOrder(newSortOrder);
  };
  */

  const handleSearch = (value) => {
    setSearchText(value);
  };

  /*
  const getBalanceUser = (payload) => {
    let total = 0;
    const clientData = getClientData(payload.client);
    servicesList?.results?.forEach((item) => {
      if (item.id == payload.service) {
        total = total + parseFloat(item?.credit_price ?? 0);
      }
    });
    if (total > parseFloat(clientData.balance)) {
      setIsCredit(false);
      setCostTotal(total);
    } else {
      setIsCredit(true);
      setCostTotal(total);
    }
  };
  */

  //COLUMNS
  const columns = [
    {
      title: content.add.form.entry_date.title,
      dataIndex: "entry_date",
      width: "200px",
      defaultSortOrder: "asc",
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => {
        return props.renderTooltipInEllipsis(
          dayjs(record.entry_date).format("YYYY-MM-DD HH:mm")
        );
      },
      sorter: (a, b) => {
        a = dayjs(a.entry_date).valueOf();
        b = dayjs(b.entry_date).valueOf();
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: content.add.form.client.title,
      dataIndex: "client",
      width: "170px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b, c, d, e) => {
        return a?.client_name?.localeCompare(b.client_name);
      },
      render: (_, record) => {
        record.client_name = createClienteName(record.client);
        return props.renderTooltipInEllipsis(record.client_name);
      },
    },
    {
      title: content.add.form?.country?.title,
      dataIndex: "country",
      width: "170px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => a?.location_name?.localeCompare(b?.location_name),
      render: (_, record) => {
        let client = getClienteData(record.client);
        let location = client?.address_?.[0].country;
        record.location_name = location;
        record.country = location;
        return props.renderTooltipInEllipsis(location);
      },
    },
    {
      title: content.add.form.vehicle.title,
      //dataIndex: "vehicle_model",
      dataIndex: "vehicle_name",
      ellipsis: {
        showTitle: false,
      },
      width: "230px",
      sorter: (a, b) => a?.vehicle_name?.localeCompare(b?.vehicle_name),
      render: (_, record) => {
        let payload = {
          model: record.vehicle_model,
          motor: record.motor,
          record,
        };
        let name = createVehicleModelName(payload);
        record.vehicle_name = name;
        return props.renderTooltipInEllipsis(name);
      },
    },
    {
      title: "ECU",
      dataIndex: "ecu_name",
      width: "220px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => a?.ecu_name?.localeCompare(b?.ecu_name),
      render: (_, record) => {
        let ecuModel = getEcuModelData(record.ecu_model);
        let ecuBrand = "";
        if (ecuModel) {
          ecuBrand = getEcuBrandData(ecuModel.brand);
        }
        let name = getEcuName(record);
        record.ecu_name = name;
        return props.renderTooltipInEllipsis(name);
      },
    },
    {
      title: content.add.form.service.title,
      dataIndex: "service_name",
      width: "230px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => a?.serviceString?.localeCompare(b.serviceString),
      render: (_, record) => {
        let serviceString = [];
        servicesList?.results?.forEach((e) => {
          if (record?.service?.includes(e?.id)) {
            serviceString.push(e.name + " - " + e.credit_price + "c");
          }
        });
        record.service_name = serviceString.join(" - ");
        record.serviceString = serviceString.join(" - ");
        return (
          <Tooltip placement="topLeft" title={serviceString.join(" - ")}>
            {serviceString.join(" - ")}
          </Tooltip>
        );
      },
    },
    {
      title: content.add.form.worker.title,
      dataIndex: "worker_name",
      width: "230px",
      sorter: (a, b) => a?.worker_name?.localeCompare(b.worker_name),
      render: (_, record) => {
        let name = createWorkerName(record.worker);
        record.worker_name = name;
        return props.renderTooltipInEllipsis(name);
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: content.add.form.state.title,
      dataIndex: "state_",
      width: "120px",
      sorter: (a, b) => a.state_?.localeCompare(b?.state_),
      filters: [
        {
          text: content?.states["pending"],
          value: "pending",
        },
        {
          text: content?.states["in process"],
          value: "in process",
        } /*
        {
          text: content?.states["in queue"],
          value: "in queue",
        },*/,
        {
          text: content?.states["finished"],
          value: "finished",
        },
        {
          text: content?.states["delivered"],
          value: "sent",
        },
      ],
      onFilter: (value, record) => {
        return value === record.state;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (a, record, b, c, d) => {
        record.state_ = content?.states[record.state];
        return createStatusTag(record.state);
      },
    },
    {
      title: content?.add?.form?.position.title,
      dataIndex: "position",
      key: "position",
      width: "100px",
      sorter: (a, b) => {
        if (a.position < b.position) {
          return -1;
        }
        if (a.position > b.position) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        let position = 0;
        jobList.forEach((item) => {
          if (
            new Date(item?.entry_date).valueOf() <=
              new Date(record?.entry_date) &&
            item.state === "pending"
          ) {
            position += 1;
          }
        });
        //return props.renderTooltipInEllipsis(text); //;
        //return props.renderTooltipInEllipsis(position); //;
        return props.renderTooltipInEllipsis(text);
      },
    },
    {
      //title: "Actions",
      width: "270px",
      fixed: "right",
      key: "action",
      render: (text, record) => (
        <Space size={18}>
          <Tooltip placement="top" title={content.tooltips.edit}>
            <EditOutlined
              onClick={() => {
                setIsFinishJob(false);
                setSelectedJob(record);
                handleEditClick(record);
                const clientData = getClientData(record.client);
                setSelectedClient(clientData);
                setFilesToUpload([])
                setFilesToDelete([])
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.delete}>
            <Popconfirm
              cancelText={content?.cancel_button}
              title={content.confirmation.delete.title}
              description={content.confirmation.delete.description}
              onConfirm={() => handleDeleteClick(record.id)}
              /* disabled={
                !record.state != "pending" && !record.state != "in process"
              }*/
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <DeleteOutlined
                style={{
                  color: "red",
                  /* opacity:
                    !record.state != "pending" || !record.state != "in process"
                      ? "0.25"
                      : "",*/
                }}
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.job_info}>
            <InfoCircleOutlined
              style={{ color: "blue" }}
              onClick={() => {
                let vehicleType = getVehicleType(record.vehicle_type)?.name;
                let vehicleBrand = record.custom_brand
                  ? record.custom_brand
                  : getVehicleBrand(record.vehicle_brand)?.name;
                let vehicleModel = record.custom_model
                  ? record.custom_model
                  : getVehicleModel(record.vehicle_model)?.name;
                let vehicleMotor = record.custom_motor
                  ? record.custom_motor
                  : getVehicleType(record.motor)?.name;
                let ecuBrand = record.custom_ecu_brand
                  ? record.custom_ecu_brand
                  : getEcuBrandData(record.ecu_brand)?.name;
                let ecuModel = record.custom_ecu_model
                  ? record.custom_ecu_model
                  : getEcuModelData(record.ecu_model)?.name;

                let description = {
                  "Datos Básicos": {
                    title: "title",
                  },
                  [content.add.form.client.title]: {
                    title: createClienteName(record.client),
                  },
                  [content.add.form.state.title]: {
                    title: content?.states[record.state],
                  },
                  "trabajador asignado": {
                    title: createWorkerName(record.worker),
                  },
                  div: { title: "div" },
                  [content.add.form.entry_date.title]: {
                    title: record.entry_date
                      ? dayjs(record.entry_date).format(dateFormat)
                      : "-",
                  },
                  [content.add.form.start_time.title]: {
                    title: record.start_time
                      ? dayjs(record.start_time).format(dateFormat)
                      : "-",
                  },
                  [content.add.form.finish_date.title]: {
                    title: record.finish_date
                      ? dayjs(record.finish_date).format(dateFormat)
                      : "-",
                  },
                  [content.add.form.end_time.title]: {
                    title: record?.sent_date
                      ? dayjs(record?.sent_date).format(dateFormat)
                      : "-",
                  },
                  "Datos del Vehículo": {
                    title: "title",
                  },
                  [content.add.form.vehicle_type.title]: {
                    title: vehicleType,
                    tooltip: "", //content.add.form.custom.type,
                  },
                  [content.add.form.vehicle_brand.title]: {
                    title: vehicleBrand,
                    tooltip: record.custom_brand
                      ? content.add.form.custom.brand
                      : "",
                  },
                  [content.add.form.vehicle_model.title]: {
                    title: vehicleModel,
                    tooltip: record.custom_model
                      ? content.add.form.custom.model
                      : "",
                  },
                  [content.add.form.vehicle_motor.title]: {
                    title: vehicleMotor,
                    tooltip: record.custom_motor
                      ? content.add.form.custom.motor
                      : "",
                  },
                  [content.add.form.ecu_brand.title]: {
                    title: ecuBrand,
                    tooltip: record.custom_ecu_brand
                      ? content.add.form.custom.ecu_brand
                      : "",
                  },
                  [content.add.form.ecu.title]: {
                    title: ecuModel,
                    tooltip: record.custom_ecu_model
                      ? content.add.form.custom.ecu_model
                      : "",
                  },
                  "Archivos Originales": {
                    title: "title",
                  },
                  [content.add.form.client_description.title]: {
                    title: record.client_description
                      ? record.client_description
                      : "-",
                  },
                  [content.add.form.client_comment.title]: {
                    title: record.client_comment ? record.client_comment : "-",
                  },
                  "Archivos Modificados": {
                    title: "title",
                  },
                  [content.add.form.actions.title]: {
                    title: record.actions ? record.actions : "-",
                  },
                  [content.add.form.worker_comment.title]: {
                    title: record.worker_comment,
                  },
                };
                setModalJobInfoVisible(true);
                setJobDescription(description);
                setSelectedJob(record);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.start_job}>
            <Popconfirm
              cancelText={content?.cancel_button}
              title={content.confirmation.start.title}
              description={content.confirmation.start.description}
              onConfirm={() => {
                if (record.state === "paused" || record.state === "pending")
                  dispatch(updateJobInProccess(record.id));
              }}
              okButtonProps={{ loading: loadingChangeInProcess }}
              disabled={record.state !== "paused" && record.state !== "pending"}
              icon={<QuestionCircleOutlined style={{ color: "blue" }} />}
            >
              <CaretRightOutlined
                style={{
                  opacity:
                    record.state === "paused" || record.state === "pending"
                      ? ""
                      : "0.5",
                }}
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.pause}>
            <Popconfirm
              cancelText={content?.cancel_button}
              title={content.confirmation.pause.title}
              description={content.confirmation.pause.description}
              onConfirm={() => {
                if (record.state === "in process")
                  dispatch(updateJobPause(record.id));
              }}
              //okButtonProps={{ loading: loadingChangeInPr }}
              disabled={!(record.state === "in process")}
              icon={<QuestionCircleOutlined style={{ color: "blue" }} />}
            >
              <PauseOutlined
                style={{
                  fontSize: "medium",//color: "black",
                  opacity: record.state === "in process" ? "" : "0.5",
                }}
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.end_job}>
            <CheckOutlined
              style={{
                color: "green",
                opacity: record.state === "in process" ? "" : "0.5",
              }}
              onClick={() => {
                if (record.state === "in process") {
                 // setFilesToVisible([])
                  const clientData = getClientData(record.client);
                  setSelectedClient(clientData);
                  setIsFinishJob(true);
                  setSelectedJob(record);
                  handleEditClick(record);
                  setFilesToUpload(record.files.filter((item)=>!item.is_from_client))
                  setFilesToDelete([])
                }
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.send_job}>
            <Popconfirm
              cancelText={content?.cancel_button}
              title={content.confirmation.send.title}
              description={content.confirmation.send.description}
              onConfirm={() => {
                if (record.state === "finished") {
                  let payload = {
                    model: record.vehicle_model,
                    motor: record.motor,
                    record: record,
                  };

                  let body = {
                    vehicle_name: createVehicleModelName(payload),
                  };
                  //const formData = {};
                  dispatch(updateJobSend(record.id, body));
                  resetStates();
                  setFinishState("");
                }
              }}
              disabled={record.state !== "finished"}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <SendOutlined
                //onClick={() => {
                //  dispatch(updateJobSend(record.id));
                //}}
                style={{
                  color: "green",
                  opacity: record.state === "finished" ? "" : "0.5",
                }}
              />
            </Popconfirm>
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.file}>
            <FileOutlined
              onClick={() => {
                setSelectedJob(record);
                setModalFileVisible(true);
                setModalFileTitle(`${content?.file}`);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  const columnsAdmin = [...columns];
  const columnsClient = [
    {
      title: content.add.form.entry_date.title,
      dataIndex: "entry_date",
      width: "180px",
      defaultSortOrder: "asc",
      sorter: (a, b) => {
        if (a.entry_date < b.entry_date) {
          return -1;
        }
        if (a.entry_date > b.entry_date) {
          return 1;
        }
        return 0;
      },
      render: (_, record) => {
        return record.entry_date !== null
          ? dayjs(record.entry_date).format("YYYY-MM-DD HH:mm")
          : "";
      },
    },
    {
      title: content.add.form.vehicle.title,
      dataIndex: "vehicle_name",
      width: "250px",
      sorter: (a, b) => a.vehicle_name?.localeCompare(b?.vehicle_name),
      render: (_, record) => {
        let payload = {
          model: record.vehicle_model,
          motor: record.motor,
          record,
        };
        let name = createVehicleModelName(payload);
        record.vehicle_name = name;
        return props.renderTooltipInEllipsis(name);
      },
    },
    {
      title: "ECU",
      dataIndex: "ecu_name",
      width: "220px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => a?.ecu_name?.localeCompare(b?.ecu_name),
      render: (_, record) => {
        let ecuModel = getEcuModelData(record.ecu_model);
        let ecuBrand = "";
        if (ecuModel) {
          ecuBrand = getEcuBrandData(ecuModel.brand);
        }
        let name = getEcuName(record);
        record.ecu_name = name;
        return props.renderTooltipInEllipsis(name);
      },
    },
    {
      title: content.add.form.client_description.title,
      dataIndex: "client_description",
      width: "250px",
      sorter: (a, b) =>
        a?.client_description?.localeCompare(b?.client_description),
    },
    {
      title: content.add.form.client_comment.title,
      dataIndex: "client_comment",
      width: "250px",
      sorter: (a, b) => a?.client_comment?.localeCompare(b?.client_comment),
    },
    {
      title: content.add.form.state.title,
      dataIndex: "state_",
      width: "120px",
      sorter: (a, b) => a?.state_?.localeCompare(b?.state_),
      filters: [
        {
          text: content?.states["pending"],
          value: "pending",
        },
        {
          text: content?.states["in process"],
          value: "in process",
        },
        {
          text: content?.states["in queue"],
          value: "in queue",
        },
        {
          text: content?.states["finished"],
          value: "finished",
        },
        {
          text: content?.states["delivered"],
          value: "sent",
        },
      ],
      onFilter: (value, record) => {
        return value === record.state;
      },
      render: (_, record) => {
        record.state_ = content?.states[record.state];
        return createStatusTag(record.state);
      },
    },
    {
      title: "position", //content?.add?.form?.stock.title,
      dataIndex: "position",
      key: "position",
      width: "100px",
      sorter: (a, b) => {
        if (a.position < b.position) {
          return -1;
        }
        if (a.position > b.position) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      //title: "Actions",
      width: "100px",
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <Space size={18}>
          <EditOutlined
            onClick={() => {
              if (record.state === "pending") {
                setSelectedJob(record);
                handleEditClick(record);
              }
            }}
            style={{ opacity: record.state == "pending" ? "" : "0.25" }}
          />          
          <Tooltip placement="top" title={content.tooltips.job_info}>
            <InfoCircleOutlined
              style={{ color: "blue" }}
              onClick={() => {
                //let vehicleModel = getVehicleModel(record.vehicle_model);
                let vehicleType = getVehicleType(record.vehicle_type)?.name;
                let vehicleBrand = record.custom_brand
                  ? record.custom_brand
                  : getVehicleBrand(record.vehicle_brand)?.name;
                let vehicleModel = record.custom_model
                  ? record.custom_model
                  : getVehicleModel(record.vehicle_model)?.name;
                let vehicleMotor = record.custom_motor
                  ? record.custom_motor
                  : getVehicleType(record.motor)?.name;
                let ecuBrand = record.custom_ecu_brand
                  ? record.custom_ecu_brand
                  : getEcuBrandData(record.ecu_brand)?.name;
                let ecuModel = record.custom_ecu_model
                  ? record.custom_ecu_model
                  : getEcuModelData(record.ecu_model)?.name;
                let description = {
                  "Datos Básicos": {
                    title: "title",
                  },
                  [content.add.form.entry_date.title]: {
                    title: record.entry_date
                      ? dayjs(record.entry_date).format(dateFormat)
                      : "-",
                  },
                  [content.add.form.start_time.title]: {
                    title: record.start_time
                      ? dayjs(record.start_time).format(dateFormat)
                      : "-",
                  },
                  [content.add.form.finish_date.title]: {
                    title: record.finish_date
                      ? dayjs(record.finish_date).format(dateFormat)
                      : "-",
                  },
                  [content.add.form.end_time.title]: {
                    title: record?.sent_date
                      ? dayjs(record?.sent_date).format(dateFormat)
                      : "-",
                  },
                  "Datos del Vehículo": {
                    title: "title",
                  },
                  [content.add.form.vehicle_type.title]: {
                    title: vehicleType,
                    tooltip: "", //content.add.form.custom.type,
                  },
                  [content.add.form.vehicle_brand.title]: {
                    title: vehicleBrand,
                    tooltip: record.custom_brand
                      ? content.add.form.custom.brand
                      : "",
                  },
                  [content.add.form.vehicle_model.title]: {
                    title: vehicleModel,
                    tooltip: record.custom_model
                      ? content.add.form.custom.model
                      : "",
                  },
                  [content.add.form.vehicle_motor.title]: {
                    title: vehicleMotor,
                    tooltip: record.custom_motor
                      ? content.add.form.custom.motor
                      : "",
                  },
                  [content.add.form.ecu_brand.title]: {
                    title: ecuBrand,
                    tooltip: record.custom_ecu_brand
                      ? content.add.form.custom.ecu_brand
                      : "",
                  },
                  [content.add.form.ecu.title]: {
                    title: ecuModel,
                    tooltip: record.custom_ecu_model
                      ? content.add.form.custom.ecu_model
                      : "",
                  },
                  "Archivos Originales": {
                    title: "title",
                  },
                  [content.add.form.client_description.title]:
                    record.client_description,
                  [content.add.form.client_comment.title]:
                    record.client_comment,
                  "Archivos Modificados": {
                    title: "title",
                  },
                  [content.add.form.worker_comment.title]:
                    record.worker_comment,
                  //"descarga de archvios cliente": "test",
                };
                setModalJobInfoVisible(true);
                setJobDescription(description);
                setSelectedJob(record);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={content.tooltips.file}>
            <FileOutlined
              onClick={() => {
                setSelectedJob(record);
                setModalFileVisible(true);
                setModalFileTitle(`Files`);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  const columnsWorker = [...columns];
  /*
    columns[0],
    columns[1],
    columns[2],
    columns[3],
    columns[5],
    columns[6],
    columns[7],
  ];
  */

  const columnsFiles = [
    {
      title: content.files.form.name,
      dataIndex: "base_name",
      width: "100px",
      sorter: (a, b) => {
        return a.base_name.localeCompare(b.base_name);
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(text); //;
      },
    },
    {
      title: content.files.form.date,
      dataIndex: "created_time",
      width: "20px",
      sorter: (a, b) => {
        a = dayjs(a.created_time).valueOf();
        b = dayjs(b.created_time).valueOf();
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      },
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return props.renderTooltipInEllipsis(dayjs(text).format(dateFormat)); //;
      },
    },
    {
      //title: "Actions",
      width: "20px",
      key: "action",
      render: (text, record) => {
        return (
          <Space size={18}>
            {((user?.role === 3 &&
              record.is_from_client &&
              selectedJob.state == "pending") ||
              user?.role === 1 ||
              user?.role === 2) && (
              <Tooltip placement="top" title={content.tooltips.delete}>
                <Popconfirm
                  cancelText={content?.cancel_button}
                  title={content.files.confirmation.delete.title}
                  description={content.files.confirmation.delete.description}
                  onConfirm={() => handleDeleteFileClick(record.id)}
                  disabled={selectedJob.state !== "pending" && user?.role === 3}
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                >
                  <DeleteOutlined
                    style={{
                      color: "red",
                      opacity:
                        user?.role === 3
                          ? selectedJob.state === "pending"
                            ? ""
                            : "0.25"
                          : "",
                    }}
                  />
                </Popconfirm>
              </Tooltip>
            )}
            <Tooltip placement="top" title={content.tooltips.download}>
              <CloudDownloadOutlined
                onClick={() => {
                  downloadFile(
                    `${
                      (window.location.hostname == "localhost" ? apiURL : "") +
                      record.file
                    }`,
                    record.name.split("/")[3]
                  );
                }}
              />
            </Tooltip>
            {!record.is_from_client && user?.role < 3 ? (
              <Tooltip placement="top" title="Mostrar/Ocultar">
                {loadingUpdateVisibleFile &&
                selectedFile.id == record.id &&
                !successUpdateVisibleFile &&
                false ? (
                  <LoadingOutlined />
                ) : !record.is_visible_to_client ? (
                  <EyeInvisibleOutlined
                    onClick={() => {
                      let body = {
                        isVisible: true,
                      };
                      setSelectedFile(record);
                      dispatch(updateVisibleFile(body, record.id));
                    }}
                  />
                ) : (
                  <EyeOutlined
                    onClick={() => {
                      let body = {
                        isVisible: false,
                      };
                      dispatch(updateVisibleFile(body, record.id));
                    }}
                  />
                )}
              </Tooltip>
            ) : (
              ""
            )}
          </Space>
        );
      },
    },
  ];

  const allColumns = [[], columnsAdmin, columnsWorker, columnsClient];
  const selectedColumn = allColumns[userInfo?.role];

  //FILTED DATA

  /*
  const filteredJobs = jobsList?.results?.filter((job) =>
    Object.values(job)
      .join(" ")
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );

  */
  let filteredJobs = jobList?.filter((job) =>
    Object.values(job)
      .join(" ")
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );

  if (fromTo.length > 0) {
    let from = fromTo[0];
    let to = fromTo[1];

    filteredJobs = filteredJobs?.filter(
      (job) =>
        new Date(job.entry_date) >= new Date(from ?? 1) &&
        new Date(job.entry_date) <= new Date(to ?? 9999999999999)
    );
  }

  const filesFromUser = selectedJob?.files?.filter((file) => {
    return file.is_from_client;
  });

  const filesEdited = selectedJob?.files?.filter((file) => {
    return !file.is_from_client;
  });

  const filterVehicleModel = vehicleModelsList?.results?.filter((model) => {
    return model.brand === selectedVehicleBrandId;
  });

  let filterVehicleBrand = [];

  const filesTabs = [
    {
      key: "original",
      label: `Original`,
      children: (
        <>
          <Row style={{ marginBottom: 16 }}>
            <Col span={user?.role === 3 ? 22 : 24}>
              <Input
                placeholder={content.files.search}
                //onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>
            <Col span={user?.role === 3 ? 1 : 0}></Col>
            <Col span={user?.role === 3 ? 1 : 0}>
              {user?.role === 3 && (
                <Button
                  icon={<CloudUploadOutlined />}
                  // type="primary"
                  shape="circle"
                  onClick={() => {
                    formUploadFile.resetFields();
                    setModalFileUploadTitle(content.files.modal.upload.title);
                    setModalFileUploadVisible(true);
                  }}
                  style={{ marginBottom: 0, float: "right" }}
                ></Button>
              )}
            </Col>
          </Row>
          <Table
            {...tableProps}
            dataSource={filesFromUser}
            columns={columnsFiles}
          />
        </>
      ),
    },
    {
      key: "edited",
      label: `Modificado`,
      children: (
        <>
          <Row style={{ marginBottom: 16 }}>
            <Col span={user?.role === 2 || user?.role === 1 ? 22 : 24}>
              <Input
                placeholder={content.files.search}
                //style={{ marginBottom: 16 }}
                //onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>
            <Col span={user?.role === 2 || user?.role === 1 ? 1 : 0}></Col>
            <Col span={user?.role === 2 || user?.role === 1 ? 1 : 0}>
              {user?.role === 2 || user?.role === 1 ? (
                <Button
                  icon={<CloudUploadOutlined />}
                  // type="primary"
                  shape="circle"
                  onClick={() => {
                    formUploadFile.resetFields();
                    setModalFileUploadTitle(content.files.modal.upload.title);
                    setModalFileUploadVisible(true);
                  }}
                  style={{ marginBottom: 0, float: "right" }}
                ></Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Table
            {...tableProps}
            dataSource={filesEdited}
            columns={columnsFiles}
          />
        </>
      ),
    },
  ];

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const downloadFile = async (url, fileName) => {
    const res = await axios.get(url, {
      responseType: "blob",
    });
    download(res.data, fileName);
  };

  const getAmountConvertion = (amount) => {
    let aux = 0;
    let eq_rate = props.useUSD
      ? parseFloat(props.USDvalue)
      : parseFloat(props.ARSvalue);
    let conv_rate = props.isUseCost ? 1 : eq_rate;
    aux = amount * conv_rate;
    return aux;
  };
  //create names

  const createServiceName = (id) => {
    let service = "";
    service = servicesList?.results?.filter((e) => {
      return id === e.id;
    })[0];
    return service
      ? (service?.name ?? "") + ` (${service?.credit_price ?? ""} c)`
      : "";
  };

  const createClienteName = (id) => {
    let client = "";
    let name = "";
    client = clientsList?.results?.filter((e) => {
      return id === e.id;
    })[0];
    return (client?.first_name ?? "") + " " + (client?.last_name ?? "");
  };

  const getClienteData = (id) => {
    return clientsList?.results?.filter((e) => {
      return id === e.id;
    })[0];
  };

  const createWorkerName = (id) => {
    let worker = "";
    let name = "";

    let list = [
      ...(workersList?.results ?? []),
      ...(adminsList?.results ?? []),
    ];
    worker = list?.filter((e) => {
      return id === e.id;
    })[0];
    return (worker?.first_name ?? "") + " " + (worker?.last_name ?? "");
  };

  const getEcuName = (payload) => {
    let ecuModel = getEcuModelData(payload.ecu_model)?.name;
    let ecuBrand = getEcuBrandData(payload.ecu_brand)?.name;

    if (payload.custom_ecu_brand !== "" && payload.custom_ecu_brand) {
      ecuBrand = payload.custom_ecu_brand;
    }

    if (payload.custom_ecu_model !== "" && payload.custom_ecu_model) {
      ecuModel = payload.custom_ecu_model;
    }
    return `${ecuBrand} - ${ecuModel}`;
  };

  const createVehicleModelName = (payload) => {
    let vehicleModel = "";
    let vehicleBrand = "";
    let vehicleMotor = "";
    let vehicleType = "";
    vehicleModel = vehicleModelsList?.results?.filter((e) => {
      return payload?.record?.vehicle_model === e.id;
    })[0];
    vehicleBrand = vehicleBrandsList?.results?.filter((e) => {
      return payload?.record?.vehicle_brand === e.id;
    })[0];
    vehicleMotor = vehicleMotorsList?.results?.filter((e) => {
      return payload?.record?.motor === e.id;
    })[0];
    vehicleType = vehicleTypesList?.results?.filter((e) => {
      return payload?.record?.vehicle_type === e.id;
    })[0];

    return (
      (vehicleType?.name ?? "") +
      " - " +
      (payload?.record?.custom_brand !== "" && payload?.record?.custom_brand
        ? payload?.record?.custom_brand
        : vehicleBrand?.name) +
      " - " +
      (payload?.record?.custom_model !== "" && payload?.record?.custom_model
        ? payload?.record?.custom_model
        : vehicleModel?.name) +
      " - " +
      (payload?.record?.custom_motor !== "" && payload?.record?.custom_motor
        ? payload?.record?.custom_motor
        : vehicleMotor?.name)
    );
  };

  const getVehicleMotor = (id) => {
    let vehicleMotor = "";
    vehicleMotor = vehicleMotorsList?.results?.filter((e) => {
      return id === e.id;
    })[0];
    return vehicleMotor;
  };

  const getVehicleBrand = (id) => {
    let vehicleBrand = "";
    vehicleBrand = vehicleBrandsList?.results?.filter((e) => {
      return id === e.id;
    })[0];
    return vehicleBrand;
  };

  const getVehicleType = (id) => {
    let vehicleType = "";
    vehicleType = vehicleTypesList?.results?.filter((e) => {
      return id === e.id;
    })[0];
    return vehicleType;
  };

  const getVehicleModel = (id) => {
    let vehicle = "";
    vehicle = vehicleModelsList?.results?.filter((e) => {
      return id === e.id;
    })[0];
    return vehicle;
  };

  const createStatusTag = (status) => {
    let statusMap = {
      pending: "error",
      "in process": "warning",
      "in queue": "warning",
      completed: "success",
      finished: "processing",
      sent: "success",
    };
    return <Tag color={statusMap[status]}>{content?.states[status]}</Tag>;
  };

  const getEcuModelData = (id) => {
    let data = ecusModelList?.results?.filter((e) => {
      return id === e.id;
    });
    return data?.[0];
  };

  const getEcuBrandData = (id) => {
    let data = ecusBrandList?.results?.filter((e) => {
      return id === e.id;
    });
    return data?.[0];
  };

  const getClientData = (id) => {
    let client = "";
    client = clientsList?.results?.filter((e) => {
      return id === e.id;
    })[0];
    return client;
  };

  const handleNewFileAction = (payload) => {
    if (payload.delete) {
      let newFileList = [...filesToUpload];
      let filesToDelete_ = [...filesToDelete]
      newFileList = newFileList.map((file) => {     
        if (file.uid != payload.uid) {
          return file
        } else if(file.id){
          filesToDelete_.push(file)
        }
      });
      newFileList = newFileList.filter((item) => item != undefined);
      setFilesToDelete(filesToDelete_)
      setFilesToUpload(newFileList);
    } else {
      let temp_ = [];
      filesToUpload.forEach((item) => {       
        if (item.uid == payload.uid) {
          item.is_visible_to_client = !item.is_visible_to_client
        }
        temp_.push(item); 
      });
      setFilesToUpload(temp_);
    }
/*
    if (payload.visible == 1) {
      let temp_ = [];
      filesToUpload.forEach((item) => {
        if (item.uid == payload.uid) {
          item.is_visible_to_client = !item.is_visible_to_client
        }
        temp_.push(item);
      });
      setFilesToUpload(temp_);
    }

    if (payload.visible == 2) {
      let temp_ = [];
      filesToUpload.forEach((item) => {
        if (item.uid == payload.uid) {
          item.is_visible_to_client = !item.is_visible_to_client
        }
        temp_.push(item);
      });
      setFilesToUpload(temp_);
    }*/
  };

  const handleChange = (info) => {
    let newFileList = info.fileList;
    setFilesToUpload(newFileList);
  };

  if (!userInfo || userInfo === "no_logged") return <></>;
  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      <div style={{ marginBottom: 16 }}>
        {/*<h1 className="title" style={{ margin: 0 }}>
          {content.title}
        </h1>
        */}
        <Title className="titleCenter">{content.title}</Title>
        <Row>
          <Col span={19}>
            <Input
              placeholder={content.search}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Col>
          <Col span={1}>
            <Button
              icon={(fromTo[0] || fromTo[1]) ? <FilterFilled /> : <FilterOutlined />}
              shape="circle"
              onClick={(e) => {setModalFilterOpen(true)}}
              style={{ marginBottom: 16, float: "right" }}
            ></Button>
          </Col>
          <ExportTable
            data={filteredJobs}
            fileTitle={content.title}
            columns={selectedColumn}
            extraData={{ client: clientsList?.results }}
          />
          <Col span={1}>
            <Button
              icon={<ReloadOutlined />}
              shape="circle"
              onClick={(e) => {
                setShowSpinner(true);
                dispatch(getJobs());
              }}
              style={{ marginBottom: 16, float: "right" }}
            ></Button>
          </Col>
          <Col span={1}>
            <Button
              icon={<PlusOutlined />}
              // type="primary"
              shape="circle"
              onClick={handleAddClick}
              style={{ marginBottom: 16, float: "right" }}
            ></Button>
          </Col>
        </Row>
      </div>
      <Table
        {...tableProps}
        dataSource={filteredJobs}
        columns={selectedColumn}
        loading={showSpinner && loadingJobs}
      />
      {/*JOB form */}
      <Modal
        width={800}
        title={modalTitle}
        open={modalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          //initialValues={jobData}
          onFinish={handleSubmit}
        >
          {(isAddJob || isFinishJob) && (
            <>
              <Form.Item label={content.add.form.file.title}>
                {isFinishJob ? (
                  <>
                    <Form.Item
                      name="files"
                      //valuePropName="fileList"
                     // getValueFromEvent={normFile}
                      noStyle
                      //value={filesToUpload}
                      rules={[
                        {
                          required: true, //filesToUpload.length == 0,
                          message: content?.add?.form?.file.placeholder,
                          validator: (_, value) => filesToUpload.length > 0 ? Promise.resolve() : Promise.reject()
                        },
                      ]}
                    >
                      <Upload.Dragger
                        multiple
                        //name="files"
                        fileList={filesToUpload}
                        beforeUpload={() => false}
                        itemRender={(originNode, file, actions, a) => {}}
                        //action="/upload.do"
                        onChange={handleChange}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          {content.add.form.file.description}
                        </p>
                      </Upload.Dragger>
                      {filesToUpload.length > 0 && (
                        <div style={{ marginBottom: 0 }}>
                          <List
                            pagination={filesToUpload.length > 3  ? { position: "bottom", align: "end", pageSize: 3} : false}
                            className="demo-loadmore-list"
                            dataSource={filesToUpload}
                            itemLayout="horizontal"
                            renderItem={(item) => (
                              <List.Item
                                actions={[
                                  (item.is_visible_to_client) ? (
                                    <EyeOutlined
                                      onClick={() => {
                                        handleNewFileAction({
                                          uid: item.uid,
                                        });
                                      }}
                                    />
                                  ) : (
                                    <EyeInvisibleOutlined
                                      onClick={() => {
                                        handleNewFileAction({
                                          uid: item.uid,
                                        });
                                      }}
                                    />
                                  ),
                                 <DeleteOutlined
                                    style={{ color: "red"}} //, visibility:  !item.id  ? "visible" : "hidden" }}
                                    onClick={() => {
                                      handleNewFileAction({
                                        delete: 1,
                                        uid: item.uid,
                                      });
                                    }}
                                  />,
                                ]}
                              >
                                <div>{item.name}</div>
                              </List.Item>
                            )}
                          />
                        </div>
                      )}
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item
                    name="files"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: content?.add?.form?.file.placeholder,
                      },
                    ]}
                  >
                    <Upload.Dragger
                      multiple
                      //name="files"
                      //  fileList={filesToUpload}
                      beforeUpload={() => false}
                      //itemRender={(originNode, file, actions, a) => {}}
                      //action="/upload.do"
                      // onChange={handleChange}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        {content.add.form.file.description}
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                )}
              </Form.Item>
              <br />
            </>
          )}
          {userInfo.role < 3 && !isFinishJob && (
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                style={{ display: "inline-block", width: "calc(46%)" }}
                label={content.add.form.client.title}
                name="client"
                rules={[
                  {
                    required: true,
                    message: content.add.form.client.placeholder,
                  },
                ]}
              >
                <Select {...selectProps} allowClear>
                  {clientsList?.results?.map((client) => (
                    <Select.Option key={client.id} value={client.id}>
                      {createClienteName(client.id)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{
                  display: "inline-block",
                  width: "calc(50%)",
                  float: "right",
                }}
                label={content.add.form.worker.title}
                name="worker"
                rules={[
                  {
                    required: false,
                    message: content.add.form.worker.placeholder,
                  },
                ]}
              >
                <Select {...selectProps} allowClear>
                  {workersList?.results?.map((worker) => (
                    <Select.Option key={worker.id} value={worker.id}>
                      {createWorkerName(worker.id)}
                    </Select.Option>
                  ))}
                  {adminsList?.results?.map((worker) => (
                    <Select.Option key={worker.id} value={worker.id}>
                      {worker.first_name + " " + worker.last_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
          )}
          {!isFinishJob && (
            <>
              <Form.Item style={{ marginBottom: 0 }}>
                {/*********/}

                <Form.Item
                  style={{ display: "inline-block", width: "calc(46%)" }}
                  label={content.add.form.vehicle_type.title}
                  name="vehicle_type"
                  rules={[
                    {
                      required: true,
                      message: content.add.form.vehicle_type.placeholder,
                    },
                  ]}
                >
                  <Select
                    {...selectProps}
                    onChange={(value) => {
                      let tempModel = vehicleModelsList?.results?.filter(
                        (model) => {
                          return value === model.type;
                        }
                      );
                      let brandList = {};
                      tempModel.forEach((model) => {
                        brandList[parseInt(model.brand)] = true;
                      });
                      filterVehicleBrand = vehicleBrandsList?.results?.filter(
                        (model) => {
                          return Object.keys(brandList).includes(
                            model.id.toString()
                          );
                        }
                      );
                      setSelectedVehicleModelId("");
                      form.setFieldsValue({
                        vehicle_model: "",
                        vehicle_motor: "",
                        vehicle_brand: "",
                        motor: "",
                      });
                      setVehicleBrandFiltered(filterVehicleBrand);
                      setSelectedVehicleTypeId(value);
                    }}
                    allowClear
                    value={selectedVehicleTypeId}
                  >
                    {vehicleTypesList?.results?.map((type) => (
                      <Select.Option key={type.id} value={type.id}>
                        {type.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {/*********/}

                {customVehicleBrand ? (
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(50%)",
                      float: "right",
                    }}
                    label={content.add.form.vehicle_brand.title}
                    name="custom_brand"
                    rules={[
                      {
                        required: true,
                        message: content.add.form.vehicle_model.placeholder,
                      },
                    ]}
                  >
                    <Input /*disabled={!selectedVehicleTypeId}*/ />
                  </Form.Item>
                ) : (
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(50%)",
                      float: "right",
                    }}
                    label={content.add.form.vehicle_brand.title}
                    name="vehicle_brand"
                    rules={[
                      {
                        required: true,
                        message: content.add.form.vehicle_model.placeholder,
                      },
                    ]}
                  >
                    <Select
                      {...selectProps}
                      onChange={(value) => {
                        form.setFieldsValue({
                          vehicle_motor: "",
                          vehicle_model: "",
                        });
                        setSelectedVehicleBrandId(value);
                      }}
                      allowClear
                      disabled={!selectedVehicleTypeId}
                      value={selectedVehicleBrandId}
                    >
                      {vehicleBrandFiltered?.map((brand) => (
                        <Select.Option key={brand.id} value={brand.id}>
                          {brand.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                <div
                  style={{
                    width: "50%",
                    float: "right",
                    //top: "-14px",
                    position: "relative",
                  }}
                >
                  <Checkbox
                    checked={customVehicleBrand}
                    onChange={(e) => {
                      setCustomVehicleBrand(e.target.checked);
                      if (e.target.checked) {
                        setCustomVehicleMotor(e.target.checked);
                        setCustomVehicleModel(e.target.checked);
                      }
                    }}
                    style={{
                      marginBottom: "18px",
                    }}
                  >
                    {content.add.form.custom.brand}
                  </Checkbox>
                </div>
              </Form.Item>
              {/*********/}
              <Form.Item style={{ marginBottom: 0 }}>
                {customVehicleModel ? (
                  <Form.Item
                    style={{ display: "inline-block", width: "calc(46%)" }}
                    label={content.add.form.vehicle_model.title}
                    name="custom_model"
                    rules={[
                      {
                        required: true,
                        message: content.add.form.vehicle_model.placeholder,
                      },
                    ]}
                  >
                    <Input /*disabled={!selectedVehicleTypeId} */ />
                  </Form.Item>
                ) : (
                  <Form.Item
                    style={{ display: "inline-block", width: "calc(46%)" }}
                    label={content.add.form.vehicle_model.title}
                    name="vehicle_model"
                    rules={[
                      {
                        required: true,
                        message: content.add.form.vehicle_model.placeholder,
                      },
                    ]}
                  >
                    <Select
                      {...selectProps}
                      allowClear
                      disabled={!selectedVehicleBrandId}
                      onChange={(value) => {
                        setSelectedVehicleModelId(value);
                      }}
                      value={selectedVehicleModelId}
                    >
                      {filterVehicleModel?.map((model) => (
                        <Select.Option key={model.id} value={model.id}>
                          {model.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {/*********/}
                {!customVehicleMotor ? (
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(50%)",
                      float: "right",
                    }}
                    label={content.add.form.vehicle_motor.title}
                    name="motor"
                    rules={[
                      {
                        required: true,
                        message: content.add.form.vehicle_motor.placeholder,
                      },
                    ]}
                  >
                    <Select
                      {...selectProps}
                      allowClear
                      onChange={(value) => {
                        setSelectedVehicleMotorId(value);
                      }}
                      value={selectedVehicleMotorId}
                      disabled={!selectedVehicleModelId}
                    >
                      {vehicleMotorsList?.results?.map((motor) => {
                        let vehicleModelData = getVehicleModel(
                          selectedVehicleModelId
                        );
                        if (!vehicleModelData?.motor?.includes(motor.id))
                          return false;
                        return (
                          <Select.Option key={motor.id} value={motor.id}>
                            {motor.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(50%)",
                      float: "right",
                    }}
                    label={content.add.form.vehicle_motor.title}
                    name="custom_motor"
                    rules={[
                      {
                        required: true,
                        message: content.add.form.vehicle_motor.placeholder,
                      },
                    ]}
                  >
                    <Input /*disabled={!selectedVehicleMotorId}*/ />
                  </Form.Item>
                )}
              </Form.Item>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  //float: "right",
                  //top: "-14px",
                  //position: "relative",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    //float: "right",
                    // top: "-14px",
                    position: "relative",
                  }}
                >
                  <Checkbox
                    style={{
                      marginBottom: "18px",
                    }}
                    checked={customVehicleModel}
                    onChange={(e) => {
                      setCustomVehicleModel(e.target.checked);
                      if (e.target.checked)
                        setCustomVehicleMotor(e.target.checked);
                    }}
                  >
                    {content.add.form.custom.model}
                  </Checkbox>
                </div>
                <div
                  style={{
                    width: "50%",
                    //float: "right",
                    //top: "-14px",
                    position: "relative",
                  }}
                >
                  <Checkbox
                    checked={customVehicleMotor}
                    onChange={(e) => {
                      if (!customVehicleModel)
                        setCustomVehicleMotor(e.target.checked);
                    }}
                  >
                    {content.add.form.custom.motor}
                  </Checkbox>
                </div>
              </div>

              {/*********/}
              <Form.Item style={{ marginBottom: 0 }}>
                {!customEcuBrand ? (
                  <Form.Item
                    style={{ display: "inline-block", width: "calc(46%)" }}
                    label={content?.add?.form?.ecu_brand.title}
                    name="ecu_brand"
                    rules={[
                      {
                        required: true,
                        message: content?.form?.ecu.placeholder,
                      },
                    ]}
                  >
                    <Select
                      {...selectProps}
                      disabled={!selectedVehicleModelId && !customVehicleModel}
                      // label={content?.form?.ecu_brand}
                      value={selectedEcuBrand}
                      onChange={(e) => {
                        form.setFieldsValue({ ecu_model: "" });
                        setSelectedEcuBrand(e);
                      }}
                    >
                      {ecusBrandList?.results?.map((model) => {
                        if (!customVehicleModel) {
                          let vehicleModel = vehicleModelsList?.results?.filter(
                            (e) => {
                              return e.id === selectedVehicleModelId;
                            }
                          )[0];
                          let ecuModel = ecusModelList?.results?.filter((e) => {
                            return e.id === vehicleModel?.ecu_model;
                          })[0];
                          if (ecuModel?.brand !== model?.id) return false;
                        }

                        return (
                          <Option key={model.id} value={model.id}>
                            {
                              model.name
                              /*
                              ecusBrandList?.results?.filter((e) => {
                                return model.id === e.id;
                              })[0]?.name*/
                            }
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(46%)",
                      float: "left",
                    }}
                    label={content.add.form.ecu_brand.title}
                    name="custom_ecu_brand"
                    rules={[
                      {
                        required: true,
                        message: content.add.form.ecu_brand.placeholder,
                      },
                    ]}
                  >
                    <Input /*disabled={!selectedVehicleTypeId}*/ />
                  </Form.Item>
                )}

                {!customEcuModel ? (
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(50%)",
                      float: "right",
                    }}
                    label={content?.add?.form?.ecu.title}
                    name="ecu_model"
                    rules={[
                      {
                        required: true,
                        message: content?.add?.form?.ecu.placeholder,
                      },
                    ]}
                  >
                    <Select
                      {...selectProps}
                      label={content?.form?.brand}
                      disabled={!selectedEcuBrand}
                      showSearch
                      value={selectedEcuModel}
                      onChange={(e) => {
                        //form.setFieldsValue({ ecu_model: "" });
                        setSelectedEcuModel(e);
                      }}
                      optionFilterProp="children"
                      //filterOption={dropdownSearch}
                    >
                      {ecusModelList?.results?.map((marca) => {
                        if (marca.brand !== selectedEcuBrand) return false;
                        return (
                          <Option key={marca.id} value={marca.id}>
                            {
                              marca.name /*
                              ecusModelList?.results?.filter((e) => {
                                return marca.id === e.id;
                              })[0]?.name*/
                            }
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(50%)",
                      float: "right",
                    }}
                    label={content.add.form.ecu.title}
                    name="custom_ecu_model"
                    rules={[
                      {
                        required: true,
                        message: content.add.form.ecu.placeholder,
                      },
                    ]}
                  >
                    <Input /*disabled={!selectedVehicleTypeId}*/ />
                  </Form.Item>
                )}
              </Form.Item>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  //float: "right",
                  //top: "-14px",
                  //position: "relative",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    //float: "right",
                    // top: "-14px",
                    position: "relative",
                  }}
                >
                  <Checkbox
                    style={{
                      marginBottom: "18px",
                    }}
                    checked={customEcuBrand}
                    onChange={(e) => {
                      setCustomEcuBrand(e.target.checked);
                      if (e.target.checked) setCustomEcuModel(e.target.checked);
                    }}
                  >
                    {content.add.form.custom.ecu_brand}
                  </Checkbox>
                </div>
                <div
                  style={{
                    width: "50%",
                    //float: "right",
                    //top: "-14px",
                    position: "relative",
                  }}
                >
                  <Checkbox
                    checked={customEcuModel}
                    onChange={(e) => {
                      if (!customEcuBrand) setCustomEcuModel(e.target.checked);
                    }}
                  >
                    {content.add.form.custom.ecu_model}
                  </Checkbox>
                </div>
              </div>

              {/*********/}

              <Form.Item
                label={content.add.form.client_description.title}
                name="client_description"
              >
                <Input.TextArea maxLength={150} />
              </Form.Item>
              <Form.Item
                label={content.add.form.client_comment.title}
                name="client_comment"
                /*
            rules={[
              {
                required: true,
                message: content.add.form.client_comment.placeholder,
              },
            ]}
            */
              >
                <Input.TextArea maxLength={500} />
              </Form.Item>
            </>
          )}
          {userInfo.role < 3 && (
            <Form.Item
              label={content.add.form.worker_comment.title}
              name="worker_comment"
              /*
            rules={[
              {
                required: true,
                message: content.add.form.worker_comment.placeholder,
              },
            ]}
            */
            >
              <Input.TextArea />
            </Form.Item>
          )}
          {userInfo.role < 3 && (
            <Form.Item
              label={content.add.form.actions.title}
              name="actions"
              /*
            rules={[
              { required: true, message: content.add.form.actions.placeholder },
            ]}
            */
            >
              <Input.TextArea />
            </Form.Item>
          )}
          {userInfo.role < 3 && (
            <>
              <Form.Item
                label={content?.add?.form?.service.title}
                name="service"
                rules={[
                  {
                    required: isFinishJob,
                    message: content?.add?.form?.service.placeholder,
                  },
                ]}
              >
                <Select
                  {...selectProps}
                  mode="multiple"
                  onChange={(e) => {
                    let total = 0;
                    let serviceObj = {};
                    servicesList?.results?.forEach((item) => {
                      serviceObj[item.id] = {
                        ...item,
                      };
                    });
                    const clientData = getClientData(selectedJob?.client);
                    setSelectedClient(clientData);
                    e.forEach((item) => {
                      total =
                        total + parseFloat(serviceObj[item]?.credit_price ?? 0);
                    });
                    if (total > parseFloat(clientData?.balance)) {
                      setIsCredit(false);
                      setCostTotal(total);
                    } else {
                      setIsCredit(true);
                      setCostTotal(total);
                    }
                  }}
                >
                  {servicesList?.results?.map((service) => (
                    <Select.Option key={service.id} value={service.id}>
                      {createServiceName(service.id)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {!isAddJob ? (
                <Form.Item
                  label={content.add.form.discount.title}
                  name="discount"
                  tooltip={content?.tooltips?.discount}
                >
                  <InputNumber
                    value={jobDiscount ?? 0}
                    style={{ width: "100%" }}
                    min={-100}
                    defaultValue={0}
                    max={100}
                    onChange={(e) => {
                      setJobDiscount(e);
                    }}
                  />
                </Form.Item>
              ) : (
                ""
              )}
            </>
          )}
          {!isAddJob ? (
            <>
              <Form.Item style={{ marginBottom: 0 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "300px" }}>
                    {content.balance_resume.cost}{" "}
                  </div>
                  <div style={{ width: "300px" }}> {costTotal}</div>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "300px" }}>
                    {content.balance_resume.discount}{" "}
                  </div>
                  <div style={{ width: "300px" }}>{jobDiscount ?? 0}%</div>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "300px" }}>
                    {content.balance_resume.total}
                  </div>
                  <div style={{ width: "300px" }}>
                    {getAmountConvertion(costTotal * (100 - jobDiscount * -1)) /
                      100}
                  </div>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "300px" }}>
                    Valor previo (Cuando se finalizo y envio)
                  </div>
                  <div style={{ width: "300px" }}>
                    {" "}
                    {getAmountConvertion(previousCost)}
                  </div>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "300px" }}>Diferencia</div>
                  <div style={{ width: "300px" }}>
                    {getAmountConvertion(
                      previousCost -
                        (costTotal * (100 - jobDiscount * -1)) / 100
                    )}
                  </div>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "300px" }}>
                    {content.balance_resume.credit}{" "}
                  </div>
                  <div style={{ width: "300px" }}>
                    {getAmountConvertion(selectedClient?.balance)}
                  </div>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "300px" }}>
                    {content.balance_resume.balance_total}{" "}
                  </div>
                  <div style={{ width: "300px" }}>
                    {" "}
                    {
                      <Tag
                        bordered={false}
                        color={
                          selectedClient?.balance -
                            (costTotal * (100 - jobDiscount)) / 100 >
                          0
                            ? "success"
                            : selectedClient?.balance -
                                (costTotal * (100 - jobDiscount)) / 100 ===
                              0
                            ? "warning"
                            : selectedClient?.balance -
                                (costTotal * (100 - jobDiscount)) / 100 <
                              0
                            ? "error"
                            : "warning"
                        }
                      >
                        {getAmountConvertion(
                          (
                            selectedClient?.balance -
                            ((costTotal * (100 - jobDiscount * -1)) / 100 -
                              previousCost)
                          ).toFixed(0)
                        )}
                      </Tag>
                    }
                  </div>
                </div>
              </Form.Item>
            </>
          ) : (
            <></>
          )}
          <Form.Item>
            {!isFinishJob ? (
              <Button
                style={{ float: "right" }}
                type="primary"
                //disabled={!isCredit}
                htmlType="submit"
                loading={isAddJob ? loadingCreate : loadingUpdate}
                onClick={() => setFinishState("")}
              >
                {isAddJob ? content.button.add : content.button.edit}
              </Button>
            ) : (
              <Space size={18} style={{ float: "right" }}>
                <Button onClick={()=>handleCancel()}>{content.button.cancel}</Button>
                <Button
                  id="saveDraft"
                  htmlType="submit"
                  style={{ float: "right" }}
                  type="primary"
                  onClick={() => setFinishState("saveDraft")}
                >
                  {content.button.draft}
                </Button>
                <Button
                  id="finishJob"
                  htmlType="submit"
                  style={{ float: "right" }}
                  type="primary"
                  onClick={() => setFinishState("finishJob")}
                >
                  {content.button.end}
                </Button>
                <Button
                  id="finishAndSendJob"
                  htmlType="submit"
                  style={{ float: "right" }}
                  type="primary"
                  onClick={() => setFinishState("finishAndSendJob")}
                >
                  {content.button.end_send}
                </Button>
              </Space>
            )}
          </Form.Item>
        </Form>
      </Modal>
      {/*FILES TABS/MODAL */}
      <Modal
        width={800}
        title={modalFileTitle}
        open={modalFileVisible}
        onCancel={handleCancelFile}
        footer={null}
      >
        <Tabs defaultActiveKey="1" items={filesTabs} />
      </Modal>
      {/*UPLOAD FILES */}
      <Modal
        width={800}
        title={modalFileUploadTitle}
        open={modalFileUploadVisible}
        onCancel={() => setModalFileUploadVisible(false)}
        footer={null}
      >
        <Form
          form={formUploadFile}
          layout="vertical"
          //initialValues={jobData}
          onFinish={handleUploadFile}
        >
          <Form.Item>
            <Form.Item
              name="files"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
              label="Files"
              rules={[
                {
                  required: true,
                  message: content?.add?.form?.file.placeholder,
                },
              ]}
            >
              <Upload.Dragger
                name="files"
                multiple
                beforeUpload={() => false}
                //action="/upload.do"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {content.add.form.file.description}
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ float: "right" }}
              type="primary"
              htmlType="submit"
              loading={loadingRegisterFile}
            >
              Agregar archivo
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/*ASSING WORKER */}
      <Filters setFromTo={setFromTo} fromTo={fromTo} setModalFilterOpen={setModalFilterOpen} modalFilterOpen={modalFilterOpen}/>
      <Modal
        width={800}
        title="add worker" //{modalFileTitle}
        open={modalWorkerAssignVisible}
        onCancel={() => setModalWorkerAssignVisible(false)}
        footer={null}
      >
        <Form
          //form={form}
          layout="vertical"
          //initialValues={jobData}
          //onFinish={handleSubmit}
        >
          {userInfo.role < 3 && (
            <>
              <Form.Item
                style={
                  {
                    // display: "inline-block",
                    // width: "calc(50%)",
                    //float: "right",
                  }
                }
                label={content.add.form.worker.title}
                name="worker"
                rules={[
                  {
                    required: true,
                    message: content.add.form.worker.placeholder,
                  },
                ]}
              >
                <Select {...selectProps} allowClear>
                  {workersList?.results?.map((worker) => (
                    <Select.Option key={worker.id} value={worker.id}>
                      {createWorkerName(worker.id)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ float: "right" }}
                  type="primary"
                  htmlType="submit"
                  //loading={isAddJob ? loadingCreate : loadingUpdate}
                >
                  {content.button.add}
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
      {/*JOB DESCRIPTION */}
      <Modal
        width={800}
        open={modalJobInfoVisible}
        onCancel={() => setModalJobInfoVisible(false)}
        footer={null}
        title={content?.description?.job}
      >
        <Descriptions
          content={props.content}
          darkMode={props.darkMode}
          setModalClientInfoVisible={setModalClientInfoVisible}
          onClickClientInfo={setClientDescription}
          data={jobDescription}
          client={clientsList}
          selectedJob={selectedJob}
        ></Descriptions>
      </Modal>
      {/*CLIENT DESCRIPTION */}
      <Modal
        width={800}
        open={modalClientInfoVisible}
        onCancel={() => setModalClientInfoVisible(false)}
        footer={null}
        title={content?.description?.client}
      >
        <Descriptions
          content={props.content}
          darkMode={props.darkMode}
          data={clientDescription}
        ></Descriptions>
      </Modal>
    </div>
  );
}

export default JobScreen;
