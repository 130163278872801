import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  Col,
  Row,
  Typography,
  Space,
  Avatar,
  Input,
  Badge,
  notification,
  Divider,
  Button,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { MessageOutlined, SendOutlined } from "@ant-design/icons";
import axios from "axios";
import { getClientsList } from "../redux/actions/userActions";
import notificationSound from "../data/not_sound.mp3";
import whiteBg from "../data/whiteBg.jpg";
import blackBg from "../data/blackBg.jpg";
import { wsApiURL, apiURL } from "../redux/store";

const { Title, Text } = Typography;

function ChatScreen(props) {
  const dataToken = props.userInfo;

  const [selectedRoom, setSelectedRoom] = useState("");
  const [chatList, setChatList] = useState([]);
  const [socket_, setSocket_] = useState("");
  const [chatRooms, setRooms] = useState([]);
  const [text, setText] = useState("");
  const [userToSearch, setUserToSearch] = useState("");
  const [noteReadMessages, setNotReadMessages] = useState([]);
  const [audio, setAudio] = useState(new Audio(notificationSound));

  let socket = "";

  const selectedRoomRef = useRef(selectedRoom);

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const {
    clientsList,
    loading: loadingClients,
    success: successClients,
  } = useSelector((state) => state.clientList);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.access}`,
    },
  };

  useEffect(() => {
    if (dataToken === "no_logged") {
      navigate("/login");
    } else if (dataToken?.role < 3) {
      dispatch(getClientsList());
    }
    //const audio = new Audio(notificationSound);
    setAudio(new Audio(notificationSound));
  }, [navigate, dataToken]);

  useEffect(() => {
    selectedRoomRef.current = selectedRoom;
    selectedRoomRef.chatRooms = chatRooms;
    selectedRoomRef.chatList = chatList;
  }, [selectedRoom, chatRooms, chatList]);
/*
  useEffect(() => {
    const isDivNearBottom = (div, pixelsFromBottom) => {
      const distanceToBottom = div.scrollHeight - (div.clientHeight + div.scrollTop);
      return distanceToBottom <= pixelsFromBottom;
    };

    let objDiv = document.getElementById("chatScreen");
    setTimeout(() => {
      //console.log( objDiv?.clientHeight - objDiv?.scrollTop, objDiv?.scrollHeight, objDiv?.clientHeight, objDiv?.scrollTop )
      if(isDivNearBottom(objDiv, 400)) { 
        objDiv.scrollTop = objDiv.scrollHeight} ; /*document.getElementById("chatWrapprer").style.visibility = "";
      }, 100);
  }, [chatList]);*/

  useEffect(() => {
    if (!socket && user && props.socket) {
      socket = props.socket; //new WebSocket(wsApiURL + `ws/chat/${userInfo?.access}`);
      socket.onmessage = (event) => {
        let notRead = 0;
        const data = JSON.parse(event.data);

        if (data?.action == "message") {
          if (
            selectedRoomRef.current === data?.roomId &&
            document.getElementById("chatScreen") != null
          ) {
            setChatList((prevChatList) => [
              ...prevChatList,
              {
                // username: data.username,
                username: data.username,
                message: data.message,
                timestamp: data.timestamp,
                user: data.user,
                isAdmin: data.isAdmin,
              },
            ]);
            let objDiv = document.getElementById("chatScreen");
            setTimeout(() => (objDiv.scrollTop = objDiv.scrollHeight), 100);
          } else {
            audio.play();
            notification.info({
              icon: <MessageOutlined style={{ color: "#108ee9" }} />,
              message: data.username,
              description: data.message,
              placement: "bottomRight",
              duration: 1,
              onClick: function () {
                navigate("/chat");
              },
            });
          }

          let room = selectedRoomRef.chatRooms;
          room.forEach((e, index) => {
            if (e.roomId == data?.roomId) {
              room[index].last_message = data.message;
              room[index].isRead =
                document.getElementById("chatScreen") == null
                  ? false
                  : selectedRoomRef.current == data?.roomId;
              room[index].last_message_time =
                dayjs().format("YYYY-MM-DD HH:mm");
            }
            if (!e.isRead & (e.last_message != null)) notRead++;
          });
          document.title = `Transyss`;
          if (notRead > 0) document.title = `(${notRead}) Transyss`;
          props.setNotReadMessages(notRead);
          setRooms((prevChatList) => [...ordenarPorFecha(room)]);
        }
      };

      setSocket_(socket);
    }

    const fetchChatsData = async () => {
      const { data } = await axios.get(`${apiURL}/api/chats/`, config);

      let unreadNotification = [];
      setRooms(ordenarPorFecha(data));
      let notRead = 0;
      data.forEach((e, index) => {
        if (!e.isRead & (e.last_message != null)) {
          unreadNotification.push({
            message: `You have unread message/s from ${e?.member?.[1]?.first_name}  ${e?.member?.[1]?.last_name}`,
          });
          notRead++;
        }
      });
      setNotReadMessages(unreadNotification);

      document.title = `Transyss`;
      if (notRead > 0) document.title = `(${notRead}) Transyss`;
      if (dataToken?.role == 3) setSelectedRoom(data[0].roomId);

      const { data: data_ } = await axios.get(
        `${apiURL}/api/chats/${data[0].roomId}/messages`,
        config
      );
      let temp = data_;
      if (dataToken?.role == 3) setChatList(temp);

      if (dataToken?.role == 3 && socket) {
        document.getElementById("chatWrapprer").style.visibility = "hidden";
        try {
          socket.send(
            JSON.stringify({
              action: "read",
              roomId: data[0].roomId,
            })
          );
        } catch (e) {
          socket.onopen = (event) => {
            socket.send(
              JSON.stringify({
                action: "read",
                roomId: data[0].roomId,
              })
            );
          };
        }
        notRead = 0;
        document.title = `Transyss`;
        if (notRead > 0) document.title = `(${notRead}) Transyss`;
        props.setNotReadMessages(notRead);
        let objDiv = document.getElementById("chatScreen");
        setTimeout(() => {
          document.getElementById("chatWrapprer").style.visibility = "";
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 100);
      }
    };
    //console.log(user?.id, selectedRoomRef.chatList.length == 0, chatList, selectedRoomRef.chatList)
    if (user?.id && chatRooms.length == 0) fetchChatsData();
  }, [user, props.socket]);

  function ordenarPorFecha(array) {
    array.sort((a, b) => {
      const dateA = new Date(a.last_message_time);
      const dateB = new Date(b.last_message_time);
      return dateB - dateA;
    });

    return array;
  }

  const messageSubmitHandler = (event) => {
    if (text != "") {
      //if (!event) event.preventDefault();
      setText("");
      socket_.send(
        JSON.stringify({
          action: "message",
          message: text,
          roomId: selectedRoom,
        })
      );
    }
  };

  const saveReadHandler = (roomId) => {
    socket_.send(
      JSON.stringify({
        action: "read",
        roomId: roomId,
      })
    );
  };

  const clickChatHandler = (e, item) => {
    let notRead = 0;
    document.getElementById("chatWrapprer").style.visibility = "hidden";
    const fetchData = async () => {
      const { data } = await axios.get(
        `${apiURL}/api/chats/${item.roomId}/messages`,
        config
      );
      setChatList(data);
    };
    if (user?.id) fetchData();
    setSelectedRoom(item.roomId);
    saveReadHandler(item.roomId);
    setChatList([]);

    let room = chatRooms;
    room.forEach((e, index) => {
      if (e.roomId == item?.roomId) {
        room[index].isRead = true;
      }
      if (!e.isRead & (e.last_message != null)) notRead++;
    });
    document.title = `Transyss`;
    if (notRead > 0) document.title = `(${notRead}) Transyss`;
    props.setNotReadMessages(notRead);
    setRooms((prevChatList) => [...ordenarPorFecha(room)]);
    let objDiv = document.getElementById("chatScreen");
    setTimeout(() => {
      objDiv.scrollTop = objDiv.scrollHeight;
      document.getElementById("chatWrapprer").style.visibility = "";
    }, 500);
  };

  function standardizeName(name) {
    // Step 1: Convert to lowercase and remove accents
    const nameLowercase = name?.toLowerCase()?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");  
    // Step 2: Remove special characters and extra spaces
    const standardizedName = nameLowercase?.replace(/[^\w\s]/g, "")?.replace(/\s+/g, ' ').trim();  
    return standardizedName;
  }

  const leftPanel = () => {
    return (
      dataToken?.role < 3 && (
        <>
          {" "}
          <Col span={6} className="userListChat">
            <Input
              style={{ marginBottom: "25px" }}
              value={userToSearch}
              onChange={(e) => {
                setUserToSearch(e.target.value);
              }}
              placeholder="Buscar por nombre y/o apellido"
              allowClear
            />
            {chatRooms
              ?.filter((e) => {
                let full_name =
                  e?.client?.first_name + " " + e?.client?.last_name;
                full_name = standardizeName(full_name ?? "")                
                let first_name = standardizeName(e?.client?.first_name ?? "")
                let last_name = standardizeName(e?.client?.last_name ?? "")
                let aux_userToSearch = standardizeName(userToSearch)
                
                return (
                  full_name?.includes(aux_userToSearch ?? "") ||
                  first_name?.includes(aux_userToSearch ?? "") ||                  
                  last_name?.includes(aux_userToSearch ?? "")
                );
              })
              .map((item) => {
                /*
            let user2 = item?.member?.filter((item) => {
              return item.id != user?.id;
            });
            user2 = user2?.[0];*/
                return (
                  <>
                    <Card
                      style={{
                        cursor: "pointer",
                        border: "none",
                        backgroundColor:
                          selectedRoom == item.roomId ? "#069dc7" : "",
                      }}
                      onClick={(e) => {
                        return clickChatHandler(e, item);
                      }}
                      id={item.roomId}
                    >
                      <Card.Meta
                        avatar={
                          <Badge dot={false & !item.isRead}>
                            <Avatar
                              src={
                                item.profile_picture
                              } /*"https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" */
                            />
                          </Badge>
                        }
                        title={
                          item?.client?.first_name +
                          " " +
                          item?.client?.last_name
                        }
                        description={
                          item?.last_message && (
                            <div>
                              {item.last_message}
                              {!item.isRead && <div className="dot"></div>}
                              <div className="dateTimeleftPanel">
                                {item.isRead ? (
                                  formatMessageTime(item.last_message_time)
                                ) : (
                                  <strong>
                                    {formatMessageTime(item.last_message_time)}
                                  </strong>
                                )}
                              </div>
                            </div>
                          )
                        }
                      />
                    </Card>
                    <Divider style={{ margin: 0 }} />
                  </>
                );
              })}
          </Col>
        </>
      )
    );
  };

  const rightPanel = () => {
    return chatList?.map((item, index) => {
      let isDivisor = false;
      let divisorDate = "";
      if (chatList[index + 1]?.timestamp) {
        if (
          dayjs(item.timestamp)
            .startOf("day")
            .diff(
              dayjs(chatList[index + 1]?.timestamp).startOf("day"),
              "day"
            ) != 0
        ) {
          if (
            dayjs(chatList[index + 1]?.timestamp)
              .startOf("day")
              .diff(dayjs().startOf("day"), "day") == 0
          ) {
            isDivisor = true;
            divisorDate = "Today";
          } else {
            isDivisor = true;
            divisorDate = dayjs(chatList[index + 1]?.timestamp).format(
              "DD/MM/YYYY"
            );
          }
        }
      }

      return (
        <>
          <Card
            className={
              item.user == user.id * 1 || (item.isAdmin && dataToken?.role < 3)
                ? "userMessage"
                : "notUserMessage"
            }
            bodyStyle={{ padding: "10px", overflowWrap: "anywhere" }}
          >
            <Text
              className="chatText"
              style={{ width: "100%", color: "white" }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  float: "left",
                  //display: item.user == dataToken.user_id ? "none" : "",
                }}
              >
                {item.user == dataToken.user_id ? "Yo" : item.username}
              </div>
              <hr className="chatSeparator" />
              {item.message}
              <hr className="chatSeparator" />
              <div style={{ float: "right", fontSize: "11px" }}>
                {formatMessageTime(item.timestamp)}
              </div>
            </Text>
          </Card>
          <hr className="chatSeparator" />
          {isDivisor && (
            <Divider orientation="middle" plain>
              {divisorDate}
            </Divider>
          )}
        </>
      );
    });
  };

  const formatMessageTime = (date) => {
    return dayjs(date).startOf("day").valueOf() !=
      dayjs().startOf("day").valueOf()
      ? dayjs(date).format("DD/MM/YYYY h:mm a")
      : dayjs(date).format("h:mm a");
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Evita el comportamiento por defecto de salto de línea
      messageSubmitHandler();
    }
  };

  if (dataToken === "no_logged" || !dataToken) return <></>;
  return (
    <div className="container_chat">
      <Title className="titleCenter"> {props.content.title}</Title>
      <>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          style={{ height: "100%" }}
        >
          {leftPanel()}
          <Col span={17 + (dataToken?.role == 3 ? 7 : 0)}>
            <Row
              id="chatScreen"
              style={{
                backgroundImage: props.darkMode
                  ? `url(${blackBg})`
                  : `url(${whiteBg})`,
               // backgroundSize: "113vh 60vh",
                backgroundSize: "cover"
              }}
            >
              <div id="chatWrapprer">{rightPanel()}</div>
            </Row>
            {/*
            <Row style={{ paddingTop: "10px", gap: 8 }}>
              <Input.Search
                disabled={!selectedRoom}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onSearch={messageSubmitHandler}
                // placeholder="input search text"
                enterButton={<SendOutlined />}
                size="large"
              />
            </Row>*/}
            <Space.Compact style={{ paddingTop: "10px", width: "100%" }}>
              <Input.TextArea
                disabled={!selectedRoom}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={handleKeyDown}
                // placeholder="input search text"
                enterButton={<SendOutlined />}
                size="large"
                autoSize={{ maxRows: 5 }}
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
              <Button
                type="primary"
                icon={<SendOutlined />}
                size={"large"}
                style={{ height: "auto" }}
                onClick={messageSubmitHandler}
              />
            </Space.Compact>
          </Col>
        </Row>
      </>
    </div>
  );
}

export default ChatScreen;
