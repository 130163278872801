import axios from "axios";
import {
  FILE_REGISTER_REQUEST,
  FILE_REGISTER_SUCCESS,
  FILE_REGISTER_FAIL,
  FILE_REGISTER_RESET,
  FILE_DETAILS_REQUEST,
  FILE_DETAILS_SUCCESS,
  FILE_DETAILS_FAIL,
  FILE_DETAILS_RESET,
  FILE_UPDATE_REQUEST,
  FILE_UPDATE_SUCCESS,
  FILE_UPDATE_FAIL,
  FILE_UPDATE_RESET,
  FILES_LIST_REQUEST,
  FILES_LIST_SUCCESS,
  FILES_LIST_FAIL,
  FILES_LIST_RESET,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_FAIL,
  FILE_DELETE_RESET,
  FILE_UPDATE_VISIBLE_REQUEST,
  FILE_UPDATE_VISIBLE_SUCCESS,
  FILE_UPDATE_VISIBLE_FAIL,
  FILE_UPDATE_VISIBLE_RESET,
} from "../constants/fileConstants";

import { apiURL } from "../store";

export const getFileDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FILE_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`${apiURL}/api/files/${id}`, config);

    dispatch({
      type: FILE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FILE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteFile = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FILE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(`${apiURL}/api/files/${id}`, config);

    dispatch({
      type: FILE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FILE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getFiles = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FILES_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`${apiURL}/api/files/?all=true`, config);

    dispatch({
      type: FILES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FILES_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateFile = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FILE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(`${apiURL}/api/files/${id}`, body, config);

    dispatch({
      type: FILE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FILE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerFile = (body, jobId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FILE_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(
      `${apiURL}/api/files/jobs/${jobId}`,
      body,
      config
    );

    dispatch({
      type: FILE_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FILE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateVisibleFile =
  (body, fileId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FILE_UPDATE_VISIBLE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const { data } = await axios.post(
        `${apiURL}/api/files/${fileId}/visible`,
        body,
        config
      );

      dispatch({
        type: FILE_UPDATE_VISIBLE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FILE_UPDATE_VISIBLE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
