export const ECU_BRAND_REGISTER_REQUEST = "ECU_BRAND_REGISTER_REQUEST";
export const ECU_BRAND_REGISTER_SUCCESS = "ECU_BRAND_REGISTER_SUCCESS";
export const ECU_BRAND_REGISTER_FAIL = "ECU_BRAND_REGISTER_FAIL";
export const ECU_BRAND_REGISTER_RESET = "ECU_BRAND_REGISTER_RESET";

export const ECU_BRAND_DETAILS_REQUEST = "ECU_BRAND_DETAILS_REQUEST";
export const ECU_BRAND_DETAILS_SUCCESS = "ECU_BRAND_DETAILS_SUCCESS";
export const ECU_BRAND_DETAILS_FAIL = "ECU_BRAND_DETAILS_FAIL";
export const ECU_BRAND_DETAILS_RESET = "ECU_BRAND_DETAILS_RESET";

export const ECU_BRAND_UPDATE_REQUEST = "ECU_BRAND_UPDATE_REQUEST";
export const ECU_BRAND_UPDATE_SUCCESS = "ECU_BRAND_UPDATE_SUCCESS";
export const ECU_BRAND_UPDATE_FAIL = "ECU_BRAND_UPDATE_FAIL";
export const ECU_BRAND_UPDATE_RESET = "ECU_BRAND_UPDATE_RESET";

export const ECUS_BRAND_LIST_REQUEST = "ECUS_BRAND_LIST_REQUEST";
export const ECUS_BRAND_LIST_SUCCESS = "ECUS_BRAND_LIST_SUCCESS";
export const ECUS_BRAND_LIST_FAIL = "ECUS_BRAND_LIST_FAIL";
export const ECUS_BRAND_LIST_RESET = "ECUS_BRAND_LIST_RESET";

export const ECU_BRAND_DELETE_REQUEST = "ECU_BRAND_DELETE_REQUEST";
export const ECU_BRAND_DELETE_SUCCESS = "ECU_BRAND_DELETE_SUCCESS";
export const ECU_BRAND_DELETE_FAIL = "ECU_BRAND_DELETE_FAIL";
export const ECU_BRAND_DELETE_RESET = "ECU_BRAND_DELETE_RESET";

//------------------------

export const ECU_MODEL_REGISTER_REQUEST = "ECU_MODEL_REGISTER_REQUEST";
export const ECU_MODEL_REGISTER_SUCCESS = "ECU_MODEL_REGISTER_SUCCESS";
export const ECU_MODEL_REGISTER_FAIL = "ECU_MODEL_REGISTER_FAIL";
export const ECU_MODEL_REGISTER_RESET = "ECU_MODEL_REGISTER_RESET";

export const ECU_MODEL_DETAILS_REQUEST = "ECU_MODEL_DETAILS_REQUEST";
export const ECU_MODEL_DETAILS_SUCCESS = "ECU_MODEL_DETAILS_SUCCESS";
export const ECU_MODEL_DETAILS_FAIL = "ECU_MODEL_DETAILS_FAIL";
export const ECU_MODEL_DETAILS_RESET = "ECU_MODEL_DETAILS_RESET";

export const ECU_MODEL_UPDATE_REQUEST = "ECU_MODEL_UPDATE_REQUEST";
export const ECU_MODEL_UPDATE_SUCCESS = "ECU_MODEL_UPDATE_SUCCESS";
export const ECU_MODEL_UPDATE_FAIL = "ECU_MODEL_UPDATE_FAIL";
export const ECU_MODEL_UPDATE_RESET = "ECU_BRAND_UPDATE_RESET";

export const ECUS_MODEL_LIST_REQUEST = "ECUS_MODEL_LIST_REQUEST";
export const ECUS_MODEL_LIST_SUCCESS = "ECUS_MODEL_LIST_SUCCESS";
export const ECUS_MODEL_LIST_FAIL = "ECUS_MODEL_LIST_FAIL";
export const ECUS_MODEL_LIST_RESET = "ECUS_MODEL_LIST_RESET";

export const ECU_MODEL_DELETE_REQUEST = "ECU_MODEL_DELETE_REQUEST";
export const ECU_MODEL_DELETE_SUCCESS = "ECU_MODEL_DELETE_SUCCESS";
export const ECU_MODEL_DELETE_FAIL = "ECU_MODEL_DELETE_FAIL";
export const ECU_MODEL_DELETE_RESET = "ECU_MODEL_DELETE_RESET";
