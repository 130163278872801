import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Skeleton,
  Checkbox,
  Input,
  Tabs,
  Row,
  Col,
  Button,
  Typography,
  Form,
  Popconfirm,
  Space,
  Table,
  Modal,
  Tooltip,
  InputNumber,
  Select,
  Divider,
  Collapse,
  Switch,
} from "antd";
import { notification } from "antd";
import {
  getClientsList,
  activateClient,
  registerClient,
  deleteClient,
  updateClient,
} from "../redux/actions/userActions";
import {
  USERS_LIST_RESET,
  CLIENTS_LIST_RESET,
  ACTIVATE_CLIENT_RESET,
  CLIENT_DELETE_RESET,
  CLIENT_UPDATE_RESET,
  CLIENT_REGISTER_RESET,
} from "../redux/constants/userConstants";
import {
  MailOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  FilterOutlined,
  FilterFilled,
  QuestionCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  EditOutlined,
  SwapOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  getCities,
  getCountries,
  getStates,
} from "../redux/actions/locationActions";

import {
  CITIES_DETAILS_RESET,
  STATE_DETAILS_RESET,
} from "../redux/constants/locationConstants";

import {
  createAccountMovement,
  getAccountMovements,
} from "../redux/actions/accountMovementActions";

import { updateAddress } from "../redux/actions/addressActions";
import dayjs from "dayjs";
import PhoneInput from "react-phone-input-2";
import ExportTable from "../components/ExportTable";
import Filters from "../components/Filters";
import UserInformationModal from "../components/UserInformationModal";
const { Option } = Select;
const { Title } = Typography;
const { Panel } = Collapse;

function ClientScreen(props) {
  const content = props.content;
  const tableProps = props.tableProps;
  const userData = props.userInfo;

  const [activeTab, setActiveTab] = useState("active");
  const [selectionType, setSelectionType] = useState("checkbox");
  const [usersToActivate, setUsersToActivate] = useState([]);
  const [searchClientText, setSearchClientText] = useState("");
  const [searchClientInactiveText, setSearchClientInactiveText] = useState("");
  //const [inactiveUsers, setInactiveUsers] = useState("");
  //const [activeUser, setActiveUser] = useState("");
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const creditToAddField = Form.useWatch("credit", form);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalBalanceOpen, setIsModalBalanceOpen] = useState(false);
  const [isUserInformationModalEdit, setIsUserInformationModalEdit] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [isModalMovementOpen, setIsModalMovementOpen] = useState(false);
  const [modalBalanceTitle, setModalBalanceTitle] = useState("");
  const [balance, setBalance] = useState("");
  const [creditToAdd, setCreditToAdd] = useState(0);
  const [modalClientTitle, setModalClientTitle] = useState();
  const [customPassword, setCustomPassword] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  //const [isAddUser, setIsAddUser] = useState(false);
  //const [isUseCost, setIsUseCost] = useState(false);
  //const [useUSD, setUseUSD] = useState(false);
  //const [USDvalue, setUSDvalue] = useState(0);
  //const [ARSvalue, setARSvalue] = useState(0);
  //const [filteredInfo, setFilteredInfo] = useState({});
  //const [sortedInfo, setSortedInfo] = useState({});
  const [sex, setSex] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  //const [message, setMessage] = useState("");
  const [countryIso2, setCountryIso2] = useState("");

  const [fromTo, setFromTo] = useState([]);
  const [modalFilterOpen, setModalFilterOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { success: sucessActivateClient } = useSelector(
    (state) => state.activateClient
  );

  const {
    success: successClientRegister,
    loading: loadingCreate,
    error: errorCreated,
  } = useSelector((state) => state.clientCreate);

  const {
    success: successClientUpdate,
    loading: loadingUpdate,
    error: errorUpdate,
  } = useSelector((state) => state.clientUpdate);

  const {
    success: successClientDelete,
    loading: loadingClientDelete,
    error: errorDelete,
  } = useSelector((state) => state.clientDelete);

  const { countries, loading: loadingCountries } = useSelector(
    (state) => state.countries
  );
  const { states, loading: loadingState } = useSelector(
    (state) => state.states
  );
  const { cities, loading: loadingCities } = useSelector(
    (state) => state.cities
  );

  /*
  const currencyList = useSelector((state) => state.currencyList);
  const { currencysList } = currencyList;
  */

  const { movementsList, loading: loadingMovementsList } = useSelector(
    (state) => state.accountMovementList
  );

  const { success: successCreateMovement, loading: loadingMovementsCreate } =
    useSelector((state) => state.accountMovementCreate);

  const {
    clientsList,
    loading: loadingClients,
    success: successClients,
  } = useSelector((state) => state.clientList);

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    if (userData && userData.role === 3) {
      navigate("/notFound");
    } else if (userData && userData.role !== 3) {
      resetStates();
      dispatch(getClientsList());
      dispatch(getAccountMovements());
    }
    if (userInfo === "no_logged") {
      navigate("/login");
    }
  }, [userData]);

  useEffect(() => {
    let iso2Country;
    let iso2State;
    if (!countries) dispatch(getCountries());
    if (country && countries && !states) {
      countries.forEach((e) => {
        if (
          country
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") ===
          e?.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        ) {
          iso2Country = e.iso2;
        }
      });
      if (iso2Country) {
        dispatch(getStates(iso2Country));
        setCountryIso2(iso2Country);
      }
    }
    if (countries && states && !cities && province) {
      states.forEach((e) => {
        if (
          province
            ?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") ===
          e?.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        ) {
          iso2State = e.iso2;
          //setProvince(e.name);
        }
      });
      if (iso2State) {
        dispatch(getCities(countryIso2, iso2State));
      }
    }
  }, [country, countries, states, province]);

  useEffect(() => {
    //clients
    //-success-
    if (sucessActivateClient && activeTab) {
      let article_2 = content?.tabs[activeTab]?.article_2;
      notification.success({
        message: content?.tabs[activeTab].title,
        description:
          content?.notification?.activate?.success.description.replace(
            /_user_/g,
            article_2
          ),
        placement: "bottomRight",
      });
      setUsersToActivate([]);
      dispatch(getClientsList());
    }
    if (successClientDelete) {
      let article_2 = content?.tabs[activeTab]?.article_2;
      notification.success({
        message: content?.tabs[activeTab].title,
        description: content?.notification?.delete?.success.description.replace(
          /_user_/g,
          article_2
        ),
        placement: "bottomRight",
      });
      dispatch(getClientsList());
    }
    if (successClientUpdate && activeTab) {
      let article_2 = content?.tabs[activeTab]?.article_2;
      notification.success({
        message: content?.tabs[activeTab].title,
        description: content?.notification?.edited?.success.description.replace(
          /_user_/g,
          article_2
        ),
        placement: "bottomRight",
      });
      dispatch(getClientsList());
      setIsModalEdit(false);
    }
    if (successClientRegister && activeTab) {
      let article_2 = content?.tabs[activeTab]?.article_2;
      notification.success({
        message: content?.tabs[activeTab].title,
        description: content?.notification?.create?.success.description.replace(
          /_user_/g,
          article_2
        ),
        placement: "bottomRight",
      });
      dispatch(getClientsList());
      setIsModalEdit(false);
    }
    //-error-
    if (errorCreated) {
      let article = content?.tabs[activeTab]?.article;
      notification.error({
        message: content?.tabs[activeTab].title,
        description: content?.notification?.create?.error.description.replace(
          /_user_/g,
          article
        ),
        placement: "bottomRight",
      });
      //  setIsModalEdit(false);
    }
    if (errorUpdate) {
      let article = content?.tabs[activeTab]?.article;
      notification.error({
        message: content?.tabs[activeTab].title,
        description: content?.notification?.edited?.error.description.replace(
          /_user_/g,
          article
        ),
        placement: "bottomRight",
      });
      //  setIsModalEdit(false);
    } /*
    if (errorCreated) {
      let article = content?.tabs[activeTab]?.article;
      notification.success({
        message: content?.tabs[activeTab].title,
        description: content?.notification?.create?.error.description.replace(
          /_user_/g,
          article
        ),
        placement: "bottomRight",
      });
    }*/
    //accountMovement
    if (successCreateMovement) {
      dispatch(getClientsList());
      dispatch(getAccountMovements());
    }
    resetStates();
  }, [
    sucessActivateClient,
    //---
    successCreateMovement,
    //---
    successClientDelete,
    successClientRegister,
    successClientUpdate,
    //
    errorCreated,
    errorUpdate,
    errorDelete,
  ]);

  /*
  useEffect(() => {
    currencysList?.results?.forEach((item) => {
      if (item.code?.toLowerCase() == "ars") {
        props.setARSvalue(item.rate ?? 0);
      }
      if (item.code?.toLowerCase() == "usd") {
        setUSDvalue(item.rate ?? 0);
      }
    });
  }, [currencysList]);
  */

  const getAmountConvertion = (amount) => {
    let aux = 0;
    let eq_rate = props.useUSD
      ? parseFloat(props.USDvalue)
      : parseFloat(props.ARSvalue);
    let conv_rate = props.isUseCost ? 1 : eq_rate;
    aux = amount * conv_rate;
    return aux;
  };

  function generatePassword() {
    let characters = "abcdefghijklmnopqrstuvwxyz";
    let length = 6;
    let password = "";
    for (let i = 0; i < length; i++) {
      let position = Math.floor(Math.random() * characters.length);
      password += characters[position];
    }
    return password;
  }

  const resetStates = () => {
    dispatch({ type: ACTIVATE_CLIENT_RESET });
    dispatch({ type: CLIENT_DELETE_RESET });
    dispatch({ type: CLIENT_UPDATE_RESET });
    dispatch({ type: CLIENT_REGISTER_RESET });
  };

  const handleAddBalace = (e) => {
    let accountMovement = {
      client: selectedUser.id,
      amount: e.credit ?? 0,
      transaction_date: new Date().toISOString(),
      concept: e.concept ?? "",
    };
    dispatch(createAccountMovement(accountMovement));
    setIsModalBalanceOpen(false);
  };

  const handleCancel = () => {
    setIsModalBalanceOpen(false);
  };

  const handleEditClick = (record) => {
    setCustomPassword(false);
    formEdit.resetFields();
    dispatch({ type: STATE_DETAILS_RESET });
    dispatch({ type: CITIES_DETAILS_RESET });
    setIsModalEdit(true);
    setIsEditing(true);
    //setIsAddUser(true);
    setSelectedUser(record);
    record.country = record.address_[0]?.country;
    record.state = record.address_[0]?.province;
    record.cities = record.address_[0]?.city;
    record.city = record.address_[0]?.city;
    record.street = record.address_[0]?.street_name;
    record.house_number = record.address_[0]?.house_number;
    record.password = "";
    record.phone = record.cell_phone;
    record.custom_password = customPassword;
    setCountry(record.country);
    setCity(record.city);
    setProvince(record.state);
    setModalClientTitle(content?.modal?.edit);
    // let tempSex = "";
    //if (isNaN(parseInt(record.sex))) {
    //   record.sex = record.sex;
    //    setSex(content?.form?.sex.options.indexOf(record.sex) ?? 1);
    //  } else {
    //     record.sex = content?.form?.sex.options[record.sex ?? 1];
    setSex(record.sex);
    //   }
    formEdit.setFieldsValue(record);
  };

  const handleAddClick = () => {
    setCustomPassword(false);
    formEdit.resetFields();
    dispatch({ type: STATE_DETAILS_RESET });
    dispatch({ type: CITIES_DETAILS_RESET });
    setCountry("");
    setCountryIso2("");
    setProvince("");
    setIsModalEdit(true);
    setIsEditing(false);
    //setIsAddUser(true);
    setModalClientTitle(content?.modal?.add);
    setSelectedUser("");
    formEdit.setFieldsValue({
      address: [],
      country: null,
      description: null,
      password: null,
      custom_password: customPassword,
      sex: null,
      dni: null,
      email: null,
      username: null,
      first_name: null,
      last_name: null,
      currency: null,
      cell_phone: null,
      phone: null,
      cities: "",
      city: "",
      states: "",
      state: "",
      business_phone: "",
      profession: "",
      company_name: null,
      street_name: "",
      street: "",
      house_number: "",
      province: "",
      floor: null,
      zip_code: null,
      apartment_number: null,
    });
  };

  //--- dispatchs
  const handleDeleteUser = (id) => {
    resetStates();
    dispatch(deleteClient(id));
  };

  const handleActivate = () => {
    resetStates();
    dispatch(activateClient(usersToActivate));
    dispatch({
      type: CLIENTS_LIST_RESET,
    });
  };

  const handleAdd = (record) => {
    resetStates();
    let address = "";
    if (!customPassword) {
      let randomPassword = generatePassword();
      record.password = randomPassword;
      record.description = randomPassword;
    }
    if (!isEditing) {
      record = {
        ...record,
        cell_phone: record.phone,
        address: [
          {
            country: country,
            province: province,
            city: city,
            street_name: record.street,
            house_number: record.house_number,
          },
        ],
      };
      dispatch(registerClient(record));
    }
  };

  const handleEdit = (record) => {
    resetStates();
    let address = {
      country: country,
      province: province,
      city: city,
      street_name: record.street,
      house_number: record.house_number,
    };
    record.cell_phone = record.phone;
    if (record.password === "") record.password = undefined;
    //let maps = content?.form?.sex.options;
    // record.sex = maps.indexOf(record.sex);
    dispatch(updateAddress(selectedUser.address_?.[0]?.id, address));
    dispatch(updateClient(selectedUser.id, record));
  };

  const handleSubmit = (e) => {
    if (isEditing) {
      handleEdit(e);
    } else {
      let record = {
        ...e,
        address: [
          {
            country: country,
            province: province,
            city: city,
            street_name: e.street,
            house_number: e.house_number,
          },
        ],
      };
      handleAdd(record);
    }
  };

  const columns = [
    {
      title: content?.form?.last_name.title,
      dataIndex: "last_name",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.last_name < b.last_name) {
          return -1;
        }
        if (a.last_name > b.last_name) {
          return 1;
        }
        return 0;
      },
      render: (text, record, b, c, d) => {
        return props.renderTooltipInEllipsis(text);
      },
    },
    {
      title: content?.form?.first_name.title,
      dataIndex: "first_name",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.first_name < b.first_name) {
          return -1;
        }
        if (a.first_name > b.first_name) {
          return 1;
        }
        return 0;
      },
      render: (text, record, b, c, d) => {
        return props.renderTooltipInEllipsis(text);
      },
    },
    {
      width: "200px",
      title: content?.form?.email.title,
      dataIndex: "email",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.email < b.email) {
          return -1;
        }
        if (a.email > b.email) {
          return 1;
        }
        return 0;
      },
      render: (text, record, b, c, d) => {
        return props.renderTooltipInEllipsis(text);
      },
    },
    {
      width: "200px",
      title: content?.form?.address.title,
      dataIndex: "address_name",
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => {
        let address_name =
          (record.address_?.[0]?.street_name ?? "") +
          " " +
          (record.address_?.[0]?.house_number ?? "");
        record.address_name = address_name;
        return props.renderTooltipInEllipsis(address_name);
      },
      sorter: (a, b) => {
        a = a.address_?.[0]?.street_name + " " + a.address_?.[0]?.house_number;
        b = b.address_?.[0]?.street_name + " " + b.address_?.[0]?.house_number;
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      },
    },
    {
      width: "200px",
      title: content?.form?.phone.title,
      dataIndex: "cell_phone",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.cell_phone < b.cell_phone) {
          return -1;
        }
        if (a.cell_phone > b.cell_phone) {
          return 1;
        }
        return 0;
      },
      render: (text, record, b, c, d) => {
        return props.renderTooltipInEllipsis(text);
      },
    },
    {
      width: "300px",
      title: content?.form?.company_name.title,
      dataIndex: "company_name",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.company_name < b.company_name) {
          return -1;
        }
        if (a.company_name > b.company_name) {
          return 1;
        }
        return 0;
      },
      render: (text, record, b, c, d) => {
        return props.renderTooltipInEllipsis(text);
      },
    },
    {
      title: content?.form?.balance.title,
      dataIndex: "balance",
      ellipsis: {
        showTitle: false,
      },
      width: "100px",
      render: (_, record) => {
        return getAmountConvertion(parseInt(record.balance ?? 0).toFixed(2)); //parseInt(record.balance ?? 0).toFixed(2);
      },
      sorter: (a, b) => {
        if (a.balance < b.balance) {
          return -1;
        }
        if (a.balance > b.balance) {
          return 1;
        }
        return 0;
      },
    },
    {
      //title: "Actions",
      width: "160px",
      key: "action",
      render: (text, record) => (
        <>
          <Space size={18}>
            <Tooltip placement="top" title={content.tooltips.edit}>
              <EditOutlined onClick={() => handleEditClick(record)} />
            </Tooltip>
            <Tooltip placement="top" title={content.tooltips.delete}>
              <Popconfirm
                cancelText={content?.cancel_button}
                title={content.notification.cancel.title}
                description={content.notification.cancel.description}
                onConfirm={() => handleDeleteUser(record.id)}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              >
                <DeleteOutlined style={{ color: "red" }} />
              </Popconfirm>
            </Tooltip>
            {record.is_active && (
              <Tooltip
                placement="top"
                title={content.tooltips.account_movement}
              >
                <SwapOutlined
                  onClick={() => {
                    setFromTo([undefined, undefined])
                    setSelectedUser(record);
                    setIsModalMovementOpen(true);
                  }}
                />
              </Tooltip>
            )}
            <Tooltip placement="top" title={content.tooltips.balance}>
              <PlusCircleOutlined
                onClick={(e) => {
                  setSelectedUser(record);
                  setBalance(record.balance);
                  setCreditToAdd(0);
                  form.resetFields();
                  setModalBalanceTitle(content?.balance?.modal?.title);
                  setIsModalBalanceOpen(true);
                }}
              />
            </Tooltip>
            <Tooltip placement="top" title={content.tooltips.information}>
              <InfoCircleOutlined
                onClick={(e) => {
                  setIsUserInformationModalEdit(true)
                  setSelectedUser(record);
                  // setBalance(record.balance);
                  // setCreditToAdd(0);
                  // form.resetFields();
                  // setModalBalanceTitle(content?.balance?.modal?.title);
                  // setIsModalBalanceOpen(true);
                }}
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];

  const columnsMovement = [
    {
      title: content.balance.form.created_time.title,
      dataIndex: "created_time",
      defaultSortOrder: "descend",
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => {
        return record.created_time !== null
          ? dayjs(record.created_time).format("YYYY-MM-DD HH:mm")
          : "";
      },
      sorter: (a, b) => {
        if (a.created_time < b.created_time) {
          return -1;
        }
        if (a.created_time > b.created_time) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: content.balance.form.concept.title,
      dataIndex: "concept",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.concept < b.concept) {
          return -1;
        }
        if (a.concept > b.concept) {
          return 1;
        }
        return 0;
      },
      render: (text, record, b, c, d) => {
        return props.renderTooltipInEllipsis(text);
      },
    },
    {
      title: content.balance.form.amount.title,
      dataIndex: "amount",
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        if (a.amount < b.amount) {
          return -1;
        }
        if (a.amount > b.amount) {
          return 1;
        }
        return 0;
      },
      render: (_, record) => {
        return getAmountConvertion(record.amount);
      },
    },
    {
      title: content.balance.form.balance_total.title,
      dataIndex: "amount_",
      render: (_, record) => {
        let total = 0.0;
        movementsList?.results?.forEach((item) => {
          if (item.client === selectedUser.id && item.id <= record.id) {
            total = total + parseFloat(item.amount);
          }
        });
        total = (
          props.isUseCost
            ? total
            : total * (props.useUSD ? props.USDvalue : props.ARSvalue)
        ).toLocaleString(window.clientInformation.language, {
          minimumFractionDigits: 2,
        });
        record.amount_ = total;
        return total;
      },
      /*
      sorter: (a, b) => {
        if (a.amount < b.amount) {
          return -1;
        }
        if (a.amount > b.amount) {
          return 1;
        }
        return 0;
      },*/
    },
  ];

  const dropdownSearch = (input, option) => {
    return (option?.children ?? "")
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(
        input
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      );
  };

  const filteredClients = clientsList?.results?.filter((client) =>
    Object.values(client)
      .join(" ")
      .toLowerCase()
      .includes(searchClientText.toLowerCase())
  );

  const inactiveUsers = clientsList?.results
    ?.filter((e) => e.is_active === false)
    .map((e) => {
      return { ...e, key: e.id };
    });

  const filteredInactiveClients = inactiveUsers?.filter((client) =>
    Object.values(client)
      .join(" ")
      .toLowerCase()
      .includes(searchClientInactiveText.toLowerCase())
  );

  const activeUser = filteredClients?.filter((e) => e.is_active === true);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setUsersToActivate(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const items = [
    {
      key: "active",
      label: content?.tabs["active"].title ?? "Loading...",
      children: (
        <>
          <div>
            <Row>
              <Col span={21}>
                <Input //.Search
                  placeholder={content.search}
                  onChange={(e) => setSearchClientText(e.target.value)}
                />
              </Col>
              <ExportTable
                data={activeUser}
                fileTitle={content?.tabs["active"].title}
                columns={columns}
                extraData={
                  {
                    //brand: ecusBrandList?.results,
                    //ecusModelList: ecusModelList?.results,
                  }
                }
              />
              <Col span={1}>
                <Button
                  icon={<PlusOutlined />}
                  // type="primary"
                  shape="circle"
                  onClick={() => handleAddClick()}
                  style={{ marginBottom: 16, float: "right" }}
                ></Button>
              </Col>
            </Row>
          </div>
          <Table
            {...tableProps}
            loading={loadingClients}
            columns={columns}
            dataSource={activeUser}
          />
        </>
      ),
    },
    {
      key: "inactive",
      label: content?.tabs["inactive"].title ?? "Loading...",
      children: (
        <div>
          <Row>
            <Col span={19}>
              <Input //.Search
                placeholder={content.search}
                onChange={(e) => setSearchClientInactiveText(e.target.value)}
              />
            </Col>
            <ExportTable
              data={filteredInactiveClients}
              fileTitle={content?.tabs["inactive"].title}
              columns={columns}
              extraData={{}}
            />
            <Col span={3}>
              <Button
                onClick={handleActivate}
                type="primary"
                disabled={usersToActivate?.length === 0}
                style={{ marginBottom: 16, float: "right" }}
              >
                {content.activate_client}
              </Button>
            </Col>
          </Row>
          <Table
            {...tableProps}
            loading={loadingClients}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            columns={columns}
            dataSource={filteredInactiveClients}
          />
        </div>
      ),
    },
  ];

  let accountMovementFilted = movementsList?.results?.filter(
    (am) => parseInt(am.client) === parseInt(selectedUser.id)
  );

  if (fromTo.length > 0) {
    let from = fromTo[0];
    let to = fromTo[1];
    accountMovementFilted = accountMovementFilted?.filter(
      (am) =>
        new Date(am.created_time) >= new Date(from ?? 1) &&
        new Date(am.created_time) <= new Date(to ?? 9999999999999)
    );
  }

  const sortByName = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  if (states) states.sort(sortByName);
  if (countries) countries.sort(sortByName);
  if (cities) cities.sort(sortByName);

  if (userData.role === 3 || !userData || userData === "no_logged")
    return <></>;
  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      {/*<h1 className="title" style={{ margin: 0 }}>
        {content.title ?? "Loading..."}
      </h1>*/}
      <Title className="titleCenter">{content.title}</Title>
      <Tabs
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e);
        }}
        items={items}
        //style={{ padding: "20px" }}
      />
      
      <Filters
        setFromTo={setFromTo}
        fromTo={fromTo}
        setModalFilterOpen={setModalFilterOpen}
        modalFilterOpen={modalFilterOpen}
      />
      <Modal
        title={modalBalanceTitle}
        open={isModalBalanceOpen}
        footer={null}
        width={500}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          onFinish={handleAddBalace}
        >
          <Form.Item
            label={content.balance.form.balance.title}
            name="Balance actual"
          >
            <div style={{ marginLeft: 12 }}>{parseInt(balance ?? 0)}</div>
          </Form.Item>
          <Form.Item label={content.balance.form.credit.title} name="credit">
            <InputNumber
              defaultValue={0}
              value={creditToAdd}
              //min={0}
              onChange={(e, a, b) => {
                setCreditToAdd(e + parseInt(balance));
              }}
            />
          </Form.Item>
          <Form.Item label={content.balance.form.total.title} name="Total">
            <div style={{ marginLeft: 12 }}>
              {parseInt(creditToAddField ?? 0) + parseInt(balance ?? 0)}
            </div>
          </Form.Item>
          <Form.Item label={content.balance.form.concept.title} name="concept">
            <Input autoComplete="none" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ float: "right" }}>
              {content?.form?.button?.add}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={content?.account_movement.title}
        open={isModalMovementOpen}
        footer={null}
        width={800}
        onCancel={() => setIsModalMovementOpen(false)}
      >
        <Row>
          <Col span={18}>
            <Space size={18} style={{ marginBottom: 10 }}>
              <Switch
                style={{ marginLeft: 10, width: 70 }}
                checkedChildren="USD"
                unCheckedChildren="Credit"
                checked={props.useUSD}
                onChange={(e) => {
                  props.setUseUSD(e);
                  props.setIsUseCost(false);
                  if (props.useARS) {
                    props.setUseARS(!e);
                  } else if (!e && !props.useARS) {
                    props.setIsUseCost(true);
                  }
                }}
              />
              <Switch
                style={{ marginLeft: 10, width: 70 }}
                checkedChildren="ARS"
                unCheckedChildren="Credit"
                checked={props.useARS}
                onChange={(e) => {
                  props.setUseARS(e);
                  props.setIsUseCost(false);
                  if (props.useUSD) {
                    props.setUseUSD(!e);
                  } else if (!e && !props.useUSD) {
                    props.setIsUseCost(true);
                  }
                }}
              />
            </Space>
          </Col>          
          <Col span={1}>
            <Button
              icon={
                (fromTo[0] || fromTo[1]) ? <FilterFilled /> : <FilterOutlined />
              }
              shape="circle"
              onClick={(e) => {
                setModalFilterOpen(true);
              }}
              style={{ marginBottom: 16, float: "right" }}
            ></Button>
          </Col>          
          <ExportTable
            data={accountMovementFilted}
            fileTitle={
              content?.account_movement.title +
              " - " +
              selectedUser.first_name +
              " " +
              selectedUser.last_name
            }
            columns={columnsMovement}
            colWidth={2}
            extraData={
              {
                //brand: ecusBrandList?.results,
                //ecusModelList: ecusModelList?.results,
              }
            }
          />
        </Row>
        <Table
          {...tableProps}
          columns={columnsMovement}
          dataSource={accountMovementFilted}
        ></Table>
      </Modal>
      <Modal
        title={modalClientTitle}
        open={isModalEdit}
        footer={null}
        width={800}
        //onOk={form.submit}
        onCancel={() => setIsModalEdit(false)}
      >
        <Form
          form={formEdit}
          name="basic"
          initialValues={selectedUser}
          onFinish={handleSubmit}
          //labelCol={{ span: 8 }}
          //wrapperCol={{ span: 16 }}
          layout="vertical"
        >
          <Collapse defaultActiveKey={["1", "2", "4"]} ghost>
            <Panel
              header={
                <Divider style={{ margin: 0 }}>{content.divisor.basic}</Divider>
              }
              key="1"
            >
              <Form.Item style={{ marginBottom: 0 }}>
                <Form.Item
                  style={{ display: "inline-block", width: "calc(49% - 8px)" }}
                  label={content?.form?.first_name.title ?? "Loading..."}
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message:
                        content?.form?.first_name.placeholder ?? "Loading...",
                    },
                  ]}
                >
                  <Input autoComplete="none" />
                </Form.Item>
                <Form.Item
                  style={{
                    display: "inline-block",
                    width: "calc(49% - 8px)",
                    float: "right",
                  }}
                  label={content?.form?.last_name.title ?? "Loading..."}
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message:
                        content?.form?.last_name.placeholder ?? "Loading...",
                    },
                  ]}
                >
                  <Input autoComplete="none" />
                </Form.Item>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Form.Item
                  label={content?.form?.dni?.title ?? "Loading..."}
                  name="dni"
                  style={{
                    display: "inline-block",
                    width: "calc(49% - 8px)",
                    float: "right",
                  }}
                  rules={[
                    {
                      required: true,
                      message: content?.form?.dni.placeholder ?? "Loading...",
                    },
                  ]}
                >
                  <Input autoComplete="none" />
                </Form.Item>
                <Form.Item
                  name="sex"
                  label={content?.form?.sex.title ?? "Loading..."}
                  style={{ display: "inline-block", width: "calc(49% - 8px)" }}
                  rules={[
                    {
                      required: true,
                      message: content?.form?.sex.placeholder ?? "Loading...",
                    },
                  ]}
                >
                  <Select
                    onChange={(value) => {
                      setSex(value);
                    }}
                    value={sex}
                    allowClear
                  >
                    <Option value={1} key={1}>
                      {content?.form?.sex.options[1] ?? "Loading..."}
                    </Option>
                    <Option value={2} key={2}>
                      {content?.form?.sex.options[2] ?? "Loading..."}
                    </Option>
                    <Option value={3} key={3}>
                      {content?.form?.sex.options[3] ?? "Loading..."}
                    </Option>
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={content?.form?.email.title ?? "Loading..."}
                name="email"
                rules={[
                  {
                    required: true,
                    message: content?.form?.email.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Input autoComplete="none" />
              </Form.Item>
              <Form.Item
                label={content?.form?.username.title ?? "Loading..."}
                name="username"
                //tooltip="This is a required field"
                rules={[
                  {
                    required: true,
                    message:
                      content?.form?.username.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Input autoComplete="none" />
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Divider style={{ margin: 0 }}>
                  {content.divisor.ubication}
                </Divider>
              }
              key="2"
            >
              <Form.Item style={{ marginBottom: 0 }}>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "inline-flex",
                    width: "100%",
                  }}
                >
                  <Form.Item
                    style={{ display: "inline-block", width: "31%" }}
                    name="country"
                    label={content?.form?.country.title ?? "Loading..."}
                    rules={[
                      {
                        required: true,
                        message:
                          content?.form?.country.placeholder ?? "Loading...",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      value={country}
                      //name="country"
                      optionFilterProp="children"
                      disabled={loadingCountries}
                      filterOption={dropdownSearch}
                      onChange={(value, a, b) => {
                        value = a.children;
                        let iso2 = a["data-iso2"];
                        //dispatch({ type: STATE_DETAILS_RESET });
                        setCountry(value);
                        setCountryIso2(iso2);
                        setCity("");
                        setProvince("");
                        dispatch({ type: STATE_DETAILS_RESET });
                        dispatch({ type: CITIES_DETAILS_RESET });
                        dispatch(getStates(iso2));
                        formEdit.setFieldsValue({
                          state: "",
                          cities: "",
                          city: "",
                        });
                      }}
                    >
                      {countries?.map((obj) => {
                        return (
                          <Option
                            key={obj.id}
                            value={obj.id}
                            data-iso2={obj.iso2}
                          >
                            {obj.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ display: "inline-block", width: "31%" }}
                    name="state"
                    label={content?.form?.state.title ?? "Loading..."}
                    rules={[
                      {
                        required: true,
                        message:
                          content?.form?.state.placeholder ?? "Loading...",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      disabled={country ? false : true}
                      optionFilterProp="children"
                      filterOption={dropdownSearch}
                      //placeholder={content?.form?.state.placeholder ?? "Loading..."}
                      //id="state"
                      value={province}
                      onChange={(value, a, b) => {
                        value = a.children;
                        let iso2 = a["data-iso2"];
                        dispatch({ type: CITIES_DETAILS_RESET });
                        setCity("");
                        dispatch(getCities(countryIso2, iso2));
                        setProvince(a.children);
                        formEdit.setFieldsValue({ cities: "", city: "" });
                      }}
                    >
                      {states?.map((obj) => {
                        return (
                          <Option
                            id={obj.id}
                            value={obj.id}
                            data-iso2={obj.iso2}
                          >
                            {obj.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ display: "inline-block", width: "31%" }}
                    name="city"
                    label={content?.form?.city.title ?? "Loading..."}
                    rules={[
                      {
                        required: true,
                        message:
                          content?.form?.city.placeholder ?? "Loading...",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={dropdownSearch}
                      // placeholder={content?.form?.city.placeholder ?? "Loading..."}
                      value={city}
                      disabled={province ? false : true}
                      name="city"
                      onChange={(value, a, b) => {
                        setCity(a.children);
                      }}
                    >
                      {cities?.map((obj) => {
                        return (
                          <Option
                            id={obj.id}
                            value={obj.id}
                            data-iso2={obj.iso2}
                          >
                            {obj.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "inline-flex",
                    width: "100%",
                  }}
                >
                  <Form.Item
                    style={{ display: "inline-block", width: "72%" }}
                    name="street"
                    label={content?.form?.street.title ?? "Loading..."}
                    rules={[
                      {
                        required: true,
                        message:
                          content?.form?.street.placeholder ?? "Loading...",
                      },
                    ]}
                  >
                    <Input autoComplete="none" />
                  </Form.Item>
                  <Form.Item
                    style={{ display: "inline-block", width: "25%" }}
                    name="house_number"
                    label={content?.form?.house_number.title ?? "Loading..."}
                    rules={[
                      {
                        required: true,
                        message:
                          content?.form?.house_number.placeholder ??
                          "Loading...",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      style={{ display: "inline-block", width: "100%" }}
                      autoComplete="none"
                    />
                  </Form.Item>
                </div>
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Divider style={{ margin: 0 }}>
                  {content.divisor.company}
                </Divider>
              }
              key="4"
            >
              <Form.Item
                label={content?.form?.company_name.title ?? "Loading..."}
                name="company_name"
                rules={[
                  {
                    required: true,
                    message:
                      content?.form?.company_name.placeholder ?? "Loading...",
                  },
                ]}
              >
                <Input autoComplete="none" />
              </Form.Item>
              <Form.Item
                label={content?.form?.phone?.title ?? "Loading..."}
                name="phone"
                rules={[
                  {
                    required: true,
                    message:
                      content?.form?.username.placeholder ?? "Loading...",
                  },
                ]}
              >
                <PhoneInput
                  enableSearch
                  country={"ar"}
                  inputClass={
                    "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                  }
                  buttonClass={
                    "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                  }
                  searchClass={
                    "phoneInput" + (props.darkMode ? " phoneDarkMode" : "")
                  }
                  // dropdownClass="phoneInput"
                  inputStyle={{
                    width: "100%",
                    height: "32px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                  buttonStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242 " : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                  dropdownStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                  searchStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: props.darkMode ? "#424242" : "#d9d9d9",
                    background: props.darkMode ? "#141414" : "white",
                    color: props.darkMode ? "white" : "black",
                  }}
                />
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Divider style={{ margin: 0 }}>
                  {content.divisor.password}
                </Divider>
              }
              key="3"
            >
              <Form.Item
                name="custom_password"
                valuePropName="checked"
                tooltip="test"
              >
                <Checkbox
                  checked={customPassword}
                  onChange={(e) => setCustomPassword(e.target.checked)}
                >
                  Custom password
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="password"
                label={content?.form?.password_1.title ?? "Loading..."}
                rules={[
                  {
                    required: customPassword,
                    message:
                      content?.form?.password_2.placeholder ?? "Loading...",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  autoComplete="none"
                  disabled={!customPassword}
                />
              </Form.Item>
              <Form.Item
                disable={customPassword}
                name="confirm"
                label={content?.form?.password_2.title ?? "Loading..."}
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: customPassword,
                    message:
                      content?.form?.password_2.placeholder ?? "Loading...",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  autoComplete="new-password"
                  disabled={!customPassword}
                />
              </Form.Item>
            </Panel>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingCreate || loadingUpdate}
                style={{ float: "right" }}
              >
                {isEditing
                  ? content?.form?.button?.edit
                  : content?.form?.button?.add}
              </Button>
            </Form.Item>
          </Collapse>
        </Form>
      </Modal>
      <UserInformationModal
        setIsUserInformationModalEdit={setIsUserInformationModalEdit}
        isUserInformationModalEdit={isUserInformationModalEdit}
        selectedUser={selectedUser}
        title={content.userInformationModal.title}
        {...props}
      >
      </UserInformationModal>
    </div>
  );
}

export default ClientScreen;
