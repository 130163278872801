import Footer from "./components/Footer";
import Slider from "./components/Slider";
import Headers from "./components/Header";
import { BrowserRouter as Router, Link, } from "react-router-dom";
import {
  Layout,
  Menu,
  theme,
  ConfigProvider,
  Tooltip,
  notification,
  Modal,
} from "antd";
import NotFound404Screen from "./screens/NotFound404Screen";
import ClientScreen from "./screens/ClientScreen";
import {
  registerScreen,
  slider,
  loginScreen,
  profileScreen,
  adminPanel,
  serviceScreen,
  productScreen,
  clientScreen,
  ecuScreen,
  tableTraslations,
  vehicleScreen,
  userScreen,
} from "./data/languajes";
import React, { useState, useEffect } from "react";
import { getProfileDetails } from "./redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { jwtVerify, decodeJwt } from "jose";
import axios from "axios";
import { MessageOutlined, SendOutlined } from "@ant-design/icons";
import { logoutUser } from "./redux/actions/userActions";
import JobScreen from "./screens/JobScreen";
import EcuScreen from "./screens/EcuScreen";
import Content from "./components/Content";
import notificationSound from "./data/not_sound.mp3";
import { apiURL, wsApiURL } from "./redux/store";
import { getJobs } from "./redux/actions/jobActions";
import { getServices } from "./redux/actions/serviceActions";
//import Content from "./components/Content";

const { Header, Content: ContentAnt, Sider } = Layout;

function App() {
  var host = window.location.host;
  let languageStoredInLocalStorage = localStorage.getItem("language");
  let themeInLocalStorage = localStorage.getItem("darkMode") === "true";
  const [language, setLanguage] = useState(
    languageStoredInLocalStorage ?? "es"
  );
  const [dataToken, setDataToken] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [darkMode, setDarkMode] = useState(themeInLocalStorage ?? false);

  const [socket_, setSocket_] = useState("");

  const [notReadMessages, setNotReadMessages] = useState(0);

  const [isUseCost, setIsUseCost] = useState(true);
  const [useUSD, setUseUSD] = useState(false);
  const [useARS, setUseARS] = useState(false);
  const [USDvalue, setUSDvalue] = useState(0);
  const [ARSvalue, setARSvalue] = useState(0);
  const [jobsId, setJobsId] = useState(-1);

  const [pendingJobs, setPendingJobs] = useState(0);
  const [isModalSesionOpen, setIsModalSesionOpen] = useState(false);

  const dispatch = useDispatch();

  const currencyList = useSelector((state) => state.currencyList);
  const { currencysList } = currencyList;

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const {
    servicesList,
    loading: loadingServices
  } = useSelector((state) => state.servicesList);

  const { jobsList, loading: loadingJobs } = useSelector(
    (state) => state.jobsList
  );

  useEffect(() => {
    const validateSession = async (token = "") => {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        await axios.get(`${apiURL}/api/users/profile/`, config);
        return true;
      } catch (error) {
        if (error.response.data.code == "token_not_valid") {
        }
        return false;
      }
    };

    async function verificarJWT(token) {
      return decodeJwt(token, { complete: true });
    }

    validateSession(userInfo?.access).then((e) => {
      if (e) {
        if (userInfo && !user) {
          dispatch(getProfileDetails());
          const token = userInfo.access;

          verificarJWT(token).then((e) => {
            if (!e) {
              dispatch(logoutUser());
            } else {
              setDataToken(e);
              dispatch(getServices())
            }
          });
        }
      } else {
        dispatch(logoutUser());
      }
    });

    if (!userInfo) setDataToken("no_logged");
  }, [userInfo, user, dispatch]);

  useEffect(() => {
    document.title = `Transyss`;
    if (dataToken?.role != 3) {
      if (notReadMessages > 0) document.title = `(${notReadMessages}) Transyss`;
    }
  }, [notReadMessages]);

  useEffect(() => {
    if (!socket_ && user) {
      const audio = new Audio(notificationSound);
      const fetchChatsData = async () => {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userInfo.access}`,
          },
        };

        const { data } = await axios.get(
          `${apiURL}/api/chats/`,
          config
        );

        let notRead = 0;
        console.log(data)
        data.forEach((e, index) => {
          if (!e.isRead & (e.last_message != null)) {
            notRead++;
          }
        });
        console.log(notRead)
        setNotReadMessages(notRead);
      };

      let socket = new WebSocket(wsApiURL + `/ws/chat/${userInfo?.access}`);
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data?.action === "message") {
          notification.info({
            icon: <MessageOutlined style={{ color: "#108ee9" }} />,
            message: data.username,
            description: data.message,
            placement: "bottomRight",
            duration: 1,
          });
          audio.play();
          fetchChatsData();
        }
      };
      setSocket_(socket);
      fetchChatsData();
    }
  }, [user]);

  useEffect(() => {
    currencysList?.results?.forEach((item) => {
      if (item.code?.toLowerCase() == "ars") {
        setARSvalue(item.rate ?? 0);
      }
      if (item.code?.toLowerCase() == "usd") {
        setUSDvalue(item.rate ?? 0);
      }
    });
  }, [currencysList]);

  useEffect(() => {
    dispatch(getJobs());
    const fetchData = () => {
      dispatch(getJobs());
      dispatch(getProfileDetails());
    };
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (dataToken?.role < 3 && dataToken) {
      let count = 0;
      let temp = [];
      let isNewJob = false;
      if (jobsList) {
        jobsList?.results?.forEach((item) => {
          if (item.state == "pending") count++;
          if (jobsId !== -1) {
            if (!jobsId.includes(item?.id) && !isNewJob) {
              isNewJob = true;
            }
          }
          temp.push(item?.id);
        });
        setJobsId(temp);
        setPendingJobs(count);
      }

      if (isNewJob) {
        notification.success({
          message: "Nuevo trabajo",
          description: `Ha ingresado un nuevo trabajo.`,
          placement: "bottomRight",
        });
        playNotificationSound();
      }
    }
  }, [jobsList]);

  useEffect(() => {
    let lastEventTime = new Date().valueOf();
    let diference = 0;
    document.addEventListener("click", (e) => {
      diference = new Date().valueOf() - lastEventTime;
      lastEventTime = new Date().valueOf();
      if (diference > 4 * 60 * 60 * 1000) { //hour - min - seg - mili
        // min - sec -
        if (userInfo?.access) {
          setIsModalSesionOpen(true);
          dispatch(logoutUser());
        }
      }
    });
  }, []);

  const handleCancelModalSesion = () => {
    setIsModalSesionOpen(false);
  };

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  function storeLanguageInLocalStorage(language) {
    localStorage.setItem("language", language);
  }

  function storeThemeInLocalStorage(darkMode) {
    localStorage.setItem("darkMode", darkMode);
  }

  const dropdownSearch = (input, option) => {
    return (option?.children ?? "")
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(
        input
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      );
  };

  const tableProps = {
    bordered: false,
    showSorterTooltip: false,
    rowKey: "id",
    scroll: { x: 500, y: 1000 },
    fixed: true /*
    //pagination: { pageSize: 20 },
    pagination: {
      
      showSizeChanger: true,
      showQuickJumper: true,
    },*/,
    pagination: {
      pageSizeOptions: ["15", "30", "50"],
      showSizeChanger: true,
      showQuickJumper: true,
      defaultPageSize: 15,
      size: "small",
      itemRender: (a, b, reactElement) => {
        return reactElement;
      },
      locale: {
        items_per_page: tableTraslations[language].items_per_page,
        jump_to: tableTraslations[language].jump_to,
        page: tableTraslations[language].page,
      },
    },
    size: "small",
    style: {
      background: darkMode ? "trasparent" : "",
      borderColor: "transparent",
      boxShadow: darkMode
        ? ""
        : "0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)",
      borderRadius: "9px",
    },
  };

  const selectProps = {
    showSearch: true,
    optionFilterProp: "children",
    filterOption: dropdownSearch,
  };

  return (
    <>
      <Router>
        <ConfigProvider
          theme={{
            algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
          }}
        >
          <Layout>
            <Headers
              pendingJobs={pendingJobs}
              notReadMessages={notReadMessages}
              content={slider[language]}
              userInfo={dataToken}
              language={language}
              darkMode={darkMode}
              setDarkMode={setDarkMode}
              storeThemeInLocalStorage={storeThemeInLocalStorage}
              handleSetLanguage={(language) => {
                setLanguage(language);
                storeLanguageInLocalStorage(language);
              }}
            />
            <Layout className="layout">
              {dataToken && dataToken !== "no_logged" ? (
                <Slider
                  setUseUSD={setUseUSD}
                  setUseARS={setUseARS}
                  //--
                  servicesList={servicesList}
                  //--
                  useUSD={useUSD}
                  useARS={useARS}
                  //--
                  setIsUseCost={setIsUseCost}
                  isUseCost={isUseCost}
                  //--
                  USDvalue={USDvalue}
                  ARSvalue={ARSvalue}
                  //--
                  setCollapsed={setCollapsed}
                  collapsed={collapsed}
                  className="slider"
                  userInfo={dataToken}
                  user={user}
                  handleSetLanguage={(language) => {
                    setLanguage(language);
                    storeLanguageInLocalStorage(language);
                  }}
                  content={slider[language]}
                  contentServices={serviceScreen[language]}
                  language={language}
                  //---
                  notReadMessages={notReadMessages}
                />
              ) : (
                ""
              )}
              <Layout
                style={{
                  marginLeft:
                    dataToken && dataToken !== "no_logged"
                      ? collapsed
                        ? "100px"
                        : "200px"
                      : "0px",
                  padding: "24px 24px 24px",
                  //background: darkMode ? "#222" : "",
                }}
              >
                <ContentAnt
                  style={{
                    background: darkMode ? "rgba(8,8,8,1)" : "white",
                    //color: darkMode ? "white" : "",
                    borderRadius: "6px",
                  }}
                >
                  <Content
                    setUseUSD={setUseUSD}
                    setUseARS={setUseARS}
                    //--
                    useUSD={useUSD}
                    useARS={useARS}
                    //--
                    setIsUseCost={setIsUseCost}
                    isUseCost={isUseCost}
                    //--
                    USDvalue={USDvalue}
                    ARSvalue={ARSvalue}
                    //--
                    darkMode={darkMode}
                    selectProps={selectProps}
                    dataToken={dataToken}
                    tableProps={tableProps}
                    language={language}
                    socket={socket_}
                    setNotReadMessages={setNotReadMessages}
                  ></Content>
                </ContentAnt>
                <Footer style={{ textAlign: "center" }} darkMode={darkMode} />
              </Layout>
            </Layout>
          </Layout>
          <Modal
            centered
            title="Cerramos tu sesión"
            cancelText={null}
            open={isModalSesionOpen}
            onOk={handleCancelModalSesion}
            onCancel={null} //handleCancelModalSesion}
          >
            <p>
              La sesión expira luego de unos minutos de inactividad. Accede
              nuevamente para seguir trabajando.
            </p>
          </Modal>
        </ConfigProvider>
      </Router>
    </>
  );
}

export default App;
