import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeScreen from "../screens/HomeScreen";
import RegisterScreen from "../screens/RegisterScreen";
import LoginScreen from "../screens/LoginScreen";
import ProfileScreen from "../screens/ProfileScreen";
import ClientScreen from "../screens/ClientScreen";
import VehicleScreen from "../screens/VehicleScreen";
import ProductScreen from "../screens/ProductScreen";
import ServiceScreen from "../screens/ServiceScreen";
import JobScreen from "../screens/JobScreen";
import EcuScreen from "../screens/EcuScreen";
import UserScreen from "../screens/UserScreen";
import NotFound404Screen from "../screens/NotFound404Screen";
import RecoveryPasswordEmailScreen from "../screens/RecoveryPasswordEmailScreen";
import RecoveryPasswordEnterPasswordScreen from "../screens/RecoveryPasswordEnterPasswordScreen";

import {
  registerScreen,
  slider,
  loginScreen,
  profileScreen,
  adminPanel,
  serviceScreen,
  productScreen,
  clientScreen,
  enterPasswordScreen,
  ecuScreen,
  enterEmailScreen,
  vehicleScreen,
  userScreen,
  jobScreen,
  homeScreen,
  chatScreen
} from "../data/languajes";
import { Tooltip } from "antd";
import ChatScreen from "../screens/ChatScreen";

function Content(props) {
  let language = props.language;
  let tableProps = props.tableProps;
  let dataToken = props.dataToken;
  let selectProps = props.selectProps;

  const renderTooltipInEllipsis = (text) => {
    return (
      <Tooltip placement="topLeft" title={text}>
        {text}
      </Tooltip>
    );
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <HomeScreen
            darkMode={props.darkMode}
            content={homeScreen[language]}
            tableProps={tableProps}
            dataToken={dataToken}
          />
        }
      />
      <Route
        path="/:slug/register"
        element={
          <RegisterScreen
            darkMode={props.darkMode}
            tableProps={tableProps}
            content={registerScreen[language]}
          />
        }
      />
      <Route
        path="/login"
        element={
          <LoginScreen
            darkMode={props.darkMode}
            tableProps={tableProps}
            content={loginScreen[language]}
          />
        }
      />
      <Route
        path="/profile"
        element={
          <ProfileScreen
            darkMode={props.darkMode}
            tableProps={tableProps}
            dataToken={dataToken}
            content={profileScreen[language]}
          />
        }
      />
      <Route
        path="/clients"
        element={
          <ClientScreen
            setUseUSD={props.setUseUSD}
            setUseARS={props.setUseARS}
            //--
            useUSD={props.useUSD}
            useARS={props.useARS}
            //--
            setIsUseCost={props.setIsUseCost}
            isUseCost={props.isUseCost}
            //--
            USDvalue={props.USDvalue}
            ARSvalue={props.ARSvalue}
            //--
            renderTooltipInEllipsis={renderTooltipInEllipsis}
            darkMode={props.darkMode}
            tableProps={tableProps}
            userInfo={dataToken}
            content={clientScreen[language]}
          />
        }
      />
      <Route
        path="/vehicles"
        element={
          <VehicleScreen
            renderTooltipInEllipsis={renderTooltipInEllipsis}
            tableProps={tableProps}
            userInfo={dataToken}
            content={vehicleScreen[language]}
            selectProps={selectProps}
          />
        }
      />
      <Route
        path="/products"
        element={
          <ProductScreen
            renderTooltipInEllipsis={renderTooltipInEllipsis}
            tableProps={tableProps}
            userInfo={dataToken}
            content={productScreen[language]}
          />
        }
      />
      <Route
        path="/services"
        element={
          <ServiceScreen
            setUseUSD={props.setUseUSD}
            setUseARS={props.setUseARS}
            //--
            useUSD={props.useUSD}
            useARS={props.useARS}
            //--
            setIsUseCost={props.setIsUseCost}
            isUseCost={props.isUseCost}
            //--
            USDvalue={props.USDvalue}
            ARSvalue={props.ARSvalue}
            //--
            renderTooltipInEllipsis={renderTooltipInEllipsis}
            tableProps={tableProps}
            userInfo={dataToken}
            content={serviceScreen[language]}
          />
        }
      />
      <Route
        path="/jobs"
        element={
          <JobScreen
            setUseUSD={props.setUseUSD}
            setUseARS={props.setUseARS}
            //--
            useUSD={props.useUSD}
            useARS={props.useARS}
            //--
            setIsUseCost={props.setIsUseCost}
            isUseCost={props.isUseCost}
            //--
            USDvalue={props.USDvalue}
            ARSvalue={props.ARSvalue}
            //--
            renderTooltipInEllipsis={renderTooltipInEllipsis}
            darkMode={props.darkMode}
            userInfo={dataToken}
            tableProps={tableProps}
            content={jobScreen[language]}
            selectProps={selectProps}
          />
        }
      />
      <Route
        path="/ecus"
        element={
          <EcuScreen
            renderTooltipInEllipsis={renderTooltipInEllipsis}
            userInfo={dataToken}
            tableProps={tableProps}
            content={ecuScreen[language]}
          />
        }
      />
      <Route
        path="/chat"
        element={
          <ChatScreen
            setNotReadMessages={props.setNotReadMessages}
            renderTooltipInEllipsis={renderTooltipInEllipsis}
            userInfo={dataToken}
            socket={props.socket}
            tableProps={tableProps}
            content={chatScreen[language]}
            darkMode={props.darkMode}
          />
        }
      />
      <Route
        path="/users"
        element={
          <UserScreen
            renderTooltipInEllipsis={renderTooltipInEllipsis}
            darkMode={props.darkMode}
            userInfo={dataToken}
            tableProps={tableProps}
            content={userScreen[language]}
          />
        }
      />
      <Route
        path="/enterEmail"
        element={
          <RecoveryPasswordEmailScreen
            userInfo={dataToken}
            tableProps={tableProps}
            content={enterEmailScreen[language]}
          />
        }
      />
      <Route
        path="/reset-password/*"
        element={
          <RecoveryPasswordEnterPasswordScreen
            userInfo={dataToken}
            tableProps={tableProps}
            content={enterPasswordScreen[language]}
          />
        }
      />
      <Route path="*" element={<NotFound404Screen />} />
    </Routes>
  );
}

export default Content;
