// Vehicle Model
export const VEHICLE_MODEL_REGISTER_REQUEST = "VEHICLE_MODEL_REGISTER_REQUEST";
export const VEHICLE_MODEL_REGISTER_SUCCESS = "VEHICLE_MODEL_REGISTER_SUCCESS";
export const VEHICLE_MODEL_REGISTER_FAIL = "VEHICLE_MODEL_REGISTER_FAIL";
export const VEHICLE_MODEL_REGISTER_RESET = "VEHICLE_MODEL_REGISTER_RESET";

export const VEHICLE_MODEL_DETAILS_REQUEST = "VEHICLE_MODEL_DETAILS_REQUEST";
export const VEHICLE_MODEL_DETAILS_SUCCESS = "VEHICLE_MODEL_DETAILS_SUCCESS";
export const VEHICLE_MODEL_DETAILS_FAIL = "VEHICLE_MODEL_DETAILS_FAIL";
export const VEHICLE_MODEL_DETAILS_RESET = "VEHICLE_MODEL_DETAILS_RESET";

export const VEHICLE_MODEL_UPDATE_REQUEST = "VEHICLE_MODEL_UPDATE_REQUEST";
export const VEHICLE_MODEL_UPDATE_SUCCESS = "VEHICLE_MODEL_UPDATE_SUCCESS";
export const VEHICLE_MODEL_UPDATE_FAIL = "VEHICLE_MODEL_UPDATE_FAIL";
export const VEHICLE_MODEL_UPDATE_RESET = "VEHICLE_MODEL_UPDATE_RESET";

export const VEHICLE_MODELS_LIST_REQUEST = "VEHICLE_MODELS_LIST_REQUEST";
export const VEHICLE_MODELS_LIST_SUCCESS = "VEHICLE_MODELS_LIST_SUCCESS";
export const VEHICLE_MODELS_LIST_FAIL = "VEHICLE_MODELS_LIST_FAIL";
export const VEHICLE_MODELS_LIST_RESET = "VEHICLE_MODELS_LIST_RESET";

export const VEHICLE_MODEL_DELETE_REQUEST = "VEHICLE_MODEL_DELETE_REQUEST";
export const VEHICLE_MODEL_DELETE_SUCCESS = "VEHICLE_MODEL_DELETE_SUCCESS";
export const VEHICLE_MODEL_DELETE_FAIL = "VEHICLE_MODEL_DELETE_FAIL";
export const VEHICLE_MODEL_DELETE_RESET = "VEHICLE_MODEL_DELETE_RESET";

// Vehicle Type
export const VEHICLE_TYPE_REGISTER_REQUEST = "VEHICLE_TYPE_REGISTER_REQUEST";
export const VEHICLE_TYPE_REGISTER_SUCCESS = "VEHICLE_TYPE_REGISTER_SUCCESS";
export const VEHICLE_TYPE_REGISTER_FAIL = "VEHICLE_TYPE_REGISTER_FAIL";
export const VEHICLE_TYPE_REGISTER_RESET = "VEHICLE_TYPE_REGISTER_RESET";

export const VEHICLE_TYPE_DETAILS_REQUEST = "VEHICLE_TYPE_DETAILS_REQUEST";
export const VEHICLE_TYPE_DETAILS_SUCCESS = "VEHICLE_TYPE_DETAILS_SUCCESS";
export const VEHICLE_TYPE_DETAILS_FAIL = "VEHICLE_TYPE_DETAILS_FAIL";
export const VEHICLE_TYPE_DETAILS_RESET = "VEHICLE_TYPE_DETAILS_RESET";

export const VEHICLE_TYPE_UPDATE_REQUEST = "VEHICLE_TYPE_UPDATE_REQUEST";
export const VEHICLE_TYPE_UPDATE_SUCCESS = "VEHICLE_TYPE_UPDATE_SUCCESS";
export const VEHICLE_TYPE_UPDATE_FAIL = "VEHICLE_TYPE_UPDATE_FAIL";
export const VEHICLE_TYPE_UPDATE_RESET = "VEHICLE_TYPE_UPDATE_RESET";

export const VEHICLE_TYPES_LIST_REQUEST = "VEHICLE_TYPES_LIST_REQUEST";
export const VEHICLE_TYPES_LIST_SUCCESS = "VEHICLE_TYPES_LIST_SUCCESS";
export const VEHICLE_TYPES_LIST_FAIL = "VEHICLE_TYPES_LIST_FAIL";
export const VEHICLE_TYPES_LIST_RESET = "VEHICLE_TYPES_LIST_RESET";

export const VEHICLE_TYPE_DELETE_REQUEST = "VEHICLE_TYPE_DELETE_REQUEST";
export const VEHICLE_TYPE_DELETE_SUCCESS = "VEHICLE_TYPE_DELETE_SUCCESS";
export const VEHICLE_TYPE_DELETE_FAIL = "VEHICLE_TYPE_DELETE_FAIL";
export const VEHICLE_TYPE_DELETE_RESET = "VEHICLE_TYPE_DELETE_RESET";

// Vehicle Motor
export const VEHICLE_MOTOR_REGISTER_REQUEST = "VEHICLE_MOTOR_REGISTER_REQUEST";
export const VEHICLE_MOTOR_REGISTER_SUCCESS = "VEHICLE_MOTOR_REGISTER_SUCCESS";
export const VEHICLE_MOTOR_REGISTER_FAIL = "VEHICLE_MOTOR_REGISTER_FAIL";
export const VEHICLE_MOTOR_REGISTER_RESET = "VEHICLE_MOTOR_REGISTER_RESET";

export const VEHICLE_MOTOR_DETAILS_REQUEST = "VEHICLE_MOTOR_DETAILS_REQUEST";
export const VEHICLE_MOTOR_DETAILS_SUCCESS = "VEHICLE_MOTOR_DETAILS_SUCCESS";
export const VEHICLE_MOTOR_DETAILS_FAIL = "VEHICLE_MOTOR_DETAILS_FAIL";
export const VEHICLE_MOTOR_DETAILS_RESET = "VEHICLE_MOTOR_DETAILS_RESET";

export const VEHICLE_MOTOR_UPDATE_REQUEST = "VEHICLE_MOTOR_UPDATE_REQUEST";
export const VEHICLE_MOTOR_UPDATE_SUCCESS = "VEHICLE_MOTOR_UPDATE_SUCCESS";
export const VEHICLE_MOTOR_UPDATE_FAIL = "VEHICLE_MOTOR_UPDATE_FAIL";
export const VEHICLE_MOTOR_UPDATE_RESET = "VEHICLE_MOTOR_UPDATE_RESET";

export const VEHICLE_MOTORS_LIST_REQUEST = "VEHICLE_MOTORS_LIST_REQUEST";
export const VEHICLE_MOTORS_LIST_SUCCESS = "VEHICLE_MOTORS_LIST_SUCCESS";
export const VEHICLE_MOTORS_LIST_FAIL = "VEHICLE_MOTORS_LIST_FAIL";
export const VEHICLE_MOTORS_LIST_RESET = "VEHICLE_MOTORS_LIST_RESET";

export const VEHICLE_MOTOR_DELETE_REQUEST = "VEHICLE_MOTOR_DELETE_REQUEST";
export const VEHICLE_MOTOR_DELETE_SUCCESS = "VEHICLE_MOTOR_DELETE_SUCCESS";
export const VEHICLE_MOTOR_DELETE_FAIL = "VEHICLE_MOTOR_DELETE_FAIL";
export const VEHICLE_MOTOR_DELETE_RESET = "VEHICLE_MOTOR_DELETE_RESET";

// Vehicle Brand
export const VEHICLE_BRAND_REGISTER_REQUEST = "VEHICLE_BRAND_REGISTER_REQUEST";
export const VEHICLE_BRAND_REGISTER_SUCCESS = "VEHICLE_BRAND_REGISTER_SUCCESS";
export const VEHICLE_BRAND_REGISTER_FAIL = "VEHICLE_BRAND_REGISTER_FAIL";
export const VEHICLE_BRAND_REGISTER_RESET = "VEHICLE_BRAND_REGISTER_RESET";

export const VEHICLE_BRAND_DETAILS_REQUEST = "VEHICLE_BRAND_DETAILS_REQUEST";
export const VEHICLE_BRAND_DETAILS_SUCCESS = "VEHICLE_BRAND_DETAILS_SUCCESS";
export const VEHICLE_BRAND_DETAILS_FAIL = "VEHICLE_BRAND_DETAILS_FAIL";
export const VEHICLE_BRAND_DETAILS_RESET = "VEHICLE_BRAND_DETAILS_RESET";

export const VEHICLE_BRAND_UPDATE_REQUEST = "VEHICLE_BRAND_UPDATE_REQUEST";
export const VEHICLE_BRAND_UPDATE_SUCCESS = "VEHICLE_BRAND_UPDATE_SUCCESS";
export const VEHICLE_BRAND_UPDATE_FAIL = "VEHICLE_BRAND_UPDATE_FAIL";
export const VEHICLE_BRAND_UPDATE_RESET = "VEHICLE_BRAND_UPDATE_RESET";

export const VEHICLE_BRANDS_LIST_REQUEST = "VEHICLE_BRANDS_LIST_REQUEST";
export const VEHICLE_BRANDS_LIST_SUCCESS = "VEHICLE_BRANDS_LIST_SUCCESS";
export const VEHICLE_BRANDS_LIST_FAIL = "VEHICLE_BRANDS_LIST_FAIL";
export const VEHICLE_BRANDS_LIST_RESET = "VEHICLE_BRANDS_LIST_RESET";

export const VEHICLE_BRAND_DELETE_REQUEST = "VEHICLE_BRAND_DELETE_REQUEST";
export const VEHICLE_BRAND_DELETE_SUCCESS = "VEHICLE_BRAND_DELETE_SUCCESS";
export const VEHICLE_BRAND_DELETE_FAIL = "VEHICLE_BRAND_DELETE_FAIL";
export const VEHICLE_BRAND_DELETE_RESET = "VEHICLE_BRAND_DELETE_RESET";
