import axios from "axios";
import {
  SERVICE_DETAILS_FAIL,
  SERVICE_DETAILS_REQUEST,
  SERVICE_DETAILS_RESET,
  SERVICE_DETAILS_SUCCESS,
  SERVICE_DELETE_FAIL,
  SERVICE_DELETE_REQUEST,
  SERVICE_DELETE_RESET,
  SERVICE_DELETE_SUCCESS,
  SERVICE_REGISTER_FAIL,
  SERVICE_REGISTER_REQUEST,
  SERVICE_REGISTER_SUCCESS,
  SERVICE_UPDATE_FAIL,
  SERVICE_UPDATE_REQUEST,
  SERVICE_UPDATE_RESET,
  SERVICE_UPDATE_SUCCESS,
  SERVICES_LIST_FAIL,
  SERVICES_LIST_REQUEST,
  SERVICES_LIST_RESET,
  SERVICES_LIST_SUCCESS,
} from "../constants/serviceConstants";

import { apiURL } from "../store";

export const getServiceDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICE_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`${apiURL}/api/services/${id}`, config);

    dispatch({
      type: SERVICE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteService = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(`${apiURL}/api/services/${id}`, config);

    dispatch({
      type: SERVICE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getServices = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICES_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/services/?all=true`,
      config
    );

    dispatch({
      type: SERVICES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERVICES_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateService = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/services/${id}`,
      body,
      config
    );

    dispatch({
      type: SERVICE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerService = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SERVICE_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`${apiURL}/api/services/`, body, config);

    dispatch({
      type: SERVICE_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
