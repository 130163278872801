import {
  CITIES_DETAILS_FAIL,
  CITIES_DETAILS_REQUEST,
  CITIES_DETAILS_RESET,
  CITIES_DETAILS_SUCCESS,
  COUNTRY_DETAILS_FAIL,
  COUNTRY_DETAILS_REQUEST,
  COUNTRY_DETAILS_RESET,
  COUNTRY_DETAILS_SUCCESS,
  STATE_DETAILS_FAIL,
  STATE_DETAILS_REQUEST,
  STATE_DETAILS_RESET,
  STATE_DETAILS_SUCCESS,
} from "../constants/locationConstants";

export const getCountriesReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_DETAILS_REQUEST:
      return { loading: true };

    case COUNTRY_DETAILS_SUCCESS:
      return { loading: false, countries: action.payload };

    case COUNTRY_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case COUNTRY_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const getStatesReducer = (state = {}, action) => {
  switch (action.type) {
    case STATE_DETAILS_REQUEST:
      return { loading: true };

    case STATE_DETAILS_SUCCESS:
      return { loading: false, states: action.payload };

    case STATE_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case STATE_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const getCitiesReducer = (state = {}, action) => {
  switch (action.type) {
    case CITIES_DETAILS_REQUEST:
      return { loading: true };

    case CITIES_DETAILS_SUCCESS:
      return { loading: false, cities: action.payload };

    case CITIES_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case CITIES_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};
