export const JOB_REGISTER_REQUEST = "JOB_REGISTER_REQUEST";
export const JOB_REGISTER_SUCCESS = "JOB_REGISTER_SUCCESS";
export const JOB_REGISTER_FAIL = "JOB_REGISTER_FAIL";
export const JOB_REGISTER_RESET = "JOB_REGISTER_RESET";

export const JOB_DETAILS_REQUEST = "JOB_DETAILS_REQUEST";
export const JOB_DETAILS_SUCCESS = "JOB_DETAILS_SUCCESS";
export const JOB_DETAILS_FAIL = "JOB_DETAILS_FAIL";
export const JOB_DETAILS_RESET = "JOB_DETAILS_RESET";

export const JOB_UPDATE_REQUEST = "JOB_UPDATE_REQUEST";
export const JOB_UPDATE_SUCCESS = "JOB_UPDATE_SUCCESS";
export const JOB_UPDATE_FAIL = "JOB_UPDATE_FAIL";
export const JOB_UPDATE_RESET = "JOB_UPDATE_RESET";

export const JOBS_LIST_REQUEST = "JOBS_LIST_REQUEST";
export const JOBS_LIST_SUCCESS = "JOBS_LIST_SUCCESS";
export const JOBS_LIST_FAIL = "JOBS_LIST_FAIL";
export const JOBS_LIST_RESET = "JOBS_LIST_RESET";

export const JOB_DELETE_REQUEST = "JOB_DELETE_REQUEST";
export const JOB_DELETE_SUCCESS = "JOB_DELETE_SUCCESS";
export const JOB_DELETE_FAIL = "JOB_DELETE_FAIL";
export const JOB_DELETE_RESET = "JOB_DELETE_RESET";

//-----

export const JOB_UPDATE_PENDING_REQUEST = "JOB_UPDATE_PENDING_REQUEST";
export const JOB_UPDATE_PENDING_SUCCESS = "JOB_UPDATE_PENDING_SUCCESS";
export const JOB_UPDATE_PENDING_FAIL = "JOB_UPDATE_PENDING_FAIL";
export const JOB_UPDATE_PENDING_RESET = "JOB_UPDATE_PENDING_RESET";

export const JOB_UPDATE_IN_PROCCESS_REQUEST = "JOB_UPDATE_IN_PROCCESS_REQUEST";
export const JOB_UPDATE_IN_PROCCESS_SUCCESS = "JOB_UPDATE_IN_PROCCESS_SUCCESS";
export const JOB_UPDATE_IN_PROCCESS_FAIL = "JOB_UPDATE_IN_PROCCESS_FAIL";
export const JOB_UPDATE_IN_PROCCESS_RESET = "JOB_UPDATE_IN_PROCCESS_RESET";

export const JOB_UPDATE_PAUSE_REQUEST = "JOB_UPDATE_PAUSE_REQUEST";
export const JOB_UPDATE_PAUSE_SUCCESS = "JOB_UPDATE_PAUSE_SUCCESS";
export const JOB_UPDATE_PAUSE_FAIL = "JOB_UPDATE_PAUSE_FAIL";
export const JOB_UPDATE_PAUSE_RESET = "JOB_UPDATE_PAUSE_RESET";

export const JOB_UPDATE_FINISHED_REQUEST = "JOB_UPDATE_FINISHED_REQUEST";
export const JOB_UPDATE_FINISHED_SUCCESS = "JOB_UPDATE_FINISHED_SUCCESS";
export const JOB_UPDATE_FINISHED_FAIL = "JOB_UPDATE_FINISHED_FAIL";
export const JOB_UPDATE_FINISHED_RESET = "JOB_UPDATE_FINISHED_RESET";

export const JOB_UPDATE_SEND_REQUEST = "JOB_UPDATE_SEND_REQUEST";
export const JOB_UPDATE_SEND_SUCCESS = "JOB_UPDATE_SEND_SUCCESS";
export const JOB_UPDATE_SEND_FAIL = "JOB_UPDATE_SEND_FAIL";
export const JOB_UPDATE_SEND_RESET = "JOB_UPDATE_SEND_RESET";