export const SERVICE_REGISTER_REQUEST = "SERVICE_REGISTER_REQUEST";
export const SERVICE_REGISTER_SUCCESS = "SERVICE_REGISTER_SUCCESS";
export const SERVICE_REGISTER_FAIL = "SERVICE_REGISTER_FAIL";
export const SERVICE_REGISTER_RESET = "SERVICE_REGISTER_RESET";

export const SERVICE_DETAILS_REQUEST = "SERVICE_DETAILS_REQUEST";
export const SERVICE_DETAILS_SUCCESS = "SERVICE_DETAILS_SUCCESS";
export const SERVICE_DETAILS_FAIL = "SERVICE_DETAILS_FAIL";
export const SERVICE_DETAILS_RESET = "SERVICE_DETAILS_RESET";

export const SERVICE_UPDATE_REQUEST = "SERVICE_UPDATE_REQUEST";
export const SERVICE_UPDATE_SUCCESS = "SERVICE_UPDATE_SUCCESS";
export const SERVICE_UPDATE_FAIL = "SERVICE_UPDATE_FAIL";
export const SERVICE_UPDATE_RESET = "SERVICE_UPDATE_RESET";

export const SERVICES_LIST_REQUEST = "SERVICES_LIST_REQUEST";
export const SERVICES_LIST_SUCCESS = "SERVICES_LIST_SUCCESS";
export const SERVICES_LIST_FAIL = "SERVICES_LIST_FAIL";
export const SERVICES_LIST_RESET = "SERVICES_LIST_RESET";

export const SERVICE_DELETE_REQUEST = "SERVICE_DELETE_REQUEST";
export const SERVICE_DELETE_SUCCESS = "SERVICE_DELETE_SUCCESS";
export const SERVICE_DELETE_FAIL = "SERVICE_DELETE_FAIL";
export const SERVICE_DELETE_RESET = "SERVICE_DELETE_RESET";
