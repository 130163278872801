import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Skeleton,
  Card,
  Col,
  Row,
  Button,
  Menu,
  Result,
  Grid,
  Typography,
  Dropdown,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  InboxOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
  FileOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  CloudUploadOutlined,
  ClockCircleOutlined,
  CloudDownloadOutlined,
  DesktopOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  ControlOutlined,
  LoginOutlined,
  HomeOutlined,
  LogoutOutlined,
  ToolOutlined,
  FlagOutlined,
  SettingOutlined,
  BarcodeOutlined,
  CarOutlined,
  CarFilled,
  UsbOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const { useBreakpoint } = Grid;

function HomeScreen(props) {
  const screens = useBreakpoint();
  const content = props.content;
  const dataToken = props.dataToken;
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const [hover, setHover] = useState(false);

  const navigate = useNavigate();

  const handleClickCard = (a) => {
    navigate(`/${a}`);
  };

  useEffect(() => {
    if (dataToken === "no_logged") {
      navigate("/login");
    }
  }, [navigate, dataToken]);

  const cols = {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 6,
    xl: 6,
    xxl: 6,
  };

  let cardData = [
    {
      title: content.job,
      key: "jobs",
      handleClick: () => handleClickCard("jobs"),
      icon: <ToolOutlined style={{ fontSize: "36px" }} />,
    },
    {
      title: content.profile,
      key: "profile",
      handleClick: () => handleClickCard("profile"),
      icon: <SettingOutlined style={{ fontSize: "36px" }} />,
    },
    {
      title: content.user,
      key: "Users",
      handleClick: () => handleClickCard("users"),
      icon: <UserOutlined style={{ fontSize: "36px" }} />,
    },
    {
      title: content.client,
      key: "Clients",
      handleClick: () => handleClickCard("clients"),
      icon: <TeamOutlined style={{ fontSize: "36px" }} />,
    },
    {
      title: content.service,
      key: "Services",
      handleClick: () => handleClickCard("services"),
      icon: <ShoppingCartOutlined style={{ fontSize: "36px" }} />,
    },
    {
      title: content.product,
      key: "Products",
      handleClick: () => handleClickCard("products"),
      icon: <BarcodeOutlined style={{ fontSize: "36px" }} />,
    },
    {
      title: content.vehicle,
      key: "Vehicles",
      handleClick: () => handleClickCard("vehicles"),
      icon: <CarFilled style={{ fontSize: "36px" }} />,
    },
    {
      title: "ECUs",
      key: "Ecus",
      handleClick: () => handleClickCard("ecus"),
      icon: <UsbOutlined style={{ fontSize: "36px" }} />,
    },
  ];

  const renderCard = ({ title, key, handleClick, icon }) => (
    <Col {...cols} key={key}>
      <Card
        {...cardStyle}
        className="cards"
        hoverable
        title={title}
        onClick={handleClick}
      >
        <p>
          <div>{icon}</div>
        </p>
      </Card>
    </Col>
  );

  let cardStyle = {
    style: { textAlign: "center", background: props.darkMode ? "" : "#f5f5f5" },
  };

  if (dataToken?.role === 3 || dataToken?.role === 2) {
    cardData = [
      {
        title: content.job,
        key: "jobs",
        handleClick: () => handleClickCard("jobs"),
        icon: <ToolOutlined style={{ fontSize: "36px" }} />,
      },
      {
        title: content.profile,
        key: "profile",
        handleClick: () => handleClickCard("profile"),
        icon: <SettingOutlined style={{ fontSize: "36px" }} />,
      },
    ];
  }
  if (dataToken === "no_logged" || !dataToken) return <></>;
  return (
    <div style={{ margin: "20px" }}>
      {/* <h1 style={{ margin: 0, padding: 0 }}>
        {content.title} {user?.first_name ?? ""} {user?.last_name ?? ""}
      </h1>
           <h1 className="title" style={{ margin: 0 }}>
        {content.title ?? "Loading..."}
      </h1>*/}
      <Title style={{ margin: 0, padding: 0 }}>
        {content.title} {user?.first_name ?? ""} {user?.last_name ?? ""}
      </Title>
      <Title level={3}>{content.subtitle}</Title>
      {/*
      <h3>{content.subtitle} </h3>
      */}
      <Row gutter={[16, 16]}>{cardData.map(renderCard)}</Row>
    </div>
  );
}

export default HomeScreen;
