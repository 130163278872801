import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGOUT,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USERS_LIST_FAIL,
  USERS_LIST_RESET,
  ACTIVATE_CLIENT_REQUEST,
  ACTIVATE_CLIENT_SUCCESS,
  ACTIVATE_CLIENT_FAIL,
  ACTIVATE_CLIENT_RESET,
  ADMIN_REGISTER_REQUEST,
  ADMIN_REGISTER_SUCCESS,
  ADMIN_REGISTER_FAIL,
  ADMIN_REGISTER_RESET,
  ADMIN_DETAILS_REQUEST,
  ADMIN_DETAILS_SUCCESS,
  ADMIN_DETAILS_FAIL,
  ADMIN_DETAILS_RESET,
  ADMIN_UPDATE_REQUEST,
  ADMIN_UPDATE_SUCCESS,
  ADMIN_UPDATE_FAIL,
  ADMIN_UPDATE_RESET,
  ADMINS_LIST_REQUEST,
  ADMINS_LIST_SUCCESS,
  ADMINS_LIST_FAIL,
  ADMINS_LIST_RESET,
  ADMIN_DELETE_REQUEST,
  ADMIN_DELETE_SUCCESS,
  ADMIN_DELETE_FAIL,
  ADMIN_DELETE_RESET,
  CLIENT_DELETE_RESET,
  CLIENT_REGISTER_RESET,
  CLIENT_UPDATE_RESET,
  WORKER_REGISTER_RESET,
  WORKER_UPDATE_RESET,
  WORKER_DETAILS_RESET,
  WORKER_DELETE_RESET,
  WORKERS_LIST_RESET,
  CLIENTS_LIST_RESET,
  USER_UPDATE_PROFILE_IMAGE_REQUEST,
  USER_UPDATE_PROFILE_IMAGE_SUCCESS,
  USER_UPDATE_PROFILE_IMAGE_FAIL,
  USER_UPDATE_PROFILE_IMAGE_RESET,
  USER_REQUEST_RECOVERY_PASSWORD_REQUEST,
  USER_REQUEST_RECOVERY_PASSWORD_SUCCESS,
  USER_REQUEST_RECOVERY_PASSWORD_FAIL,
  USER_REQUEST_RECOVERY_PASSWORD_RESET,
  USER_UPDATE_PASSWORD_RESET,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_FAIL,
  USER_REGISTER_RESET,
} from "../constants/userConstants";

import {
  CLIENTS_LIST_REQUEST,
  CLIENTS_LIST_SUCCESS,
  CLIENTS_LIST_FAIL,
  CLIENT_DELETE_REQUEST,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAIL,
  CLIENT_DETAILS_REQUEST,
  CLIENT_DETAILS_SUCCESS,
  CLIENT_DETAILS_FAIL,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_FAIL,
  CLIENT_REGISTER_REQUEST,
  CLIENT_REGISTER_SUCCESS,
  CLIENT_REGISTER_FAIL,
} from "../constants/userConstants";

import {
  WORKERS_LIST_REQUEST,
  WORKERS_LIST_SUCCESS,
  WORKERS_LIST_FAIL,
  WORKER_DELETE_REQUEST,
  WORKER_DELETE_SUCCESS,
  WORKER_DELETE_FAIL,
  WORKER_DETAILS_REQUEST,
  WORKER_DETAILS_SUCCESS,
  WORKER_DETAILS_FAIL,
  WORKER_UPDATE_REQUEST,
  WORKER_UPDATE_SUCCESS,
  WORKER_UPDATE_FAIL,
  WORKER_REGISTER_REQUEST,
  WORKER_REGISTER_SUCCESS,
  WORKER_REGISTER_FAIL,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };

    case USER_LOGIN_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userRequestRecoveryPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REQUEST_RECOVERY_PASSWORD_REQUEST:
      return { loading: true };

    case USER_REQUEST_RECOVERY_PASSWORD_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };

    case USER_REQUEST_RECOVERY_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    case USER_REQUEST_RECOVERY_PASSWORD_RESET:
      return {};
    default:
      return state;
  }
};

export const userUpdatePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PASSWORD_REQUEST:
      return { loading: true };

    case USER_UPDATE_PASSWORD_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };

    case USER_UPDATE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    case USER_UPDATE_PASSWORD_RESET:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };

    case USER_REGISTER_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };

    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case USER_REGISTER_RESET:
      return {};

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userUpdatePictureReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_IMAGE_REQUEST:
      return { loading: true };

    case USER_UPDATE_PROFILE_IMAGE_SUCCESS:
      return { loading: false, success: true, userImage: action.payload };

    case USER_UPDATE_PROFILE_IMAGE_FAIL:
      return { loading: false, error: action.payload };

    case USER_UPDATE_PROFILE_IMAGE_RESET:
      return {};

    default:
      return state;
  }
};

export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };

    case USER_DETAILS_SUCCESS:
      return { loading: false, success: true, user: action.payload };

    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case USER_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { ...state, loading: true };

    case USER_DELETE_SUCCESS:
      return { loading: false, success: true, user: action.payload };

    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case USER_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };

    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };

    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    case USER_UPDATE_PROFILE_RESET:
      return {};

    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };

    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true, user: action.payload };

    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case USER_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const usersListReducer = (state = {}, action) => {
  switch (action.type) {
    case USERS_LIST_REQUEST:
      return { loading: true };

    case USERS_LIST_SUCCESS:
      return { loading: false, success: true, userList: action.payload };

    case USERS_LIST_FAIL:
      return { loading: false, error: action.payload };

    case USERS_LIST_RESET:
      return {};

    default:
      return state;
  }
};

//Clients

export const getClientsReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENTS_LIST_REQUEST:
      return { loading: true };

    case CLIENTS_LIST_SUCCESS:
      return { loading: false, success: true, clientsList: action.payload };

    case CLIENTS_LIST_FAIL:
      return { loading: false, error: action.payload };

    case CLIENTS_LIST_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteClientReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_DELETE_REQUEST:
      return { loading: true };

    case CLIENT_DELETE_SUCCESS:
      return { loading: false, success: true, client: action.payload };

    case CLIENT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case CLIENT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const getClientDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_DETAILS_REQUEST:
      return { loading: true };

    case CLIENT_DETAILS_SUCCESS:
      return { loading: false, success: true, client: action.payload };

    case CLIENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateClientReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_UPDATE_REQUEST:
      return { loading: true };

    case CLIENT_UPDATE_SUCCESS:
      return { loading: false, success: true, client: action.payload };

    case CLIENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case CLIENT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const registerClientReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_REGISTER_REQUEST:
      return { loading: true };

    case CLIENT_REGISTER_SUCCESS:
      return { loading: false, success: true, client: action.payload };

    case CLIENT_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case CLIENT_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};

export const activateClientReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIVATE_CLIENT_REQUEST:
      return { loading: true };

    case ACTIVATE_CLIENT_SUCCESS:
      return { loading: false, success: true, activateClient: action.payload };

    case ACTIVATE_CLIENT_FAIL:
      return { loading: false, error: action.payload };

    case ACTIVATE_CLIENT_RESET:
      return {};

    default:
      return state;
  }
};

//workers

export const getWorkersReducer = (state = {}, action) => {
  switch (action.type) {
    case WORKERS_LIST_REQUEST:
      return { loading: true };

    case WORKERS_LIST_SUCCESS:
      return { loading: false, success: true, workersList: action.payload };

    case WORKERS_LIST_FAIL:
      return { loading: false, error: action.payload };

    case WORKERS_LIST_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteWorkerReducer = (state = {}, action) => {
  switch (action.type) {
    case WORKER_DELETE_REQUEST:
      return { loading: true };

    case WORKER_DELETE_SUCCESS:
      return { loading: false, success: true, worker: action.payload };

    case WORKER_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case WORKER_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

export const getWorkerDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case WORKER_DETAILS_REQUEST:
      return { loading: true };

    case WORKER_DETAILS_SUCCESS:
      return { loading: false, success: true, worker: action.payload };

    case WORKER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case WORKER_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const updateWorkerReducer = (state = {}, action) => {
  switch (action.type) {
    case WORKER_UPDATE_REQUEST:
      return { loading: true };

    case WORKER_UPDATE_SUCCESS:
      return { loading: false, success: true, worker: action.payload };

    case WORKER_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case WORKER_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const registerWorkerReducer = (state = {}, action) => {
  switch (action.type) {
    case WORKER_REGISTER_REQUEST:
      return { loading: true };

    case WORKER_REGISTER_SUCCESS:
      return { loading: false, success: true, worker: action.payload };

    case WORKER_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case WORKER_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};

//admin

export const getAdminsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMINS_LIST_REQUEST:
      return { loading: true };

    case ADMINS_LIST_SUCCESS:
      return { loading: false, success: true, adminsList: action.payload };

    case ADMINS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ADMINS_LIST_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_DELETE_REQUEST:
      return { loading: true };

    case ADMIN_DELETE_SUCCESS:
      return { loading: false, success: true, admin: action.payload };

    case ADMIN_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

export const getAdminDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_DETAILS_REQUEST:
      return { loading: true };

    case ADMIN_DETAILS_SUCCESS:
      return { loading: false, success: true, admin: action.payload };

    case ADMIN_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const updateAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_REQUEST:
      return { loading: true };

    case ADMIN_UPDATE_SUCCESS:
      return { loading: false, success: true, admin: action.payload };

    case ADMIN_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const registerAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_REGISTER_REQUEST:
      return { loading: true };

    case ADMIN_REGISTER_SUCCESS:
      return { loading: false, success: true, admin: action.payload };

    case ADMIN_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case ADMIN_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};
