export const PRODUCT_REGISTER_REQUEST = "PRODUCT_REGISTER_REQUEST";
export const PRODUCT_REGISTER_SUCCESS = "PRODUCT_REGISTER_SUCCESS";
export const PRODUCT_REGISTER_FAIL = "PRODUCT_REGISTER_FAIL";
export const PRODUCT_REGISTER_RESET = "PRODUCT_REGISTER_RESET";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";
export const PRODUCT_DETAILS_RESET = "PRODUCT_DETAILS_RESET";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const PRODUCTS_LIST_REQUEST = "PRODUCTS_LIST_REQUEST";
export const PRODUCTS_LIST_SUCCESS = "PRODUCTS_LIST_SUCCESS";
export const PRODUCTS_LIST_FAIL = "PRODUCTS_LIST_FAIL";
export const PRODUCTS_LIST_RESET = "PRODUCTS_LIST_RESET";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";
export const PRODUCT_DELETE_RESET = "PRODUCT_DELETE_RESET";

//-----------

export const SERIAL_NUMBER_REGISTER_REQUEST = "SERIAL_NUMBER_REGISTER_REQUEST";
export const SERIAL_NUMBER_REGISTER_SUCCESS = "SERIAL_NUMBER_REGISTER_SUCCESS";
export const SERIAL_NUMBER_REGISTER_FAIL = "SERIAL_NUMBER_REGISTER_FAIL";
export const SERIAL_NUMBER_REGISTER_RESET = "SERIAL_NUMBER_REGISTER_RESET";

export const SERIAL_NUMBER_DETAILS_REQUEST = "SERIAL_NUMBER_DETAILS_REQUEST";
export const SERIAL_NUMBER_DETAILS_SUCCESS = "SERIAL_NUMBER_DETAILS_SUCCESS";
export const SERIAL_NUMBER_DETAILS_FAIL = "SERIAL_NUMBER_DETAILS_FAIL";
export const SERIAL_NUMBER_DETAILS_RESET = "SERIAL_NUMBER_DETAILS_RESET";

export const SERIAL_NUMBER_UPDATE_REQUEST = "SERIAL_NUMBER_UPDATE_REQUEST";
export const SERIAL_NUMBER_UPDATE_SUCCESS = "SERIAL_NUMBER_UPDATE_SUCCESS";
export const SERIAL_NUMBER_UPDATE_FAIL = "SERIAL_NUMBER_UPDATE_FAIL";
export const SERIAL_NUMBER_UPDATE_RESET = "SERIAL_NUMBER_UPDATE_RESET";

export const SERIAL_NUMBERS_LIST_REQUEST = "SERIAL_NUMBERS_LIST_REQUEST";
export const SERIAL_NUMBERS_LIST_SUCCESS = "SERIAL_NUMBERS_LIST_SUCCESS";
export const SERIAL_NUMBERS_LIST_FAIL = "SERIAL_NUMBERS_LIST_FAIL";
export const SERIAL_NUMBERS_LIST_RESET = "SERIAL_NUMBERS_LIST_RESET";

export const SERIAL_NUMBER_DELETE_REQUEST = "SERIAL_NUMBER_DELETE_REQUEST";
export const SERIAL_NUMBER_DELETE_SUCCESS = "SERIAL_NUMBER_DELETE_SUCCESS";
export const SERIAL_NUMBER_DELETE_FAIL = "SERIAL_NUMBER_DELETE_FAIL";
export const SERIAL_NUMBER_DELETE_RESET = "SERIAL_NUMBER_DELETE_RESET";
