import axios from "axios";
import {
  ECU_BRAND_DETAILS_FAIL,
  ECU_BRAND_DETAILS_REQUEST,
  ECU_BRAND_DETAILS_RESET,
  ECU_BRAND_DETAILS_SUCCESS,
  ECU_BRAND_REGISTER_FAIL,
  ECU_BRAND_REGISTER_REQUEST,
  ECU_BRAND_REGISTER_SUCCESS,
  ECU_BRAND_UPDATE_FAIL,
  ECU_BRAND_UPDATE_REQUEST,
  ECU_BRAND_UPDATE_RESET,
  ECU_BRAND_UPDATE_SUCCESS,
  ECUS_BRAND_LIST_FAIL,
  ECUS_BRAND_LIST_REQUEST,
  ECUS_BRAND_LIST_RESET,
  ECUS_BRAND_LIST_SUCCESS,
  ECU_BRAND_DELETE_FAIL,
  ECU_BRAND_DELETE_REQUEST,
  ECU_BRAND_DELETE_RESET,
  ECU_BRAND_DELETE_SUCCESS,
  ECU_MODEL_REGISTER_REQUEST,
  ECU_MODEL_REGISTER_SUCCESS,
  ECU_MODEL_REGISTER_FAIL,
  ECU_MODEL_DETAILS_REQUEST,
  ECU_MODEL_DETAILS_SUCCESS,
  ECU_MODEL_DETAILS_FAIL,
  ECU_MODEL_DETAILS_RESET,
  ECU_MODEL_UPDATE_REQUEST,
  ECU_MODEL_UPDATE_SUCCESS,
  ECU_MODEL_UPDATE_FAIL,
  ECU_MODEL_UPDATE_RESET,
  ECUS_MODEL_LIST_REQUEST,
  ECUS_MODEL_LIST_SUCCESS,
  ECUS_MODEL_LIST_FAIL,
  ECUS_MODEL_LIST_RESET,
  ECU_MODEL_DELETE_REQUEST,
  ECU_MODEL_DELETE_SUCCESS,
  ECU_MODEL_DELETE_FAIL,
  ECU_MODEL_DELETE_RESET,
} from "../constants/ecuConstants";

import { apiURL } from "../store";

//ECU BRANDS
export const getEcuBrandDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ECU_BRAND_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`${apiURL}/api/ecu-brands/${id}`, config);

    dispatch({
      type: ECU_BRAND_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ECU_BRAND_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getEcuBrands = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ECUS_BRAND_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/ecu-brands/?all=true`,
      config
    );

    dispatch({
      type: ECUS_BRAND_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ECUS_BRAND_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateEcuBrand = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ECU_BRAND_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/ecu-brands/${id}`,
      body,
      config
    );

    dispatch({
      type: ECU_BRAND_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ECU_BRAND_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerEcuBrands = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ECU_BRAND_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(
      `${apiURL}/api/ecu-brands/`,
      body,
      config
    );

    dispatch({
      type: ECU_BRAND_REGISTER_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ECU_BRAND_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteEcuBrand = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ECU_BRAND_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(
      `${apiURL}/api/ecu-brands/${id}`,
      config
    );

    dispatch({
      type: ECU_BRAND_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ECU_BRAND_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

//ECU MODELS

export const getEcuModelDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ECU_MODEL_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`${apiURL}/api/ecu-brands/${id}`, config);

    dispatch({
      type: ECU_MODEL_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ECU_MODEL_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getEcuModels = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ECUS_MODEL_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/ecu-models/?all=true`,
      config
    );

    dispatch({
      type: ECUS_MODEL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ECUS_MODEL_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateEcuModels = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ECU_MODEL_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/ecu-models/${id}`,
      body,
      config
    );

    dispatch({
      type: ECU_MODEL_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ECU_MODEL_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerEcuModel = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ECU_MODEL_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(
      `${apiURL}/api/ecu-models/`,
      body,
      config
    );

    dispatch({
      type: ECU_MODEL_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ECU_MODEL_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteEcuModel = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ECU_MODEL_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(
      `${apiURL}/api/ecu-models/${id}`,
      config
    );

    dispatch({
      type: ECU_MODEL_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ECU_MODEL_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
