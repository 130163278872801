import {
  ECU_BRAND_DETAILS_FAIL,
  ECU_BRAND_DETAILS_REQUEST,
  ECU_BRAND_DETAILS_RESET,
  ECU_BRAND_DETAILS_SUCCESS,
  ECU_BRAND_REGISTER_FAIL,
  ECU_BRAND_REGISTER_REQUEST,
  ECU_BRAND_REGISTER_SUCCESS,
  ECU_BRAND_REGISTER_RESET,
  ECU_BRAND_UPDATE_FAIL,
  ECU_BRAND_UPDATE_REQUEST,
  ECU_BRAND_UPDATE_RESET,
  ECU_BRAND_UPDATE_SUCCESS,
  ECUS_BRAND_LIST_FAIL,
  ECUS_BRAND_LIST_REQUEST,
  ECUS_BRAND_LIST_RESET,
  ECUS_BRAND_LIST_SUCCESS,
  ECU_BRAND_DELETE_FAIL,
  ECU_BRAND_DELETE_REQUEST,
  ECU_BRAND_DELETE_RESET,
  ECU_BRAND_DELETE_SUCCESS,
  ECU_MODEL_REGISTER_REQUEST,
  ECU_MODEL_REGISTER_SUCCESS,
  ECU_MODEL_REGISTER_FAIL,
  ECU_MODEL_REGISTER_RESET,
  ECU_MODEL_DETAILS_REQUEST,
  ECU_MODEL_DETAILS_SUCCESS,
  ECU_MODEL_DETAILS_FAIL,
  ECU_MODEL_DETAILS_RESET,
  ECU_MODEL_UPDATE_REQUEST,
  ECU_MODEL_UPDATE_SUCCESS,
  ECU_MODEL_UPDATE_FAIL,
  ECU_MODEL_UPDATE_RESET,
  ECUS_MODEL_LIST_REQUEST,
  ECUS_MODEL_LIST_SUCCESS,
  ECUS_MODEL_LIST_FAIL,
  ECUS_MODEL_LIST_RESET,
  ECU_MODEL_DELETE_REQUEST,
  ECU_MODEL_DELETE_SUCCESS,
  ECU_MODEL_DELETE_FAIL,
  ECU_MODEL_DELETE_RESET,
} from "../constants/ecuConstants";

//ecu brand
export const getEcusBrandReducer = (state = {}, action) => {
  switch (action.type) {
    case ECUS_BRAND_LIST_REQUEST:
      return { loading: true };

    case ECUS_BRAND_LIST_SUCCESS:
      return { loading: false, ecusBrandList: action.payload };

    case ECUS_BRAND_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getEcuBrandDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ECU_BRAND_DETAILS_REQUEST:
      return { loading: true };

    case ECU_BRAND_DETAILS_SUCCESS:
      return { loading: false, success: true, ecuBrand: action.payload };

    case ECU_BRAND_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateEcuBrandReducer = (state = {}, action) => {
  switch (action.type) {
    case ECU_BRAND_UPDATE_REQUEST:
      return { loading: true };

    case ECU_BRAND_UPDATE_SUCCESS:
      return { loading: false, success: true, ecuBrand: action.payload };

    case ECU_BRAND_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case ECU_BRAND_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const registerEcuBrandReducer = (state = {}, action) => {
  switch (action.type) {
    case ECU_BRAND_REGISTER_REQUEST:
      return { loading: true };

    case ECU_BRAND_REGISTER_SUCCESS:
      return { loading: false, success: true, ecuBrand: action.payload };

    case ECU_BRAND_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case ECU_BRAND_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteEcuBrandReducer = (state = {}, action) => {
  switch (action.type) {
    case ECU_BRAND_DELETE_REQUEST:
      return { loading: true };

    case ECU_BRAND_DELETE_SUCCESS:
      return { loading: false, success: true, ecuBrand: 1 }; // action.payload };

    case ECU_BRAND_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case ECU_BRAND_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

//ecu models
export const getEcusModelReducer = (state = {}, action) => {
  switch (action.type) {
    case ECUS_MODEL_LIST_REQUEST:
      return { loading: true };

    case ECUS_MODEL_LIST_SUCCESS:
      return { loading: false, ecusModelList: action.payload };

    case ECUS_MODEL_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getEcuModelDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ECU_MODEL_DETAILS_REQUEST:
      return { loading: true };

    case ECU_MODEL_DETAILS_SUCCESS:
      return { loading: false, ecuModel: action.payload };

    case ECU_MODEL_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateEcuModelReducer = (state = {}, action) => {
  switch (action.type) {
    case ECU_MODEL_UPDATE_REQUEST:
      return { loading: true };

    case ECU_MODEL_UPDATE_SUCCESS:
      return { loading: false, success: true, ecuModel: action.payload };

    case ECU_MODEL_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case ECU_MODEL_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const registerEcuModelReducer = (state = {}, action) => {
  switch (action.type) {
    case ECU_MODEL_REGISTER_REQUEST:
      return { loading: true };

    case ECU_MODEL_REGISTER_SUCCESS:
      return { loading: false, success: true, ecuModel: action.payload };

    case ECU_MODEL_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case ECU_MODEL_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteEcuModelReducer = (state = {}, action) => {
  switch (action.type) {
    case ECU_MODEL_DELETE_REQUEST:
      return { loading: true };

    case ECU_MODEL_DELETE_SUCCESS:
      return { loading: false, success: true, ecuModel: 1 }; //action.payload };

    case ECU_MODEL_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case ECU_MODEL_DELETE_RESET:
      return {};

    default:
      return state;
  }
};
