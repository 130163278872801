import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Breadcrumb,
  Layout,
  Menu,
  Modal,
  Typography,
  theme,
  Row,
  Col,
  Dropdown,
  Button,
  Tag,
  Card,
  Form,
  InputNumber,
  Avatar,
  Badge,
  Space,
  Alert,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  ControlOutlined,
  LoginOutlined,
  HomeOutlined,
  LogoutOutlined,
  MessageOutlined,
  ToolOutlined,
  BellOutlined,
  FlagOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { logoutUser } from "../redux/actions/userActions";
import LanguageSwitcher from "./LanguajeSwitcher";
import DarkModeToggle from "../components/darkMode/darkModeToggle";
import {
  getCurrencys,
  registerCurrency,
  updateCurrency,
} from "../redux/actions/currencyAction";
import { apiURL } from "../redux/store";
import TLogo from "../data/logo/TLogo.svg";

const { Header: HeaderLayout, Content, Footer } = Layout;

const { Title } = Typography;

function Header(props) {
  const userToken = props.userInfo;
  const language = props.language;
  const content = props.content;

  const [isModalCostOpen, setIsModalCostOpen] = useState(false);
  const [form] = Form.useForm();
  const [usdRate, setUsdRate] = useState(0);
  const [arsRate, setArsRate] = useState(0);

  const isNotLogged = userToken === "no_logged" || !userToken;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const currencyList = useSelector((state) => state.currencyList);
  const { currencysList } = currencyList;

  const currencyUpdate = useSelector((state) => state.currencyUpdate);
  const { success: successCurrencyUpdate } = currencyUpdate;

  const currencyCreate = useSelector((state) => state.currencyCreate);
  const { success: successCurrencyCreate } = currencyCreate;

  const showModalCost = () => {
    setIsModalCostOpen(true);
  };

  const handleOkCost = () => {
    setIsModalCostOpen(false);
  };

  const handleCancelCost = () => {
    setIsModalCostOpen(false);
  };

  const onSubmit = (record) => {
    if (currencysList?.results?.length > 0) {
      currencysList?.results.forEach((item) => {
        if (item.code == "ars") {
          let arsId = item.id;
          let ars = {
            rate: record.ars,
          };
          dispatch(updateCurrency(arsId, ars));
        }
        if (item.code == "usd") {
          let usdId = item.id;
          let usd = {
            rate: record.usd,
          };
          dispatch(updateCurrency(usdId, usd));
        }
      });
    } else {
      Object.entries(record).forEach(([key, val]) => {
        let temp = {
          name: key,
          code: key,
          rate: val,
        };
        dispatch(registerCurrency(temp));
      });
    }
  };

  let items = [];

  if (userToken !== "no_logged") {
    items = [
      ...items,
      {
        key: "info",
        icon: (
          <Avatar
            style={{
              position: "relative",
              top: "8px",
              right: "-6px",
              background: "white",
            }}
            //size={30}
            src={
              (window.location.hostname == "localhost" ? apiURL : "") +
              user?.profile_picture ?? "" // "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            } //"https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
          />
        ),
        children: [
          {
            label: <Link to="/profile">{content.profile}</Link>,
          },
          {
            label: <Link to="/">{content.logout}</Link>,
            onClick: function () {
              dispatch(logoutUser());
            },
          },
        ],
      },
    ];
  } else {
    items = [
      ...items,
      /*
      {
        label: <Link to="/register">{content.register}</Link>,
        key: "register",
      },
      */
      {
        label: <Link to="/login">{content.login}</Link>,
        key: "login",
      },
    ];
  }

  useEffect(() => {
    dispatch(getCurrencys());
  }, []);

  useEffect(() => {
    let ars = 0;
    let usd = 0;
    currencysList?.results.forEach((item) => {
      if (item.code == "ars") {
        ars = item.rate;
      }
      if (item.code == "usd") {
        usd = item.rate;
      }
    });
    setUsdRate(usd);
    setArsRate(ars);
  }, [currencysList]);

  useEffect(() => {
    if (successCurrencyUpdate || successCurrencyCreate)
      dispatch(getCurrencys());
    handleCancelCost();
  }, [successCurrencyUpdate, successCurrencyCreate]);

  const menu = (
    <Menu>
      <Menu.Item key="opcion1">
        <Link to="/jobs">
          <Alert
            message={`Tienes ${props.pendingJobs} trabajo/s pendiente/s`}
            type="info"
          />
        </Link>
      </Menu.Item>
    </Menu>
  );

  const notLoggedUser = () => {
    return (
      <Row align="middle" justify="center">
        <Col
          //span={19}
          xs={4} //listo
          sm={5} //listo
          md={12} //listo
          lg={14} //listo
          xl={15}
          xxl={18}
        >
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Avatar
              src={
                TLogo
              }
              style={{ cursor: "pointer" }}
            />

            <Title
              style={{
                margin: 0,
                color: "white",
                marginLeft: "12px",
                marginTop: "1px",
              }}
              level={4}
            >
              Transyss
            </Title>
          </div>
        </Col>
        <Col xs={3} sm={5} md={3} lg={2} xl={2} xxl={2}>
          <Menu
            selectable={false}
            theme="dark"
            mode="horizontal"
            items={items}
            style={{
              width: "300px",
            }}
          >
            {items.map((item) => (
              <Menu.Item key={item.key} icon={item.icon} style={item.style}>
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
        </Col>
        <Col
          xs={7}
          sm={6}
          md={3}
          lg={4}
          xl={3}
          xxl={2}
        //span={2}
        >
          <DarkModeToggle
            setDarkMode={props.setDarkMode}
            darkMode={props.darkMode}
            storeThemeInLocalStorage={props.storeThemeInLocalStorage}
          />
        </Col>
        <Col xs={5} sm={4} md={3} lg={2} xl={2} xxl={1}>
          <LanguageSwitcher
            handleSetLanguage={props.handleSetLanguage}
            languaje={language}
          />
        </Col>
      </Row>
    );
  };
  const loggedHeader = () => {
    return (
      <Row align="middle" justify="center">
        <Col
          //span={19}
          xs={1}
          sm={5}
          md={9}
          lg={12}
          xl={9}
          xxl={16}
        >
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Avatar
              src={
                TLogo
              }
              style={{ cursor: "pointer" }}
            />

            <Title
              style={{
                margin: 0,
                color: "white",
                marginLeft: "12px",
                marginTop: "1px",
              }}
              level={4}
            >
              Transyss
            </Title>
          </div>
        </Col>

        <Col
          //span={19}
          xs={1}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          xxl={1}
        >
          {user?.role < 3 ? (
            <Dropdown
              overlay={menu}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
            >
              <Badge size="small" count={props.pendingJobs}>
                <BellOutlined
                  style={{ color: "white", fontSize: "22px" }}
                /* onClick={() => {
                navigate("/jobs");
              }}*/
                />
              </Badge>
            </Dropdown>
          ) : (
            ""
          )}
        </Col>
        <Col xs={5} sm={4} md={3} lg={2} xl={2} xxl={1}>
          <Badge count={props.notReadMessages} /*offset={[0, 6]}*/ size="small">
            {/*
            <Button
            shape="circle"
            size="large"
              icon={<MessageOutlined />}
              onClick={() => {
                navigate("/chat");
              }}
            ></Button>*/}
            <MessageOutlined
              style={{ color: "white", fontSize: "22px" }}
              onClick={() => {
                navigate("/chat");
              }}
            ></MessageOutlined>
          </Badge>
        </Col>

        <Col
          //span={1}
          xs={5}
          sm={3}
          md={3}
          lg={2}
          xl={4}
          xxl={2}
        >
          <Tag
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (user?.role === 1) {
                let ars = 0;
                let usd = 0;

                currencysList?.results.forEach((item) => {
                  if (item.code == "ars") {
                    ars = item.rate;
                  }
                  if (item.code == "usd") {
                    usd = item.rate;
                  }
                });

                let temp = {
                  ars,
                  usd,
                };

                form.setFieldsValue(temp);
                showModalCost(true);
              }
            }}
            bordered={false}
            color={
              user?.balance > 0
                ? "success"
                : user?.balance === 0
                  ? "warning"
                  : user?.balance < 0
                    ? "error"
                    : "success"
            }
          >
            {user?.role === 3
              ? content?.credit + " " + (user?.balance ?? 0)
              : user?.role === 1 || user?.role === 2
                ? `US$: ${usdRate} - AR$: ${arsRate}`
                : ""}
          </Tag>
        </Col>
        <Col
          //span={1}
          xs={4}
          sm={4}
          md={3}
          lg={2}
          xl={3}
          xxl={1}
        >
          <LanguageSwitcher
            handleSetLanguage={props.handleSetLanguage}
            languaje={language}
          />
        </Col>
        <Col
          xs={7}
          sm={6}
          md={4}
          lg={4}
          xl={3}
          xxl={2}
        //span={2}
        >
          <DarkModeToggle
            setDarkMode={props.setDarkMode}
            darkMode={props.darkMode}
            storeThemeInLocalStorage={props.storeThemeInLocalStorage}
          />
        </Col>
        <Col
          //span={1}
          xs={5}
          sm={4}
          md={3}
          lg={2}
          xl={2}
          xxl={1}
        >
          <Menu
            items={items}
            selectable={false}
            theme="dark"
            mode="horizontal"
          ></Menu>
        </Col>
      </Row>
    );
  };
  return (
    <>
      <HeaderLayout
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          //  width: "100%",
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "flex-end",
        }}
      >
        {isNotLogged ? notLoggedUser() : loggedHeader()}
      </HeaderLayout>
      <Modal
        footer={null}
        title={content?.equ_modal?.title}
        open={isModalCostOpen}
        // onOk={handleOkCost}
        width={350}
        onCancel={handleCancelCost}
      >
        <Form
          form={form}
          // name="customized_form_controls"
          //layout="vertical"
          labelCol={{ span: 8 }}
          onFinish={onSubmit}
        >
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              name="usd"
              style={{ display: "inline-block" }}
              label={content?.equ_modal?.dolar}
              rules={[{ required: true }]}
            >
              <InputNumber min={0} />
            </Form.Item>
            <span
              className="ant-form-text"
              style={{ marginLeft: 8, marginTop: "5px" }}
            >
              US$
            </span>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              name="ars"
              style={{ display: "inline-block" }}
              label={content?.equ_modal?.ars}
              rules={[{ required: true }]}
            >
              <InputNumber min={0} />
            </Form.Item>
            <span
              className="ant-form-text"
              style={{ marginLeft: 8, marginTop: "5px" }}
            >
              AR$
            </span>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ float: "right" }}>
              {content?.equ_modal?.button}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Header;
