import React, { useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

import { Button, Form, Input, notification, Typography, Col, Row, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { loginUser } from "../redux/actions/userActions";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import logoTransyssBlack from "../data/logo/logoTransyssBlack.png";
import logoTransyssWhite from "../data/logo/logoTransyssWhite.png";

const LoginScreen = (props) => {
  const content = props.content;
  const darkMode = props.darkMode

  const { Text, Title } = Typography;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  const logoTransyss = darkMode ? logoTransyssWhite : logoTransyssBlack

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
    if (error) {
      notification.error({
        message: content?.notification?.create?.error?.title,
        description: content?.notification?.create?.error?.description,
        placement: "bottomRight",
      });
    }
  }, [navigate, userInfo, redirect, error]);

  const submitHandler = (e) => {
    dispatch(loginUser(e.email, e.password));
  };

  return (
    <div className="centerForm" style={{ height: "80vh" }}>
      {!userInfo && (
        <>
          <Row gutter={48} justify="center">
            <Col align="center" span={12} md={12}> {/* <Col xs={2} sm={4} md={6} lg={8} xl={10}> */}
              <Image
                src={logoTransyss}
                preview={false}
              />
              <Title level={3} align="center" >{content?.imageSubtitle ?? "Loading..."}</Title>
            </Col>
            <Col align="center" span={24} md={8}>
              <Form
                name="normal_login"
                className="login-form"
                style={{ width: "66%" }}
                onFinish={submitHandler}
              >

                <Title level={1}>{content?.greeting ?? "Loading..."}</Title>
                <Title level={5}>{content?.title ?? "Loading..."}</Title>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: content?.form?.email.placeholder },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder={content?.form?.email?.title ?? "Loading..."}
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: content?.form?.password.placeholder },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder={content?.form?.password?.title ?? "Loading..."}
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    {content?.button ?? "Loading..."}
                  </Button>
                  {/*
            <Link to="/register" style={{ float: "left", marginTop: "13px" }}>
              {content?.subtitle ?? "Loading..."}
            </Link>
            */}
                  <Link
                    to="/enterEmail"
                    style={{ float: "left", marginTop: "13px" }}
                  >
                    {content?.recovery ?? "Loading..."}
                  </Link>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default LoginScreen;
