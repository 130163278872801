import React, { useEffect, useState } from "react";
import { Form, Modal, DatePicker, Button } from "antd";

import esES from "antd/lib/locale/es_ES";
import dayjs from "dayjs";

import { ReloadOutlined } from "@ant-design/icons";

function Filters(props) {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const disabledDate = (current) => {
    return current && current < dayjs(from).startOf("day");
  };

  return (
    <div style={{ margin: "20px" }}>
      <Modal
        title="Filtros"
        footer={[
          <>
            <Button
              key="submit"
              type="secondary"
              icon={<ReloadOutlined />}
              onClick={() => {
                props.setFromTo([undefined, undefined]);
                setFrom();
                setTo();
                // props.setModalFilterOpen(false)
              }}
            ></Button>
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                props.setFromTo([from, to]);
                props.setModalFilterOpen(false);
              }}
            >
              Aplicar
            </Button>
          </>,
        ]}
        width={350}
        open={props.modalFilterOpen}
        onCancel={() => props.setModalFilterOpen(false)}
        centered
      >
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          style={{ maxWidth: 700 }}
        >
          <Form.Item label="Fecha desde">
            <DatePicker
              style={{ width: "200px" }}
              allowClear
              value={from}
              placeholder=""
              locale={{
                lang: {
                  locale: "es",
                  shortMonths: [
                    "Ene",
                    "Feb",
                    "Mar",
                    "Abr",
                    "May",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dic",
                  ],
                  shortWeekDays: [
                    "Dom",
                    "Lun",
                    "Mar",
                    "Mie",
                    "Jue",
                    "Vie",
                    "Sab",
                  ],
                  yearFormat: "YYYY",
                  monthBeforeYear: true,
                },
              }}
              showToday={false}
              //value={props.fromTo[0]}
              onChange={(e) => {
                if (to) {
                  if (dayjs(e).startOf("day") > dayjs(to).startOf("day"))
                    setTo(e);
                }
                // props.setFromTo([e, props.fromTo[1]]);
                setFrom(e);
              }}
            />
          </Form.Item>
          <Form.Item label="Fecha hasta">
            <DatePicker
              value={to}
              disabledDate={disabledDate}
              allowClear
              placeholder=""
              locale={{
                lang: {
                  locale: "es",
                  shortMonths: [
                    "Ene",
                    "Feb",
                    "Mar",
                    "Abr",
                    "May",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dic",
                  ],
                  shortWeekDays: [
                    "Dom",
                    "Lun",
                    "Mar",
                    "Mie",
                    "Jue",
                    "Vie",
                    "Sab",
                  ],
                  yearFormat: "YYYY",
                  monthBeforeYear: true,
                },
              }}
              showToday={false}
              //value={props.fromTo[1]}
              style={{ width: "200px" }}
              onChange={(e) => {
                //props.setFromTo([props.fromTo[0], e]);
                setTo(e);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Filters;
