import React from "react";
import { Layout } from "antd";
const { Footer: FooterLayout } = Layout;

function Footer(props) {
  const d = new Date();
  const year = d.getFullYear();
  return (
    <FooterLayout
      style={{
        textAlign: "center",
        // background: props.darkMode ? "#222" : "",
        //color: props.darkMode ? "#fff" : "",
      }}
    >
      Copyright &copy; Transyss {year}
    </FooterLayout>
  );
}

export default Footer;
