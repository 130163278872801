export const ADDRESS_REGISTER_REQUEST = "ADDRESS_REGISTER_REQUEST";
export const ADDRESS_REGISTER_SUCCESS = "ADDRESS_REGISTER_SUCCESS";
export const ADDRESS_REGISTER_FAIL = "ADDRESS_REGISTER_FAIL";
export const ADDRESS_REGISTER_RESET = "ADDRESS_REGISTER_RESET";

export const ADDRESS_DETAILS_REQUEST = "ADDRESS_DETAILS_REQUEST";
export const ADDRESS_DETAILS_SUCCESS = "ADDRESS_DETAILS_SUCCESS";
export const ADDRESS_DETAILS_FAIL = "ADDRESS_DETAILS_FAIL";
export const ADDRESS_DETAILS_RESET = "ADDRESS_DETAILS_RESET";

export const ADDRESS_UPDATE_REQUEST = "ADDRESS_UPDATE_REQUEST";
export const ADDRESS_UPDATE_SUCCESS = "ADDRESS_UPDATE_SUCCESS";
export const ADDRESS_UPDATE_FAIL = "ADDRESS_UPDATE_FAIL";
export const ADDRESS_UPDATE_RESET = "ADDRESS_UPDATE_RESET";

export const ADDRESSES_LIST_REQUEST = "ADDRESSES_LIST_REQUEST";
export const ADDRESSES_LIST_SUCCESS = "ADDRESSES_LIST_SUCCESS";
export const ADDRESSES_LIST_FAIL = "ADDRESSES_LIST_FAIL";
export const ADDRESSES_LIST_RESET = "ADDRESSES_LIST_RESET";

export const ADDRESS_DELETE_REQUEST = "ADDRESS_DELETE_REQUEST";
export const ADDRESS_DELETE_SUCCESS = "ADDRESS_DELETE_SUCCESS";
export const ADDRESS_DELETE_FAIL = "ADDRESS_DELETE_FAIL";
export const ADDRESS_DELETE_RESET = "ADDRESS_DELETE_RESET";
