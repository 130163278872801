import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Modal,
  Descriptions,
} from "antd";
import { userInformationModal } from "../data/languajes";

function UserInformationModal(props) {
  let languageStoredInLocalStorage = localStorage.getItem("language") ?? "es";

  // console.log(props, languageStoredInLocalStorage)
  let content = userInformationModal[languageStoredInLocalStorage]

  let fieldsMap = {
    email: content?.email, //"Email",
    first_name: content?.fName, //"Nombre",
    company_name: content?.companyName, //"Nombre de la compania",
    balance: content?.balance, //"Balance",
    description: content?.description, //"Descripcion",
    // role: "Rol",
    dni: content?.DNI, //"D.N.I.",
    username: content?.username, //"Nombre de usuario",
    last_name: content?.lName, //"Apellido",
    cell_phone: content?.cellphone, //"Celular",
    address_name: content?.address, //"Direccion",
    city: content?.city, //"Ciudad",
    zip_code: content?.zipCode, //"Zip code",
    province: content?.state, //"Provincia",
    country: content?.country, //"Pais",
  };

  let orderFieldsMap = [
    "first_name",
    "country",
    "last_name",
    "province",
    "dni",
    "city",
    "username",
    "address_name",
    "email",
    "company_name",

    "cell_phone",
    "balance",
  ]

  let data = props.selectedUser;

  data = {
    ...data,
    ...data.address_?.[0] ?? [],
  };

  if(!data?.address_name){
    if(data?.street_name){
      data.address_name = data.street_name
      if(data?.house_number){
        data.address_name += " "
        data.address_name += " " + data.house_number
      }
    }
  }

  return ( 
    <div>
      <Modal
        title={props.title}
        open={props.isUserInformationModalEdit}
        footer={null}
        width={1600}
        onCancel={() => props.setIsUserInformationModalEdit(false)}
      >
        <Descriptions bordered column={2}>
          {orderFieldsMap.map((key) => {
            let item = data[key]    
            if (typeof item != "object" && fieldsMap[key] && item){
              return (
                <Descriptions.Item label={fieldsMap[key]}>
                  {item}
                </Descriptions.Item>
              );
            } else if ((typeof item != "object" && fieldsMap[key] ) || !item){
              return (
                <Descriptions.Item label={fieldsMap[key]}>&nbsp;</Descriptions.Item>
              );
            }
          
          })}
        </Descriptions>
      </Modal>
    </div>
  );
}

export default UserInformationModal;
