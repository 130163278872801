import axios from "axios";
import {
  ADDRESS_REGISTER_REQUEST,
  ADDRESS_REGISTER_SUCCESS,
  ADDRESS_REGISTER_FAIL,
  ADDRESS_REGISTER_RESET,
  ADDRESS_DETAILS_REQUEST,
  ADDRESS_DETAILS_SUCCESS,
  ADDRESS_DETAILS_FAIL,
  ADDRESS_DETAILS_RESET,
  ADDRESS_UPDATE_REQUEST,
  ADDRESS_UPDATE_SUCCESS,
  ADDRESS_UPDATE_FAIL,
  ADDRESS_UPDATE_RESET,
  ADDRESSES_LIST_REQUEST,
  ADDRESSES_LIST_SUCCESS,
  ADDRESSES_LIST_FAIL,
  ADDRESSES_LIST_RESET,
  ADDRESS_DELETE_REQUEST,
  ADDRESS_DELETE_SUCCESS,
  ADDRESS_DELETE_FAIL,
  ADDRESS_DELETE_RESET,
} from "../constants/addressConstants";

import { apiURL } from "../store";

export const getAddressDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADDRESS_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`${apiURL}/api/addresses/${id}`, config);

    dispatch({
      type: ADDRESS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADDRESS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteAddress = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADDRESS_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(
      `${apiURL}/api/addresses/${id}`,
      config
    );

    dispatch({
      type: ADDRESS_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADDRESS_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getAddresses = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADDRESSES_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/addresses/?all=true`,
      config
    );

    dispatch({
      type: ADDRESSES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADDRESSES_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateAddress = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADDRESS_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/address/${id}`,
      body,
      config
    );

    dispatch({
      type: ADDRESS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADDRESS_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerAddress = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADDRESS_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`${apiURL}/api/address/`, body, config);

    dispatch({
      type: ADDRESS_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADDRESS_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
