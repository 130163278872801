import {
  VEHICLE_MODEL_REGISTER_REQUEST,
  VEHICLE_MODEL_REGISTER_SUCCESS,
  VEHICLE_MODEL_REGISTER_FAIL,
  VEHICLE_MODEL_REGISTER_RESET,
  VEHICLE_MODEL_DETAILS_REQUEST,
  VEHICLE_MODEL_DETAILS_SUCCESS,
  VEHICLE_MODEL_DETAILS_FAIL,
  VEHICLE_MODEL_DETAILS_RESET,
  VEHICLE_MODEL_DELETE_REQUEST,
  VEHICLE_MODEL_DELETE_SUCCESS,
  VEHICLE_MODEL_DELETE_FAIL,
  VEHICLE_MODEL_DELETE_RESET,
  VEHICLE_MODEL_UPDATE_REQUEST,
  VEHICLE_MODEL_UPDATE_SUCCESS,
  VEHICLE_MODEL_UPDATE_FAIL,
  VEHICLE_MODEL_UPDATE_RESET,
  VEHICLE_MODELS_LIST_REQUEST,
  VEHICLE_MODELS_LIST_SUCCESS,
  VEHICLE_MODELS_LIST_FAIL,
  VEHICLE_MODELS_LIST_RESET,
  VEHICLE_TYPE_REGISTER_REQUEST,
  VEHICLE_TYPE_REGISTER_SUCCESS,
  VEHICLE_TYPE_REGISTER_FAIL,
  VEHICLE_TYPE_REGISTER_RESET,
  VEHICLE_TYPE_DETAILS_REQUEST,
  VEHICLE_TYPE_DETAILS_SUCCESS,
  VEHICLE_TYPE_DETAILS_FAIL,
  VEHICLE_TYPE_DETAILS_RESET,
  VEHICLE_TYPE_DELETE_REQUEST,
  VEHICLE_TYPE_DELETE_SUCCESS,
  VEHICLE_TYPE_DELETE_FAIL,
  VEHICLE_TYPE_DELETE_RESET,
  VEHICLE_TYPE_UPDATE_REQUEST,
  VEHICLE_TYPE_UPDATE_SUCCESS,
  VEHICLE_TYPE_UPDATE_FAIL,
  VEHICLE_TYPE_UPDATE_RESET,
  VEHICLE_TYPES_LIST_REQUEST,
  VEHICLE_TYPES_LIST_SUCCESS,
  VEHICLE_TYPES_LIST_FAIL,
  VEHICLE_TYPES_LIST_RESET,
  VEHICLE_MOTOR_REGISTER_REQUEST,
  VEHICLE_MOTOR_REGISTER_SUCCESS,
  VEHICLE_MOTOR_REGISTER_FAIL,
  VEHICLE_MOTOR_REGISTER_RESET,
  VEHICLE_MOTOR_DETAILS_REQUEST,
  VEHICLE_MOTOR_DETAILS_SUCCESS,
  VEHICLE_MOTOR_DETAILS_FAIL,
  VEHICLE_MOTOR_DETAILS_RESET,
  VEHICLE_MOTOR_DELETE_REQUEST,
  VEHICLE_MOTOR_DELETE_SUCCESS,
  VEHICLE_MOTOR_DELETE_FAIL,
  VEHICLE_MOTOR_DELETE_RESET,
  VEHICLE_MOTOR_UPDATE_REQUEST,
  VEHICLE_MOTOR_UPDATE_SUCCESS,
  VEHICLE_MOTOR_UPDATE_FAIL,
  VEHICLE_MOTOR_UPDATE_RESET,
  VEHICLE_MOTORS_LIST_REQUEST,
  VEHICLE_MOTORS_LIST_SUCCESS,
  VEHICLE_MOTORS_LIST_FAIL,
  VEHICLE_MOTORS_LIST_RESET,
  VEHICLE_BRAND_REGISTER_REQUEST,
  VEHICLE_BRAND_REGISTER_SUCCESS,
  VEHICLE_BRAND_REGISTER_FAIL,
  VEHICLE_BRAND_REGISTER_RESET,
  VEHICLE_BRAND_DETAILS_REQUEST,
  VEHICLE_BRAND_DETAILS_SUCCESS,
  VEHICLE_BRAND_DETAILS_FAIL,
  VEHICLE_BRAND_DETAILS_RESET,
  VEHICLE_BRAND_DELETE_REQUEST,
  VEHICLE_BRAND_DELETE_SUCCESS,
  VEHICLE_BRAND_DELETE_FAIL,
  VEHICLE_BRAND_DELETE_RESET,
  VEHICLE_BRAND_UPDATE_REQUEST,
  VEHICLE_BRAND_UPDATE_SUCCESS,
  VEHICLE_BRAND_UPDATE_FAIL,
  VEHICLE_BRAND_UPDATE_RESET,
  VEHICLE_BRANDS_LIST_REQUEST,
  VEHICLE_BRANDS_LIST_SUCCESS,
  VEHICLE_BRANDS_LIST_FAIL,
  VEHICLE_BRANDS_LIST_RESET,
} from "../constants/vehicleConstants";

//Model

export const getVehicleModelsReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_MODELS_LIST_REQUEST:
      return { loading: true };

    case VEHICLE_MODELS_LIST_SUCCESS:
      return { loading: false, vehicleModelsList: action.payload };

    case VEHICLE_MODELS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const deleteVehicleModelReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_MODEL_DELETE_REQUEST:
      return { loading: true };

    case VEHICLE_MODEL_DELETE_SUCCESS:
      return { loading: false, success: true, vehicleModel: action.payload };

    case VEHICLE_MODEL_DELETE_RESET:
      return {};

    case VEHICLE_MODEL_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getVehicleModelDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_MODEL_DETAILS_REQUEST:
      return { loading: true };

    case VEHICLE_MODEL_DETAILS_SUCCESS:
      return { loading: false, vehicleModel: action.payload };

    case VEHICLE_MODEL_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateVehicleModelReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_MODEL_UPDATE_REQUEST:
      return { loading: true };

    case VEHICLE_MODEL_UPDATE_SUCCESS:
      return { loading: false, success: true, vehicleModel: action.payload };

    case VEHICLE_MODEL_UPDATE_RESET:
      return {};

    case VEHICLE_MODEL_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const registerVehicleModelReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_MODEL_REGISTER_REQUEST:
      return { loading: true };

    case VEHICLE_MODEL_REGISTER_SUCCESS:
      return { loading: false, success: true, vehicleModel: action.payload };

    case VEHICLE_MODEL_REGISTER_RESET:
      return {};

    case VEHICLE_MODEL_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

//Type

export const getVehicleTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_TYPES_LIST_REQUEST:
      return { loading: true };

    case VEHICLE_TYPES_LIST_SUCCESS:
      return { loading: false, vehicleTypesList: action.payload };

    case VEHICLE_TYPES_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getVehicleTypeDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_TYPE_DETAILS_REQUEST:
      return { loading: true };

    case VEHICLE_TYPE_DETAILS_SUCCESS:
      return { loading: false, vehicleType: action.payload };

    case VEHICLE_TYPE_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const deleteVehicleTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_TYPE_DELETE_REQUEST:
      return { loading: true };

    case VEHICLE_TYPE_DELETE_SUCCESS:
      return { loading: false, success: true, vehicleType: 1 }; //action.payload };

    case VEHICLE_TYPE_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case VEHICLE_TYPE_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

export const updateVehicleTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_TYPE_UPDATE_REQUEST:
      return { loading: true };

    case VEHICLE_TYPE_UPDATE_SUCCESS:
      return { loading: false, success: true, vehicleType: action.payload };

    case VEHICLE_TYPE_UPDATE_RESET:
      return {};

    case VEHICLE_TYPE_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const registerVehicleTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_TYPE_REGISTER_REQUEST:
      return { loading: true };

    case VEHICLE_TYPE_REGISTER_SUCCESS:
      return { loading: false, success: true, vehicleType: action.payload };

    case VEHICLE_TYPE_REGISTER_RESET:
      return {};

    case VEHICLE_TYPE_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

//motor

export const getVehicleMotorsReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_MOTORS_LIST_REQUEST:
      return { loading: true };

    case VEHICLE_MOTORS_LIST_SUCCESS:
      return { loading: false, vehicleMotorsList: action.payload };

    case VEHICLE_MOTORS_LIST_FAIL:
      return { loading: false, error: action.payload };

    case VEHICLE_MOTORS_LIST_RESET:
      return {};

    default:
      return state;
  }
};

export const getVehicleMotorDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_MOTOR_DETAILS_REQUEST:
      return { loading: true };

    case VEHICLE_MOTOR_DETAILS_SUCCESS:
      return { loading: false, vehicleMotor: action.payload };

    case VEHICLE_MOTOR_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case VEHICLE_MOTOR_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteVehicleMotorReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_MOTOR_DELETE_REQUEST:
      return { loading: true };

    case VEHICLE_MOTOR_DELETE_SUCCESS:
      return { loading: false, success: true, vehicleMotor: 1 }; //action.payload };

    case VEHICLE_MOTOR_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case VEHICLE_MOTOR_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

export const updateVehicleMotorReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_MOTOR_UPDATE_REQUEST:
      return { loading: true };

    case VEHICLE_MOTOR_UPDATE_SUCCESS:
      return { loading: false, success: true, vehicleMotor: action.payload };

    case VEHICLE_MOTOR_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case VEHICLE_MOTOR_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const registerVehicleMotorReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_MOTOR_REGISTER_REQUEST:
      return { loading: true };

    case VEHICLE_MOTOR_REGISTER_SUCCESS:
      return { loading: false, success: true, vehicleMotor: action.payload };

    case VEHICLE_MOTOR_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case VEHICLE_MOTOR_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};

//Brands

export const getVehicleBrandsReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_BRANDS_LIST_REQUEST:
      return { loading: true };

    case VEHICLE_BRANDS_LIST_SUCCESS:
      return { loading: false, vehicleBrandsList: action.payload };

    case VEHICLE_BRANDS_LIST_FAIL:
      return { loading: false, error: action.payload };

    case VEHICLE_BRANDS_LIST_RESET:
      return {};

    default:
      return state;
  }
};

export const getVehicleBrandDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_BRAND_DETAILS_REQUEST:
      return { loading: true };

    case VEHICLE_BRAND_DETAILS_SUCCESS:
      return { loading: false, vehicleBrand: action.payload };

    case VEHICLE_BRAND_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case VEHICLE_BRAND_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteVehicleBrandReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_BRAND_DELETE_REQUEST:
      return { loading: true };

    case VEHICLE_BRAND_DELETE_SUCCESS:
      return { loading: false, success: true, vehicleBrand: 1 }; //action.payload };

    case VEHICLE_BRAND_DELETE_FAIL:
      return { loading: false, error: action.payload };

    case VEHICLE_BRAND_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

export const updateVehicleBrandReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_BRAND_UPDATE_REQUEST:
      return { loading: true };

    case VEHICLE_BRAND_UPDATE_SUCCESS:
      return { loading: false, success: true, vehicleBrand: action.payload };

    case VEHICLE_BRAND_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case VEHICLE_BRAND_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const registerVehicleBrandReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_BRAND_REGISTER_REQUEST:
      return { loading: true };

    case VEHICLE_BRAND_REGISTER_SUCCESS:
      return { loading: false, success: true, vehicleBrand: action.payload };

    case VEHICLE_BRAND_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case VEHICLE_BRAND_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};
