export const ACCOUNT_MOVEMENT_REGISTER_REQUEST =
  "ACCOUNT_MOVEMENT_REGISTER_REQUEST";
export const ACCOUNT_MOVEMENT_REGISTER_SUCCESS =
  "ACCOUNT_MOVEMENT_REGISTER_SUCCESS";
export const ACCOUNT_MOVEMENT_REGISTER_FAIL = "ACCOUNT_MOVEMENT_REGISTER_FAIL";
export const ACCOUNT_MOVEMENT_REGISTER_RESET =
  "ACCOUNT_MOVEMENT_REGISTER_RESET";

export const ACCOUNT_MOVEMENT_DETAILS_REQUEST =
  "ACCOUNT_MOVEMENT_DETAILS_REQUEST";
export const ACCOUNT_MOVEMENT_DETAILS_SUCCESS =
  "ACCOUNT_MOVEMENT_DETAILS_SUCCESS";
export const ACCOUNT_MOVEMENT_DETAILS_FAIL = "ACCOUNT_MOVEMENT_DETAILS_FAIL";
export const ACCOUNT_MOVEMENT_DETAILS_RESET = "ACCOUNT_MOVEMENT_DETAILS_RESET";

export const ACCOUNT_MOVEMENT_UPDATE_REQUEST =
  "ACCOUNT_MOVEMENT_UPDATE_REQUEST";
export const ACCOUNT_MOVEMENT_UPDATE_SUCCESS =
  "ACCOUNT_MOVEMENT_UPDATE_SUCCESS";
export const ACCOUNT_MOVEMENT_UPDATE_FAIL = "ACCOUNT_MOVEMENT_UPDATE_FAIL";
export const ACCOUNT_MOVEMENT_UPDATE_RESET = "ACCOUNT_MOVEMENT_UPDATE_RESET";

export const ACCOUNT_MOVEMENTS_LIST_REQUEST = "ACCOUNT_MOVEMENTS_LIST_REQUEST";
export const ACCOUNT_MOVEMENTS_LIST_SUCCESS = "ACCOUNT_MOVEMENTS_LIST_SUCCESS";
export const ACCOUNT_MOVEMENTS_LIST_FAIL = "ACCOUNT_MOVEMENTS_LIST_FAIL";
export const ACCOUNT_MOVEMENTS_LIST_RESET = "ACCOUNT_MOVEMENTS_LIST_RESET";

export const ACCOUNT_MOVEMENT_DELETE_REQUEST =
  "ACCOUNT_MOVEMENT_DELETE_REQUEST";
export const ACCOUNT_MOVEMENT_DELETE_SUCCESS =
  "ACCOUNT_MOVEMENT_DELETE_SUCCESS";
export const ACCOUNT_MOVEMENT_DELETE_FAIL = "ACCOUNT_MOVEMENT_DELETE_FAIL";
export const ACCOUNT_MOVEMENT_DELETE_RESET = "ACCOUNT_MOVEMENT_DELETE_RESET";
