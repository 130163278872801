import axios from "axios";
import {
  VEHICLE_MODEL_REGISTER_REQUEST,
  VEHICLE_MODEL_REGISTER_SUCCESS,
  VEHICLE_MODEL_REGISTER_FAIL,
  VEHICLE_MODEL_DETAILS_REQUEST,
  VEHICLE_MODEL_DETAILS_SUCCESS,
  VEHICLE_MODEL_DETAILS_FAIL,
  VEHICLE_MODEL_DETAILS_RESET,
  VEHICLE_MODEL_DELETE_REQUEST,
  VEHICLE_MODEL_DELETE_SUCCESS,
  VEHICLE_MODEL_DELETE_FAIL,
  VEHICLE_MODEL_DELETE_RESET,
  VEHICLE_MODEL_UPDATE_REQUEST,
  VEHICLE_MODEL_UPDATE_SUCCESS,
  VEHICLE_MODEL_UPDATE_FAIL,
  VEHICLE_MODEL_UPDATE_RESET,
  VEHICLE_MODELS_LIST_REQUEST,
  VEHICLE_MODELS_LIST_SUCCESS,
  VEHICLE_MODELS_LIST_FAIL,
  VEHICLE_MODELS_LIST_RESET,
  VEHICLE_TYPE_REGISTER_REQUEST,
  VEHICLE_TYPE_REGISTER_SUCCESS,
  VEHICLE_TYPE_REGISTER_FAIL,
  VEHICLE_TYPE_DETAILS_REQUEST,
  VEHICLE_TYPE_DETAILS_SUCCESS,
  VEHICLE_TYPE_DETAILS_FAIL,
  VEHICLE_TYPE_DETAILS_RESET,
  VEHICLE_TYPE_DELETE_REQUEST,
  VEHICLE_TYPE_DELETE_SUCCESS,
  VEHICLE_TYPE_DELETE_FAIL,
  VEHICLE_TYPE_DELETE_RESET,
  VEHICLE_TYPE_UPDATE_REQUEST,
  VEHICLE_TYPE_UPDATE_SUCCESS,
  VEHICLE_TYPE_UPDATE_FAIL,
  VEHICLE_TYPE_UPDATE_RESET,
  VEHICLE_TYPES_LIST_REQUEST,
  VEHICLE_TYPES_LIST_SUCCESS,
  VEHICLE_TYPES_LIST_FAIL,
  VEHICLE_TYPES_LIST_RESET,
  VEHICLE_MOTOR_REGISTER_REQUEST,
  VEHICLE_MOTOR_REGISTER_SUCCESS,
  VEHICLE_MOTOR_REGISTER_FAIL,
  VEHICLE_MOTOR_DETAILS_REQUEST,
  VEHICLE_MOTOR_DETAILS_SUCCESS,
  VEHICLE_MOTOR_DETAILS_FAIL,
  VEHICLE_MOTOR_DETAILS_RESET,
  VEHICLE_MOTOR_DELETE_REQUEST,
  VEHICLE_MOTOR_DELETE_SUCCESS,
  VEHICLE_MOTOR_DELETE_FAIL,
  VEHICLE_MOTOR_DELETE_RESET,
  VEHICLE_MOTOR_UPDATE_REQUEST,
  VEHICLE_MOTOR_UPDATE_SUCCESS,
  VEHICLE_MOTOR_UPDATE_FAIL,
  VEHICLE_MOTOR_UPDATE_RESET,
  VEHICLE_MOTORS_LIST_REQUEST,
  VEHICLE_MOTORS_LIST_SUCCESS,
  VEHICLE_MOTORS_LIST_FAIL,
  VEHICLE_MOTORS_LIST_RESET,
  VEHICLE_BRAND_REGISTER_REQUEST,
  VEHICLE_BRAND_REGISTER_SUCCESS,
  VEHICLE_BRAND_REGISTER_FAIL,
  VEHICLE_BRAND_DETAILS_REQUEST,
  VEHICLE_BRAND_DETAILS_SUCCESS,
  VEHICLE_BRAND_DETAILS_FAIL,
  VEHICLE_BRAND_DETAILS_RESET,
  VEHICLE_BRAND_DELETE_REQUEST,
  VEHICLE_BRAND_DELETE_SUCCESS,
  VEHICLE_BRAND_DELETE_FAIL,
  VEHICLE_BRAND_DELETE_RESET,
  VEHICLE_BRAND_UPDATE_REQUEST,
  VEHICLE_BRAND_UPDATE_SUCCESS,
  VEHICLE_BRAND_UPDATE_FAIL,
  VEHICLE_BRAND_UPDATE_RESET,
  VEHICLE_BRANDS_LIST_REQUEST,
  VEHICLE_BRANDS_LIST_SUCCESS,
  VEHICLE_BRANDS_LIST_FAIL,
  VEHICLE_BRANDS_LIST_RESET,
} from "../constants/vehicleConstants";

import { apiURL } from "../store";

//Vehicle type

export const getVehicleTypeDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_TYPE_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/vehicle-types/${id}`,
      config
    );

    dispatch({
      type: VEHICLE_TYPE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_TYPE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteVehicleType = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_TYPE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(
      `${apiURL}/api/vehicle-types/${id}`,
      config
    );

    dispatch({
      type: VEHICLE_TYPE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_TYPE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getVehicleTypes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_TYPES_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/vehicle-types/?all=true`,
      config
    );

    dispatch({
      type: VEHICLE_TYPES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_TYPES_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateVehicleType = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_TYPE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/vehicle-types/${id}`,
      body,
      config
    );

    dispatch({
      type: VEHICLE_TYPE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_TYPE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerVehicleType = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_TYPE_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(
      `${apiURL}/api/vehicle-types/`,
      body,
      config
    );

    dispatch({
      type: VEHICLE_TYPE_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_TYPE_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

//Vehicle motor

export const getVehicleMotorDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_MOTOR_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`${apiURL}/api/motors/${id}`, config);

    dispatch({
      type: VEHICLE_MOTOR_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_MOTOR_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const deleteVehicleMotor = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_MOTOR_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(`${apiURL}/api/motors/${id}`, config);

    dispatch({
      type: VEHICLE_MOTOR_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_MOTOR_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getVehicleMotors = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_MOTORS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`${apiURL}/api/motors/?all=true`, config);

    dispatch({
      type: VEHICLE_MOTORS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_MOTORS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateVehicleMotor = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_MOTOR_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/motors/${id}`,
      body,
      config
    );

    dispatch({
      type: VEHICLE_MOTOR_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_MOTOR_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerVehicleMotor = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_MOTOR_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`${apiURL}/api/motors/`, body, config);

    dispatch({
      type: VEHICLE_MOTOR_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_MOTOR_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

//vehicle model

export const getVehicleModelDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_MODEL_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/vehicle-models/${id}`,
      config
    );

    dispatch({
      type: VEHICLE_MODEL_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_MODEL_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteVehicleModel = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_MODEL_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(
      `${apiURL}/api/vehicle-models/${id}`,
      config
    );

    dispatch({
      type: VEHICLE_MODEL_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_MODEL_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getVehicleModels = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_MODELS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/vehicle-models/?all=true`,
      config
    );

    dispatch({
      type: VEHICLE_MODELS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_MODELS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateVehicleModel = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_MODEL_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/vehicle-models/${id}`,
      body,
      config
    );

    dispatch({
      type: VEHICLE_MODEL_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_MODEL_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerVehicleModel = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_MODEL_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(
      `${apiURL}/api/vehicle-models/`,
      body,
      config
    );

    dispatch({
      type: VEHICLE_MODEL_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_MODEL_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

//Vehicle brands

export const getVehicleBrandDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_BRAND_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/vehicle-brands/${id}`,
      config
    );

    dispatch({
      type: VEHICLE_BRAND_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_BRAND_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteVehicleBrand = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_BRAND_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(
      `${apiURL}/api/vehicle-brands/${id}`,
      config
    );

    dispatch({
      type: VEHICLE_BRAND_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_BRAND_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getVehicleBrands = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_BRANDS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(
      `${apiURL}/api/vehicle-brands/?all=true`,
      config
    );

    dispatch({
      type: VEHICLE_BRANDS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_BRANDS_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateVehicleBrand = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_BRAND_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(
      `${apiURL}/api/vehicle-brands/${id}`,
      body,
      config
    );

    dispatch({
      type: VEHICLE_BRAND_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_BRAND_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerVehicleBrand = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_BRAND_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(
      `${apiURL}/api/vehicle-brands/`,
      body,
      config
    );

    dispatch({
      type: VEHICLE_BRAND_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_BRAND_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
